@import "../../../../scss/vars";
@import "../../../../scss/mixins";

/* START .technologies */
.technologies {

    @include responsiveMin(992px) {
        display: flex;
    }

    &__detail-info {

        @include responsiveMin(992px) {
            order: 2;
            flex: 0 0 50%;
            display: flex;
            overflow: hidden;
        }
    }

    &__list {
        background: #3E3E53;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid rgba(255, 255, 255, .05);

        @include responsiveMin(992px) {
            border: none;
            border-right: 1px solid rgba(255, 255, 255, .05);
            order: 1;
            flex: 0 0 50%;
        }
    }

    &__title {
        overflow: hidden;

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: .01;
            font-weight: 900;
            font-size: rem(36);
            line-height: rem(36);
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            text-align: center;

            @include responsiveMin(992px) {
                font-size: rem(72);
                line-height: rem(85);
            }
        }

        &-text {
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            text-align: center;
            width: 100%;
            height: 100%;
            font-weight: 900;
            font-size: rem($m_h2_font_size);
            line-height: rem($m_h2_line_height);
            color: #FFFFFF;
            margin: 0;

            @include responsiveMin(992px) {
                font-size: rem(36);
                line-height: rem(42);
            }
        }
    }

    &__item {
        flex: 0 0 50%;
        min-height: rem(180);
        position: relative;
        transition: all .3s ease-in-out;

        &:after {
            content: '';
            display: block;
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background: rgba(255, 255, 255, .05);
        }

        @include responsiveMin(992px) {
            min-height: rem(320);
            flex: 0 0 33.33333%;

            & * {
                transition: all .3s ease-in-out;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                border-bottom: 1px solid rgba(255, 255, 255, .05);
            }

            &:nth-child(3n+3) {
                &:after {
                    display: none;
                }
            }
        }

        @include responsiveMax(991px) {
            border-top: 1px solid rgba(255, 255, 255, .05);

            &:nth-child(2n+2) {
                &:after {
                    display: none;
                }
            }

            &:nth-child(1),
            &:nth-child(2) {
                border: none;
            }
        }

        &-inner {
            height: 100%;
        }

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: .02;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                max-width: 100%;
                max-height: rem(100);
                height: 100%;
            }

            @include responsiveMin(992px) {
                opacity: .01;
                padding: rem(10);

                svg {
                    max-height: 100%;
                    height: auto;
                }
            }
        }

        a {
            width: 100%;
            height: 100%;
            z-index: 2;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-decoration: none;
        }

        &-icon {

            svg {
                max-width: 100%;
                height: auto;
            }

            @include responsiveMin(992px) {
                width: auto;
            }

            @include responsiveMax(991px) {
                svg {
                    height: rem(50);
                }
            }
        }

        &-title {
            color: #fff;
            font-weight: bold;
            font-size: rem(14);
            line-height: rem(16);
            padding: rem(10) 0 0;
            text-align: center;
            position: relative;

            @include responsiveMin(992px) {
                padding: rem(20) 0 0 0;
                font-size: rem(18);
                line-height: rem(21);
                display: flex;
                align-items: center;
                position: static;
            }
        }

        &-link {
            display: none;

            @include responsiveMin(992px) {
                display: block;
                opacity: 0;
                text-align: center;
                height: 0;

                span {
                    color: #fff;
                }
            }
        }

        &--info-block {
            @include responsiveMin(992px) {

                &:hover {
                    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);

                    .technologies__item {

                        &-icon {
                            transform: translateY(rem(-10));
                        }

                        &-title {
                            transform: translateY(rem(10));
                        }
                    }
                }
            }
        }

        &--link {
            @include responsiveMin(992px) {

                &:hover {
                    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);

                    .technologies__item {

                        &-title,
                        &-icon {
                            transform: translateY(rem(-20));
                        }

                        &-link {
                            transform: translateY(rem(20));
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &-arrow {
            @include responsiveMin(991px) {
                display: inline;
                position: relative;
                margin-left: rem(10);

                i {
                    &:before {
                        font-size: rem(8);
                        display: block;
                        transform: rotate(-90deg);
                    }
                }
            }

            @include responsiveMax(991px) {
                display: block;
                position: absolute;
                bottom: rem(-12);
                left: 0;
                width: 100%;
                text-align: center;

                i {
                    &:before {
                        font-size: rem(6);
                        transition: transform .5s;
                        display: block;
                    }
                }
            }
        }

        &--active {
            box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);

            .technologies__item-arrow {
                i {
                    &:before {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }


    &__scheme {
        position: relative;
        overflow: hidden;
        background: #3E3E53;
        padding: rem(10) 0;

        @include responsiveMin(992px) {
            flex: 0 0 100%;
            padding: 0;
        }

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: .03;
            font-size: rem(64);
            font-weight: 900;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
            justify-content: center;

            @include responsiveMin(992px) {
                font-size: rem(195);
            }
        }

        &-inner {

            @include responsiveMin(992px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                z-index: 2;
                transition: all .6s ease-in-out;
                height: 100%;

                &--moved-right {
                    margin-left: rem(100);
                }
            }
        }

        &-img {
            width: 100%;
            height: 100%;
            position: relative;

            img {
                width: 100%;
            }

            @include responsiveMax(991px) {
                width: rem(640);
                margin: 0 auto;
            }
            @include responsiveMax(639px) {
                width: rem(320);
                margin: 0 auto;
            }
        }
    }

    &__description {
        display: none;

        @include responsiveMin(992px) {
            display: block;
            flex: 0;
            background: $services_description_bg;
            position: relative;
            overflow: hidden;
            transition: all .6s ease-in-out;

            &--opened {
                flex: 0 0 66.66666%;
            }

            &-bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: .02;
                font-size: rem(230);
                line-height: rem(230);
                font-weight: 900;
                display: flex;
                text-align: left;
                text-transform: uppercase;
                color: #FFFFFF;
                z-index: 1;
            }

            &-content {
                position: relative;
                z-index: 2;
                width: rem(640);
                height: 100%;
            }

            &-item {
                width: rem(400);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                opacity: 0;

                &.active {
                    opacity: 1;

                    .technologies {

                        &__description {

                            &-title {
                                transform: translateX(0);
                                opacity: 1;
                            }

                            &-text {
                                transform: translateX(0);
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            &-title {
                font-weight: bold;
                font-size: rem(24);
                line-height: rem(28);
                text-transform: uppercase;
                color: #FFFFFF;
                transition: all .5s ease-in-out;
                transform: translateX(-100%);
                opacity: 0;
            }

            &-text {
                font-size: rem($d_text_font_size);
                line-height: rem($d_text_line_height);
                color: #FFFFFF;
                padding-top: rem(5);
                transition: all .5s ease-in-out;
                transform: translateX(-100%);
                opacity: 0;

                p {
                    margin: rem(15) 0;
                }
            }
        }

        @include responsiveMax(991px) {
            display: block;
            background: #787A95;
            overflow: hidden;
            transition: all .4s;
            position: relative;

            &-item {
                text-align: center;
                width: 100%;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                padding: rem(30) rem(20);

                &--visible {
                    opacity: 1;
                }
            }

            &-title {
                font-weight: bold;
                font-size: rem($m_h3_font_size);
                line-height: rem($m_h3_line_height);
                text-align: center;
                text-transform: uppercase;
                color: #FFFFFF;
                margin-bottom: rem(10);
            }

            &-text {
                font-size: rem($m_text_font_size);
                line-height: rem($m_text_line_height);
                color: #FFFFFF;
                text-align: left;

                p {
                    margin-top: rem(10);

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

/* END .technologies */
