@import "../../../scss/vars";
@import "../../../scss/mixins";

/* START .cookie-opt-in */

.cookie-opt-in {
  .page__meta-description {
    span {
      font-weight: bold;
    }
  }
}

/* END .cookie-opt-in */