@import "../../../scss/vars";

@import "../../../scss/mixins";

.hosting__animation{
  max-width: rem(400);
  width: 100%;
  margin: 0 auto;

  @include responsiveMin(992px) {
    margin: auto;
    margin-left: rem(50);
    width: rem(590);
    max-width: none;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      height: rem(500);
    }
  }

    svg {
        width: 100%;
        height: auto;

          .blink {
              animation: visibleElem .5s ease-in-out  infinite;

              @for $i from 1 through 58 {
                .blink {
                  &:nth-child(#{$i}) {
                    animation-delay: $i * 5000ms;
                  }
                }
              }
            }
          }
        
    .grey {
      fill:none;
      stroke:#dddde4;
    }
}
