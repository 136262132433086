@import "../../../scss/vars";
@import "../../../scss/mixins";

.item__services-title {
    margin: rem(30) 0 rem(20);
    display: inline-flex;
    align-items: center;
    position: relative;
    padding-right: rem(10);

    @include responsiveMin(992px) {
        margin: rem(88) 0 rem(24);
    }

    img {
        margin-right: rem(10);
    }

    span {
        font-weight: bold;
        font-size: rem(14);
        line-height: rem(16);
        text-transform: uppercase;

        @include responsiveMin(992px) {
            font-size: rem(18);
            line-height: rem(21);
        }
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        height: rem(18);
        width: 100%;
        background: #3e3e53;
        opacity: 0.2;
        transform: translateY(-50%);
        top: 50%;

        @include responsiveMin(992px) {
            height: rem(22);
        }
    }
}
