@import "../../../../scss/vars";
@import "../../../../scss/mixins";

/* START .split-screen */
.split-screen {
    position: relative;

    .sk-cube-grid .sk-cube {
        background-color: #fff;
    }

    @include responsiveMin(992px) {
        height: rem(640);
    }

    &.transition {
        & * {
            transition: all .5s ease-in-out;
        }
    }

    &__bg-elements {

        @include responsiveMin(992px) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        @include responsiveMax(992px) {
            display: none;
        }
    }

    &__container {
        position: relative;
        width: 100%;
        z-index: 2;
        background: #fff;

        @include responsiveMin(992px) {
            background: transparent;
            align-items: center;
            display: flex;
            height: 100%;
        }
    }

    &__item {
        padding: rem(30) rem(20);

        @include responsiveMin(992px) {
            padding: 0;
            text-align: left;
            flex: 0 0 50%;
            max-width: 50%;
        }

        &--left-col {

            @include responsiveMin(992px) {
                padding: 0 rem(360) 0 rem(200);
            }

            @include responsiveMax(992px) {
                background: #FF6826;
                padding-bottom: rem(107);

                .split-screen {
                    &__item {
                        &-title,
                        &-description{
                            color: #fff;
                        }
                    }
                }
            }
        }

        &--right-col {

            @include responsiveMin(992px) {
                padding: 0 rem(200) 0 rem(360);
            }

            .split-screen {
                &__item {
                    &-title {
                        color: $services_list_bg;
                    }

                    &-description {
                        color: $services_list_bg;
                    }
                }
            }
        }

        &-body {
            @include responsiveMin(992px) {
                text-align: left;
            }
        }

        &-title {
            color: $services_list_bg;
            font-size: rem($m_h3_font_size);
            line-height: rem($m_h3_line_height);
            text-transform: uppercase;
            margin-bottom: rem(10);
            text-align: center;

            @include responsiveMin(992px) {
                font-size: rem(36);
                line-height: rem(42);
                font-weight: 900;
                margin-bottom: rem(20);
                color: #fff;
                text-align: left;
            }
        }

        &-description {
            padding: 0;
            font-size: rem($m_text_font_size);
            line-height: rem($m_text_line_height);
            color: #767994;

            @include responsiveMin(992px) {
                padding: rem(6) 0 rem(16);
                font-size: rem($d_text_font_size);
                line-height: rem($d_text_line_height);
                color: #FFF;
            }

            p {
                margin: rem(10) 0;

                @include responsiveMin(992px) {
                    margin: 1rem 0;
                }

                &:first-child {
                     margin: 0;
                }
            }
        }

        &-link {
            display: none;

            @include responsiveMin(992px) {
                color: #fff;
                display: inline-block;
            }
        }

        &-image {
            display: none;

            @include responsiveMax(991px) {
                display: block;
                max-width: rem(230);
                margin: rem(-107) auto rem(30);

                img {
                    width: 100%;
                    display: block;
                }
            }
        }
    }

    // Background block

    &__wrapper {
        overflow: hidden;

        @include responsiveMax(992px) {
            flex: 0 0 50%;
        }

        @include responsiveMin(992px) {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
        }

        &--before {
            left: 0;
            z-index: 2;
            width: 50%;

            .split-screen {

                &__content {
                    background: #FF6826;
                }

                &__image {

                    @include responsiveMax(992px) {
                        transform: translateX(50%);
                    }
                }
            }
        }

        &--after {
            right: 0;
            z-index: 1;

            .split-screen {

                &__image {

                    @include responsiveMax(992px) {
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        padding: 9% 0 6%;

        @include responsiveMin(992px) {
            width: 100vw;
            height: 100%;
        }

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 83%;
            height: 62%;
            top: 0;
            left: 0;
            z-index: 2;

            @include responsiveMin(992px) {
                width: 100%;
                height: 100%;
            }
        }

    }

    &__image {
        z-index: 3;
        position: relative;

        @include responsiveMin(992px) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: rem(470);
            display: flex;
            align-items: center;
            transform-style: preserve-3d;
            perspective: rem(470);
        }

        img {
            width: 100%;
            display: block;
            margin-left: .8%;

            @include responsiveMin(992px) {
                margin-left: rem(-4);
            }
        }
    }

    &__top-bg,
    &__bottom-bg {
        display: none;

        @include responsiveMin(992px) {
            display: block;
            position: absolute;
            z-index: -1;

            &:before,
            &:after {
                position: absolute;
                z-index: -1;
                display: block;
                content: "";
            }

            &:before {
                width: rem(800);
                height: rem(200);
                background: url("../../../../images/bg-el-1-white.svg") no-repeat;
                background-size: contain;
                opacity: 0.03;
            }

            &:after {
                width: rem(600);
                height: rem(500);
                background: url("../../../../images/bg-el-2-white.svg") no-repeat;
                background-size: contain;
                opacity: 0.04;
            }
        }
    }

    &__top-bg {
        @include responsiveMin(992px) {
            top: 0;
            left: 0;
        }
    }

    &__bottom-bg {
        @include responsiveMin(992px) {
            bottom: 0;
            right: 0;
            transform: rotate(180deg);

            &:before {
                background: url("../../../../images/bg-el-1-dark.svg") no-repeat;
                background-size: contain;
            }

            &:after {
                background: url("../../../../images/bg-el-2-dark.svg") no-repeat;
                background-size: contain;
            }
        }
    }

    &__bg-left-gears,
    &__bg-right-gears {
        display: none;

        @include responsiveMin(992px) {
            display: block;
            position: absolute;
            z-index: -1;

            &:before {
                position: absolute;
                z-index: -1;
                display: block;
                content: "";
                opacity: 0.03;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__bg-left-gears {

        @include responsiveMin(992px) {
            left: rem(310);
            bottom: 0;
            width: rem(350);
            height: rem(400);

            &:before {
                background: url("../../../../images/gears-left-white.svg") no-repeat;
                background-size: contain;
            }
        }
    }

    &__bg-right-gears {

        @include responsiveMin(992px) {
            top: rem(50);
            right: rem(370);
            width: rem(300);
            height: rem(350);

            &:before {
                background: url("../../../../images/gears-right-dark.svg") no-repeat;
                background-size: contain;
            }
        }
    }
}

/* END .split-screen */