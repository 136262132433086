@import "../../../../scss/vars";
@import "../../../../scss/mixins";

/* START .contact-us */
.contact-us {
  padding: rem(150) 0 rem(50);

  @include responsiveMax(991px) {
    padding: rem(33) 0 0;
  }

  &__title {
    position: relative;
    margin-bottom: rem(100);
    padding-left: rem(150);

    @include responsiveMax(991px) {
      margin: 0 0 rem(80);
      padding: 0 rem(20);
    }

    @include responsiveMax(767px) {
      margin: 0 0 rem(30);
    }

    &-text {
      font-weight: 900;
      font-size: rem(48);
      line-height: rem(56);
      text-transform: uppercase;
      color: #3E3E53;
    }

    @include responsiveMax(991px) {
      &-text {
        font-size: rem(36);
        line-height: rem(42);
        text-align: center;
      }
    }

    @include responsiveMax(767px) {
      &-text {
        font-size: rem($m_h2_font_size);
        line-height: rem($m_h2_line_height);
      }
    }
  }

  &__advantages {
    padding: 0 rem(20);

    @include responsiveMin(992px) {
      display: flex;
      align-items: center;
      padding: 0 0 0 rem(150);
    }
  }

  &__advantage {
    position: relative;

    @include responsiveMin(992px) {
      margin-right: rem(60);
      padding-right: rem(220);
    }

    &-title {
      font-weight: bold;
      font-size: rem(24);
      line-height: rem(28);
      color: #3E3E53;

      @include responsiveMax(991px) {
        font-size: rem(14);
        line-height: rem(16);
      }
    }

    &-subtitle {
      position: relative;
      padding-bottom: rem(56);

      span {
        font-weight: 900;
        font-size: rem(48);
        line-height: rem(56);
        text-transform: uppercase;
        position: relative;

        &:before {
          display: block;
          content: '';
          position: absolute;
          left: 100%;
          margin-left: rem(30);
          top: rem(25);
          height: rem(5);
          width: rem(150);
          z-index: 1;
          background: yellow;
        }
      }

      @include responsiveMax(991px) {
        padding-bottom: rem(30);

        span {
          font-size: rem(20);
          line-height: rem(23);
          position: relative;

          &:before {
            margin-left: rem(30);
            top: rem(9);
            height: rem(4);
            width: rem(75);
          }
        }
      }
    }

    &-bg {
      font-weight: 900;
      font-size: rem(96);
      line-height: rem(113);
      text-transform: uppercase;
      opacity: 0.06;
      position: absolute;
      top: 0;
      left: 0;

      @include responsiveMax(991px) {
        font-size: rem(46);
        line-height: rem(56);
      }
    }

    &:nth-child(1) {
      .contact-us {
        &__advantage {
          &-subtitle {
            span{
              &:before {
                background: #FFBB50;
              }
            }
          }

          &-subtitle,
          &-bg {
            color: #FFBB50;
          }
        }
      }
    }

    &:nth-child(2) {
      .contact-us {
        &__advantage {

          &-subtitle {
            span {
              &:before {
                background: #F599B9;
              }
            }
          }

          &-subtitle,
          &-bg {
            color: #F599B9;
          }
        }
      }
    }

    &:nth-child(3) {
      margin-right: 0;

      .contact-us {
        &__advantage {

          &-subtitle {
            span {
              &:before {
                background: #FC6076;
              }
            }
          }

          &-subtitle,
          &-bg {
            color: #FC6076;
          }

        }
      }
    }
  }

  &__bg {
    margin-top: rem(30);
    text-align: right;
    position: relative;

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: #3E3E53;
      opacity: 0.05;
      height: rem(10);
      width: 100%;
      z-index: 1;
    }

    &-text {
      display: inline-block;
      padding-left: rem(100);
      position: relative;
      z-index: 2;
      background: #fff;

      span {
        font-weight: 900;
        font-size: rem(96);
        line-height: rem(113);
        text-transform: uppercase;
        color: #3E3E53;
        opacity: 0.05;
      }
    }

    @include responsiveMax(991px) {
      margin: rem(20) 0 rem(30);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:before {
        position: relative;
        left: auto;
        top: auto;
        transform: none;
        width: rem(75);
        height: rem(5);
        margin: 0 auto;
      }

      &-text {
        display: block;
        padding: rem(20) 0 0 0;

        span {
          font-size: rem(48);
          line-height: rem(48);
          text-align: center;
          display: block;
        }
      }
    }
  }

}

/* END .contact-us */
