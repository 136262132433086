@import "../../../scss/vars";
@import "../../../scss/mixins";

.web-design-page {

    .services {
        background: #3e3e53;
        color: #ffffff;

        &__wrapper {
            display: flex;
            flex-wrap: wrap;

            @include responsiveMin(992px) {
                width: 100%;
                flex-wrap: nowrap;
            }
        }

        &__items {
            flex-basis: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid hsla(0, 0%, 100%, 0.05);
            border-left: 1px solid hsla(0, 0%, 100%, 0.05);
            position: relative;
            padding: rem(43) 0;
            transition: all 0.3s linear;

            &:hover {
                box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
            }
            &:hover .services__items_icon {
                transform: translateY(rem(-5));
            }
            &:hover .services__items_title {
                transform: translateY(rem(5));
            }

            &_bg {
                position: absolute;
                max-width: rem(188);
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-repeat: no-repeat;
                background-size: 70%;
                background-position: center;
                opacity: 0.02;

                @include responsiveMin(992px) {
                    background-size: 100%;
                    left: 20%;
                }
            }

            @include responsiveMin(992px) {
                flex-basis: 18%;
                padding: rem(100) 0;
            }

            &_icon {
                padding-bottom: rem(10);
                display: flex;
                justify-content: center;
                transition: all 0.3s linear;

                @include responsiveMin(992px) {
                    padding-bottom: rem(20);
                }
                img {
                    max-width: rem(50);
                    @include responsiveMin(992px) {
                        max-width: rem(70);
                    }
                }
            }

            &_title {
                color: #ffffff;
                white-space: nowrap;
                font-weight: bold;
                font-size: rem(14);
                transition: all 0.3s linear;
                text-transform: none;

                @include responsiveMin(992px) {
                    font-size: rem(24);
                    line-height: rem(28);
                }
            }

            &-bg {
                position: relative;
                display: block;
                max-width: rem(100);
                opacity: 0.02;
            }
        }

        &__title-design {
            font-size: rem(20);
            font-weight: 900;
            font-feature-settings: "pnum" on, "lnum" on;
            text-align: center;
            max-width: rem(88);

            @include responsiveMin(992px) {
                font-size: rem(36);
                max-width: rem(158);
            }
        }

        .services__title_bg {
            position: absolute;
            font-size: rem(36);
            line-height: 100%;
            font-weight: 900;
            opacity: 0.02;
            text-align: center;
            max-width: rem(158);

            @include responsiveMin(992px) {
                font-size: rem(64);
                max-width: rem(280);
            }
        }
    }

}