@mixin responsiveMin($size) {
    @media (min-width: $size) { @content; }
}

@mixin responsiveMax($size) {
    @media (max-width: $size) { @content; }
}

@function rem($size) {
    @return $size / $root-font-size * 1rem;
}

@mixin when-inside($context) {
    #{$context} & {
        @content;
    }
}