@import '../../scss/vars.scss';
@import '../../scss/mixins.scss';

.wordpress {
  &__content {
    &_projects {
      padding-left: rem(20);
      padding-right: rem(20);
      margin-bottom: rem(50);

      @include responsiveMin(992px) {
        margin-bottom: rem(130);
        padding-left: rem(220);
        padding-right: rem(220);
      }

      &-wrapper {
        @include responsiveMin(992px) {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          row-gap: rem(50);
          column-gap: 3.5%;
        }
      }

      &-title {
        font-style: normal;
        font-weight: 900;
        font-size: rem(20);
        line-height: rem(23);
        color: #181C2D;
        margin-bottom: rem(30);

        @include responsiveMin(992px) {
          font-size: rem(36);
          line-height: rem(42);
          margin-bottom: rem(50);
        }
      }

      .projects-card {
        width: 100%;
        text-decoration: none;
        background: #fff;
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
        margin-bottom: 30px;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include responsiveMin(992px) {
          width: 31%;
          margin-bottom: 0;

          &:first-of-type {
            margin-left: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }

        &.hidden {
          display: none;
        }

        &--inner {
          display: block;
          position: relative;
          height: 100%;
          background: #fff;
          box-shadow: 0px 0px rem(50) rgba(0, 0, 0, 0.2);
          text-decoration: none;
          z-index: 1;
        }

        &__top {
          position: relative;


          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            backdrop-filter: blur(2px);
            z-index: 1;

            @include responsiveMin(1920px) {
              backdrop-filter: blur(rem(2));
            }
          }
        }

        &__img {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          height: rem(207);
          object-fit: cover;
          z-index: 0;

          @include responsiveMin(992px) {
            height: rem(356);
          }
        }

        &__logo {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-height: rem(140);
          margin: 0 auto;
          z-index: 2;

          @include responsiveMin(992px) {
            max-height: rem(180);
            min-width: rem(186);
          }

          img {
            height: 100%;
            width: 100%;
            max-height: rem(140);

            @include responsiveMin(992px) {
              max-height: rem(180);
              min-width: rem(186);
            }
          }
        }

        &__wrap {
          padding: rem(20) rem(20);
          @include responsiveMin(992px) {
            padding: rem(20);
          }
        }

        &__bottom {
          justify-content: space-between;
          display: flex;

          @include responsiveMin(700px) {
            min-height: rem(202);
          }

          @include responsiveMin(992px) {
            flex-direction: column;
          }
        }

        &__text-container {
          color: #fff;
          margin-bottom: rem(20);

          @include responsiveMin(992px) {
            margin-bottom: rem(15);
          }
        }

        &__text {
          display: flex;
          margin-bottom: rem(10);

          &:last-of-type {
            margin-bottom: 0;
          }

          p {
            font-size: rem(16);
            line-height: 150%;
            color: #353540;
            word-break: break-word;
          }

          svg {
            display: block;
            min-width: rem(24);
            min-height: rem(24);
            max-width: rem(24);
            max-height: rem(24);
            margin-right: rem(5);
          }
        }

        &__title {
          font-weight: bold;
          font-size: rem(18);
          line-height: 100%;
          margin-bottom: rem(15);
        }

        &__btn {
          display: block;
          width: fit-content;
          margin-left: auto;
          margin-right: 0;
        }

        &--end {
          flex: 1;

          .projects-card--inner {
            padding-top: rem(125);
            padding-bottom: rem(125);
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #3e3e53;
            overflow: hidden;
            box-shadow: none;
            height: 100%;
            text-decoration: none;

            @include responsiveMin(992px) {
              padding-top: rem(186);
              padding-bottom: rem(186);
            }
          }

          &__title {
            font-weight: 900;
            font-size: rem(20);
            line-height: rem(23);
            color: #ffffff;
            text-transform: uppercase;
            position: relative;
            max-width: rem(101);

            @include responsiveMin(992px) {
              font-size: rem(36);
              line-height: rem(42);
              max-width: rem(182);
            }
          }

          &__bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 900;
            font-size: rem(63);
            line-height: 1;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            opacity: 0.01;

            @include responsiveMin(992px) {
              font-size: rem(96);
            }
          }
        }

        .projects-card__btn {
          display: block;
          position: relative;
          width: fit-content;
          margin-left: auto;
          margin-right: 0;
          text-decoration: none;

          &_text {
            font-weight: bold;
            font-size: rem(14);
            line-height: rem(16);
            color: #3E3E53;
            padding: 0 rem(10);
            position: relative;

            &:before {
              content: "";
              position: absolute;
              width: rem(5);
              height: rem(17);
              background: #3e3e53;
              opacity: 0.2;
              left: 0;
              transition: all 0.5s ease;
              transform: translateY(-50%);
              top: 50%;

              @include responsiveMin(992px) {
                height: rem(22);
              }
            }

            @include responsiveMin(992px) {
              font-size: rem(18);
              line-height: rem(21);
            }
          }
        }
      }
    }
  }
}