@import "../../../scss/vars.scss";
@import "../../../scss/mixins.scss";

.blog-page-loader {
    height: 100vh;
    background: #3e3e53;
    
    .sk-cube-grid .sk-cube {
        background-color: #ffffff;
    }
}

.post-date {
    font-size: rem(16);
    line-height: rem(19);
    margin: 0 auto rem(40);

    span {
        background: #767994;
        padding: 0 rem(10);
    }

    @include responsiveMin(992px) {
        font-size: rem(24);
        line-height: rem(28);
        margin: 0 0 rem(16) 0;
    }
}
