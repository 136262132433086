@import "scss/vars";
@import "scss/mixins";

body {
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;

    &::-webkit-scrollbar {
        width: 10px;

        @include responsiveMin(1920px) {
            width: rem(10);
        }
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: #FFF;

        @include responsiveMin(1920px) {
            box-shadow: inset 0 0 rem(6) rgba(0, 0, 0, 0.3);
        }
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 4px;

        @include responsiveMin(1920px) {
            border-radius: rem(4);
        }
    }

    &.menu-is-open {
        overflow: hidden !important;
        padding-right: 10px;

        @include responsiveMin(1920px) {
            padding-right: rem(10);
        }
    }

    &.overflowHidden {
        @include responsiveMin(992px) {
            padding-right: 10px;
            margin-right: 0 !important;
        }

        @include responsiveMin(1920px) {
            padding-right: rem(10);
        }

        .page__nav {
            @include responsiveMin(992px) {
                margin-right: 10px;
            }

            @include responsiveMin(1920px) {
                margin-right: rem(10);
            }
        }
    }

    &.menu-is-open .footer__btn {
        right: 10px;
        opacity: 0;

        @include responsiveMin(1920px) {
            right: rem(10);
        }
    }

    @include responsiveMax(991px) {
        &.menu-is-open {
            padding-right: 0;
        }

        &.menu-is-open .footer__btn {
            right: 0;
        }
    }

    &.menu-is-open .page__content {
        filter: blur(2px);
    }

    &.scrolling {
        @include responsiveMin(992px) {
            overflow: hidden !important;
            padding-right: 10px;
        }

        @include responsiveMin(1920px) {
            overflow: hidden !important;
            padding-right: rem(10);
        }
    }
}

.fslightbox-open {
    body {
        padding-right: 10px;
        margin-right: 0 !important;

        @include responsiveMin(1920px) {
            padding-right: rem(10);
        }
    }

    .page__nav {
        @include responsiveMin(992px) {
            margin-right: rem(15);
        }
    }
}

.base-btn {
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0 rem(9);
    font-weight: bold;
    font-size: rem(14);
    line-height: rem(16);
    transform: none;
    text-transform: uppercase;
    display: inline-block;

    @include responsiveMin(992px) {
        font-size: rem(18);
        line-height: rem(21);
        padding: 0 rem(10) rem(1);
    }

    &:before {
        content: '';
        display: block;
        height: 100%;
        width: rem(4);
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        opacity: 0.2;
        transition: all .3s;

        @include responsiveMin(992px) {
            width: rem(5);
        }
    }

    &:hover {
        &:before {
            width: 100%;
        }
    }

    &--dark {
        color: #353540;

        &::before {
            background: #353540;
        }
    }
}

.page {
    position: relative;
    overflow: hidden;

    &__title {
        position: relative;
        text-align: center;

        &-text {
            font-weight: 900;
            color: #3E3E53;
            font-size: rem($m_h1_font_size);
            line-height: rem($m_h1_line_height);
            text-transform: uppercase;

            @include responsiveMin(992px) {
                font-size: rem($d_h1_font_size);
                line-height: rem($d_h1_line_height);
            }
        }

        &-bg {
            display: none;

            @include responsiveMin(992px) {
                white-space: nowrap;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                display: flex;
                justify-content: center;
                text-transform: uppercase;
                font-weight: 900;
                font-size: rem(96);
                line-height: rem(113);
                color: #3E3E53;
                opacity: 0.03;
            }
        }
    }

    &__meta {
        position: relative;

        &-content {
            width: 100%;
            padding: rem(30) rem(20);

            @include responsiveMin(992px) {
                padding: rem(173) rem(20);
                max-width: rem(1210);
                margin: 0 auto;
            }
        }

        &-subtitle {
            padding-top: 10px;
            font-weight: 900;
            font-size: rem($m_h2_font_size);
            line-height: rem($m_h2_line_height);
            color: #767994;
            text-align: center;

            @include responsiveMin(992px) {
                padding-top: 20px;
                font-size: rem($d_h2_font_size);
                line-height: rem($d_h2_line_height);
                white-space: nowrap;
                display: flex;
                justify-content: center;
            }
        }

        &-description {
            font-size: rem($m_text_font_size);
            line-height: rem($m_text_line_height);
            color: #3E3E53;
            padding-top: rem(10);

            p {
                margin-top: 10px;

                &:first-child {
                    margin-top: 0;
                }
            }

            @include responsiveMin(992px) {
                padding-top: rem(50);
                font-size: rem($d_text_font_size);
                line-height: rem($d_text_line_height);
                width: rem(800);
                margin: 0 auto;
            }
        }

        &-bg {
            display: none;

            @include responsiveMin(992px) {
                display: block;
                position: absolute;
                z-index: -1;

                &:before {
                    position: absolute;
                    z-index: -1;
                    display: block;
                    content: "";
                }
            }

            &--top {
                top: 0;
                left: 0;

                @include responsiveMin(992px) {
                    &:before {
                        width: rem(800);
                        height: rem(200);
                        background: url("./images/bg-el-1-dark.svg") no-repeat;
                        background-size: contain;
                        opacity: 0.03;
                    }
                }
            }

            &--bottom {
                bottom: 0;
                right: 0;
                transform: rotate(180deg);

                @include responsiveMin(992px) {
                    &:before {
                        width: rem(420);
                        height: rem(350);
                        background: url("./images/bg-el-2-dark.svg") no-repeat;
                        background-size: contain;
                        opacity: 0.04;
                    }
                }
            }
        }
    }
}

.loading-block {
    opacity: 0;
    animation-name: sha;
    animation-fill-mode: forwards;
    animation-duration: .3s;

    &--1 {
        animation-delay: inherit;
    }

    &--2 {
        animation-delay: .6s;
    }

    &--3 {
        animation-delay: 1.2s;
    }

    &--4 {
        animation-delay: 1.8s;
    }
}

@keyframes sha {
    from { opacity: 0; }
    to { opacity: 1; }
}
