@import "../../scss/vars";
@import "../../scss/mixins";

/* START .start-banner */
.banner-image {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding-top: rem(100);

    @include responsiveMax(991px) {
        right: 0 !important;
        padding: rem(60) rem(20) 0;
        width: 50%;

        img {
            opacity: 0.1;
        }
    }

    @include responsiveMax(767px) {
        right: 0 !important;
        width: 100%;
    }

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }
}

.banner-title {
    width: 50%;
    font-size: inherit;

    @include responsiveMax(991px) {
        width: 100%;
        padding-top: rem(120);
    }

    &__text-wrap {
        display: block;

        &--left-position {
            margin-bottom: rem(10);

            @include responsiveMin(992px) {
                padding-left: rem(100);
                transform: translateX(rem(50));
                opacity: 0;
                margin-bottom: rem(30);
            }
        }

        &--right-position {
            text-align: right;
            position: relative;
            padding-left: rem(100);

            @include responsiveMin(992px) {
                padding-left: rem(350);
            }

            .banner-title {
                &__right {
                    position: relative;
                    display: inline-block;
                }

                &__text {
                    transform: translateX(rem(-50));
                    opacity: 0;
                }
            }
        }
    }

    &__text {
        text-transform: uppercase;
        font-weight: 900;
        font-size: rem($m_h1_font_size);
        line-height: rem($m_h1_line_height);
        color: #FFFFFF;
        padding: 0 rem(20);
        display: inline-block;

        &--opacity {
            opacity: .3;
        }

        @include responsiveMin(992px) {
            font-size: rem(36);
            line-height: rem(42);
            padding: 0;
        }
    }

    &__separator {
        display: block;
        position: absolute;
        left: rem(-60);
        top: 50%;
        height: rem(4);
        width: rem(50);
        margin-top: rem(-2);

        @include responsiveMin(992px) {
            width: rem(150);
            left: rem(-250);
        }

        &:before {
            content: '';
            display: block;
            background: #fff;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            margin: auto;
            height: 100%;
            width: 0;
            transform: scale(0);
        }
    }
}

.swiper-slide-active {
    .banner-title {
        &__text-wrap {
            &--left-position {
                animation: top-text-show 2s ease-in-out forwards, top-text-hide 1s 5s ease-in-out forwards;
            }

            &--right-position {
                .banner-title__text {
                    animation: bottom-text-show 1.5s 1s ease-in-out forwards, bottom-text-hide 1.5s 5.5s ease-in-out forwards;
                }
            }
        }

        &__separator {
            &:before {
                animation: separator-hide .3s 2.3s ease-in-out reverse forwards, separator-hide .5s 4.5s ease-in-out forwards;
            }
        }
    }
}

@keyframes top-text-show {
    0% {
        transform: translateX(rem(50));
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes top-text-hide {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(rem(-50));
        opacity: 0;
    }
}

@keyframes bottom-text-show {
    0% {
        transform: translateX(rem(-50));
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes bottom-text-hide {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(rem(50));
        opacity: 0;
    }
}

@keyframes separator-hide {
    0% {
        width: 100%;
        transform: scale(1);
    }

    100% {
        width: 0;
        transform: scale(0);
    }
}

/* END .start-banner */


/* START .partners-logos */
.partners-logos {
    display: none;

    @include responsiveMin(992px) {
        left: 0;
        bottom: rem(100);
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        &__item {
            padding: 0 rem(24);
            opacity: .7;

            img {
                display: block;
                max-width: 100%;
            }
        }

    }
}

/* END .partners-logos */


/* START .scroll-down */
.scroll-down {
    @include responsiveMin(992px) {
        position: absolute;
        width: fit-content;
        left: 50%;
        transform: translateX(-50%);
        bottom: rem(30);
    }

    &__btn {
        display: block;
        margin: 0 auto;
        width: rem(20);
        height: rem(30);
        border: rem(2) solid #fff;
        border-radius: rem(22);
        position: relative;
        cursor: pointer;

        &-i {
            display: block;
            width: rem(10);
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: rem(6);
            animation: nudgeMouse 2.4s ease-out 0s infinite normal;

            svg {
                width: 100%;
                height: rem(33);
            }
        }
    }
}

@keyframes nudgeMouse {
    0% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(0.33333rem);
    }

    60% {
        transform: translateY(0.33333rem);
    }

    100% {
        transform: translateY(0px);
    }
}

/* END .scroll-down */

.start-banner {
    position: relative;
    z-index: 1;
    padding: 0 0 rem(30);
    overflow: hidden;
    min-height: rem(400);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .data-preloader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .sk-cube-grid .sk-cube {
        background-color: #fff;
    }

    @include responsiveMin(992px) {
        flex-direction: row;
        align-items: center;
        height: 95vh;
        max-height: rem(1000);
        justify-content: space-between;
        padding: rem(135) 0 rem(80);
    }

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    &:before {
        background: $home_start_banner_gradient;
        transform: rotate(-180deg);
    }

    &__slider {
        width: 100%;
    }

    &--home {
        .banner-image {
            @include responsiveMin(992px) {
                max-width: rem(900);
                right: rem(30);
            }
        }

        .scroll-down {
            padding-top: rem(32);

            @include responsiveMax(991px) {
                padding-top: rem(10);
            }
        }
    }

    &--it-betreuung {
        &:before {
            background: #FC6076;
        }

        .banner-image {
            @include responsiveMin(992px) {
                max-width: rem(740);
                right: rem(100);
            }
        }

        .banner-title {
            @include responsiveMax(991px) {
                margin-bottom: rem(20);
            }

            &__text-wrap {
                &--right-position {
                    .banner-title__text {
                        font-size: rem(14);
                        line-height: rem(16);
                        font-weight: bold;
                        text-transform: none;

                        @include responsiveMin(992px) {
                            font-size: rem(24);
                            line-height: rem(31);
                        }
                    }
                }
            }
        }
    }

    &--software {
        &:before {
            background: #F599B9;
        }

        .banner-image {
            @include responsiveMin(992px) {
                max-width: rem(736);
                right: rem(100);
            }
        }

        .banner-title {
            @include responsiveMax(991px) {
                margin-bottom: rem(20);
            }

            &__text-wrap {
                &--right-position {
                    .banner-title__text {
                        font-size: rem(14);
                        line-height: rem(16);
                        font-weight: bold;
                        text-transform: none;
                        color: #fff;

                        @include responsiveMin(992px) {
                            font-size: rem(24);
                            line-height: rem(31);
                        }

                        @include responsiveMax(767px) {
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &--hosting {
        &:before {
            background: #fff;
        }

        .banner-image {
            @include responsiveMin(992px) {
                max-width: rem(354);
                right: rem(360);
            }
        }

        .banner-title {
            @include responsiveMax(991px) {
                margin-bottom: rem(20);
            }

            &__text-wrap {
                &--right-position {
                    .banner-title__text {
                        font-size: rem(14);
                        line-height: rem(16);
                        font-weight: bold;
                        text-transform: none;
                        color: #3E3E53;

                        @include responsiveMin(992px) {
                            font-size: rem(24);
                            line-height: rem(31);
                        }

                        @include responsiveMax(767px) {
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }

            &__text {
                color: #787A95;

                &--opacity {
                    opacity: 1;
                }
            }

            &__separator {
                &:before {
                    background: #3E3E53;
                }
            }
        }

        .scroll-down {
            &__btn {
                border: rem(2) solid #3E3E53;

                &-i {
                    svg {
                        width: 100%;
                        height: rem(33);

                        path {
                            stroke: #3E3E53;
                        }
                    }
                }
            }
        }
    }

    &--referenzen {
        @include responsiveMin(992px) {
            height: 75vh;
        }

        &:before {
            background: $referenzen_banner_gradient;
        }

        .banner-image {
            @include responsiveMin(992px) {
                max-width: rem(700);
                right: rem(140);
            }
        }

        .banner-title {
            @include responsiveMax(991px) {
                margin-bottom: rem(20);
            }

            &__text-wrap {
                &--right-position {
                    .banner-title__text {
                        font-size: rem(14);
                        line-height: rem(16);
                        text-transform: none;
                        font-weight: bold;

                        @include responsiveMin(992px) {
                            font-size: rem(24);
                            line-height: rem(31);
                        }
                    }
                }
            }
        }

        .scroll-down {
            @include responsiveMin(992px) {
                display: none;
            }
        }
    }

    &--cookieOptIn {
        &:before {
            background: #FF6826;
        }

        .banner-image {
            &__back-side,
            &__front-side {
                right: rem(0);
                padding-top: rem(50);
            }

            &__front-side {
                right: rem(418);
                padding-top: rem(210);
            }
        }

        .banner-title {
            @include responsiveMax(991px) {
                margin-bottom: rem(20);
            }

            &__text-wrap {
                &--right-position {
                    padding-left: rem(320);

                    @include responsiveMax(991px) {
                        padding-left: rem(80);
                    }

                    .banner-title__text {
                        font-size: rem(14);
                        line-height: rem(16);
                        text-transform: none;
                        font-weight: bold;

                        @include responsiveMin(992px) {
                            font-size: rem(24);
                            line-height: rem(31);
                        }

                        @include responsiveMax(767px) {
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &--karriere {
        &:before {
            background: $it_betreuung_banner_gradient;
        }

        .banner-image {
            &__back-side,
            &__front-side {
                right: rem(67);
                padding-top: 0;
                justify-content: flex-start;
            }

            &__front-side {
                right: rem(362);
            }
        }

        .banner-title {
            @include responsiveMax(991px) {
                margin-bottom: rem(47);
            }

            &__text-wrap {
                &--left-position {
                    padding-bottom: rem(60);

                    @include responsiveMax(991px) {
                        padding-bottom: 0;

                        .banner-title__text {
                            font-size: rem(22);
                            line-height: rem(26);

                            br {
                                display: none;
                            }
                        }
                    }
                }

                &--right-position {
                    .banner-title__text {
                        font-size: rem(16);
                        line-height: rem(21);
                        font-weight: bold;
                        text-transform: none;

                        @include responsiveMin(992px) {
                            font-size: rem(24);
                            line-height: rem(31);
                        }
                    }
                }
            }
        }
    }

    &--eCommerce {
        &:before {
            background: #3E3E53;
        }

        .banner-image {            
            @include responsiveMin(992px) {
                max-width: rem(742);
                right: rem(100);
                padding-top: rem(60);

            }
        }

        .banner-title {
            @include responsiveMax(991px) {
                margin-bottom: rem(20);
            }

            &__text-wrap {
                &--right-position {
                    padding-left: rem(420);

                    @include responsiveMax(991px) {
                        padding-left: rem(80);
                    }

                    .banner-title__text {
                        font-size: rem(14);
                        line-height: rem(16);
                        font-weight: bold;
                        text-transform: none;
                        color: #fff;

                        @include responsiveMin(992px) {
                            font-size: rem(24);
                            line-height: rem(31);
                        }

                        @include responsiveMax(767px) {
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &--corporateWebsites {
        &:before {
            background: #3E3E53;
        }

        .banner-image {
            @include responsiveMin(991px) {
                width: rem(630);
                right: rem(160);
            }
        }

        .banner-title {
            @include responsiveMax(991px) {
                margin-bottom: rem(20);
            }

            &__text-wrap {
                &--right-position {
                    padding-left: rem(420);

                    @include responsiveMax(991px) {
                        padding-left: rem(100);
                    }

                    .banner-title__text {
                        font-size: rem(14);
                        line-height: rem(16);
                        font-weight: bold;
                        text-transform: none;
                        color: #fff;

                        @include responsiveMin(992px) {
                            font-size: rem(24);
                            line-height: rem(28);
                        }

                        @include responsiveMax(767px) {
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &--webDesign {
        &:before {
            background: $main-gradient;
        }

        .banner-image {
            @include responsiveMin(992px) {
                max-width: rem(735);
                right: rem(160);
                padding: rem(100) 0;
            }
        }

        .banner-title {
            @include responsiveMax(991px) {
                margin-bottom: rem(20);
            }

            &__text-wrap {
                &--right-position {
                    .banner-title__text {
                        font-size: rem(14);
                        line-height: rem(16);
                        font-weight: bold;
                        text-transform: none;
                        color: #fff;

                        @include responsiveMin(992px) {
                            font-size: rem(24);
                            line-height: rem(31);
                        }

                        @include responsiveMax(767px) {
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &--cookieBannerIntegration {
        &:before {
            background: #3E3E53;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: rem(352);
            height: rem(224);
            background-image: url('../../images/alkima-usercentrics.svg');
            background-size: contain;
            background-repeat: no-repeat;

            @include responsiveMin(992px) {
                width: rem(572);
                height: rem(362);
                background-image: url('../../images/alkima-usercentrics.svg');
            }
        }

        .banner-image {
            @include responsiveMax(767px) {
                width: rem(256);
                left: rem(-40);
            }

            @include responsiveMin(991px) {
                width: rem(570);
                right: rem(270);
            }
        }

        .banner-title {
            @include responsiveMax(991px) {
                padding-top: rem(242);
                margin-bottom: rem(20);
            }

            @include responsiveMin(992px) {
                padding-top: rem(280);
            }

            &__text-wrap {
                &--right-position {
                    padding-left: rem(420);

                    @include responsiveMax(991px) {
                        padding-left: rem(100);
                    }

                    .banner-title__text {
                        font-size: rem(14);
                        line-height: rem(16);
                        font-weight: bold;
                        text-transform: none;
                        color: #fff;

                        @include responsiveMin(992px) {
                            font-size: rem(24);
                            line-height: rem(28);
                        }

                        @include responsiveMax(767px) {
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &__single-slide {
        .banner-title {
            &__text-wrap {

                &--left-position {
                    animation: top-text-show 2s ease-in-out forwards;
                }

                &--right-position {

                    .banner-title__text {
                        animation: bottom-text-show 1.5s 1s ease-in-out forwards;
                    }
                }
            }

            &__separator {
                &:before {
                    animation: separator-hide .3s 2.3s ease-in-out reverse forwards;
                }
            }
        }
    }
}
