@import "../../../../../scss/mixins.scss";
@import "../../../../../scss/vars.scss";

.post__author {
    margin: rem(15) 0 rem(30);
    @include responsiveMin(992px) {
        margin: 0;
        padding-top: rem(80);
        max-height: rem(78);
    }

    &--wrapper {
        display: flex;
        gap: rem(10);

        &-reverse {
            @include responsiveMin(992px) {
                flex-direction: row-reverse;
            }
        }
    }

    &-img {
        width: auto;
        height: 100%;
        object-fit: cover;

        @include responsiveMin(992px) {
            flex-basis: 20%;
        }
        
        img {
            display: block;
            width: rem(56);
            height: rem(56);
            border-radius: 100%;
            border: 1px solid #767994;

            @include responsiveMin(992px) {
                border: rem(1) solid #767994;
                width: 100%;
                height: 100%;
                max-width: rem(77);
                max-height: rem(77);
            }
        }
    }

    &--info {
        height: fit-content;
        margin: auto 0;
        text-align: left;

        &-reverse {
            @include responsiveMin(992px) {
                text-align: right;
            }
        }

        @include responsiveMin(992px) {
            margin-left: rem(30);
        }

        span {
            display: block;
        }

        .author-name {
            font-weight: bold;
            font-size: rem(18);
            line-height: rem(21);
            margin-bottom: rem(5);
            color: #3E3E53;

            @include responsiveMin(992px) {
                font-size: rem(24);
                line-height: rem(28);
            }
        }

        .author-work {
            color: #3E3E53;
            font-size: rem(14);
            line-height: rem(16);
            font-weight: 500;

            @include responsiveMin(992px) {
                font-size: rem(14);
                line-height: rem(24);
                font-weight: 400;
            }
        }
    }
}
