@import "../../../scss/vars";

@import "../../../scss/mixins";

.web-design-page {
    .mobile-first {
        position: relative;
        z-index: 1;
        background: #ffffff;

        @include responsiveMin(992px) {
            min-height: 100vh;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                min-height: 130vh;
                height: 130vh;
            }
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 100%;

            @include responsiveMin(992px) {
                flex-direction: row;
                min-height: 100vh;

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    z-index: 3;
                }

                &:before {
                    width: rem(800);
                    height: rem(200);
                    background-image: url("../images/bg/wave-top-white.svg");
                    top: 0;
                    left: 0;
                }

                &:after {
                    width: rem(600);
                    height: rem(500);
                    bottom: 0;
                    right: 0;
                    background-image: url("../images/bg/wave-bottom.svg");
                }
            }
        }

        &__inner {
            overflow: hidden;
            position: relative;
            z-index: 2;

            @include responsiveMin(992px) {
                flex-basis: 50%;
            }
            &-right {
                z-index: 7;
                background: #ffffff;
                @include responsiveMin(992px) {
                    z-index: 1;

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        .red {
            background: linear-gradient(311.99deg, #ff6826 0%, #ff4d66 48.44%, #fc6076 100%);
            min-height: rem(400);
            display: flex;
            flex-direction: column;
            align-items: center;

            @include responsiveMin(992px) {
                transform: translateX(200%);

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    transform: none !important;
                }
            }

            &-animate {
                @include responsiveMin(992px) {
                    animation: mobileAnimate 2.5s ease forwards;

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        animation: none !important;
                        transition-property: none !important;
                        transform: none !important;
                    }

                    @keyframes mobileAnimate {
                        0% {
                            transform: translateX(200%);
                        }
                        100% {
                            transform: translateX(0);
                            background: linear-gradient(311.99deg, #ff6826 0%, #ff4d66 48.44%, #fc6076 100%);
                        }
                    }
                }
            }
        }

        &__title {
            color: #ffffff;
            padding: rem(30);
            font-size: rem(20);
            font-weight: 900;
            line-height: 150%;
            text-align: center;
            text-transform: uppercase;

            @include responsiveMin(992px) {
                font-size: rem(36);
                line-height: rem(42);
                text-align: left;
                white-space: nowrap;
                position: absolute;
                top: rem(140);
                left: rem(113);
                transform: translateX(300%);

                &-animate {
                    animation: titleAnimated 2s ease 1.5s forwards;

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        animation: none !important;
                        transition-property: none !important;
                        transform: none !important;
                    }

                    @keyframes titleAnimated {
                        0% {
                            transform: translateX(300%);
                        }

                        100% {
                            transform: translateX(0);
                        }
                    }
                }
            }

            &-bg {
                display: none;
                @include responsiveMin(992px) {
                    display: block;
                    position: absolute;
                    color: #ffffff;
                    opacity: 0.03;
                    font-size: rem(96);
                    line-height: rem(113);
                    font-weight: 900;
                    text-transform: uppercase;
                    width: 100%;
                    top: rem(140);
                    left: rem(113);
                }
            }
        }

        &__phone {
            position: absolute;
            padding-top: rem(30);
            z-index: 3;
            left: 0;
            right: 0;
            top: rem(10);
            img {
                margin: auto;
                display: block;
            }
        }

        .mobile-screen {
            position: relative;

            @include responsiveMin(992px) {
                width: rem(555);
                max-height: rem(970);
                position: relative;
                padding-top: rem(70);
            }

            &__moc-up {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
                pointer-events: none;
            }

            &__frame {
                width: rem(360);
                max-height: rem(783);
                margin: rem(4) auto;
                transform: translateX(rem(-1));
                overflow-y: scroll;
                border-radius: rem(20);

                &::-webkit-scrollbar {
                    width: rem(10);
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 rem(6) rgba(0, 0, 0, 0.3);
                    background: #FFF;
                    margin-bottom: rem(62);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: darkgrey;
                    border-radius: rem(4);
                }

                img {
                    width: 100%;
                    margin-bottom: rem(50);
                }
            }
        }

        &__img {
            @include responsiveMin(992px) {
                position: absolute;
                top: rem(304);
                z-index: 5;
                left: rem(200);
                transform: translateX(-500%);

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    top: rem(504);
                }

                &-animate {
                    animation: phoneEffect 2s ease 4s forwards;

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        animation: none !important;
                        transition-property: none !important;
                        transform: none !important;
                    }

                    @keyframes phoneEffect {
                        0% {
                            transform: translateX(-500%);
                        }
                        100% {
                            transform: translateX(0);
                        }
                    }
                }
            }

            img {
                width: 100%;
            }
        }

        &__text {
            padding: rem(30) rem(20);
            color: #3e3e53;
            font-size: rem(14);
            line-height: 150%;

            @include responsiveMin(992px) {
                padding: rem(400) rem(280) rem(300);
                font-size: rem(16);
                transform: translateX(100%);

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    padding: 0;
                    max-width: 450px;
                }

                &-animate {
                    @include responsiveMin(992px) {
                        animation: textMobile 2s ease 3s forwards;

                        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                            animation: none !important;
                            transition-property: none !important;
                            transform: none !important;
                        }

                        @keyframes textMobile {
                            0% {
                                transform: translateX(100%);
                            }
                            100% {
                                transform: translateX(0);
                            }
                        }
                    }
                }
            }
        }

        &__message {
            display: none;
            @include responsiveMin(992px) {
                display: block;
                position: absolute;
                bottom: 35%;
                right: rem(-60);
                width: rem(150);
                height: rem(220);
                z-index: -1;
                transform: translateX(-100%);

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    bottom: 60%;
                }
            }

            &--text {
                @include responsiveMin(992px) {
                    width: 100%;
                    height: rem(150);
                    border: 5px solid #fff;
                    border-radius: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: rem(14);
                    line-height: rem(20);
                    text-transform: uppercase;
                    color: #ffffff;
                    font-family: "Joystix Monospace";
                    position: relative;
                    text-align: center;
                    padding: rem(50);

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        padding: rem(25);

                        div {
                            width: 100%;
                            display: flex;
                            text-align: center;
                        }
                    }
                }
            }

            &--wire {
                @include responsiveMin(992px) {
                    position: absolute;
                }
            }

            &-animate {
                @include responsiveMin(992px) {
                    animation: phone 1s ease 12s forwards;

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        animation: none !important;
                        transition-property: none !important;
                        transform: none !important;
                    }

                    @keyframes phone {
                        0% {
                            transform: translateX(-100%);
                        }
                        50% {
                            transform: translateX(10%);
                        }
                        100% {
                            transform: translateX(0);
                        }
                    }
                }
            }
        }

        &__btn {
            display: none;
            @include responsiveMin(992px) {
                display: block;
                position: absolute;
                bottom: rem(-3);
                left: rem(90);
                width: rem(375);
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }

            &--left,
            &--right {
                border: none;
                outline: none;
                padding: 0;
                width: 50%;

                img {
                    width: 100%;
                }
            }
        }
    }

    .pink {
        display: none;
        @include responsiveMin(992px) {
            display: block;
            color: #f65d74;
            position: absolute;
            top: rem(170);
            left: rem(-806);
            opacity: 0;
            font-size: rem(36);
            line-height: rem(42);
            text-align: left;
            font-weight: 900;

            &-bg {
                position: absolute;
                width: 100%;
                color: #3e3e53;
                opacity: 0.03;
                font-size: rem(96);
                line-height: rem(113);
                font-weight: 900;
                white-space: nowrap;
                left: 0;
            }
            &-animate {
                color: #ffffff;
                animation: colorEffect 2s ease 3s forwards;

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    animation: none !important;
                    transition-property: none !important;
                    transform: none !important;
                }

                @keyframes colorEffect {
                    0% {
                        color: #ffffff;
                        opacity: 0;
                    }
                    100% {
                        color: #f65d74;
                        opacity: 1;
                    }
                }
            }
        }
    }

    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
            .pink {
                left: rem(-812);
            }
        }
    }
}