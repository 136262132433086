@import "../../../scss/vars";

@import "../../../scss/mixins";

.content__icon {
    display: block;
    width: rem(70);
    height: rem(70);
    margin: 0 auto;
    margin-bottom: rem(10);

    @include responsiveMin(992px) {
        margin: 0;
        margin-bottom: rem(10);
    }
}
