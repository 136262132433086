@import "../../../scss/vars";

@import "../../../scss/mixins";

.design {
    position: relative;

    @include responsiveMin(992px) {
        min-height: 100vh;
    }

    &.transition {
        & * {
            transition: all 0.5s ease-in-out;
        }
    }

    &__title {
        font-size: rem(20);
        font-weight: 900;
        line-height: 150%;
        text-align: center;
        text-transform: uppercase;

        @include responsiveMin(992px) {
            font-size: rem(36);
            line-height: rem(42);
            text-align: left;
        }
    }

    &__bg {
        @include responsiveMin(992px) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 100vh;
            // height: 100%;
            z-index: 1;
        }
    }
    &__wrapper {
        @include responsiveMin(992px) {
            align-items: center;
            position: relative;
            width: 100%;
            min-height: 100vh;
            display: flex;
            z-index: 2;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                min-height: 100vh; 
                height: 100vh;
            }

            &:before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background-size: cover;
            }

            &:before {
                width: rem(600);
                height: rem(500);
                // background-image: url("../images/bg/wave-top-white.svg");
                // background-image: url("./images/bg/wave-top.svg");
            }

            &:after {
                width: rem(800);
                height: rem(200);
                background-image: url("../images/bg/wave-top-white.svg");
                // background-image: url("./images/bg/wave-top-second.svg");
            }
        }
    }

    &__item {
        padding: rem(30) rem($left_right_padding);
        overflow: hidden;

        @include responsiveMin(992px) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        &--left {
            color: #fff;
            background: linear-gradient(282.39deg, #fc6076 0%, #ff4d66 49.48%, #ff6826 100%);

            @include responsiveMax(991px) {
                padding-bottom: 0;
            }

            @include responsiveMin(992px) {
                padding: 0 rem(360) 0 rem(200);
                background: transparent;
            }
        }

        &--right {
            color: #3e3e53;
            background: #ffffff;
            @include responsiveMax(991px) {
                padding-top: 0;
                // background: linear-gradient(282.39deg, #fc6076 0%, #ff4d66 49.48%, #ff6826 100%);
            }

            @include responsiveMin(992px) {
                padding: 0 rem(200) 0 rem(360);
                background: transparent;
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            @include responsiveMin(992px) {
                text-align: left;
            }
        }
    }
    &__content {
        font-size: rem($m_text_font_size);
        line-height: rem($m_text_line_height);
        @include responsiveMin(992px) {
            font-size: rem($d_text_font_size);
            line-height: rem($d_text_line_height);
        }
    }

    &__img {
        display: none;

        @include responsiveMax(991px) {
            display: block;

            img {
                max-width: rem(230);
                display: block;
                margin: 0 auto;
            }
        }

        &--ux {
            order: -1;
            margin: rem(10) 0 rem(18) 0;
        }

        &--ui {
            margin: rem(19) rem(30) rem(10) 0;
        }
    }
}

.web-design-page {
    .view {
        display: none;

        @include responsiveMin(992px) {
            display: flex;
        }

        &__wrapper {
            overflow: hidden;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;

            &--left {
                left: 0;
                z-index: 2;
                width: 50%;

                .view__item {
                    background: linear-gradient(282.39deg, #fc6076 0%, #ff4d66 49.48%, #ff6826 100%);
                }

                .view__gears--bottom {
                    background: url("./images/bg/gear-bottom.svg") no-repeat bottom left rem(200);
                    bottom: 0;
                    left: 0;
                }
            }

            &--right {
                right: 0;
                z-index: 1;
                background: #fff;
                .view__item {
                    background: #fff;
                    // background: linear-gradient(282.39deg, #fc6076 0%, #ff4d66 24.74%, #ff6826 50%);
                }

                .view__gears--top {
                    background: url("./images/bg/gear-top.svg") no-repeat top rem(50) right rem(300);
                    top: 0;
                    right: 0;
                }
            }
        }

        &__item {
            position: relative;
            padding: 9% 0 6%;
            width: 100vw;
            height: 100%;

            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 2;
                background-size: cover;
            }
            &:before {
                width: rem(600);
                height: rem(500);
                background-image: url("./images/bg/wave-bottom.svg");
            }

            &:after {
                width: rem(800);
                height: rem(200);
                background-image: url("./images/bg/wave-bottom-second.svg");
            }
        }

        &-img {
            z-index: 3;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: rem(215);
            display: flex;
            transform-style: preserve-3d;
            perspective: rem(470);

            img {
                width: 100%;
                height: 100%;
                display: block;
                margin-left: rem(-4);
            }
        }

        &__gears {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 5;
        }
    }
}
