@import "../../../scss/vars";
@import "../../../scss/mixins";
.client-review {
    padding: rem(40) rem(20) rem(20);
    margin: 0 auto;
    @include responsiveMin(992px) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: rem(125) rem(100) 0;
    }
    &__picture {
        margin-bottom: rem(10);
        text-align: center;
        img {
            max-width: 100%;
            height: auto;
        }
        @include responsiveMin(992px) {
            order: 1;
            padding: 0 0 0 rem(100);
        }
    }
    &__content {
        max-width: rem(800);
        @include responsiveMin(992px) {
            flex: 0 0 rem(800);
        }
    }
    &__text {
        font-size: rem(14);
        line-height: 1.5;
        color: #3e3e53;
        margin-top: rem(10);
        
        @include responsiveMin(992px) {
            font-size: rem(16);
            margin-top: rem(15);
        }
    }
    &__photo {
        display: flex;
        justify-content: center;
        position: relative;
        margin-bottom: rem(10);
        @include responsiveMin(992px) {
            display: block;
            margin-bottom: rem(20);
        }
        &:before {
            @include responsiveMax(991px) {
                content: '';
                display: block;
                height: 4px;
                position: absolute;
                top: 50%;
                left: rem(-20);
                right: rem(-20);
                transform: translateY(-50%);
                background: #eee;
                z-index: -1;
            }
        }
        img {
            display: block;
            width: 80px;
            height: auto;
            background: #fff;
            border: 4px solid #eee;
            border-radius: 50%;
            @include responsiveMin(992px) {
                width: rem(140);
                border: rem(5) solid #eee;
            }
        }
    }
    &__position {
        font-size: rem(14);
        line-height: rem(16);
        color: #767994;
        text-align: center;
        @include responsiveMin(992px) {
            text-align: left;
        }
    }
    &__name {
        font-weight: bold;
        font-size: rem(18);
        line-height: 1.2;
        color: #3e3e53;
        text-align: center;
        
        @include responsiveMin(992px) {
            font-size: rem(24);
            text-align: left;
        }
    }
}