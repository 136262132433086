@import "../../../scss/vars";
@import "../../../scss/mixins";

// .footerWithContactBlock {
//     overflow-x: hidden;
//     overflow-y: visible;
// }

.contactBlock {
    display: none;

    @include responsiveMin(992px) {
        display: flex;
        align-items: center;
        position: relative;
        height: rem(200);
        box-shadow: 0 rem(-50) rem(50) rem(-50) rgba(0, 0, 0, 0.2);

        &__holder {
            overflow: hidden;
            display: flex;
            align-items: center;
            position: relative;
            height: 100%;
            width: 100%;
        }

        &__bgText {
            position: absolute;
            height: 100%;
            right: rem(400);
            display: flex;
            align-items: center;
            opacity: 0.03;
            top: 0;

            span {
                text-transform: uppercase;
                font-weight: 900;
                font-size: rem(230);
                line-height: 100%;
                background: linear-gradient(315deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        &__image {
            display: block;
            position: absolute;
            bottom: 0;
            z-index: 10;
            width: rem(310);
            transform: translateX(-300%);
            pointer-events: none;
            margin-left: rem(60);
        }

        &__itemsHolder {
            margin-left: rem(430);
            margin-right: rem(60);

            &_item {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                margin-bottom: rem(5);
                font-weight: 900;
                font-size: rem(36);
                line-height: rem(42);

                &:last-child {
                    margin-bottom: 0;
                }

                &:after {
                    content: '';
                    display: block;
                    height: rem(6);
                    width: rem(150);
                }

                &--1 {
                    color: #FFB744;
                    transform: translateX(-300%);

                    &:after {
                        background: #FFB744;
                        margin-left: rem(30);
                    }
                }

                &--2 {
                    color: #F599B9;
                    transform: translateX(-300%);

                    &:after {
                        background: #F599B9;
                        margin-left: rem(52);
                    }
                }

                &--3 {
                    color: #FC6076;
                    transform: translateX(-300%);

                    &:after {
                        background: #FC6076;
                        margin-left: rem(42);
                    }
                }
            }
        }

        &__textHolder {
            width: rem(628);
            transform: translateX(300%);

            &_title {
                margin-bottom: rem(20);
                font-weight: bold;
                font-size: rem(24);
                line-height: rem(28);
                color: #3E3E53;
            }

            &_text {
                font-size: rem(16);
                line-height: 150%;
                color: #3E3E53;
            }
        }

        .smallContactButton {
            position: fixed;
            text-transform: uppercase;
            width: rem(100);
            height: rem(100);
            left: rem(55);
            bottom: rem(55);
            text-decoration: none;
            z-index: 10;

            &--active {
                animation: smallContactButtonActive .5s ease;
            }

            &--inActive {
                left: -100%;
                animation: smallContactButtonInActive .5s ease;
            }

            &:hover i {
                transform-origin: top center;
                animation-name: swing;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
        
            &__inner {
                position: relative;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: linear-gradient(315deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 1px solid rgba(255, 255, 255, 0.5);
                z-index: 9;
        
                i {
                    font-size: rem(16);
                    margin-bottom: rem(5);
                }
        
                p {
                    font-size: rem(14);
                    line-height: rem(16);
                    font-weight: bold;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }
            }
        
            &__shadow {
                position: absolute;
                border-radius: 50%;overflow: hidden;
                animation: pulsate 3s ease-out infinite;
        
                &--first {
                    z-index: 8;
                    top: rem(-12);
                    left: rem(-12);
                    width: 125%;
                    height: 125%;
                    background: linear-gradient(315deg, rgba(252, 96, 118, 0.5) 0%, rgba(255, 77, 102, 0.5) 48.44%, rgba(255, 104, 38, 0.5) 100%);
                    border: 1px solid rgba(255, 255, 255, 0.5);
                }
        
                &--second {
                    z-index: 7;
                    top: rem(-25);
                    left: rem(-25);
                    width: 150%;
                    height: 150%;
                    background: linear-gradient(315deg, rgba(252, 96, 118, 0.2) 0%, rgba(255, 77, 102, 0.2) 48.44%, rgba(255, 104, 38, 0.2) 100%);
                    border: 1px solid rgba(255, 255, 255, 0.2);
                }
            }
        }

        .bigContactButton {
            opacity: 0;
        }
    }

    &--active {
        .contactBlock__image {
            animation: fadeInLeft 2s ease forwards;
            animation-delay: 500ms;
        }

        .contactBlock__itemsHolder_item {
            &--1{
                animation: fadeInLeft 2s ease forwards;
                animation-delay: 1s;
            }

            &--2{
                animation: fadeInLeft 2s ease forwards;
                animation-delay: 1.2s;
            }

            &--3{
                animation: fadeInLeft 2s ease forwards;
                animation-delay: 1.4s;
            }
        }

        .contactBlock__textHolder {
            animation: fadeInRight 2s ease forwards;
            animation-delay: 500ms;
        }

        .bigContactButton {
            opacity: 1;
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            text-decoration: none;
            animation: bigContactButton 2s ease forwards;

            &:hover i {
                transform-origin: top center;
                animation-name: swing;
                animation-duration: 1s;
                animation-fill-mode: both;
            }

            &__inner {
                width: 100%;
                height: 100%;
                background: linear-gradient(315deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: rem(24);
                    margin-right: rem(15);
                }

                p {
                    font-size: rem(24);
                    line-height: rem(28);
                    text-transform: uppercase;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }

            &__shadow {
                display: none;
            }
        }
    }
}

@keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

@keyframes smallContactButtonActive {
    0% {
        left: -100%;
    }
    100% {
        left: rem(55);
    }
}

@keyframes smallContactButtonInActive {
    0% {
        left: rem(55);
    }
    100% {
        left: -100%;
    }
}

@keyframes bigContactButton {
    0% {
        left: auto;
        right: 0;
        width: 100%;
    }

    100% {
        left: auto;
        right: 0;
        width: rem(400);
    }
}

@keyframes fadeInLeft {
    0% {
        transform: translateX(-300%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeInRight {
    0% {
        transform: translateX(300%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}