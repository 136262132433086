@import "../../../scss/vars";
@import "../../../scss/mixins";

/* START .cv-page */

.cv-page {
  position: relative;

  &--preloader {
    min-height: 100vh;
    position: relative;

    .data-preloader {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__top-bg,
  &__bottom-bg {
    position: absolute;
    z-index: -1;

    &:before,
    &:after {
      position: absolute;
      z-index: -1;
      display: block;
      content: "";
      opacity: 0.03;
    }

  }

  @include responsiveMin(991px) {
    &__top-bg {
      top: 0;
      left: 0;

      &:before {
        width: rem(800);
        height: rem(200);
        background: url("../../../images/bg-el-1-dark.svg") no-repeat;
        background-size: contain;
      }
    }

    &__bottom-bg {
      bottom: 0;
      right: 0;
      transform: rotate(180deg);

      &:after {
        width: rem(600);
        height: rem(500);
        background: url("../../../images/bg-el-2-dark.svg") no-repeat;
        background-size: contain;
      }
    }
  }

  &__content {
    padding: 120px 0 0;

    @include responsiveMin(991px) {
      padding: 100px 0 0;

      &:before,
      &:after {
        position: absolute;
        z-index: -1;
        display: block;
        content: "";
        opacity: 0.03;
      }

      &:before {
        width: rem(300);
        height: rem(350);
        background: url("../../../images/gears-right-dark.svg") no-repeat;
        background-size: contain;
        top: rem(100);
        right: rem(350);
      }

      &:after {
        width: rem(350);
        height: rem(400);
        background: url("../../../images/gears-left-dark.svg") no-repeat;
        background-size: contain;
        top: rem(270);
        left: rem(250);
      }
    }

    a {
      color: #3E3E53;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__container {
    max-width: 320px;
    margin: 0 auto;
    padding: 0 0 rem(50);

    color: #3E3E53;

    @include responsiveMin(991px) {
      padding: 0 0 rem(120);
    }
  }

  &__photo {
    height: 150px;
    width: 150px;
    margin: 0 auto 30px;
    background: url("../../../images/cvcard-default-photo.svg") no-repeat;
    background-size: contain;
    border-radius: 50%;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      opacity: 1;

      &.cv-page__photo-normal {
        background-color: #fff;
      }

      &.cv-page__photo-default {
        display: none;
      }
    }

    &-default {
      opacity: .15;
    }
  }

  &__info {
    position: relative;
    width: 100%;
    min-height: 50px;
    display: flex;
    gap: rem(15);

    &-text {
      flex: 1 1 auto;
    }

    &-name {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 4px;
    }

    &-position {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 2px;

      color: #767994;
    }

    &-social-links {
      height: 50px;
      width: 50px;
      flex-shrink: 0;

      a {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #3e3e53;
      }
    }

    .icon-linkedin,
    .icon-xing {
      &:before {
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        background-image: none;
      }
    }
  }

  &__info_list {
    position: relative;

    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;

    a {
      display: flex;
      padding: 30px 0;
      text-decoration: none;
    }

    border-bottom: 1px solid rgba(62, 62, 83, .05);

    &:hover i {
      background: linear-gradient(135deg, #FF6826 0%, #FF4D66 49.48%, #FC6076 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &:hover p {
      background: linear-gradient(45deg, #FF6826 0%, #FF4D66 49.48%, #FC6076 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transform: translate3d(5px, 0, 0);
    }

    p {
      padding-left: rem(20);

      transition: transform .3s linear;
    }

    .icon-phone,
    .icon-envelope,
    .icon-sphere {
      &:before {
        color: #3e3e53;
      }
    }
  }

  &__btn {
    position: relative;
    margin: 21px 0 0;
    padding: 20px 0;
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all .3s;
    }

    &:before {
      background: $main_gradient;
    }

    &:after {
      background: $main_gradient_hover;
      opacity: 0;
    }

    &:hover {
      cursor: pointer;

      &:after {
        opacity: 1;
      }

      .cv-page__btn-icon {
        transform-origin: top center;
        animation-name: swing;
        animation-duration: 1s;
        animation-fill-mode: both;
      }
    }

    &-text {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-transform: uppercase;
      padding-left: rem(20);
    }

    &-icon {
      i {
        font-size: 18px;
      }
    }
  }

}

/* END .cv-page */