@import "../../../../scss/vars";
@import "../../../../scss/mixins";

/* START .support-advantages */
.support-advantages {
  &__list {
    display: flex;
    flex-wrap: wrap;

    @include responsiveMin(992px) {
      background: $service_advantages_bg;
    }
  }
}

.support-advantage {
  padding: rem(100) rem(180) rem(74) rem(100);
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  border-right: 1px solid rgba(255,255,255, .1);
  position: relative;

  &:last-child{
    border-right: none;
  }

  &__icon {
    img {
      max-width: 100%;
    }
  }

  &__title {
    padding: rem(10) 0;

    @include responsiveMin(992px) {
      padding: rem(20) 0 rem(15);
    }

    &-text {
      font-weight: 900;
      font-size: rem($m_h2_font_size);
      line-height: rem($m_h2_line_height);
      color: #FFFFFF;
      text-transform: uppercase;

      @include responsiveMin(992px) {
        font-size: rem($d_h2_font_size);
        line-height: rem($d_h2_line_height);
      }
    }
  }

  &__description {
    font-size: rem($m_text_font_size);
    line-height: rem($m_text_line_height);
    color: #FFFFFF;

    @include responsiveMin(992px) {
      font-size: rem($d_text_font_size);
      line-height: rem($d_text_line_height);
    }
  }

  &__index {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;

    span {
      font-weight: 900;
      font-size: rem(400);
      line-height: rem(400);
      text-align: right;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      opacity: 0.02;
    }
  }


  @include responsiveMax(991px) {
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    border: none;

    &__head {
      background: $service_advantages_bg;
      padding: rem(50) rem(15);
      border-right: 1px solid rgba(#fff ,.1);
    }

    &:last-child {
      .support-advantage {
        &__head {
          border: none;
        }
      }
    }

    &__index {
      right: auto;
      left: 0;
      align-items: flex-start;

      span {
        font-size: rem(96);
        line-height: rem(96);
      }
    }

    &__description {
      color: #767994;
      padding: rem(15);
      flex: 1;
    }
  }

  @include responsiveMax(767px) {
    background: #3E3E53;
    flex: 0 0 100%;
    max-width: 100%;
    border: none;
    padding: rem(30) 0;
    border-bottom: 1px solid rgba(#fff, .1);

    &:last-child {
      border-bottom: none;
    }

    &__head {
      padding: 0 rem(20) rem(10);
      background: transparent;
      border: none;
    }

    &__index {
      left: rem(20);
      top: rem(10);
    }

    &__icon {
      img {
        max-width: rem(50);
      }
    }

    &__title {
      padding: rem(10) 0 0;

      &-text {
        font-size: rem($m_h3_font_size);
        line-height: rem($m_h3_line_height);
      }
    }

    &__description {
      font-size: rem($m_text_font_size);
      line-height: rem($m_text_line_height);
      color: #fff;
      padding: 0 rem(20);
      text-align: left;
    }
  }
}

/* END .support-advantages */
