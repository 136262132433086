@import "../../../scss/vars";
@import "../../../scss/mixins";

/* START .partner-page */
.web-design-page {
    p {
        padding-top: rem(10);
    }
    
    .description {
        @include responsiveMin(992px) {
            max-height: rem(920);
        }

        &__subtitle {
            color: #ffffff;
        }

        &__text {
            @include responsiveMin(992px) {
                padding: rem(40) 0 rem(40);
                text-align: left;
                width: rem(800);
                margin: 0 auto;
            }
        }

        &__img {
            &--back-side {
                max-height: 100%;
                position: absolute;
                bottom: rem(-60);
                right: 0;
    
                @include responsiveMax(992px) {
                    margin: auto;
                    left: 0;
                }
                @include responsiveMin(992px) {
                    right: rem(180);
                    margin: 0;
                    bottom: 0;
                }
            }
    
            &--front-side {
                display: none;
                max-width: rem(608);
                height: auto;
    
                @include responsiveMin(992px) {
                    display: block;
                }
            }
        }
    }
}

.loading {
    height: 100vh;
}
.isFirefox {
    width: rem(300);
}

@include responsiveMax(992px) {
}

/* END .partner-page */
