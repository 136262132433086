@import "../../../scss/vars.scss";
@import "../../../scss/mixins";

.text {
    font-size: rem(14);
    line-height: 150%;
    color: #3e3e53;

    @include responsiveMin(992px) {
        font-size: rem(16);
        line-height: rem(24);
    }

    p {
        margin-bottom: rem(10);

        @include responsiveMin(992px) {
            margin-bottom: rem(20);
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a {
        color: #ff4d66;

        &:hover {
            text-decoration: none;
        }
    }
}
