@import "../../../scss/vars";
@import "../../../scss/mixins";

/* START .contact-page */

.contact-page {
    position: relative;
    background-color: #fff;
    
    @include responsiveMin(992px) {
        background-color: $contact-page__form-col-bg;
    }

    &.transition {
        & * {
            transition: all .5s ease-in-out;
        }
    }

    &__content {
        position: relative;
        height: auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        @include responsiveMin(992px) {
            flex-direction: row;
            z-index: 2;
            min-height: 100vh;
        }
    }

    &__col {
        position: relative;
        z-index: 1;

        @include responsiveMin(992px) {
            flex: 0 0 50%;
        }

        &--info {
            z-index: 3;
            overflow: hidden;

            @include responsiveMax(991px) {
                order: 2;
            }
        }

        &--form {
            @include responsiveMax(991px) {
                position: relative;
                background: $contact-page__form-col-bg;
                order: 1;
            }

            .data-preloader {
                &__el {

                    .sk-cube {
                        background-color: #fff;
                    }
                }
            }
        }
    }

    &__backgrop {
        @include responsiveMin(992px) {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 50%;
            background-color: #fff;
            z-index: 2;
        }
    }

    &__animation {
        display: none;

        @include responsiveMin(992px) {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 3;
            pointer-events: none;
        }
    }

    .contact-animation {
        @include responsiveMin(992px) {
            overflow: hidden;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;

            &__front {
                left: 0;
                z-index: 2;
                width: 50%;
            }

            &__back {
                right: 0;
                z-index: 1;

                .contact-animation {
                    &__image {
                        transform: translateX(-50%, 0);
                    }

                    &__success-message {
                        height: 100%;
                        width: 62vw;
                        position: absolute;
                        top: 0;
                        right: 0;
                        clip-path: circle(30px at 60% 83%);
                        transition: clip-path 0.8s ease-in-out;
                        background: linear-gradient(315deg, #FF6826 0%, #FF4D66 49.48%, #FC6076 100%);
                        &--active {
                            z-index: 1;
                            clip-path: circle(100% at 60% 50%);
                        }
                    }
                }
            }

            &__content {
                position: relative;
                padding: 0;
                width: 100vw;
                height: 100%;
            }

            &__image {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
                margin: 0 auto;
                width: rem(400);
                display: flex;
                align-items: center;
                transform-style: preserve-3d;
                perspective: rem(400);

                img {
                    width: 100%;
                    display: block;
                }
            }
        }
    }

    .contact-info {
        position: relative;
        padding: rem(30) rem(20) 0;

        @include responsiveMin(992px) {
            height: 100%;
            z-index: 1;
            padding: rem(200) 0 0;

            &:before,
            &:after {
                position: absolute;
                z-index: -1;
                display: block;
                content: "";
            }

            &:before {
                top: 0;
                left: 0;
                width: rem(800);
                height: rem(200);
                background: url("images/left_top_bg_element.svg");
                background-repeat: no-repeat;
                background-size: contain;
            }

            &:after {
                width: rem(350);
                height: rem(400);
                left: rem(200);
                bottom: 0;
                background: url("images/bg_gears.svg");
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        &__title {
            &-bg,
            &-text {
                display: none;
            }

            @include responsiveMin(992px) {
                margin-left: rem(100);
                position: relative;
                z-index: 1;

                &-text {
                    display: block;
                    font-weight: 900;
                    font-size: rem(48);
                    line-height: 1.16;
                    color: #3E3E53;
                }

                &-bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    font-weight: 900;
                    font-size: rem(96);
                    line-height: rem(113);
                    text-transform: uppercase;
                    color: #3E3E53;
                    opacity: 0.03;
                    display: block;
                }
            }
        }

        &__meta {
            @include responsiveMin(992px) {
                padding: rem(100) 0 0 rem(300);
            }
        }

        &__subtitle {
            display: none;

            @include responsiveMin(992px) {
                display: block;
                font-weight: bold;
                color: #3E3E53;
                font-size: rem(24);
                line-height: rem(28);
                margin-bottom: rem(20);
            }
        }

        &__description {
            font-size: rem($m_text_font_size);
            line-height: rem($m_text_line_height);
            color: #3E3E53;

            @include responsiveMin(992px) {
                font-size: rem($d_text_font_size);
                line-height: rem($d_text_line_height);
                width: rem(356);
                padding-left: rem(50);
            }
        }

        &__contact-data {
            padding: rem(30) 0 0;

            @include responsiveMin(992px) {
                padding: rem(60) rem(150) rem(80) rem(470);
              }
        
              @include responsiveMin(1500px) {
                padding: rem(60) rem(150) rem(80) rem(500);
              }
        
              @include responsiveMin(1700px) {
                padding: rem(60) rem(100) rem(80) rem(500);
            }
        }

        &__address {
            font-weight: bold;
            font-size: rem(18);
            line-height: rem(21);
            font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
            color: #767994;
            padding-bottom: rem(30);

            &-icon {
                flex: 0 0 rem(18);
                text-align: center;
                margin-right: rem(8);

                i {
                    &:before {
                        font-size: rem(20);
                    }
                }
            }

            > p {
                padding-left: rem(26);
            }

            & > div {
                display: flex;
            }

            > p, & > div {
                margin-bottom: rem(20);

                @include responsiveMin(992px) {
                    margin-bottom: rem(15);
                }
            }
        }

        &__hours {
            font-weight: bold;
            font-size: rem(18);
            line-height: rem(21);
            font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
            color: #767994;
            display: flex;

            &-text {
                padding-left: rem(10);
            }

            &-icon {
                flex: 0 0 rem(18);
                text-align: center;

                i {
                    &:before {
                        font-size: rem(18);
                    }
                }
            }
        }

        &__email,
        &__phone {
            padding-top: rem(30);
            display: flex;

            a {
                text-decoration: none;
                display: flex;
                align-items: center;

                @include responsiveMin(992px) {

                    &:hover {
                        i {
                            &:before {
                                color: #fc6076;
                            }
                        }
                        span {
                            display: block;
                            background: $main_gradient;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;

                            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                                color: rgba(255, 87, 77, 1);
                                background: none;
                            }
                        }
                    }
                }
            }

            i {

                flex: 0 0 rem(18);
                text-align: center;

                &:before {
                    color: #3E3E53;
                    font-size: rem(18);
                    transition: all .3s;
                }
            }

            span {
                padding-left: rem(10);
                font-weight: bold;
                font-size: rem(18);
                line-height: rem(21);
                display: flex;
                align-items: center;
                text-decoration: none;
                color: #3E3E53;
                transition: all .3s;
            }
        }

        &__email {
            i {
                &:before {
                    font-size: rem(15);
                }
            }
        }

        &__parallax {
            pointer-events: none;

            img {
                max-width: 100%;
                display: block;
                margin: 0 auto;
            }

            @include responsiveMax(559px) {
                padding: 0 rem(30) 0 rem(60);
            }

            @include responsiveMax(991px) {
                margin-top: rem(20);
            }

            @include responsiveMin(992px) {
                position: absolute;
                bottom: rem(-2);
                left: rem(-40);
                width: rem(404);
            }
        }
    }
}

.contact-service {
    padding: rem(15) rem(20);
    margin: rem(-30) rem(-20) 0;
    box-shadow: 0 0 rem(50) rgba(#000, 0.2);

    @include responsiveMin(992px) {
        display: flex;
        padding: 0;
        margin: rem(30) rem(100) 0;
        box-shadow: none;
    }

    &__list {
        @include responsiveMin(992px) {
            flex: 0 0 rem(280);
            padding-right: rem(20);
        }

        &:first-child {
            @include responsiveMin(992px) {
                flex: 0 0 rem(370);
            }
    
            @include responsiveMin(1500px) {
                flex: 0 0 rem(400);
            }
        }
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        margin: rem(15) 0;
        color: #3e3e53;

        @include responsiveMin(992px) {
            margin: 0 0 rem(20) 0;
        }

        &::before {
            @include responsiveMax(991px) {
                content: '';
                width: rem(4);
                height: rem(4);
                background-color: currentColor;
                border-radius: 50%;
                margin: 0 rem(20) 0 rem(5);
                flex-shrink: 0;
            }
        }
    }

    &__icon {
        width: rem(50);
        height: auto;
        margin-right: rem(10);

        .contact-service__list:first-child & {
            width: rem(80);
        }

        @include responsiveMax(991px) {
            display: none;
        }
    }

    &__name {
        font-size: rem(14);
        line-height: 1.16;
        font-weight: 700;

        @include responsiveMin(992px) {
            font-size: rem(18);
        }
    }
}

/* END .contact-page */
