@import "../../../scss/vars";
@import "../../../scss/mixins";

.relevant-posts {
    margin-top: rem(60);
    color: #3E3E53;
    font-size: rem(16);
    line-height: 150%;

    @include responsiveMin(992px) {
        margin-top: rem(100);
    }

    &.restricted {
        padding: 0 rem(20) rem(60);

        @include responsiveMin(992px) {
            max-width: rem(850);
            padding-bottom: rem(160);
            margin: 0 auto;
        }
    }

    &__title {
        font-weight: 700;
        font-size: rem(22);
        line-height: rem(26);
        margin-top: 0;
        margin-bottom: rem(20);

        @include responsiveMin(992px) {
            font-size: rem(32);
            line-height: rem(38);
        }
    }
}

.relevant-post {
    margin-bottom: rem(20);
    box-shadow: 0px 0px rem(30) rgba(24, 28, 45, 0.1);

    @include responsiveMin(768px) {
        display: flex;
        box-shadow: 0px 0px rem(50) rgba(24, 28, 45, 0.2);
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &__image {
        height: fit-content;

        @include responsiveMin(768px) {
            width: rem(330);
        }

        @include responsiveMin(992px) {
            width: rem(383);
        }
    }

    &__text-container {
        display: flex;
        flex-direction: column;
        padding: rem(15);

        @include responsiveMin(768px) {
            flex: 1;
            padding: rem(20);
        }
    }

    &__title {
        font-size: rem(18);
        line-height: rem(21);
        font-weight: 700;
        margin-bottom: rem(5);

        @include responsiveMin(992px) {
            font-size: rem(24);
            line-height: rem(28);
            margin-bottom: rem(10);
        }

        a {
            color: inherit;
            text-decoration: none;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* autoprefixer: off */
            -webkit-box-orient: vertical;
            /* autoprefixer: on */
        }
    }

    &__excerpt {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        margin-bottom: rem(10);
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
    }

    &__date {
        display: flex;
        align-items: center;

        i, span {
            display: block;
        }

        i {
            margin-right: rem(5);
        }

        span {
            font-size: rem(12);
            line-height: rem(14);

            @include responsiveMin(992px) {
                font-size: rem(14);
                line-height: rem(16);
            }
        }
    }

    &__button {
        position: relative;
        padding: 0 rem(10);
        text-decoration: none;

        &:hover {
            &:before {
                width: 100%;
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: rem(5);
            height: 100%;
            background: rgba($color: #3E3E53, $alpha: 0.2);
            transition: 0.5s ease;
            z-index: -1;
        }

        span {
            font-weight: 700;
            font-size: rem(14);
            line-height: rem(16);
            text-transform: uppercase;
            color: #3E3E53;

            @include responsiveMin(992px) {
                font-size: rem(18);
                line-height: rem(21);
            }
        }
    }
}