@import "../../../../scss/vars";
@import "../../../../scss/mixins";

/* START .contact-page */

.cookie-feedback {
  position: relative;

  &.transition {
    & * {
      transition: all .5s ease-in-out;
    }
  }

  &__content {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;

    @include responsiveMin(992px) {
      flex-direction: row;
      z-index: 2;
      height: 100vh;
    }
  }

  &__col {
    @include responsiveMin(992px) {
      flex: 0 0 50%;
      overflow: hidden;
    }

    &--form {
      @include responsiveMax(991px) {
        background: $contact-page__form-col-bg;
      }
    }

    &-im {
      display: none;

      @include responsiveMax(991px) {
        display: block;
        margin: rem(-100) 0 0;

        img {
          display: block;
          width: rem(230);
          margin: 0 auto;
        }
      }
    }
  }

  &__animation {
    display: none;

    @include responsiveMin(992px) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }
  .cookie-feedback-animation {
    @include responsiveMin(992px) {
      overflow: hidden;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;

      &__front {
        left: 0;
        z-index: 2;
        width: 50%;

        .cookie-feedback-animation {

          &__content {
            background: #fff;
          }
        }
      }

      &__back {
        right: 0;
        z-index: 1;

        .cookie-feedback-animation {

          &__content {
            background: $contact-page__form-col-bg;
          }

          &__image {
            transform: translateX(-50%, 0);
          }
        }
      }

      &__content {
        position: relative;
        padding: 0;
        width: 100vw;
        height: 100%;
      }

      &__image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        width: rem(400);
        display: flex;
        align-items: center;
        transform-style: preserve-3d;
        perspective: rem(400);

        img {
          width: 100%;
          display: block;
          margin-left: rem(-5);
        }
      }
    }
  }

  .cookie-feedback-info {
    position: relative;

    @include responsiveMin(992px) {
      height: 100%;
      z-index: 1;
      padding-top: rem(250);

      &:after {
        position: absolute;
        z-index: -1;
        display: block;
        content: "";
        width: rem(350);
        height: rem(400);
        left: rem(200);
        bottom: 0;
        background: url("images/bg_gears.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &__title {
      position: relative;
      z-index: 1;
      text-align: center;

      &-text {
        font-weight: 900;
        font-size: rem($m_h2_font_size);
        line-height: rem($m_h2_line_height);
        text-transform: uppercase;
        color: #3E3E53;
      }

      &-bg {
       display: none;
      }

      @include responsiveMin(992px) {
        margin-left: rem(150);
        width: rem(600);
        text-align: left;

        &-text {
          font-size: rem(48);
          line-height: rem(56);
        }

        &-bg {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          font-weight: 900;
          font-size: rem(96);
          line-height: rem(113);
          text-transform: uppercase;
          color: #3E3E53;
          opacity: 0.03;

          span {
            white-space: nowrap;
          }
        }
      }
    }

    &__description {
      font-size: rem(18);
      line-height: rem(27);
      color: #3E3E53;
      width: rem(340);
      margin: rem(100) 0 0 rem(250);

      @include responsiveMax(991px) {
        font-size: rem($m_text_font_size);
        line-height: rem($m_text_line_height);
        width: 100%;
        margin: 0;
        padding: rem(10) rem(20) rem(120);
        text-align: center;
      }
    }
  }

  .cookie-feedback-form {
    position: relative;
    padding-top: rem(56);

    @include responsiveMin(992px) {
      padding-top: 0;
      height: 100%;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        position: absolute;
        z-index: -1;
        display: block;
        content: "";
        top: rem(50);
        left: rem(50);
        width: rem(300);
        height: rem(350);
        background: url("images/bg_gears_white.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &__form-wrapper {
      max-width: rem(360);
      padding: 0 rem(20);
      width: 100%;
      position: relative;
      z-index: 1;

      @include responsiveMax(991px) {
        margin: 0 auto;
        padding-bottom: rem(30);
      }
    }

    &__form-checkbox {
      margin-top: rem(15);
    }

    &__form-loader {
      text-align: center;
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: rem(15);
      width: 100%;
      display: none;

      &--active {
        display: block;
      }
    }

  }

  .form-field {
    margin-top: rem(20);
    opacity: .5;
    transition: all .3s;

    &--active,
    &--error,
    &--filled {
      opacity: 1;

      .form-field {
        &__input {

          input,
          textarea {
            background: rgba(255,255,255, .1);
          }
        }
      }
    }

    &--active {
      .form-field {
        &__input {

          .form-field__label-block {
            display: block !important;
            top: rem(10);
            font-size: rem(12);
            line-height: rem(14);
          }

          input,
          textarea {
            padding: rem(25) rem(15) rem(10) !important;
          }

          .form-field__input--clear {
            display: none !important;
          }

          &:before {
            height: 2px !important;
          }
        }
      }
    }

    &--filled {
      .form-field {
        &__input {

          .form-field__label-block {
            display: none;
          }

          input {
            padding: rem(20) rem(15);
          }
          textarea{
            padding: rem(20) rem(15) rem(10);
          }

          .form-field__input--clear {
            display: block;
          }

          &:before {
            height: 1px;
          }
        }
      }
    }

    &--error {
      .form-field {
        &__input {

          &:before {
            background: $footer-feedback-btn-bg;
          }
        }

        &__label-text {
          color: #DB4437;
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }

    &__label-block {
      position: absolute;
      top: rem(20);
      padding-left: rem(15);
      width: 100%;

      font-size: rem(14);
      line-height: rem(16);
      color: #FFFFFF;

      transition: all .3s ease;
    }

    &__label {
      position: relative;
      padding-bottom: rem(6);
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-text {
        display: block;
        font-weight: bold;
      }

      &-required {
        position: absolute;
        right: rem(15);
      }
    }

    &__input {
      position: relative;

      input,
      textarea {
        width: 100%;
        height: rem(55);
        background: rgba(255,255,255, .2);
        border: none;
        padding: rem(25) rem(15) rem(10);
        font-size: rem(14);
        line-height: rem(16);
        color: #FFFFFF;
        outline: 0;

        transition: all .3s linear;

        &::placeholder {
          opacity: 0;
          color: rgba(255,255,255, .3);
          font-feature-settings: 'pnum' on, 'lnum' on;
        }
      }

      &--clear {
        display: none;
        position: absolute;
        top: rem(20);
        right: rem(15);
        cursor: pointer;

        i::before {
          font-size: rem(10);
        }
      }

      textarea {
        height: rem(95);
        resize: none;
        line-height: 150%;
        padding: rem(25) rem(15) rem(5);
        display: block;

        &+.form-field__input--clear {
          top: rem(5);
        }
      }

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #fff;
      }
    }

    &__status-counter {
      font-size: rem(10);
      line-height: rem(12);
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      opacity: 0.5;
    }

    &__status {
      padding-top: rem(4);
      display: none;
      align-items: center;
      flex: 1;

      &--visible {
        display: flex !important;
      }

      &-msg {
        flex: 1;
        font-size: rem(10);
        line-height: rem(12);
        color: #DB4437;
      }

      &-icon {
        font-size: rem(10);
      }
    }
  }

  .checkbox-field {
    margin-bottom: rem(20);
    padding: 0 0 0 rem(16);
    position: relative;

    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    input + label {
      position: relative;
      cursor: pointer;
      font-size: rem(12);
      line-height: rem(14);
      color: #FFFFFF;
    }

    input + label:before,
    input + label:after {
      content: '';
      position: absolute;
      top: 0;
      left: rem(-16);
      width: rem(11);
      height: rem(11);
      border: 1px solid #fff;
      transition: .3s;
    }

    input + label:after {
      content: '✓';
      opacity: 0;
      font-size: rem(10);
      line-height: rem(10);
      text-align: right;
    }

    input:checked + label:after {
      opacity: 1;
    }

    i.icon-error {
      position: absolute;
      left: 0;
      top: rem(16);
      opacity: 0;

      &:before {
        font-size: rem(12);
      }
    }

    &--not-checked {

      input + label:before,
      input + label:after {
        color: #db4437;
        border-color: #db4437;
      }

      i.icon-error {
        opacity: 1;
      }
    }
  }

  .form-submit {
    text-align: center;

    button {
      height: rem(60);
      width: 100%;
      background: transparent;
      position: relative;
      border: none;
      transition: all .3s;
      overflow: hidden;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        transition: opacity .3s;
      }

      &:before {
        background: linear-gradient(304.43deg, #FF6826 0%, #FF4D66 49.48%, #FC6076 100%);
      }

      &:after {
        background: linear-gradient(304.43deg, #FF6826 0%, #FF4D66 49.48%, #FC6076 100%);
        opacity: 0;
        z-index: 2;
      }

      @include responsiveMin(992px) {
        &:before {
          transform: rotate(-180deg);
        }

        &:hover {
          &:after {
            opacity: 1;
          }
        }
      }

      @include responsiveMax(767px) {
        width: 100%;
      }

      &.loading {
        width: rem(60);
        border-radius: 50%;
      }
    }

    &__label,
    &__success {
      position: relative;
      z-index: 3;
      font-weight: bold;
      font-size: rem(18);
      line-height: rem(21);
      text-transform: uppercase;
      color: #FFFFFF;

      &--hidden {
        opacity: 0;
      }
    }

    &__label {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__icon {
      padding: rem(3) 0 0 rem(10);
    }

    &__loader,
    &__success {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      opacity: 0;
      transition: opacity .3s;

      &--visible {
        opacity: 1;
      }
    }

    &__success {
      background: #4CAF50;
    }
  }
}

.lds-dual-ring {
  display: inline-block;
  width: rem(60);
  height: rem(60);
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  margin: 1px;
  border-radius: 50%;
  border: rem(4) solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* END .contact-page */