@import '../../scss/vars';
@import '../../scss/mixins';

.page {
	&__footer {
        display: block !important;

		@include responsiveMax(991px) {
			&--fixed-buttons {
				padding-bottom: rem(60);
			}
		}
	}

	&__footer-btns {
		@include responsiveMax(991px) {
			display: flex;
			bottom: rem(-60);
			transition: bottom 0.3s;
		}

		&--hidden {
			@include responsiveMin(992px) {
				flex: 0;
			}

			@include responsiveMax(991px) {
				display: block;
			}
		}

		&--fixed {
			@include responsiveMax(991px) {
				position: fixed;
				bottom: 0;
				left: 0;
				width: 100%;
				z-index: 100;

				&--hidden {
					display: block;
				}
			}
		}
	}
}

/* START footer */
.footer {
	&__main {
        position: relative;
		background: #353540;
        overflow: hidden;

        @include responsiveMin(992px) {
            display: flex;
            min-height: rem(400);
            padding: rem(100) 0 rem(40) 0;
            align-items: center;
        }
    }

    //footer-top
    &__top {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 72px 30px;
        overflow: hidden;
        z-index: 1;

        @include responsiveMin(992px) {
            position: absolute;
            left: rem(-16);
            top: 50%;
            transform: translateY(-50%);
            padding: 0;
            width: rem(520);
            height: rem(520);
        }

        &_bg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: rem(400);
            height: rem(400);
            z-index: -1;
            pointer-events: none;

            @include responsiveMin(768px) {
                width: rem(520);
                height: rem(520);
            }
        }
    }

    &__logo {
        width: rem(220);

        @include responsiveMin(768px) {
            width: rem(245);
        }

        svg {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    //block with menus
    &__content {
        @include responsiveMin(992px) {
            margin-left: rem(600);
        }

        &--top {
            margin-bottom: rem(40);

            @include responsiveMin(992px) {
                display: flex;
                margin-bottom: rem(50);
            }
        }

        &--bottom {
            padding: 0 rem(20);

            @include responsiveMin(992px) {
                display: flex;
                justify-content: space-between;
                padding: 0;
            }
        }
    }

    &__follow-us {
        display: flex;
        align-items: center;
        margin-bottom: rem(40);

        @include responsiveMin(992px) {
            margin-bottom: rem(10);
        }

        span {
            color: #fff;
            font-weight: 700;
            font-size: rem(18);
            line-height: rem(21);
            text-transform: uppercase;
        }

        div {
            display: flex;
            margin-left: rem(20);
        }

        .icon-svg {
            svg {
                position: relative;
                z-index: 2;
                width: 1em;
                height: 1em;
            }
        }

        a {
            display: flex;
            align-items: center;
            text-decoration: none;
            margin-left: rem(20);

            &:first-of-type {
                @include responsiveMin(992px) {
                    margin-left: 0;
                }
            }

            &:hover {
                background: transparent;

                i {
                    &:after {
                        opacity: 1;
                    }

                    &:before {
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: #fff;

                        @media screen and (-ms-high-contrast: active),
                            (-ms-high-contrast: none) {
                            color: #fff;
                        }
                    }
                }

                .icon-svg__path {
                    fill: #fff;
                }
            }

            i {
                position: relative;
                z-index: 2;
                width: rem(50);
                height: rem(50);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
                transition: all 0.3s;
                overflow: hidden;
                font-size: rem(18);

                &:after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    transition: all 0.3s;
                    background: $footer-feedback-btn-bg;
                    opacity: 0;
                }

                &:before {
                    background: $footer-feedback-btn-bg;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    z-index: 2;

                    @media screen and (-ms-high-contrast: active),
                        (-ms-high-contrast: none) {
                        color: rgba(255, 87, 77, 1);
                        background: none;
                    }
                }
            }
        }
    }

    &__honors {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 rem(-4);
        justify-content: center;

        @include responsiveMin(992px) {
            margin: auto;
        }
    }

    &__honors-desktop{
        background-color: $software-page__link-col;
        padding: rem(30);
        text-align: center;
    }

    &__honors-picture {
        padding: 0 rem(17);
        margin-bottom: rem(8);
        max-width: 50%;
        max-height: rem(90);

        &.usercentrics {
            max-width: 48%;
        }

        &.erecht {
            max-width: 56%;
        }

        &.itportal {
            flex: 0 1 auto;
        }

        &.we_train{
            max-width: 34.8%;
        }

        img {
            display: block;
            max-width: 100%;
            max-height: inherit;
        }

        @include responsiveMin(992px) {
            max-height: rem(106);
        }
    }

    &__col {
        @include responsiveMin(992px) {
            width: fit-content;
            margin-right: rem(80);

            &--last {
                margin-right: 0;
            }

            .footer__item {
                &:nth-of-type(2) {
                    margin-top: rem(20);
                }
            }
        }
    }

    &__item {
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.05);

        &:first-of-type {
            border-top: 1px solid rgba($color: #fff, $alpha: 0.05);
        }

        @include responsiveMin(992px) {
            border: none !important;
		}

        &_title {
            display: flex;
            justify-content: space-between;
            padding: rem(20);

            @include responsiveMin(992px) {
                padding: 0;
                margin-bottom: rem(20);
            }

            &.opened {
                &:after {
                    transform: rotate(180deg);
                }
            }

            p {
                font-weight: bold;
                font-size: rem(18);
                line-height: rem(21);
                color: #fff;
                text-transform: uppercase;
            }

            &:after {
                content: '';
                display: block;
                width: rem(20);
                height: rem(20);
                background-image: url('./images/arrow-icon.svg');
                transition: .5s;

                @include responsiveMin(992px) {
                    display: none;
                }
            }
        }

        &_inner {
            overflow: hidden;
            transition: 0.5s;

            @include responsiveMin(992px) {
                height: auto !important;
                overflow: visible;
            }
        }

        &_menu {
            padding: 0 rem(20) rem(20) rem(20);

            @include responsiveMin(992px) {
                padding: 0;
            }

            li {
                margin-bottom: rem(20);

                @include responsiveMin(992px) {
                    margin-bottom: rem(15);
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            a, p {
                word-break: break-word;
                overflow-wrap: break-word;
                font-weight: bold;
                font-size: rem(14);
                line-height: rem(16);
                text-decoration: none;
                color: rgba($color: #fff, $alpha: .5);
            }

            a {
                transition: .3s;

                &:hover {
                    color: #fff;
                }
            }
        }
    }

    &__contacts {
        &_phone, &_email, &_address > div {
            display: flex;

            i {
                width: rem(14);
                margin-right: rem(8);

                &:before {
                    color: rgba($color: #fff, $alpha: .5);
                    transition: .3s;
                }
            }
        }

        &_phone, &_email {
            span {
                text-decoration: underline;
            }

            &:hover {
                i:before {
                    color: #fff;
                }

                span {
                    text-decoration: none !important;
                    color: #fff;
                }
            }
        }

        &_phone {
            margin-bottom: rem(20);

            @include responsiveMin(992px) {
                margin-bottom: rem(15);
            }

            i:before {
                font-size: rem(12);
            }
        }

        &_email {
            margin-bottom: rem(40);

            @include responsiveMin(992px) {
                margin-bottom: rem(35);
            }

            i:before {
                font-size: rem(11);
            }
        }

        &_address {
            i:before {
                font-size: rem(16);
            }

            > p {
                padding-left: rem(22);
            }

            > p, & > div {
                margin-bottom: rem(20);

                @include responsiveMin(992px) {
                    margin-bottom: rem(15);
                }
            }
        }
    }
    &__smoke{
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-image: url('./images/smoke.svg');
        width: 100%;
        height: 42px;
        background-repeat: repeat-x;
    }

    //rocket images
    &__mobile-rocket {
        display: block;
        margin: 0 auto;
        margin-top: rem(30);
        max-width: 80%;
        margin-bottom: rem(15);

        @include responsiveMin(992px) {
            display: none;
        }
    }

    &__desktop-rocket {
        display: none;

        @include responsiveMin(992px) {
            display: block;
            position: absolute;
            right: 0;
            bottom: rem(-1);
            width: rem(281);
        }
    }

    //footer-bottom
	&__bottom {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
		padding: rem(10);
        background-color: rgba(118, 121, 148, 0.1);

		@include responsiveMin(992px) {
            flex-direction: row;
            justify-content: space-between;
			padding: rem(5) rem(20);
		}

        nav, p {
            width: fit-content;
        }

        nav {
            margin-bottom: rem(10);

            @include responsiveMin(992px) {
                margin-bottom: 0;
                order: 2;
            }

            ul {
                display: flex;

                li {
                    margin-right: rem(5);

                    &:last-of-type {
                        margin-right: 0;

                        a:after {
                            display: none;
                        }
                    }

                    a {
                        font-weight: bold;
                        font-size: rem(10);
                        color: #3E3E53;
                        text-decoration: none;
                        transition: .3s;

                        &:hover {
                            color: #9F9FA9;
                        }

                        &:after {
                            content: '|';
                            margin: 0 rem(5);
                            color: #3E3E53;
                            opacity: 0.1;
                        }
                    }
                }
            }
        }

        p {
            font-size: rem(10);
            color: #3E3E53;
            opacity: 0.7;

            @include responsiveMin(992px) {
                order: 1;
            }
        }
	}

    //footer-fixed-buttons
	&__btn {
		@include responsiveMax(991px) {
			flex: 0 0 50%;
			height: rem(60);
		}

		&-scroll-top {
			background: #fff;

			@include responsiveMin(992px) {
				position: fixed;
				bottom: rem(120);
				right: rem(10);
				z-index: 98;
				opacity: 0;
				transition: opacity 0.3s;
				background: transparent;
			}

			&.visible {
				opacity: 1;
			}

			button {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-decoration: none;
				cursor: pointer;
				background: transparent;
				border: none;
				border-top: 1px solid rgba(62, 62, 83, 0.05);
				outline: 0;

				@include responsiveMin(992px) {
					width: rem(80);
					height: rem(80);
					border: none;

					&:hover {
						.footer__btn {
							&-icon {
								transform: translateY(rem(-4));
							}
						}
					}
				}
			}

			.footer__btn {
				&-text {
					font-weight: bold;
					font-size: rem(14);
					line-height: rem(16);
					text-transform: uppercase;
					padding-top: rem(4);
					text-align: center;
					color: #fff;
					transition: color 0.3s;

					@media screen and (-ms-high-contrast: active),
						(-ms-high-contrast: none) {
						white-space: pre-wrap;
						word-wrap: break-word;
					}

					@include responsiveMax(991px) {
						background: $main_gradient;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}

				&-icon {
					transition: all 0.3s;

					i {
						color: #fff;
						transition: color 0.3s;
						font-size: rem(6);

						@include responsiveMax(991px) {
							background: $main_gradient;
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
						}
					}
				}
			}
		}

		&--dark-color {
			@include responsiveMin(992px) {
				.footer__btn {
					&-icon {
						i {
							color: #3e3e53;
						}
					}

					&-text {
						color: #3e3e53;
					}
				}
			}
		}

		&-feedback {
			position: relative;

			@include responsiveMin(992px) {
				display: none;
			}

			a {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-decoration: none;

				&:before,
				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
					transition: all 0.3s;
				}

				&:before {
					background: $main_gradient;
				}

				&:after {
					background: $main_gradient_hover;
					opacity: 0;
				}

				&:hover {
					&:after {
						opacity: 1;
					}

					.footer__btn {
						&-icon {
							transform-origin: top center;
							animation-name: swing;
							animation-duration: 1s;
							animation-fill-mode: both;
						}
					}
				}
			}

			.footer__btn {
				&-text {
					font-weight: bold;
					font-size: rem(22);
					line-height: rem(26);
					text-transform: uppercase;
					color: #ffffff;
					padding-top: rem(10);

					@include responsiveMax(991px) {
						font-size: rem(14);
						line-height: rem(16);
						padding-top: rem(4);
					}
				}

				&-icon {
					transition: all 0.3s;

					i {
						color: #fff;
						font-size: rem(20);

						@include responsiveMax(991px) {
							font-size: rem(12);
						}
					}
				}
			}
		}
	}
}

@keyframes swing {
	20% {
		-webkit-transform: rotate3d(0, 0, 1, 15deg);
		transform: rotate3d(0, 0, 1, 15deg);
	}

	40% {
		-webkit-transform: rotate3d(0, 0, 1, -10deg);
		transform: rotate3d(0, 0, 1, -10deg);
	}

	60% {
		-webkit-transform: rotate3d(0, 0, 1, 5deg);
		transform: rotate3d(0, 0, 1, 5deg);
	}

	80% {
		-webkit-transform: rotate3d(0, 0, 1, -5deg);
		transform: rotate3d(0, 0, 1, -5deg);
	}

	to {
		-webkit-transform: rotate3d(0, 0, 1, 0deg);
		transform: rotate3d(0, 0, 1, 0deg);
	}
}
/* END footer */

//google-reviews-badge
#google-reviews-badge {
	z-index: 100;

	&:hover {
		[class*="BadgeContainer__Inner"] {
		    box-shadow: 0 0 rem(30) rgba(0, 0, 0, .5);
		}
	}

	[class*="BadgeContainer__Inner"] {
		width: rem(50);
		height: rem(50);
		bottom: rem(70);
		right: rem(10);
		margin: 0;
		box-shadow: 0 0 rem(20) rgba(0, 0, 0, .3);
		border-radius: 50%;
		border: 1px solid transparent;
		transition: .3s;

		@include responsiveMin(992px) {
		width: rem(60);
		height: rem(60);
		right: rem(20);
		bottom: rem(55);
		}
	}

	[class*="Rating__Container"] {
		display: none !important;
	}
}
