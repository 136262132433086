@import "../../../scss/vars";

@import "../../../scss/mixins";

.products {
    position: relative;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        z-index: -1;
    }

    @include responsiveMin(992px) {
        min-height: 100vh;
    }

    &.transition {
        & * {
            transition: all 0.5s ease-in-out;
        }
    }

    &__bg {
        @include responsiveMin(992px) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 100vh;
            z-index: 1;
        }
    }

    &__wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        @include responsiveMin(992px) {
            flex-direction: row;
            align-items: center;
            width: 100%;
            z-index: 2;
        }
    }

    &__item {
        color: #fff;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        min-height: rem(360);

        @include responsiveMin(992px) {
            overflow: hidden;
            padding: rem(40) rem(111) rem(50);
            flex: 0 0 50%;
            max-width: 50%;
            min-height: 100%;
        }

        &--left {
            @include responsiveMax(991px) {
                padding-bottom: 0;
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            min-height: 100%;
        }

        &-logo {
            z-index: 2;
            margin: rem(30);
        }
    }

    &__img {
        display: none;
        @include responsiveMax(991px) {
            display: block;
            margin-top: rem(200);
            img {
                width: 100%;
            }
            &--second {
                order: -1;
                margin-top: 0;
                margin-bottom: rem(200);
            }
        }
    }
}

.products__bg {
    display: none;

    @include responsiveMin(992px) {
        display: flex;
    }

    &__wrapper {
        overflow: hidden;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;

        .products__tehnologies {
            position: absolute;
            display: flex;
            bottom: rem(50);
            color: #fff;
            width: 42%;
            justify-content: space-between;
            margin: 0 rem(111);
            text-align: center;
            font-weight: bold;
            line-height: rem(21);
            font-size: rem(18);

            .tehnologies-title {
                margin-top: rem(20);
            }
        }

        &--left {
            left: 0;
            z-index: 2;
            width: 50%;

            .products__bg__item {
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        &--right {
            right: 0;
            z-index: 1;

            .products__bg__item {
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
            .products__tehnologies {
                right: 0;
            }
        }
    }

    &__item {
        position: relative;
        padding: 9% 0 6%;
        width: 100vw;
        height: 100%;
    }

    &-img {
        @include responsiveMin(992px) {
            z-index: 3;
            position: absolute;
            max-width: rem(540);
            display: flex;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            transform-style: preserve-3d;
            perspective: rem(500);
            img {
                width: 100%;
            }
        }

        @include responsiveMin(1266px) {
            width: 100%;
            height: 100%;
            justify-content: center;
        }
    }
}
.products__bg-img--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.products__bg-img--title {
    font-weight: 900;
    font-size: rem(48);
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    @include responsiveMin(992px) {
        font-weight: 900;
        font-size: rem(100);
        line-height: 100%;
    }
}
.title-mobile {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: rem(-80);
}
