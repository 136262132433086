@import "../../scss/vars";
@import "../../scss/mixins";

@mixin button-gradient {
    &:before,
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        transition: opacity .3s;
        background: linear-gradient(349.38deg, #FF6826 0%, #FF4D66 49.48%, #FC6076 100%);
    }

    &:after {
        opacity: 0;
        z-index: 2;
    }

    @include responsiveMin(992px) {
        &:before {
            transform: rotate(-180deg);
        }

        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }
}


.button {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: rem(10);
    background: transparent;
    border: none;
    color: white;
    font-size: rem(18);
    text-transform: uppercase;
    font-weight: 700;
    user-select: none;

    &__label {
        z-index: 3;
    }

    &__icon {
        font-size: 1.2em;
        z-index: 3;
    }

    [class^="icon-"]::before, [class*=" icon-"]::before {
        color: inherit;
    }

    &__shadow {
        position: absolute;
        top: rem(-10);
        left: rem(-10);
        right: rem(-10);
        bottom: rem(-10);
        opacity: 0;
        pointer-events: none;
        
        .button:not(:hover) & {
            animation: shadow 2s ease-out infinite;
        }

        &::before {
            content: '';
            position: absolute;
            top: rem(5);
            left: rem(5);
            right: rem(5);
            bottom: rem(5);
            background: linear-gradient(315deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
            opacity: 0.5;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(315deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
            opacity: 0.2;
        }

        @keyframes shadow {
            0% {
                transform: scale(0.95, 0.8);
                opacity: 0;
            }
            50% {
                transform: scale(1, 1);
                opacity: 1;
            }
            100% {
                transform: scale(0.95, 0.8);
                opacity: 0.1;
            }
        }

        @keyframes hide-shadow {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
    }

    &--primary {
        width: 100%;
        height: rem(60);
        position: relative;

        @include button-gradient;

        @include responsiveMin(992px) {
            height: rem(50);
        }
    }

    &--secondary {
        width: 100%;
        height: rem(60);
        position: relative;

        @include button-gradient;

        @include responsiveMin(992px) {
            height: rem(50);
        }
    }

    &--flat {
        @include responsiveMax(991px) {
            font-size: rem(14);
        }
    }
}

.button-submit {
    position: relative;
    text-align: center;

    button {
        height: rem(60);
        width: 100%;
        background: transparent;
        position: relative;
        border: none;
        transition: all .3s;
        overflow: hidden;

        @include button-gradient;

        @include responsiveMax(767px) {
            width: 100%;
        }

        @include responsiveMin(992px) {
            height: rem(50);
        }

        &.loading {
            width: rem(60);
            border-radius: 50%;

            @include responsiveMin(992px) {
                width: rem(50);
            }
        }
    }

    &__label {
        position: relative;
        z-index: 3;
        font-weight: bold;
        font-size: rem(18);
        line-height: rem(21);
        text-transform: uppercase;
        color: #FFFFFF;

        &--hidden {
            opacity: 0;
        }
    }

    &__label {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        position: absolute;
        top: rem(23);
        right: rem(30);
        z-index: 5;
        opacity: 1;
        transition: transform 2s cubic-bezier(0.4, -0.6, 0.3, 1), opacity 1s ease-in;

        @include responsiveMin(992px) {
            top: rem(17);
        }

        &--sending {
            transform: translate3d( 500px, 0, 0);
            opacity: .7;
        }
    }

    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
        opacity: 0;
        transition: opacity .3s;

        &--visible {
            opacity: 1;
        }
    }

    &__ring {
        display: inline-block;
        width: rem(60);
        height: rem(60);

        @include responsiveMin(992px) {
            width: rem(50);
            height: rem(50);
        }

        &::after {
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            margin: 1px;
            border-radius: 50%;
            border: rem(4) solid #fff;
            border-color: #fff transparent #fff transparent;
            animation: button-dual-ring 1.2s linear infinite;
        }
    }
    
    @keyframes button-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}