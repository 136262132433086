@import "../../../scss/vars";
@import "../../../scss/mixins";

/* START .hosting-page */

.hosting-reviews {
    padding: rem(70) 0 rem(80) 0;

    @include responsiveMax(991px) {
        padding: rem(30) rem(20);
    }

    &__title {
        position: relative;
        padding-bottom: rem(33);
        text-align: center;

        &-text {
            font-weight: 900;
            font-size: rem(48);
            line-height: rem(56);
            text-transform: uppercase;
            color: #3E3E53;
        }

        &-bg {
            display: none;
        }

        @include responsiveMax(991px) {
            padding-bottom: rem(20);

            &-text {
                font-size: rem($m_h2_font_size);
                line-height: rem($m_h2_line_height);
            }
        }

        @include responsiveMin(992px) {
            &-bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                z-index: 2;
                font-weight: 900;
                font-size: rem(96);
                line-height: rem(113);
                text-transform: uppercase;
                color: #3E3E53;
                opacity: 0.03;
                display: flex;
                justify-content: center;
                overflow: hidden;

                span {
                    white-space: nowrap;
                }
            }
        }
    }

    &__content {

        @include responsiveMin(992px) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__screen {
        margin: rem(30) 0;
        text-align: center;

        @include responsiveMin(992px) {
            flex: 0 0 rem(490);
            margin: 0 rem(165);
        }

        img {
            max-width: 100%;
        }
    }
    
    &__more-link {

        a {
            color: $services_list_bg;
            font-size: rem(14);
            line-height: rem(16);

            &:before {
                background: $services_list_bg;
            }
        }

        @include responsiveMax(991px) {
            text-align: center;
        }
    }

    &__review {

        @include responsiveMin(992px) {
            flex: 1;
            display: flex;
            flex-direction: column;

            &--left {
                padding-left: rem(150);

                .hosting-review {
                    &__project-logo {
                        left: 0;
                    }
                }
            }

            &--right {
                padding-right: rem(150);

                .hosting-review {
                    text-align: right;

                    &__photo {
                        display: inline-block;
                    }

                    &__project-logo {
                        right: 0;
                    }
                }

                .hosting-reviews {
                    &__more-link {
                        text-align: right;
                    }
                }
            }
        }

        @include responsiveMax(991px) {
            &--left {

                .hosting-review {
                    &__project-logo {
                        top: rem(0);
                    }
                }
            }
        }
    }

}

.hosting-review {
    flex: 1;
    padding-bottom: rem(20);
    position: relative;
    text-align: center;

    @include responsiveMin(992px) {
        padding-bottom: rem(76);
        text-align: left;
    }

    &__photo {
        width: rem(80);
        height: rem(80);
        margin: 0 auto;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        z-index: 2;

        img {
            width: 100%;
        }

        @include responsiveMin(992px) {
            margin: 0;
            width: rem(100);
            height: rem(100);
        }
    }

    &__author-name {
        font-weight: bold;
        padding: rem(10) 0 0;
        font-size: rem($m_h3_font_size);
        line-height: rem($m_h3_line_height);
        color: $services_list_bg;

        @include responsiveMin(992px) {
            padding: rem(20) 0 rem(5);
            font-size: rem(18);
            line-height: rem(21);
        }
    }

    &__author-position {
        padding-bottom: rem(10);
        font-size: rem(12);
        line-height: rem(14);
        color: $services_description_bg;

        @include responsiveMin(992px) {
            font-size: rem(14);
            line-height: rem(16);
            padding-bottom: rem(20);
        }
    }

    &__text {
        color: $services_list_bg;
        font-size: rem($m_text_font_size);
        line-height: rem($m_text_line_height);

        p {
            margin-top: rem(10);

            &:first-child {
                margin-top: 0;
            }
        }

        @include responsiveMin(992px) {
            font-size: rem($d_text_font_size);
            line-height: rem($d_text_line_height);
        }
    }

    &__project-logo {
        position: absolute;
        bottom: rem(28);
        width: 100%;

        img {
            max-width: 100%;
        }

        @include responsiveMin(992px) {
            top: rem(30);
        }
    }
}

.about-hosting {
    background: #3E3E53;
    padding: rem(30) rem(20);

    @include responsiveMin(992px) {
        padding: rem(100) 0 rem(60);
    }

    &__title {
        position: relative;
        padding: 0 0 rem(73);
        margin-left: rem(150);
        text-align: left;

        &-text {
            font-weight: 900;
            font-size: rem(48);
            line-height: rem(56);
            text-transform: uppercase;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #fff;
        }

        &-bg {
            display: none;
        }

        @include responsiveMax(991px) {
            text-align: center;
            margin: 0;
            padding: 0 0 rem(10);

            &-text {
                font-size: rem($m_h2_font_size);
                line-height: rem($m_h2_line_height);
            }
        }

        @include responsiveMin(992px) {
            &-bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: left;
                z-index: 2;
                font-weight: 900;
                font-size: rem(96);
                line-height: rem(113);
                font-feature-settings: 'pnum' on, 'lnum' on;
                text-transform: uppercase;
                color: #fff;
                opacity: 0.01;
                display: flex;
                justify-content: center;
                overflow: hidden;

                span {
                    white-space: nowrap;
                }
            }
        }
    }

    &__content {

        @include responsiveMin(992px) {
            padding:0 rem(30) 0 rem(300);
            display: flex;
            align-items: flex-end;
        }
    }

    &__description {
        font-size: rem($m_text_font_size);
        line-height: rem($m_text_line_height);
        color: #FFFFFF;

        p {
            margin-top: rem(10);

            &:first-child {
                margin-top: 0;
            }
        }

        @include responsiveMin(992px) {
            max-width: rem(727);
            margin-right: rem(30);
            font-size: rem(18);
            line-height: rem(27);
            text-align: left;

            p {
                margin-top: rem(15);

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    &__logotypes {
        display: flex;

        &-item {
            margin-left: rem(70);

            img {
                max-width: 100%;
            }
        }

        @include responsiveMax(991px) {
            justify-content: center;
            padding-top: rem(20);

            &-item {
                margin-left: 0;

                &:first-child {
                    margin-right: rem(20);
                }

                &:last-child {

                }
            }
        }
    }

    &__advantages {
        display: flex;
        padding-top: rem(100);

        @include responsiveMax(991px) {
            padding-top: 0;
            flex-wrap: wrap;
        }
    }

    &__advantage {
        flex: 0 0 25%;
        padding-left: rem(100);
        position: relative;
        z-index: 1;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex: none;
            width: 25%;
        }

        &-icon {
            width: rem(70);
            height: rem(70);

            svg {
                max-width: 100%;
                width: 100%;
                height: auto;
            }
        }

        &-label {
            font-weight: bold;
            font-size: rem(18);
            line-height: rem(21);
            color: #FFFFFF;
            padding-top: rem(20);
        }

        &-index {
            position: absolute;
            right: 0;
            z-index: -1;
            bottom: rem(-60);
            font-weight: 900;
            font-size: rem(400);
            line-height: rem(400);
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #FFFFFF;
            opacity: 0.02;
        }

        @include responsiveMax(991px) {
            flex: 0 0 50%;
            padding: rem(40) 0 0;
            text-align: center;

            &-icon {
                width: rem(50);
                margin: 0 auto;
            }

            &-label {
                font-size: rem(14);
                line-height: rem(16);
            }

            &-index {
                right: auto;
                top: rem(20);
                left: 0;
                font-size: rem(96);
                line-height: rem(96);
                bottom: auto;
            }
        }
    }
}

.hosting-split-screen {
    .solutions {
        position: relative;

        &__item {
            &--right-col {
                .solutions {
                    &__item {
                        &-title {
                            color: #3E3E53;
                        }

                        &-description {
                            color: #3E3E53;
                        }
                    }
                }
            }
        }
    }

    .view {
        &__wrapper {
            &--before {
                .view {

                    &__content {
                        background: #FF6826;
                    }
                }
            }
            &--after {
                .view {

                    &__content {
                        background: #fff;
                    }
                }
            }
        }
    }
}

/* END .hosting-page */