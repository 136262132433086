@import "../../../../scss/vars";
@import "../../../../scss/mixins";

/* START .included-services */
.included-services {
  padding: rem(100) 0 0;

  @include responsiveMax(991px) {
    padding: rem(30) 0;
  }

  &__title {
    position: relative;
    margin-bottom: rem(80);
    margin-left: rem(200);

    &-text {
      font-weight: 900;
      font-size: rem(48);
      line-height: rem(56);
      text-transform: uppercase;
      color: #3E3E53;
    }

    &-bg {
      display: none;
    }

    @include responsiveMin(992px) {
      &-bg {
        display: block;
        font-weight: 900;
        font-size: rem(96);
        line-height: rem(113);
        text-transform: uppercase;
        color: #3E3E53;
        opacity: 0.03;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    @include responsiveMax(991px) {
      margin: 0;
      text-align: center;
      padding: 0 rem(20) rem(20);

      &-text {
        font-size: rem($m_h2_font_size);
        line-height: rem($m_h2_line_height);
      }
    }
  }

  &__list {
    display: flex;
  }

  &__item {
    max-width: 50%;
    flex-grow: 1;
    padding: 0 rem(50) 0 rem(100);
    position: relative;

    @include responsiveMin(992px) {
      &:after {
        content: '';
        display: block;
        width: 1px;
        height: rem(320);
        background: #3E3E53;
        opacity: 0.1;
        position: absolute;
        right: 0;
        top: rem(30);
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

    &-inner {
      display: flex;
      justify-content: space-between;
    }

    &-index {
      @include responsiveMin(992px) {
        font-weight: 900;
        font-size: rem(400);
        line-height: rem(400);
        text-align: right;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #3E3E53;
        opacity: 0.03;
        margin-left: rem(-50);
      }
    }

    &-icon {
      margin-top: rem(70);
      position: relative;
      display: inline-block;

      img {
        max-width: 100%;
      }

      i {
        position: absolute;
        left: 100%;
        margin-left: rem(10);

        &:before {
          font-size: rem(22);
          color: #FF4D66;
        }
      }
    }

    &-title {
      margin-top: rem(20);
      font-weight: bold;
      font-size: rem(24);
      line-height: rem(28);
      color: #3E3E53;
      white-space: nowrap;
    }

    @include responsiveMax(991px) {
      padding: rem(5) rem(0) 0;
      text-align: center;
      border-right: 1px solid rgba(#3E3E53, 0.1);

      &:last-child {
        border: none;
      }

      &-inner {
        justify-content: center;
      }

      &-icon {
        padding: 0 rem(25);
        margin: 0;

        i {
          position: absolute;
          left: auto;
          right: rem(7);
          margin-left: 0;

          &:before {
            font-size: rem(12);
          }
        }
      }

      &-index {
        display: none;
      }

      &-title {
        white-space: normal;
        font-size: rem(14);
        line-height: rem(16);

        br {
          display: block;
        }
      }
    }
  }
}

/* END .included-services */
