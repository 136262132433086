@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.corporate-websites-page {
    color: #3E3E53;
    
    &--preloader {
        min-height: 100vh;
        position: relative;
        background: #3E3E53;
    
        .data-preloader {
            position: absolute;
            top: 0;
            left: 0;
        }

        .sk-cube {
            background: #FFF;
        }
    }

    h1 {
        color: #3E3E53;
        text-align: center;
        font-weight: 900;
        font-size: rem($m_h1_font_size);
        line-height: rem($m_h1_line_height);
        text-transform: uppercase;

        @include responsiveMin(992px) {
            font-size: rem($d_h1_font_size);
            line-height: rem($d_h1_line_height);
            max-width: rem(1000);
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
    }
    
    &__titling {
		position: relative;
		padding-top: rem(30);
        padding-bottom: rem(10);

        @include responsiveMax(991px) {
            max-width: 100%;
            margin-left: rem(20);
            margin-right: rem(20);
        }

        @include responsiveMin(992px) {
            padding-top: rem(100);
        }

		&-text {
			font-weight: $d_h1_font_weight;
            font-size: rem($m_h1_font_size);
            line-height: rem($m_h1_line_height);
			text-transform: uppercase;
			color: #3e3e53;
			text-align: center;

            a {
                color: #ff4d66;
            }
		}

		&-bg {
			display: none;
		}

        @include responsiveMin(992px) {
            &-text {
                font-size: rem($d_h1_font_size);
                line-height: rem($d_h1_line_height);
            }

			&-bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				text-align: center;
				z-index: 2;
				font-weight: 900;
				font-size: rem(96);
				line-height: rem(113);
				text-transform: uppercase;
				color: #3e3e53;
				opacity: 0.03;
				display: flex;
				justify-content: center;
				overflow: hidden;
                padding-top: rem(100);

				span {
					white-space: nowrap;
				}
			}
		}
	}

    section {
        padding: rem(20) rem(20) rem(30) rem(20);
        position: relative;

        @include responsiveMin(992px) {
            padding: 0;
            min-height: rem(771);

            &:nth-of-type(1) {
                padding-left: rem(150);
                padding-right: rem(100);
                min-height: rem(771);
    
                .number {
                    left: rem(150);
                    top: rem(425);
                }
            }

            &:nth-of-type(2) {
                padding-left: rem(50);
                padding-right: rem(170);
    
                .number {
                    left: auto;
                    right: rem(150);
                    top: rem(444);
                }
            }

            &:nth-of-type(3) {
                padding-top: rem(183);
                padding-left: rem(150);
                min-height: rem(800);
    
                .number {
                    left: rem(150);
                    top: rem(426);
                }
            }

            &:nth-of-type(4) {
                padding-top: rem(150);
                padding-left: rem(150);
                padding-right: rem(60);
    
                .number {
                    display: none;
                }
            }

            &:nth-of-type(5) {
                padding: rem(150);
                min-height: rem(890);
    
                .number {
                    left: rem(150);
                    top: rem(373);
                }
            }
        }

        .number {
            position: absolute;
            font-weight: 900;
            font-size: rem(96);
            line-height: 90%;
            color: rgba($color: #3E3E53, $alpha: .05);
            top: 0;
            left: rem(20);
            z-index: -1;
    
            @include responsiveMin(992px) {
                font-size: rem(400);
                line-height: 100%;
                top: rem(560);
            }
        }
    }

    &__title {
        position: relative;

        @include responsiveMin(992px) {
            margin-bottom: rem(50);
        }

        &-text {
            font-size: rem($m_h2_font_size);
            line-height: rem($m_h2_line_height);
            text-align: center;
            font-weight: $m_h2_font_weight;
            text-transform: uppercase;

            a {
                color: #ff4d66;
            }

            @include responsiveMin(992px) {
                width: fit-content;
                font-size: rem($d_h2_font_size);
                line-height: rem($d_h2_line_height);
                text-align: left;
            }
        }

        &-bg {
            display: none;

            @include responsiveMin(992px) {
                z-index: -10;
                display: block;
                min-width: 100vw;
                font-weight: 900;
                font-size: rem(96);
                line-height: 1;
                text-transform: uppercase;
                color: #3e3e53;
                opacity: 0.03;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        br {
            display: none;

            @include responsiveMin(992px) {
                display: block;
            }
        }
    }

    &__text {
        width: fit-content;

        a {
            color: #ff4d66;
        }

        p {
            font-size: rem($m_text_font_size);
            line-height: rem($m_text_line_height);
            margin-top: rem(10);
    
            @include responsiveMin(992px) {
                font-size: rem($d_text_font_size);
                line-height: rem($d_text_line_height);
                max-width: rem(500);
                margin-top: rem(20);
                margin-left: rem(300);
    
                &:first-of-type {
                    margin-left: rem(150);
                }
            }
        }

        h3 {
            font-size: rem($m_h3_font_size);
            line-height: rem($m_h3_line_height);
            margin-top: rem(10);
    
            @include responsiveMin(992px) {
                font-size: rem($d_h3_font_size);
                line-height: rem($d_h3_line_height);
                margin-left: rem(300);
                margin-top: rem(50);
                margin-top: rem(20);
            }
        }
    }

    &__image {
        width: 100%;
        max-width: rem(500);   
        margin: 0 auto;
        margin-top: rem(20);

        @include responsiveMin(992px) {
            max-width: none !important;  
            margin: 0;
        }
    }

    img {
        display: block;
        max-width: 100%;
    }

    .konzeption {
        margin-top: rem(10);

        @include responsiveMin(992px) {
            margin-top: 0;
            padding-top: rem(150);
        }

        &__image {
            @include responsiveMin(992px) {
                position: absolute;
                top: rem(150);
                right: rem(100);
                margin: 0;
                width: rem(870);
            }

            .laptop {
                position: relative;
                width: 75.862%;
                margin-left: auto;
                margin-right: 0;
                z-index: 0;

                @include responsiveMin(992px) {
                    width: rem(660);
                    animation-delay: 500ms !important;
                }
            }

            .tablet {
                width: 31%;
                margin-top: -50%;
                z-index: 1;

                @include responsiveMin(992px) {
                    width: rem(270);
                }
            }

            .phone {
                position: relative;
                width: 28.73%;
                margin-top: -22%;
                margin-left: auto;
                margin-right: 0;
                z-index: 2;

                @include responsiveMin(992px) {
                    width: rem(250);
                    animation-delay: 1000ms !important;
                }
            }

            .laptop, .tablet, .phone {
                img {
                    width: 100%;
                }
            }
        }
    }

    .design {
        @include responsiveMin(992px) {
            display: flex;
            padding-top: rem(130);
            padding-left: rem(150);
            padding-right: rem(320);

            &__textHolder {
                order: 2;
                margin-top: rem(20);
                margin-left: auto;
            }
        }

        .corporate-websites-page__text1 {
            width: fit-content;

            a {
                color: #ff4d66;
            }
    
            p {
                font-size: rem($m_text_font_size);
                line-height: rem($m_text_line_height);
                margin-bottom: rem(16);
                
                &:last-of-type {
                    margin-bottom: 0;
                }
        
                @include responsiveMin(992px) {
                    font-size: rem($d_text_font_size);
                    line-height: rem($d_text_line_height);
                    max-width: rem(500);
                    margin-left: rem(150);
                }
            }
    
            h3 {
                font-size: rem($m_h3_font_size);
                line-height: rem($m_h3_line_height);
                margin-bottom: rem(10);
        
                @include responsiveMin(992px) {
                    font-size: rem($d_h3_font_size);
                    line-height: rem($d_h3_line_height);
                    margin-bottom: rem(20);
                }
            }
        }

        .corporate-websites-page__text2 {
            width: fit-content;

            a {
                color: #ff4d66;
            }
    
            p {
                font-size: rem($m_text_font_size);
                line-height: rem($m_text_line_height);
                margin-bottom: rem(10);
        
                @include responsiveMin(992px) {
                    font-size: rem($d_text_font_size);
                    line-height: rem($d_text_line_height);
                    max-width: rem(500);
                    margin-left: rem(300);
                    margin-bottom: rem(20);
                }
            }
    
            h3 {
                font-size: rem($m_h3_font_size);
                line-height: rem($m_h3_line_height);
                margin-top: rem(10);
                margin-bottom: rem(10);
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
        
                @include responsiveMin(992px) {
                    font-size: rem($d_h3_font_size);
                    line-height: rem($d_h3_line_height);
                    margin-left: rem(300);
                    margin-top: rem(20);
                    margin-bottom: rem(20);
                }
            }
        }

        &__icon {
            margin-top: rem(20);
            width: rem(70);
            height: rem(70);
            margin-left: auto;
            margin-right: auto;

            @include responsiveMin(992px) {
                margin-left: rem(300);
                margin-top: rem(50);
            }
        }

        &__image {
            position: relative;

            @include responsiveMin(992px) {
                width: rem(821);
                height: rem(700);
            }

            .card1 {
                width: 37.65%;
                filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2));

                @include responsiveMin(992px) {
                    width: rem(320);
                    height: rem(640);
                }

                img {
                    width: 100%;
                }
            }

            .card2 {
                filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2));
                width: 70.59%;
                margin-top: -66%;
                margin-left: 28%;
                

                @include responsiveMin(992px) {
                    width: rem(614);
                    height: rem(404);
                    margin-top: -68%;
                    margin-left: 29%;
                }

                img {
                    width: 100%;
                }
            }

            .card3 {
                filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2));
                width: 28.23%;
                margin-top: -19%;
                margin-left: 46%;
                

                @include responsiveMin(992px) {
                    width: rem(240);
                    height: rem(390);
                    margin-top: -19%;
                    margin-left: 48%;
                }

                img {
                    width: 100%;
                }
            }
        }

        &__link-show {
            margin-right: 0;
            margin-left: auto;
            width: fit-content;

            a {
                color: #3E3E53;
    
                &:before {
                    background: #3E3E53;
                }
            }
        }
    }

    .machine-optimization {
        margin-top: rem(10);

        @include responsiveMin(992px) {
            margin-top: 0;
        }

        .corporate-websites-page__text1 {
            width: fit-content;

            a {
                color: #ff4d66;
            }
    
            p {
                font-size: rem($m_text_font_size);
                line-height: rem($m_text_line_height);
                margin-bottom: rem(16);

                &:last-of-type {
                    margin-bottom: 0;
                }
        
                @include responsiveMin(992px) {
                    font-size: rem($d_text_font_size);
                    line-height: rem($d_text_line_height);
                    max-width: rem(500);
                    margin-left: rem(150);
                }
            }
    
            h3 {
                font-size: rem($m_h3_font_size);
                line-height: rem($m_h3_line_height);
                margin-bottom: rem(10);
        
                @include responsiveMin(992px) {
                    font-size: rem($d_h3_font_size);
                    line-height: rem($d_h3_line_height);
                    margin-bottom: rem(20);
                }
            }
        }

        .corporate-websites-page__text2 {
            width: fit-content;

            a {
                color: #ff4d66;
            }
    
            p {
                font-size: rem($m_text_font_size);
                line-height: rem($m_text_line_height);
                margin-bottom: rem(10);
        
                @include responsiveMin(992px) {
                    font-size: rem($d_text_font_size);
                    line-height: rem($d_text_line_height);
                    max-width: rem(500);
                    margin-left: rem(300);
                    margin-bottom: rem(20);
                }
            }
    
            h3 {
                font-size: rem($m_h3_font_size);
                line-height: rem($m_h3_line_height);
                margin-top: rem(10);
                margin-bottom: rem(10);
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
        
                @include responsiveMin(992px) {
                    font-size: rem($d_h3_font_size);
                    line-height: rem($d_h3_line_height);
                    margin-left: rem(300);
                    margin-top: rem(20);
                    margin-bottom: rem(20);
                }
            }
        }

        &__icon {
            margin-top: rem(20);
            width: rem(70);
            height: rem(70);
            margin-left: auto;
            margin-right: auto;

            @include responsiveMin(992px) {
                margin-left: rem(300);
                margin-top: rem(50);
            }
        }

        &__image {
            position: relative;
            width: calc(100% + 60px);
            margin-right: -60px;

            img {
                width: 100%;
                height: 100%;
            }

            @include responsiveMin(992px) {
                width: rem(950);
                right: 0 !important;
                position: absolute;
                top: rem(150);
                margin: 0;
            }
        }
    }

    .online-marketing {
        margin-top: rem(10);

        @include responsiveMin(992px) {
            margin-top: 0;
            padding-top: rem(150);
        }

        &__image {
            margin-top: 23%;

            @include responsiveMin(500px) {
                margin-top: rem(100);
            }

            @include responsiveMin(992px) {
                position: absolute;
                top: rem(270);
                right: rem(100);
                margin: 0;
                width: rem(870);
            }

            .card1 {
                position: relative;
                width: 71.78%;
                filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2));
                z-index: 1;

                @include responsiveMin(992px) {
                    width: rem(646);
                    height: rem(162);
                }

                img {
                    width: 100%;
                }
            }

            .card2 {
                position: relative;
                filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2));
                width: 66.44%;
                z-index: 2;
                margin-top: -33.5%;
                margin-left: 32%;
                

                @include responsiveMin(992px) {
                    width: rem(598);
                    height: rem(162);
                    margin-top: -34.5%;
                    margin-left: 32%;
                }

                img {
                    width: 100%;
                }
            }

            .speaker {
                width: 44.44%;
                z-index: 0;
                margin-top: -7.5%;
                margin-left: 43%;
                

                @include responsiveMin(992px) {
                    width: rem(400);
                    height: rem(506);
                    margin-top: -3.5%;
                    margin-left: 42.5%;
                }

                img {
                    width: 100%;
                }
            }
        }
    }

    .content-management {
        @include responsiveMin(992px) {
            min-height: auto !important;

            &__top {
                display: flex;
                position: relative;
                min-height: rem(288);
            }

            &__textHolder {
                order: 2;
                margin-top: rem(20);
                margin-left: auto;

                .corporate-websites-page__text {
                    padding-right: rem(240);
                }
            }
        }

        &__bottom {
            @include responsiveMin(992px) {
                display: flex;
                margin-top: rem(66);
                min-height: rem(286);
            }

            &_number {
                position: absolute;
                display: flex;
                left: 0;
                top: rem(70);
                pointer-events: none;

                @include responsiveMin(992px) {
                    left: auto;
                    right: rem(90);
                    top: 0;
                }

                .chapter-number {
                    font-weight: 900;
                    font-size: rem(30);
                    line-height: 90%;
                    color: rgba($color: #3E3E53, $alpha: .05);
                    top: 0;
                    left: rem(20);
                    z-index: -1;
            
                    @include responsiveMin(992px) {
                        font-size: rem(90);
                        line-height: 100%;
                        top: 0;
                        margin-top: rem(-10);
                    }
                }

                .underchapter-number {
                    font-weight: 900;
                    font-size: rem(96);
                    line-height: 90%;
                    color: rgba($color: #3E3E53, $alpha: .05);
                    top: 0;
                    left: rem(20);
                    z-index: -1;
            
                    @include responsiveMin(992px) {
                        font-size: rem(400);
                        line-height: 100%;
                        top: rem(560);
                        margin-top: rem(-57);
                    }
                }
            }

            &_item {
                position: relative;
                margin-top: rem(20);

                @include responsiveMin(992px) {
                    width: 33.3333%;
                }
            }

            &_icon {
                margin-bottom: rem(10);
                margin-left: auto;
                margin-right: auto;

                @include responsiveMin(992px) {
                    margin-left: 0;
                    margin-bottom: rem(50);
                    width: rem(70);
                }
            }

            &_text {
                a {
                    color: #ff4d66;
                }

                p {
                    font-size: rem($m_text_font_size);
                    line-height: rem($m_text_line_height);
                    margin-top: rem(10);
            
                    @include responsiveMin(992px) {
                        font-size: rem($d_text_font_size);
                        line-height: rem($d_text_line_height);
                        max-width: rem(360);
                    }
                }
        
                h3 {
                    width: fit-content;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: rem(10);
                    font-size: rem($m_h3_font_size);
                    line-height: rem($m_h3_line_height);

                    @include responsiveMin(992px) {
                        font-size: rem($d_h3_font_size);
                        line-height: rem($d_h3_line_height);
                        margin-left: 0;
                    }
                }
            }
        }

        &__image {
            position: relative;
            display: flex;
            align-items: center;

            @include responsiveMin(992px) {
                width: rem(716);
                height: rem(250);
            }

            .gear-wrapper {
                position: relative;

                img {
                    &:first-child {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        filter: drop-shadow(0px 20px 50px rgba(0, 0, 0, 0.2));

                        @include responsiveMin(1920px) {
                            filter: drop-shadow(0px rem(20) rem(50) rgba(0, 0, 0, 0.2));
                        }
                    }

                    &:last-child {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &--1 {
                   width: 33%; 

                    @include responsiveMin(992px) {
                        width: rem(236);
                        height: rem(250);
                    }

                    img {
                        &:first-child {
                            animation: rotation 5s linear infinite;
                        }

                        &:last-child {
                            width: 67.8%;
                        }
                    }
                }

                &--2 {
                    width: 26.5%; 
                    margin-left: rem(10);
                    margin-right: rem(10);

                    @include responsiveMin(992px) {
                        width: rem(190);
                        height: rem(200);
                        margin-left: rem(20);
                        margin-right: rem(20);
                    }

                    img {
                        &:first-child {
                            animation: rotation 5s linear infinite reverse;
                        }

                        &:last-child {
                            width: 68.5%;
                        }
                    }
                }

                &--3 {
                    width: 33.5%; 

                    @include responsiveMin(992px) {
                        width: rem(240);
                        height: rem(250);
                    }

                    img {
                        &:first-child {
                            animation: rotation 5s linear infinite;
                        }

                        &:last-child {
                            width: 60.4%;
                        }
                    }
                }
            }
        }
    }

    .tsparticles {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    // fade animations
    @include responsiveMin(992px) {
        .anim-item {
            .fadeInUp {
                will-change: transform;
                transform: translateY(200%);
                opacity: 0;
            }

            .fadeInLeft {
                will-change: transform;
                transform: translateX(-200%);
                opacity: 0;
            }

            .fadeInRight {
                will-change: transform;
                transform: translateX(200%);
                opacity: 0;
            }

            &.active {
                .fadeInUp {
                    animation: fadeInUp 1.5s ease forwards;
                }

                .fadeInLeft {
                    animation: fadeInLeft 1.5s ease forwards;
                }

                .fadeInRight {
                    animation: fadeInRight 1.5s ease forwards;
                }
            }
        }
    }

    @keyframes fadeInUp {
        from {
            transform: translateY(200%);
            opacity: 0;
        }

        to {
            transform: translateY(0);
            opacity: 1
        }
    }

    @keyframes fadeInLeft {
        0% {
            transform: translateX(-200%);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes fadeInRight {
        0% {
            transform: translateX(200%);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes rotationBack {
        0% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
}