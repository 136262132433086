@import '../../../scss/vars';
@import '../../../scss/mixins';

/* START .partner-page */

.partner-page {
    &--preloader {
        min-height: 100vh;
        position: relative;

        .data-preloader {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__title {
        position: relative;
        margin-bottom: rem(30);

        &-text {
            font-weight: $d_h1_font_weight;
            font-size: rem($m_h1_font_size);
            line-height: rem($m_h1_line_height);
            text-transform: uppercase;
            color: #3e3e53;
            text-align: center;
        }

        &-bg {
            display: none;
        }

        @include responsiveMin(992px) {
            margin-bottom: rem(100);

            &-text {
                font-size: rem($d_h1_font_size);
                line-height: rem($d_h1_line_height);
            }

            &-bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                z-index: 2;
                font-weight: 900;
                font-size: rem(96);
                line-height: rem(113);
                text-transform: uppercase;
                color: #3e3e53;
                opacity: 0.03;
                display: flex;
                justify-content: center;
                overflow: hidden;

                span {
                    white-space: nowrap;
                }
            }
        }
    }

    &__content {
        padding: rem(30) rem(20);

        @include responsiveMin(992px) {
            padding: rem(100) rem(20);

            &:before,
            &:after {
                position: absolute;
                z-index: -1;
                display: block;
                content: '';
                opacity: 0.03;
            }

            &:before {
                width: rem(300);
                height: rem(350);
                background: url('../../../images/gears-right-dark.svg')
                    no-repeat;
                background-size: contain;
                top: rem(224);
                left: rem(250);
            }

            &:after {
                width: rem(350);
                height: rem(400);
                background: url('../../../images/gears-left-dark.svg') no-repeat;
                background-size: contain;
                top: rem(280);
                right: rem(250);
            }
        }
    }

    &__list {
        @include responsiveMin(992px) {
            max-width: rem(1150);
            margin: 0 auto;
        }
    }

    &__item {
        margin-bottom: rem(50);

        &:last-child {
            margin-bottom: 0;
        }

        @include responsiveMin(992px) {
            display: flex;
            margin-bottom: rem(100);
        }

        &-logo {
            width: rem(200);

            a {
                width: 100%;
                display: block;
                text-decoration: none;
            }

            img {
                width: 100%;
                max-width: 100%;
                height: auto;
            }

            @include responsiveMin(992px) {
                width: rem(360);
                margin-right: rem(70);
            }
        }

        &-text-container {
            @include responsiveMin(992px) {
                flex: 1;
            }
        }

        &-title {
            font-weight: $d_h2_font_weight;
            font-size: rem($m_h2_font_size);
            line-height: rem($m_h2_line_height);
            color: #3e3e53;
            margin: rem(10) 0 rem(10);

            a {
                text-decoration: none;
                color: #3e3e53;
            }

            @include responsiveMin(992px) {
                font-size: rem($d_h2_font_size);
                line-height: rem($d_h2_line_height);
                margin: 0 0 rem(15);
            }
        }

        &-description {
            font-size: rem($m_text_font_size);
            line-height: rem($m_text_line_height);
            color: #3e3e53;

            @include responsiveMin(992px) {
                font-size: rem($d_text_font_size);
                line-height: rem($d_text_line_height);
            }

            p {
                margin-top: rem(10);

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        &-button {
            display: block;
            text-decoration: none;
            margin-top: rem(20);

            span {
                font-family: Raleway;
                font-style: normal;
                font-weight: bold;
                color: #353540;
                text-transform: none;

                &::before {
                        background: #353540;
                }
            }
        }
    }

    &__top-bg,
    &__bottom-bg {
        display: none;

        @include responsiveMin(992px) {
            display: block;
            position: absolute;
            z-index: -1;

            &:before,
            &:after {
                position: absolute;
                z-index: -1;
                display: block;
                content: '';
            }
        }
    }

    &__top-bg {
        top: 0;
        left: 0;

        @include responsiveMin(992px) {
            &:before {
                width: rem(800);
                height: rem(200);
                background: url('../../../images/bg-el-1-dark.svg') no-repeat;
                background-size: contain;
                opacity: 0.03;
            }
        }
    }

    &__bottom-bg {
        bottom: 0;
        right: 0;
        transform: rotate(180deg);

        @include responsiveMin(992px) {
            &:after {
                width: rem(420);
                height: rem(350);
                background: url('../../../images/bg-el-2-dark.svg') no-repeat;
                background-size: contain;
                opacity: 0.04;
            }
        }
    }
}

/* END .partner-page */
