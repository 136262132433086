@import "../../scss/vars";
@import "../../scss/mixins";

.field {
    margin-bottom: rem(20);
    opacity: .5;
    transition: all .3s;

    &--active,
    &--error,
    &--filled {
        opacity: 1;

        .field__input {

            input,
            textarea {
                background: rgba(255, 255, 255, .1);
            }
        }
    }

    &--active {
        .field__input {

            .field__label-block {
                display: block !important;
                top: rem(10);
                font-size: rem(12);
                line-height: rem(14);
            }

            input,
            textarea {
                padding: rem(25) rem(15) rem(10) !important;
            }

            .field__input--clear {
                display: none !important;
            }

            &:before {
                height: 2px !important;
            }
        }
    }

    &--filled {
        .field__input {

            .field__label-block {
                display: none;
            }

            input {
                padding: rem(20) rem(15);
            }

            textarea {
                padding: rem(20) rem(15) rem(10);
            }

            .field__input--clear {
                display: block;
            }

            &:before {
                height: 1px;
            }
        }
    }

    &--error {
        .field__input {

            &:before {
                background: $footer-feedback-btn-bg;
            }
        }

        .field__label-text {
            color: #db4437;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__label-block {
        position: absolute;
        top: rem(20);
        padding-left: rem(15);
        width: 100%;
        line-height: 1.15;
        color: #fff;
        transition: all .3s ease;

        @include responsiveMin(992px) {
            top: rem(17);
        }
    }

    &__label {
        position: relative;
        padding-bottom: rem(6);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-text {
            display: block;
            font-weight: bold;
        }

        &-required {
            position: absolute;
            right: rem(15);
        }
    }

    &__input {
        position: relative;
        font-size: rem(14);

        @include responsiveMin(992px) {
            font-size: rem(12);
        }

        input,
        textarea {
            width: 100%;
            height: rem(55);
            background: rgba(255, 255, 255, .2);
            border: none;
            padding: rem(25) rem(15) rem(10);
            line-height: rem(16);
            color: #fff;
            outline: 0;
            transition: padding 0.3s linear;

            &::placeholder {
                opacity: 0;
                color: rgba(255, 255, 255, .3);
                font-feature-settings: 'pnum' on, 'lnum' on;
            }

            @include responsiveMin(992px) {
                height: rem(46);
                padding: rem(16) rem(15) rem(10);
            }
        }

        &--clear {
            display: none;
            position: absolute;
            top: rem(20);
            right: rem(15);
            cursor: pointer;

            i::before {
                font-size: rem(10);
            }

            @include responsiveMin(992px) {
                top: rem(17);
            }
        }

        textarea {
            display: block;
            max-width: 100%;
            height: rem(200);
            line-height: 150%;
            padding: rem(25) rem(15) rem(5);
            resize: vertical;

            &::-webkit-resizer {
                display: none;
            }

            @include responsiveMin(992px) {
                height: rem(168);
            }

            & + .field__input--clear {
                top: rem(5);
            }
        }

        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: #fff;
        }

        &--textarea {
            &::after {
                content: '';
                position: absolute;
                right: rem(6);
                bottom: rem(6);
                width: rem(20);
                height: rem(20);
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='icon/content/filter_list_24px' opacity='0.3'%3E%3Cpath id='icon/content/filter_list_24px_2' fill-rule='evenodd' clip-rule='evenodd' d='M2.5834 17.0118C2.25796 17.3373 2.25796 17.8649 2.5834 18.1903C2.90883 18.5158 3.43647 18.5158 3.76191 18.1903L18.19 3.76224C18.5154 3.4368 18.5154 2.90916 18.19 2.58372C17.8646 2.25829 17.3369 2.25829 17.0115 2.58372L2.5834 17.0118ZM15.101 17.4582C15.4264 17.7836 15.954 17.7836 16.2795 17.4582L17.458 16.2796C17.7834 15.9542 17.7834 15.4266 17.458 15.1011C17.1326 14.7757 16.6049 14.7757 16.2795 15.1011L15.101 16.2796C14.7755 16.6051 14.7755 17.1327 15.101 17.4582ZM10.1428 17.7022C9.81733 18.0276 9.28969 18.0276 8.96426 17.7022C8.63882 17.3767 8.63882 16.8491 8.96426 16.5237L16.5235 8.96444C16.8489 8.639 17.3766 8.639 17.702 8.96444C18.0274 9.28987 18.0274 9.81751 17.702 10.1429L10.1428 17.7022Z' fill='white'/%3E%3C/g%3E%3C/svg%3E");
                background-position: center;
                background-size: contain;
            }
        }
    }

    &__status {
        padding-top: rem(4);
        display: none;
        align-items: center;
        flex: 1;

        &--visible {
            display: flex !important;
        }

        &-msg {
            flex: 1;
            font-size: rem(10);
            line-height: rem(12);
            color: #DB4437;
        }

        &-icon {
            font-size: rem(10);
        }
    }
}
