@import "../../../../scss/vars";
@import "../../../../scss/mixins";

.about-us-page__content {
    .timeline {
        position: relative;
        overflow: hidden;
        color: #3e3e53;
        padding-top: rem(30);
        padding-left: rem(20);
        padding-right: rem(20);
    
        @include responsiveMin(992px) {
            padding-top: rem(150);
            padding-left: rem(150);
            padding-right: 0;
    
            &:before,
            &:after {
                content: "";
                position: absolute;
                background-position: center;
                background-size: cover;
                top: 0;
                right: 0;
            }
    
            &:before {
                width: rem(800);
                height: rem(200);
                background-image: url("../../../../images/bg-el-1-dark.svg");
                background-size: contain;
                opacity: 0.02;
                transform: scale(1, -1) rotate(180deg);
            }

            &:after {
                width: rem(600);
                height: rem(500);
                background-image: url("../../../../images/bg-el-2-dark.svg");
                background-size: contain;
                opacity: 0.02;
                transform: scale(1, -1) rotate(180deg);
            }
    
            .second_level {
                color: #ffffff;
                margin-bottom: rem(20);

                .second_level-title {
                    color: #ffffff;
                }
    
                @include responsiveMin(992px) {
                    margin: rem(130) 0 rem(105) rem(130);
                    font-size: rem(36);
                    line-height: rem(42);
                }
            }
        }
    
        &__title {
            margin-bottom: rem(20);
    
            @include responsiveMin(992px) {
                position: relative;
                margin-bottom: rem(100);
            }
    
            &_text {
                text-align: center;
    
                @include responsiveMin(992px) {
                    text-align: left;
                }
            }
    
            &_bg {
                display: none;
    
                @include responsiveMin(992px) {
                    display: block;
                    position: absolute;
                    top: 0;
                    text-transform: uppercase;
                    opacity: 0.03;
                    font-weight: 900;
                    font-size: rem(96);
                    line-height: rem(114);
                }
            }
        }
    
        &__wrapper {
            position: relative;

            @include responsiveMin(992px) {
                display: flex;
    
                &:after {
                    content: "";
                    position: absolute;
                    background-image: url("../images/TimeLine/smoke.svg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    bottom: rem(-25);
                    right: 0;
                    width: 60%;
                    min-height: rem(150);
                }
            }
        }

        &__start-image {
            position: absolute;
            width: rem(173);
            left: 45%;
            transform: translateX(-50%);
            bottom: rem(-1);

            @include responsiveMin(992px) {
                width: rem(353);
                left: rem(205);
                z-index: 2;
                transform: translateX(0);
            }
        }
    
        &__content {
            width: fit-content;
            margin: 0 auto;
            padding-bottom: rem(50);
    
            @include responsiveMin(992px) {
                width: 70%;
                margin: 0;
                padding-left: rem(65);
                padding-bottom: rem(170);
            }
        }
    
        &__item {
            position: relative;
            padding-bottom: rem(50);
    
            @include responsiveMin(992px) {
                padding-bottom: rem(130);
            }
    
            &:before {
                content: '';
                position: absolute;
                width: rem(2);
                height: 100%;
                top: 0;
                left: rem(32);
                transform: translateX(-50%);
                background: linear-gradient(315deg, #fc6076 0%, #ff4d66 48.44%, #ff6826 100%);
                z-index: 1;
    
                @include responsiveMin(992px) {
                    left: rem(64);
                }
            }

            &:first-of-type {
                &:before {
                    top: rem(10);
                }

                .timeline__item--inner {
                    align-items: flex-start;
                }

                .timeline__item_content {
                    padding-top: 0;

                    @include responsiveMin(992px) {
                        display: block;
                    }
                }

                .timeline__item_date {
                    padding-top: 0;

                    svg {
                        @include responsiveMin(992px) {
                            width: rem(80);
                            height: rem(43);
                        }
                    }
                }

                .timeline__item_title {
                    margin-top: rem(5);

                    @include responsiveMin(992px) {
                        margin-top: rem(8);
                        margin-bottom: rem(30);
                    }
                }

                img {
                    width: rem(112);
                    
                    @include responsiveMin(992px) {
                        width: rem(203);
                    }
                }
            }
    
            &:last-child {
                padding-bottom: 0;

                &:before {
                    display: none;
                }
            }
    
            &--inner {
                position: relative;
                width: fit-content;
                display: flex;
                position: relative;
                z-index: 2;

                &.image-only {
                    align-items: center;

                    .timeline__item_content {
                        padding-top: 0;
                    }
                }
            }

            &_content {
                padding-top: rem(19);
                margin-left: rem(30);

                @include responsiveMin(992px) {
                    margin-left: rem(75);
                    margin-top: rem(3);
                }

                &.no-achievements-title {
                    padding-top: 0;
                    margin-top: rem(2);

                    @include responsiveMin(992px) {
                        margin-top: rem(-4);
                    }
                }
            }
    
            &_date{
                font-weight: 900;
                text-transform: uppercase;
                position: relative;
                width: fit-content;
                height: fit-content;
                text-align: center;
                min-width: rem(65);
                padding-top: rem(15);
                padding-bottom: rem(15);
                background: #FFF;
    
                @include responsiveMin(992px) {
                    min-width: rem(130);
                    margin-right: rem(40);
                }

                span {
                    background: linear-gradient(315deg, #fc6076 0%, #ff4d66 48.44%, #ff6826 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: rem(24);
                    line-height: .8;

                    @include responsiveMin(992px) {
                        font-size: rem(48);
                    }
                }
            }
    
            &_title {
                font-size: rem(15);
                font-weight: 700;
                margin-bottom: 10px;
    
                @include responsiveMin(992px) {
                    font-size: rem(24);
                }
            }
    
            .achievements {
                .timeline__item_title {
                    padding-left: rem(14);

                    @include responsiveMin(992px) {
                        padding-left: rem(0);
                    }
                }

                &__container {
                    display: flex;
                    flex-wrap: wrap;
                    margin: rem(-10) rem(-8) 0;
        
                    @include responsiveMin(992px) {
                        margin: 0 rem(-15);
                    }
                }
    
                &__item {
                    padding: 0 rem(8);
                    margin-top: rem(10);
    
                    @include responsiveMin(992px) {
                        margin-top: 0;
                        padding: 0 rem(15);
                    }
    
                    &:nth-child(1) {
                        .achievements__item_number {
                            color: #FFBB50;
                        }
                    }
    
                    &:nth-child(2) {
                        .achievements__item_number {
                            color: #F599B9;
                        }
                    }
    
                    &:nth-child(3) {
                        .achievements__item_number {
                            color: #FF4D66;
                        }
                    }
    
                    &:nth-child(4) {
                        .achievements__item_number {
                            color: #767994;
                        }
                    }
    
                    &:nth-child(5) {
                        .achievements__item_number {
                            color: #FF6826;
                        }
                    }

                    &--inner {
                        min-width: rem(90);
                    }

                    &_number {
                        font-weight: 900;
                        font-size: rem(36);
                        line-height: rem(42);
                        text-align: center;
                        letter-spacing: -0.05em;
                        text-transform: uppercase;
    
                        @include responsiveMin(992px) {
                            font-size: rem(64);
                            line-height: rem(75);
                        }
                    }
    
                    &_text {
                        font-weight: bold;
                        font-size: rem(12);
                        line-height: rem(14);
                        text-align: center;
    
                        @include responsiveMin(992px) {
                            font-size: rem(18);
                            line-height: rem(21);
                        }
                    }
                }
            }

            .image-block {
                width: fit-content;
                padding-left: rem(14);

                @include responsiveMin(992px) {
                    padding-left: rem(0);
                }

                &.add-line {
                    &:before {
                        content: '';
                        display: block;
                        width: rem(2);
                        height: rem(25);
                        margin: rem(10) 0;
                        background: #FC6076;
                    }
                }

                img {
                    display: block;
                    height: rem(85);

                    @include responsiveMin(992px) {
                        height: rem(172);
                    }
                }
            }
        }
    
        &__rocket-container {
            position: relative;
            width: 30%;
        }
    
        &__rocket {
            position: absolute;
            right: 0;
            left: 0;
            top: 20%;
            bottom: 0;
            transition: all 0.1s linear;
    
            &:after {
                content: "";
                width: rem(45);
                transform: translateY(3%);
                height: 150%;
                background: #3e3e53;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 1;
                clip-path: polygon(40% 0%, 60% 0%, 100% 100%, 0% 100%);
    
                @include responsiveMin(992px) {
                    width: rem(100);
                }
            }
    
            img {
                position: absolute;
                right: 0;
                left: 0;
                margin: auto;
                z-index: 2;
                width: rem(129);
            }
        }
    
        .tsparticles {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    
        //code for smoke
        .exhaust-fumes {
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            bottom: -2%;
            right: -25%;
    
            .inner {
                display: block;
                background: #3e3e53 !important;
                border-radius: 50%;
                position: absolute;
                right: calc(var(--x) * 1px);
                bottom: rem(-70);
            }
    
            #smoke8 {
                animation: fumes2 calc((var(--y) * 0.05) * 1s) infinite;
            }
    
            #smoke3,
            #smoke5,
            #smoke7,
            #smoke9,
            #smoke11 {
                animation: fumes calc((var(--y) * 0.3) * 1s) infinite;
                animation-delay: calc((var(--y)) * 5ms);
            }
        }
    }
    
    @keyframes fumes {
        0% {
            transform: scale(0.5);
        }
    
        50% {
            transform: scale(1.1);
            background-color: transparent;
        }
        
        51% {
            transform: scale(1);
        }
    
        100% {
            background: #3E3E53;
            transform: scale(1.5);
        }
    }
    
    @keyframes fumes2 {
        50% {
            transform: scale(1.4);
        }
    }
}