@import "../../../../scss/vars";
@import "../../../../scss/mixins";

/* START .reviews */
.reviews {

    &__container {

        @include responsiveMin(992px) {
            display: flex;
            overflow: hidden;
        }
    }

    &__content {
        padding: rem(30) 0;

        @include responsiveMin(992px) {
            width: 100%;
            padding: rem(70) 0 0;
            transition: all .3s ease-in-out;
            overflow: hidden;
            position: relative;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        padding: 0 0 rem(20) 0;

        @include responsiveMin(992px) {
            padding: 0 rem(200) rem(50) rem(200);
            justify-content: space-between;
        }
    }

    &__title {
        font-weight: 900;
        font-size: rem($m_h2_font_size);
        line-height: rem($m_h2_line_height);
        text-transform: uppercase;
        color: #3E3E53;
        text-align: center;

        @include responsiveMin(992px) {
            font-size: rem($d_h2_font_size);
            line-height: rem($d_h2_line_height);
        }

        &-bg {
            display: none;

            @include responsiveMin(992px) {
                display: block;
                top: 0;
                left: rem(100);
                position: absolute;
                font-weight: 900;
                font-size: rem(96);
                line-height: rem(113);
                text-transform: uppercase;
                color: #3E3E53;
                opacity: 0.03;
                white-space: nowrap;
            }
        }
    }

    &__link-show-all {
        a {
            color: #3E3E53;

            &:before {
                background: #3E3E53;
            }
        }
    }

    &__footer {
        @include responsiveMax(991px) {
            text-align: center;
            padding-top: rem(30);
        }
    }

    &__list {
        width: 100%;
        &-item {
            padding: 0 0 rem(20);
            height: 100%;
            width: 100%;
        }
    }

    &__slide-btn-prev,
    &__slide-btn-next {
        display: none;

        @include responsiveMin(992px) {
            display: block;
            outline: 0;
            height: rem(20);
            width: rem(12);
            margin-top: rem(-10);
            background: none;
            position: absolute;
            z-index: 2;
            top: 50%;
            cursor: pointer;

            i {
                display: block;

                &:before {
                    font-size: rem(22);
                    color: #3E3E53;
                }
            }
        }

    }

    &__slide-btn-prev {
        left: rem(100);
    }

    &__slide-btn-next {
        right: rem(100);
    }

    &__pagination {
        position: relative;
        display: none;

        @include responsiveMax(991px) {
            display: flex;
            justify-content: center;
            bottom: 0 !important;

            span {
                margin: 0 rem(8) !important;
                width: rem(15);
                height: rem(15);
                opacity: 1;
                outline: 0;
                position: relative;
                background: transparent;
                display: block;
                color: transparent;
                font-size: 0;

                &:before,
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    border-radius: 50%;
                    transition: all .3s;
                }

                &:before {
                    background: rgba(#3E3E53, .2);
                    width: rem(10);
                    height: rem(10);
                }

                &:after {
                    width: 100%;
                    height: 100%;
                    background: $main_gradient;
                    opacity: 0;
                }

                &.swiper-pagination-bullet-active {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .review {
        overflow: hidden;
        opacity: 0;

        @include responsiveMin(992px) {
            display: flex;
            height: 100%;
            padding: 0;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                flex-wrap: wrap;
            }
        }

        &__project-mockup {

            @include responsiveMin(992px) {
                padding: 0 rem(200) 0 rem(10);
            }

            @include responsiveMax(991px) {
                padding: 0 rem(20);
            }

            img {
                max-width: 100%;

                @include responsiveMax(991px) {
                    display: block;
                    width: 100%;
                }
            }
        }

        &__photo-col {

            @include responsiveMin(992px) {
                flex: 0 0 50%;
                display: flex;
                align-items: flex-end;

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    flex: none;
                    width: 50%;
                }
            }
        }

        &__detailinfo-col {

            @include responsiveMin(992px) {
                flex: 0 0 50%;

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    flex: none;
                    width: 50%;
                }
            }
        }

        &__detailinfo-wrap {
            text-align: center;
            padding: 0 rem(20);

            @include responsiveMin(992px) {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 0 rem(10) 0 rem(200);
                text-align: left;
            }
        }

        &__text {
            flex: 1;
            text-align: left;

            p {
                font-size: rem($m_text_font_size);
                line-height: rem($m_text_line_height);
                color: #3E3E53;
                margin-top: rem(10);

                @include responsiveMin(992px) {
                    font-size: rem($d_text_font_size);
                    line-height: rem($d_text_line_height);
                    margin-top: rem(15);
                }
            }
        }

        &__project-logo {
            padding-bottom: rem(50);

            img {
                max-width: 100%;
            }

            @include responsiveMax(991px) {
                display: none;
            }
        }
    }

    .author {

        &__name {
            font-weight: bold;
            font-size: rem($m_h3_font_size);
            line-height: rem($m_h3_line_height);
            color: #3E3E53;

            @include responsiveMin(992px) {
                font-size: rem(24);
                line-height: rem(28);
            }
        }

        &__position {
            font-size: rem(12);
            line-height: rem(14);
            color: #767994;

            @include responsiveMin(992px) {
                font-size: rem(14);
                line-height: rem(16);
            }
        }

        &__photo {
            width: rem(100);
            height: rem(100);
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: rem(20);
            background: #EEEEEE;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                display: block;
                transition: all .3s;

                &.swiper-lazy {
                    opacity: 0;
                }

                &.swiper-lazy-loaded {
                    opacity: 1;
                }
            }
        }
    }
}

.swiper-slide {
    height: auto;
}

.progress-bar {
    &__desktop {
        display: none;

        @include responsiveMin(992px) {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: rem(10);
            z-index: 2;
        }

        &:before,
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
        }

        &:before {
            left: 0;
            background: $home_start_banner_gradient;
            z-index: 1;
        }

        &:after {
            right: 0;
            background: #EEEEEE;
            z-index: 2;
        }
    }

    &__mobile {
        display: none;

        @include responsiveMax(991px) {
            display: flex;
            align-items: center;
            padding-bottom: rem(10);
        }
    }

    &__before {
        flex: 1;
        height: rem(4);
        position: relative;
        z-index: 2;

        &:before,
        &:after {
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
        }

        &:before {
            z-index: 1;
            background: $main_gradient_hover;
            left: 0;
            width: 0;
        }

        &:after {
            z-index: 2;
            background: #F5F5F5;
            right: 0;
            width: 100%;
        }
    }

    &__center {
        flex: 0 0 rem(88);
        height: rem(88);
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        transform: scale(1.009);
        z-index: 3;

        &:before,
        &:after {
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
        }

        &:before {
            z-index: 1;
            background: #FF6826;
            left: 0;
            width: 0;
        }

        &:after {
            z-index: 2;
            background: #F5F5F5;
            right: 0;
            width: 100%;
        }
    }

    &__photo {
        width: rem(80);
        height: rem(80);
        position: relative;
        z-index: 3;
        border-radius: 50%;
        overflow: hidden;
        margin: rem(4) 0 0 rem(4);

        img {
            max-width: 100%;
        }
    }

    &__after {
        flex: 1;
        height: rem(4);
        position: relative;
        z-index: 2;

        &:before,
        &:after {
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
        }

        &:before {
            z-index: 1;
            background: $main_gradient;
            left: 0;
            width: 0;
        }

        &:after {
            z-index: 2;
            background: #F5F5F5;
            right: 0;
            width: 100%;
        }
    }
}

.swiper-slide-active {

    .review {
        opacity: 1;
    }

    &.show-content {

        .review {
            &__photo-col {
                animation: photo-col-position 1s 0s ease-out forwards;
            }

            &__detailinfo-col {
                animation: text-col-position 1s 0s ease-out forwards;
            }
        }
    }

    &.hide-content{

        .review {
            &__project-mockup {
                animation: photo-col-position 1s 7s ease-out reverse forwards;
            }

            &__detailinfo-wrap {
                animation: text-col-position  1s 7s ease-out reverse forwards;
            }
        }
    }

    &.play-timeline {

        .progress-bar {

            &__before {

                &:before,
                &:after {
                    animation: progress-bar-animation 2.9s linear forwards;
                }

                &:before {
                    animation-direction: reverse;
                }
            }

            &__center {

                &:before,
                &:after {
                    animation: progress-bar-animation 2.2s 2.9s linear forwards;
                }

                &:before {
                    animation-direction: reverse;
                }
            }

            &__after {

                &:before,
                &:after {
                    animation: progress-bar-animation 2.9s 5.1s linear forwards;
                }

                &:before {
                    animation-direction: reverse;
                }
            }

            &__desktop {

                &:after {
                    animation: progress-bar-animation 8s linear forwards;
                }
            }
        }
    }
}

// center - 27.5%
// before & after - 36.25%
// slide speed = 8s

@keyframes progress-bar-animation {
    0% {
        width: 100%;
    }

    100% {
        width: 0;
    }
}

@keyframes text-col-position {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes photo-col-position {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

///* END .reviews */