@import "../../../../../scss/mixins.scss";
@import "../../../../../scss/vars.scss";

.post-social-links {
    display: flex;
    justify-content: space-between;
    padding: rem(20) rem(5) rem(30);

    @include responsiveMin(700px) {
        justify-content: center;
    }

    @include responsiveMin(992px) {
        padding-right: rem(90);
        padding-bottom: rem(85);
        justify-content: space-between;
    }
    @include responsiveMin(1400px) {
        justify-content: start;
    }
    .social-icon {
        display: block;

        @include responsiveMin(992px) {
            margin-right: rem(20);
        }

        svg path {
            fill: url(#paint_linear_header);
            transition: fill 1s linear;
        }
        #gradient_disabled {
            opacity: 0;
            display: inline-block;
            -webkit-transition: opacity 0.5s linear;
            -moz-transition: opacity 0.5s linear;
            -o-transition: opacity 0.5s linear;
            transition: opacity 0.5s linear;
        }
        svg:hover path {
            fill: url(#paint_linear_header-white);
        }
        svg:hover #gradient_disabled {
            opacity: 1;
        }
    }
}

.social-links--fixed {
    flex-direction: column;
    padding: 0;
    position: absolute;
    top: rem(80);
    left: rem(150);
    display: flex;
    opacity: 0;

    &-fixed {
        position: fixed;
    }
    &-visible {
        animation: shareBarFlip 1s ease-in-out forwards;
        @keyframes shareBarFlip {
            from {
                transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
                animation-timing-function: ease-in;
                opacity: 0;
            }
            40% {
                transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
                animation-timing-function: ease-in;
            }
            60% {
                transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
                opacity: 1;
            }
            80% {
                transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
            }
            100% {
                transform: perspective(400px);
                opacity: 1;
            }
        }
    }
    rect {
        fill: url(#paint_linear_header);
    }
    svg path {
        fill: url(#paint_linear_header-white);
        transition: all 1s linear;
    }
    svg:hover rect {
        fill: url(#paint0_linear-1);
    }
}
.share-button {
    margin: rem(20) 0 rem(40);
    position: absolute;
    bottom: rem(-20);
    right: rem(20);

    &__footer {
        margin-bottom: 0;
        text-align: center;
    }
    &__footer {
        margin-bottom: rem(30);
    }
}
