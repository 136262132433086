@import "../../../../../scss/mixins.scss";
@import "../../../../../scss/vars.scss";

.post {
  &__info {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(40);
    flex-direction: column;
    gap: rem(30);

    @include responsiveMin(992px) {
      flex-direction: row;
    }

    &--details {
      display: flex;
      flex-direction: column;
      gap: rem(5);
    }

    span {
      font-size: rem(14);
      line-height: rem(21);

      @include responsiveMin(1612px) {
        font-size: rem(16);
        line-height: rem(24);
      }
    }
  }

  &__date,
  &__date--edited {
    display: flex;
    align-items: center;
    gap: rem(5);
    min-height: rem(20);
    width: 100%;

    svg{
      width: rem(20);
      height: rem(20);
      padding: rem(1);
      object-fit: cover;
    }
  }
}