@import "../../scss/vars";
@import "../../scss/mixins";

.contact-form {
    $this: &;
    $contact-page: #{$this}--page;
    $contact-block: #{$this}--block;
    $step-start: #{$this}--start;
    $step-fields-1: #{$this}--fields-1;
    $step-fields-2: #{$this}--fields-2;
    $step-success: #{$this}--success;

    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: rem(30) rem(20);
    margin: 0 rem(-20);
    color: #fff;
    z-index: 1;
    overflow: hidden;
    background-color: $contact-page__form-col-bg;

    @include responsiveMin(760px) {
        margin: 0;
        padding: rem(30);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;
        pointer-events: none;
        box-shadow: inset 0 0 rem(100) rem(70) rgba(#181c2d, 1);
    }

    &--page {
        height: 100%;
        min-height: 100vh;
        padding: rem(110) rem(20) rem(30);
        margin: 0;
        overflow: visible;
        background-color: transparent;

        @include responsiveMin(992px) {
            min-height: 0;
            padding-top: rem(180);
            padding-bottom: rem(20);
            
            &::before {
                top: -42%;
                bottom: -42%;
                right: 0;
                width: 200%;
                box-shadow: inset rem(460) 0 rem(160) rem(600) rgba(#181c2d, 1);
            }
        }

    }

    &__heading {
        position: relative;
        display: block;
        width: 100%;
        max-width: rem(340);
        font-weight: 900;
        font-size: rem(22);
        line-height: 1.16;
        color: #fff;
        z-index: 1;

        @include responsiveMin(992px) {
            font-size: rem(48);
        }

        &--desktop-hidden {
            @include responsiveMin(992px) {
                display: none;
            }
        }
    }

    &__glow {
        @include responsiveMin(992px) {
            position: absolute;
            top: rem(-130);
            left: rem(380);
            pointer-events: none;
        }

        &::before,
        &::after {
            @include responsiveMin(992px) {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: rem(320);
                height: rem(420);
                opacity: 0.1;
                filter: blur(136px);
                transform: matrix(0.99, 0.16, -0.07, 1, 0, 0);
                background: #ff4d66;
            }
        }

        &::after {
            left: rem(200);
            background: #ffb744;
        }
    }

    &__triangle {
        position: absolute;
        right: 0;
        bottom: 0;
        width: rem(1100);
        background: linear-gradient(to bottom left, transparent 0%, transparent 50%, #494a60 50%, #494a60 100%);
        opacity: 0.5;
        z-index: -1;
        pointer-events: none;

        @include responsiveMin(992px) {
            right: rem(200);
        }

        @include when-inside($contact-page) {
            @include responsiveMin(992px) {
                left: -38%;
            }
        }

        &::before {
            content: '';
            display: block;
            padding-top: 100%;
        }
    }

    &__lines {
        position: absolute;
        top: rem(200);
        right: rem(-10);
        background-color: #ffb744;
        width: rem(98);
        height: rem(3);
        opacity: 0.4;
        pointer-events: none;

        @include responsiveMin(992px) {
            width: rem(170);
            height: rem(7);
            top: rem(200);
            right: rem(40);
        }

        @include when-inside($contact-block) {
            transform: scale(0.8);
            opacity: 0.8;
        }
        
        &::before ,
        &::after {
            content: '';
            position: absolute;
            top: rem(25);
            left: 25%;
            width: 100%;
            height: 100%;
            background-color: #f599b9;

            @include responsiveMin(992px) {
                top: rem(55);
                left: 20%;
            }
        }

        &::after {
            background-color: #fc6076;
            top: rem(50);
            left: 50%;

            @include responsiveMin(992px) {
                top: rem(110);
                left: 40%;
            }
        }

        &--1 {
            @include when-inside(#{$contact-block}#{$step-start }) {
                top: rem(78);
                
                @include responsiveMin(992px) {
                    top: rem(184);
                    right: rem(-10);
                }
            }

            @include when-inside(#{$contact-block}#{$step-fields-1 }) {
                @include responsiveMin(992px) {
                    top: rem(130);
                    left: rem(-80);
                }
            }

            @include when-inside(#{$contact-block}#{$step-fields-2 }) {
                @include responsiveMin(992px) {
                    top: rem(130);
                    left: rem(-80);
                }
            }

            @include when-inside(#{$contact-block}#{$step-success }) {
                top: rem(78);
                
                @include responsiveMin(992px) {
                    display: none;
                }
            }
        }

        &--2 {
            top: auto;
            bottom: rem(330);
            left: rem(-30);

            @include when-inside($contact-page) {
                @include responsiveMax(991px) {
                    display: none;
                }
            }

            @include when-inside(#{$contact-block}#{$step-start }) {
                top: rem(372);
                left: rem(-50);

                @include responsiveMin(992px) {
                    top: rem(320);
                    left: rem(220);
                }
            }

            @include when-inside(#{$contact-block}#{$step-fields-1 }) {
                display: none;
            }
    
            @include when-inside(#{$contact-block}#{$step-fields-2 }) {
                display: none;
            }

            @include when-inside(#{$contact-block}#{$step-success }) {
                top: rem(372);
                left: rem(-50);

                @include responsiveMin(992px) {
                    top: rem(120);
                }
            }
        }
    }

    &__subtitle {
        position: absolute;
        bottom: rem(100);
        font-size: rem(48);
        font-weight: 900;
        opacity: 0;
        text-transform: uppercase;
        user-select: none;
        z-index: 1;
        white-space: nowrap;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;

        @include responsiveMin(992px) {
            font-size: rem(88);
            opacity: 0.03;
        }

        @include when-inside($step-success) {
            opacity: 0.03;
        }

        @include when-inside($contact-block) {
            opacity: 0;
        }

        @include when-inside(#{$contact-block}#{$step-start}) {
            opacity: 0.03;
        }
        
        @include when-inside(#{$contact-block}#{$step-success}) {
            opacity: 0.03;
        }
    }

    &__count {
        position: absolute;
        top: rem(35);
        left: rem(-5);
        font-size: rem(245);
        font-weight: 900;
        opacity: 0.1;
        line-height: 0.8;
        pointer-events: none;
        z-index: -1;
        
        @include responsiveMin(992px) {
            top: rem(10);
            left: rem(160);
            font-size: rem(268);
        }

        @include when-inside($contact-page) {
            top: 0;
            font-size: rem(176);

            @include responsiveMin(992px) {
                left: rem(-130);
                font-size: rem(268);
            }
        }

        @include when-inside(#{$contact-page}#{$step-success}) {
            font-size: rem(128);
            top: rem(18);

            @include responsiveMin(992px) {
                font-size: rem(192);
            }
        }

        sup {
            position: absolute;
            top: rem(6);
            left: 95%;
            font-size: rem(64);
            line-height: 1;

            @include responsiveMin(992px) {
                top: rem(12);
            }
        }

        [class^="icon-"], [class*=" icon-"] {
            font-weight: inherit;
        }
    }

    &__form {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: rem(340);
        z-index: 1;
        animation-fill-mode: forwards;
        animation-duration: 0.3s;

        @include when-inside($step-start) {
            animation-name: show-1;
        }

        @include when-inside($step-fields-1) {
            animation-name: show-2;
        }

        @include when-inside($step-fields-2) {
            animation-name: show-3;
        }

        @include when-inside($step-success) {
            animation-name: show-4;
        }

        @include responsiveMin(992px) {
            max-width: 100%;
            
            @include when-inside($contact-page) {
                max-width: rem(304);
            }
        }

        @include when-inside($contact-block) {
            min-height: rem(580);

            @include responsiveMin(992px) {
                min-height: rem(465);
            }
        }

        @include when-inside(#{$contact-page}#{$step-success}) {
            padding-top: rem(136);

            @include responsiveMin(992px) {
                padding-top: rem(180);
            }
        }

        @keyframes show-1 {
            from {opacity: 0}
            to {opacity: 1}
        }
        @keyframes show-2 {
            from {opacity: 0}
            to {opacity: 1}
        }
        @keyframes show-3 {
            from {opacity: 0}
            to {opacity: 1}
        }
        @keyframes show-4 {
            from {opacity: 0}
            to {opacity: 1}
        }
    }

    &__head {
        position: relative;
        display: flex;
        align-items: flex-end;
        flex: 1 0 rem(200);

        @include responsiveMin(992px) {
            flex: 1 0 0;
            position: static;
        }

        @include when-inside($contact-page) {
            flex-basis: 0;
            padding-top: rem(70);

            @include responsiveMin(992px) {
                padding-top: rem(90);
            }
        }

        @include when-inside($step-start) {
            position: static;
            flex-direction: column;
            justify-content: space-between;       
        }

        @include when-inside($step-success) {
            position: static;
            flex: 0;
        }
    }

    &__title {
        max-width: rem(240);
        font-size: rem(20);
        font-weight: 900;
        line-height: 1.2;
        padding-bottom: rem(20);

        @include responsiveMin(992px) {
            max-width: 100%;
            font-size: rem(36);
        }

        @include when-inside($contact-block) {
            max-width: rem(170);

            @include responsiveMin(992px) {
                max-width: rem(304);
                margin-left: auto;
            }
        }

        @include when-inside($step-start) {
            max-width: 100%;
            width: 100%;
            font-size: rem(22);

            @include responsiveMin(992px) {
                font-size: rem(48);
            }
        }
    }

    &__image {
        img {
            display: block;
            width: 100%;
            max-width: 100%;
            height: auto;
        }

        @include when-inside($step-start) {
            width: 110%;
            align-self: center;

            @include responsiveMin(992px) {
                position: absolute;
                left: 0;
                bottom: rem(-30);
                width: rem(350);
            }
        }

        @include when-inside($step-fields-1) {
            position: absolute;
            right: rem(-20);
            overflow: hidden;
            bottom: 0;
            width: 60%;
            max-width: rem(200);
            max-height: 110%;
            z-index: -1;

            @include responsiveMin(992px) {
                left: 0;
                bottom: rem(-30);
                width: rem(310);
                max-width: none;
            }
        }

        @include when-inside($step-fields-2) {
            position: absolute;
            right: rem(10);
            bottom: 0;
            width: 42%;
            max-width: rem(130);
            max-height: 100%;
            overflow: hidden;
            z-index: -1;

            @include responsiveMin(992px) {
                left: rem(40);
                bottom: rem(-30);
                width: rem(200);
                max-width: none;
            }
        }

        @include when-inside($step-success) {
            width: rem(200);
            position: absolute;
            left: 50%;
            bottom: rem(60);
            transform: translateX(-50%);

            @include responsiveMin(992px) {
                position: absolute;
                left:0;
                bottom: rem(-30);
                width: rem(300);
                transform: none;
            }
        }
    }

    &__body {
        @include when-inside($contact-block) {
            @include responsiveMin(992px) {
                width: 100%;
                max-width: rem(304);
                margin-left: auto;
            }
        }
    }

    &__back {
        position: absolute;
        top: 0;
        left: 0;

        @include when-inside($contact-page) {
            top: rem(30);

            @include responsiveMin(992px) {
                top: 0;
            }
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;

        @include responsiveMin(992px) {
            display: none;
        }
    }

    .checkbox {
        margin-bottom: rem(20);
        padding: 0 0 0 rem(16);
        position: relative;

        input {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }

        input + label {
            position: relative;
            cursor: pointer;
            font-size: rem(12);
            line-height: rem(14);
            color: #fff;
        }

        input + label:before,
        input + label:after {
            content: '';
            position: absolute;
            top: 0;
            left: rem(-16);
            width: rem(11);
            height: rem(11);
            border: 1px solid #fff;
            transition: .3s;
        }

        input + label:after {
            content: '✓';
            opacity: 0;
            font-size: rem(10);
            line-height: rem(10);
            text-align: right;
        }

        input:checked + label:after {
            opacity: 1;
        }

        i.icon-error {
            position: absolute;
            left: 0;
            top: rem(16);
            opacity: 0;

            &:before {
                font-size: rem(12);
            }
        }

        &--not-checked {

            input + label:before,
            input + label:after {
                color: #db4437;
                border-color: #db4437;
            }

            i.icon-error {
                opacity: 1;
            }
        }
    }

    &__success {
        @include when-inside($contact-block) {
            margin-top: rem(45);
            flex: 1 0 0;

            @include responsiveMin(992px) {
                margin: rem(50) 0 auto auto;
                max-width: rem(304);
            }
        }
    }

    .success {
        &__head {
            display: flex;
            align-items: flex-end;
            grid-gap: rem(10);
            margin-bottom: rem(5);
            
            @include responsiveMin(992px) {
                grid-gap: rem(15);
                margin-bottom: rem(15);
            }
        }

        &__img {
            display: inline-block;
            width: rem(87);
            flex-shrink: 0;
            
            @include responsiveMin(992px) {
                width: rem(91);
            }

            svg {
                max-width: 100%;
                height: auto;
            }
        }

        &__title {
            font-size: rem(20);
            font-weight: 900;
            
            @include responsiveMin(992px) {
                font-size: rem(36);
            }
        }

        &__text {
            font-size: rem(18);
            line-height: 1.16;
            font-weight: 700;
            margin-bottom: rem(20);

            @include responsiveMin(992px) {
                font-size: rem(24);
            }
        }
    }
}
