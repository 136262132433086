@import '../../../../scss/vars';
@import '../../../../scss/mixins';

.start-page {
    .services {
        padding: 30px 20px;
        background: #3E3E53;

        @include responsiveMin(992px) {
            padding: rem(70) rem(135);
        }

        &__header {
            color: #fff !important;
            padding-top: 0;
            padding-bottom: 0;

            @include responsiveMax(991px) {
                padding-left: 0;
                padding-right: 0;
            }

            @include responsiveMin(992px) {
                padding-bottom: rem(50);
            }
        }

        &__title {
            padding-bottom: rem(10);

            @include responsiveMin(992px) {
                padding-bottom: rem(50);
            }
            
            &-text {
                color: #fff !important;
                font-style: normal;
                font-weight: 900;
                font-size: 20px;
                line-height: 23px;
                font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;

                @include responsiveMin(992px) {
                    font-size: rem(36);
                    line-height: rem(42);
                }
            }

            &-bg {
                top: rem(-12);
                
                div {
                    color: #fff !important;
                    font-style: normal;
                    font-weight: 900;
                    font-size: rem(96);
                    line-height: rem(113);
                }
            }
        }

        &__description {
            color: #fff !important;
            padding-top: 0;
            padding-bottom: rem(30);

            @include responsiveMin(992px) {
                padding-bottom: 0;
            }
        }

        &__container {
            @include responsiveMin(992px) {
                display: flex;
            }
        }

        &__wrapper {

            @include responsiveMin(992px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-auto-rows: 1fr;
                grid-gap: rem(30);
            }
        }

        &__support {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 20px;
            background-color: #fff;
            margin-top: 20px;
            position: relative;
            cursor: pointer;

            @include responsiveMin(992px) {
                padding: rem(20) rem(40);
                min-width: rem(530);
                width: rem(530);
                margin-top: 0;
                margin-left: rem(30);
            }

            &:hover {
                .services__support-icon {
                    p {
                        svg {
                            path {
                                fill: #FC6076;
                            }
                        }
                    }
                }
            }

            &-header {
                &--top {
                    position: relative;
                    min-height: 198px;
                    margin-bottom: 20px;

                    @include responsiveMin(992px) {
                        margin-bottom: rem(20);
                        min-height: rem(445);
                    }
                }
            }

            &-bg {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 198px;
                height: 198px;

                @include responsiveMin(992px) {
                    width: rem(430);
                    height: auto;
                }
            }

            &-icon {
                width: 152px;
                height: 152px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);

                @include responsiveMin(992px) {
                    width: rem(228);
                    height: auto;
                }

                p {
                    svg {
                        width: 100%;
                        height: 100%;

                        path {
                            transition: .3s;
                        }
                    }
                }
            }

            &-title {
                font-style: normal;
                font-weight: bold;
                font-size: rem($m_h3_font_size);
                line-height: rem($m_h3_line_height);
                font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
                color: #353540;
                margin-bottom: 20px;
                text-decoration: none;

                @include responsiveMin(992px) {
                    font-size: rem($d_h3_font_size);
                    line-height: rem($d_h3_line_height);
                    margin-bottom: rem(20);
                }
            }

            &-description {
                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 150%;
                    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
                    color: #353540;
                    margin-bottom: 20px;
                    text-decoration: none;

                    @include responsiveMin(992px) {
                        font-size: rem(16);
                        margin-bottom: rem(20);
                    }
                }
            }

            &-link {
                .base-btn {
                    font-family: Raleway;
                    font-style: normal;
                    font-weight: bold;
                    color: #353540;
                    text-transform: uppercase;

                    &::before {
                        background: #353540;
                    }
                }
            }
        }

        &__item {
            margin-bottom: 20px;

            @include responsiveMin(992px) {
                width: 100%;
                margin-bottom: 0;

                &-inner {
                    height: 100%;
                    background-color: #fff;
                }
            }

            &:hover {
                h3 {
                    background: #FC6076;
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            h3 {
                padding: 20px;
                background: #767994;
                color: #FFFFFF;
                font-size: rem($m_h3_font_size);
                line-height: rem($m_h3_line_height);
                font-style: normal;
                font-weight: 900;
                transition: .3s;

                @include responsiveMin(992px) {
                    padding: rem(20) rem(40);
                    font-size: rem($d_h3_font_size);
                    line-height: rem($d_h3_line_height);
                }
            }

            &-info {
                background: #FFFFFF;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: 1;

                @include responsiveMin(992px) {
                    padding: rem(20) rem(40);
                }
            }

            &-description {
                p {
                    color: #353540;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 150%;
                    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;

                    @include responsiveMin(992px) {
                        font-size: rem(16);
                    }
                }
            }

            .services__item-link {
                margin-top: 20px;

                @include responsiveMin(992px) {
                    margin-top: rem(20);
                }

                .base-btn {
                    font-family: Raleway;
                    font-style: normal;
                    font-weight: bold;
                    color: #353540;
                    text-transform: uppercase;

                    &::before {
                        background: #353540;
                    }
                }
            }
        }

        &__item-block {
            text-decoration: none;
            display: flex;
            flex-direction: column;
            height: 100%;
            cursor: pointer;
        }
    }
}

/* END .services */
