$root-font-size: 14;
@import '../../../../scss/mixins.scss';

@include responsiveMin(992px) {
    .e-commerce-page {
        .anim-item {
            .hosting__rightLaptop {
                will-change: transform;
                transform: translateX(200%);
            }

            .hosting__textHolder {
                will-change: margin;
                animation-delay: 4000ms !important;
            }

            .hosting__number {
                will-change: left;
                right: auto !important;
                left: rem(130);
                animation-delay: 4000ms !important;
            }

            .hosting__leftLaptop {
                will-change: transform;
                transform: translateX(-200%);
                animation-delay: 4000ms !important;
            }

            &.active {
                .hosting__rightLaptop {
                    animation: hosting__rightLaptop 5s ease forwards;
                }

                .hosting__textHolder {
                    animation: hosting__textHolder 1.5s ease forwards;
                }

                .hosting__number{
                    animation: hosting__number 1.5s ease forwards;
                }

                .hosting__leftLaptop {
                    animation: hosting__leftLaptop 1.5s ease forwards;
                }
            }
        }
    }
}

@keyframes hosting__rightLaptop {
    0% {
        transform: translateX(200%);
    }

    30% {
        transform: translateX(0);
    }

    80% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(200%);
    }
}

@keyframes hosting__number {
    0% {
        left: rem(130);
    }

    100% {
        left: 80.6%;
    }
}

@keyframes hosting__textHolder {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: rem(800);
    }
}

@keyframes hosting__leftLaptop {
    0% {
        transform: translateX(-200%);
    }

    100% {
        transform: translateX(0);
    }
}

//animation for svg error image
@keyframes blink-fancy{
    0%, 25%, 28.33%, 45%, 48.33%, 51.67%, 93.33%, 96.67%{transform: scaleY(1);}
    26.67%, 46.67%, 50%, 95% {transform: scaleY(0.1);}
}

@keyframes flame-flicker-1{
    25% {transform: scale3d(1,1.1,1);}
    40% {transform: scale3d(1,0.9,1);}
    50% {transform: scale3d(1,1.05,1);}
    65% {transform: scale3d(1,0.7,1);}
    75% {transform: scale3d(1,1,1);}
    90% {transform: scale3d(1,1.1,1);}
    100% {transform: scale3d(1,1,1);}
}

@keyframes flame-flicker-2{
    15% {transform: scale3d(0.9,0.8,1);}
    33% {transform: scale3d(1.2, 0.5,1);}
    47% {transform: scale3d(0.7, 0.7,1);}
    63% {transform: scale3d(0.72, 1.1,1);}
    70% {transform: scale3d(0.65, 1,1);}
    77% {transform: scale3d(1, 0.8,1);}
    85% {transform: scale3d(0.7, 0.95,1)}
    100% {transform: scale3d(1,1,1);}
}

@keyframes flame-ember{
    0% {transform: translate(0, 0) rotate(0deg);}
    25% {transform: translate(rem(10), rem(-25)) rotate(-45deg);}
    50% {transform: translate(0, rem(-50)) rotate(0);}
    75% {transform: translate(rem(-10), rem(-75)) rotate(45deg);}
    100% {transform: translate(0, rem(-100)) rotate(0);}
}

@keyframes flame-ember-alt{
    0% {transform: translate(0, 0) rotate(0deg);}
    25% {transform: translate(rem(-15), rem(-25)) rotate(45deg);}
    50% {transform: translate(0, rem(-50)) rotate(0);}
    75% {transform: translate(rem(10), rem(-75)) rotate(-45deg);}
    100% {transform: translate(0, rem(-100)) rotate(0);}
}

@keyframes flame-ember-opacity{
    45% {opacity: 1;}
    100% {opacity: 0;}
}

@keyframes scale-pulse {
    0% {transform: scale(0.8,0.8);}
    50% {transform: scale(1.1, 1.1);}
    100% {transform: scale(0.8,0.8);}
}

@keyframes opacity-pulse{
    0% {opacity: 0.2;}
    50% {opacity: 0.5;}
    100% {opacity: 0.2;}
}

.flame-front{ 
    transform-origin: center bottom;
    animation: flame-flicker-1 1s ease-in-out infinite;
}

.flame-inner{
    transform-origin: center bottom;
    animation: flame-flicker-2 .8s ease-in-out infinite;
}

#flame-inner-right{animation-duration: 1s; animation-delay: -1s;}

#flame-back{animation-direction: reverse; animation-delay: 0.5s; animation-duration: 1.6s;}

#flame-front-right{animation-duration: 1.3s; animation-delay: 0.2s}

.emitted-ember{
    transform-origin: center center;
    -moz-transform-origin: rem(485.125) rem(51.33);
    animation: flame-ember 2s linear infinite, flame-ember-opacity 2s ease-out infinite;
    display: none;
}

.emitted-ember-alt{
    animation: flame-ember-alt 1.7s linear infinite, flame-ember-opacity 1.7s ease-out infinite;
}

.smoke{
    transform-origin: center center; 
    animation: scale-pulse .7s ease-in-out infinite;
}

#smoke-float-1 {animation-delay: 0.4s;}
#smoke-float-2 {animation-delay: 0.2s;}
#smoke-float-3 {animation-delay: 0s;}
#smoke-float-4 {animation-delay: -0.2s;}
#smoke-float-5 {animation-delay: -0.4s;}

.glow{
    transform-origin: center center;
    -moz-transform-origin: rem(378.729) rem(109.75);
    animation: opacity-pulse 4s ease-in-out infinite 0.1s, scale-pulse 4s ease-in-out alternate infinite;
}

#glow-outer-1{animation-delay: -0.1s;}
#glow-inner {animation-delay: -0.3s;}

#eyes-left, #eyes-right{
    animation: blink-fancy 6s linear infinite;
}

.bill-highlight{animation: opacity-pulse 4s ease-in-out infinite;}