@import "../../../../scss/mixins.scss";
@import "../../../../scss/vars.scss";


.post {
    position: relative;
    // STYLES FOR ARTICLE IN POST
    .articles__wrapper {
        justify-content: center;
        margin-bottom: rem(20);

        @include responsiveMin(992px) {
            margin-top: rem(50);
        }

        .articles__item {
            margin-left: 0;
            margin-top: rem(20);
        }
    }

    .gallery-swiper {
        position: relative;
        margin-bottom: rem(10) !important;

        @include responsiveMin(992px) {
            margin-bottom: rem(15) !important;
        }

        &:hover {
            .swiper-button-next, .swiper-button-prev {
                opacity: 1;
            }
        }

        .swiper-wrapper {
            position: relative;
            z-index: -1;

            .swiper-slide {
                display: flex;
                flex-direction: column;
                height: auto;

                &.without-description {
                    .photo-description {
                        height: 0;
                    }
                }
    
                img {
                    flex: 1;
                    width: 95%;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: rem(10);
                    margin-bottom: rem(10);
                    height: 100%;
                    object-fit: cover;
                    border: 5px solid #FFFFFF;
                    box-sizing: border-box;
                    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.22);

                    @include responsiveMin(992px) {
                        margin-top: rem(15);
                        margin-bottom: rem(15);
                    }
                }

                .photo-description {
                    height: rem(50);
                }

                span {
                    font-style: italic;
                    // font-weight: 300;
                    font-size: rem(14);
                    line-height: rem(25);
                    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off, 'kern' off;
                    color: #1B1B1B;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    /* autoprefixer: off */
                    -webkit-box-orient: vertical;
                    /* autoprefixer: on */
                    margin-left: 2.5%;
                    margin-right: 2.5%;  

                    @include responsiveMin(992px) {
                        font-size: rem(16);                
                    }
                }
            }
        }

        .swiper-button-disabled {
            display: none !important;
        }

        .swiper-button-next, .swiper-button-prev {
            position: relative;
            width: rem(47);
            height: rem(65);
            position: absolute;
            top: calc(50% - 25px);
            transform: translateY(-50%) matrix(-1, 0, 0, 1, 0, 0);
            z-index: 10;
            background-color: red;
            background: #FFFFFF;
            box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.2);
            cursor: pointer;
            opacity: 0;
            transition: .3s;
            outline: none;

            &::before {
                content: '';
                background-image: url(./images/Shape.svg);
                background-repeat: no-repeat;
                background-size: cover;
                width: rem(12);
                height: rem(20);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .swiper-button-prev {
            // left: rem(15);
            left: calc(2.5% + 15px);

            &::before {
                transform: translate(-50%, -50%) rotate(180deg);
            }
        }

        .swiper-button-next {
            // right: rem(15);
            right: calc(2.5% + 15px);
        }
    }

    .thumbnail-swiper {
        height: rem(82);
        width: calc(100% + 40px);
        margin: 0 rem(-20);
        padding-bottom: rem(5);
        padding-top: rem(5);

        @include responsiveMin(992px) {
            margin-left: 2.5%;
            margin-right: 2.5%;
            height: rem(107);
            width: 95%;
        }

        .swiper-wrapper {
            @include responsiveMin(992px) {
                width: fit-content !important;
                // transform: translate3d(0px, 0px, 0px) !important;

                &:hover .swiper-slide {
                    width: rem(46) !important;
                }
                
                .swiper-slide:hover {
                    width: rem(148) !important;
                    opacity: 1;
    
                    & ~ .swiper-slide-active {
                        width: rem(46) !important;
                    }
                }
            }
        }

        .swiper-slide {
            height: auto;
            width: rem(46) !important;
            opacity: 0.5;
            transition: 0.3s;
            border: rem(2) solid transparent;

            &-active {
                width: rem(148) !important;
                opacity: 1;
                border: rem(2) solid #FFFFFF; 
                box-sizing: border-box;
                filter: drop-shadow(0px 0px rem(3) rgba(0, 0, 0, 0.25));
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    // .slider-button-prev {
    //     position: absolute;
    //     left: rem(-47);
    // }

    .background_left {
        position: absolute;
        left: 0;
        top: 0;
        width: rem(800);
        height: rem(500);

        @include responsiveMax(991px) {
            display: none;
        }
    }

    .background_right {
        position: absolute;
        right: 0;
        top: 0;
        width: rem(600);
        height: rem(800);

        @include responsiveMax(991px) {
            display: none;
        }
    }

    // AND
    // STYLES FOR ARTICLE IN POST

    // STYLES FOR CATEGORIES IN POST
    .categories__items {
        color: #3e3e53;
        flex-direction: column;
        padding: 0 rem(20);

        @include responsiveMin(992px) {
            margin-bottom: rem(60);
            padding: 0;
        }

        .categories__item--title {
            &:before {
                background: #3e3e53;
            }
        }
        a {
            color: #3e3e53;
        }
    }

    // AND
    // STYLES FOR CATEGORIES IN POST

    &__img {
        height: rem(300);
        object-fit: cover;
        height: 100%;

        @include responsiveMin(992px) {
            height: rem(240);
            width: rem(365);
        }
    }

    &__breadcrumbs {
        margin-bottom: rem(30);
        display: flex;

        @include responsiveMax(991px) {
            margin-bottom: rem(30);
        }

        .home-link {
            svg {
                width: rem(24);
                height: rem(24);
            }
        }

        &_item {
            margin-left: rem(10);
            display: flex;
            font-size: rem(16);
            font-weight: 500;

            &--page {
                span {
                    text-decoration: underline;
                    background: linear-gradient(282.39deg, #FC6076 0%, #FF4D66 49.48%, #FF6826 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-decoration-color: #FC6076;
                }

                a {
                    text-decoration: none;

                    &:hover {
                        span {
                            text-decoration: none !important;
                            text-decoration-color: none !important;
                        }
                    }
                }
            }

            svg {
                margin-right: rem(10);
                width: rem(8);
                height: rem(12);
                margin-top: rem(5);
                min-width: rem(8);
                min-height: rem(12);

                @include responsiveMin(992px) {
                    margin-top: rem(6);
                }
            }

            span {
                color: #1B1B1B;
                font-weight: 500;
                font-size: rem(16);
                line-height: 150%;
                max-height: rem(24);
                overflow : hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                /* autoprefixer: off */
                -webkit-box-orient: vertical;
                /* autoprefixer: on */
            }
        }
    }

    .post-subtitle {
        font-weight: 600;
        font-size: rem(18);
        line-height: rem(21);
        color: #181C2D;
        padding: 0;
        // margin-bottom: rem(30);
        letter-spacing: normal;

        @include responsiveMin(992px) {
            font-size: rem(24);
            line-height: rem(28);
        }
    }

    .linkedin-link {
        margin-top: rem(50);
        margin-bottom: rem(50);
        width: fit-content;
        margin-right: auto;
        margin-left: auto;

        @include responsiveMin(992px) {
            margin-top: rem(100);
            margin-bottom: rem(100);
        }

        a {
            display: flex;
            align-items: center;
            text-decoration: none;

            span {
                font-weight: bold;
                font-size: rem(24);
                line-height: rem(28);
                color: #3E3E53;
                margin-right: rem(6);
                display: block;
                // margin-bottom: rem(-2);
    
                @include responsiveMin(992px) {
                    font-size: rem(24);
                    line-height: rem(28);
                }
            }

            img {
                width: rem(106);
                height: rem(27);
            }
        }
    }

    &__content {
        color: #3e3e53;
        padding-top: rem(116);

        @include responsiveMin(992px) {
            display: flex;
            position: relative;
            justify-content: space-between;
            padding-top: rem(200);
        }

        ol.contents {
            > li {
                &:nth-child(n+10) {
                    >a {
                        p {
                            margin-left: rem(25);
                        }
                    }

                    ol {
                        li {
                            a {
                                p {
                                    margin-left: rem(35);
                                }
                            }
                        }
                    }
                }

                > a {
                    text-decoration: none;
                    
                    p {
                        text-decoration: none;
                        font-weight: bold;
                        font-size: rem(16);
                        line-height: rem(25);
                        color: #3E3E53;
                        margin-left: rem(20);
                        padding-bottom: 0;
                        margin-bottom: rem(5);
                    }

                    &:before {
                        position: absolute;
                        left: rem(30);
                        text-decoration: none;
                        font-weight: bold;
                        font-size: rem(16);
                        line-height: rem(25);
                        color: #3E3E53;

                        @include responsiveMin(992px) {
                            left: rem(50);
                        }
                    }

                    &:hover {
                        color: #FC6076;

                        &:before {
                            color: #FC6076;
                        }

                        p {
                            color: #FC6076;
                        }
                    }
                }
                
                ol {
                    padding-left: rem(16);
                    
                    > li {
                        > a {
                            position: relative;
                            text-decoration: none;

                            &:hover {
                                color: #FC6076;

                                &:before {
                                    color: #FC6076;
                                }
        
                                p {
                                    color: #FC6076;
                                }
                            }

                            p {
                                text-decoration: none;
                                font-weight: 500;
                                font-size: rem(16);
                                line-height: rem(25);
                                color: #3E3E53;
                                margin-left: rem(30);
                                padding-bottom: 0;
                                margin-bottom: rem(5);
                            }

                            &::before {
                                position: absolute;
                                left: 0;
                                text-decoration: none;
                                font-weight: 500;
                                font-size: rem(16);
                                line-height: rem(25);
                                color: #3E3E53;
                            }
                        }

                        ol {
                            > li {
                                > a {
                                    p {
                                        margin-left: rem(40);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        h1 {
            font-weight: 900;
            font-size: rem(24);
            line-height: rem(28);
            color: #181C2D;
            margin-bottom: rem(15);

            @include responsiveMin(992px) {
                font-size: rem(48);
                line-height: rem(56);
                margin-bottom: rem(20);
            }
        }

        h2 {
            font-weight: bold;
            font-size: rem(22);
            line-height: rem(26);
            color: #181C2D;
            margin-bottom: rem(30);
            margin-top: rem(10);

            @include responsiveMin(992px) {
                font-size: rem(32);
                line-height: rem(38);
            }
        }

        h3 {
            font-weight: 600;
            font-size: rem(20);
            line-height: rem(23);
            color: #181C2D;
            margin-bottom: rem(20);
            margin-top: rem(10);

            @include responsiveMin(992px) {
                font-size: rem(30);
                line-height: rem(35);
            }
        }

        h4 {
            font-weight: 600;
            font-size: rem(18);
            line-height: rem(21);
            color: #181C2D;
            margin-bottom: rem(20);

            @include responsiveMin(992px) {
                font-size: rem(24);
                line-height: rem(28);
            }
        }

        h2, h3, h4 {
            a {
                color: #181C2D;
                word-break: break-word;

                &:hover {
                    text-decoration: none;
                    color: #ff4d66;
                }
            }
        }

        .post-subtitle {
            font-weight: bold;
            color: #1B1B1B;
        }

        .post__author {
            padding: 0;
            margin: 0;
            width: 100%;

            a {
                text-decoration: none;
            }

            &__wrapper {
                display: flex;
                align-items: center;
                flex-direction: row;
            }

            &-info {
                margin-left: rem(10);
                margin-right: rem(10);

                @include responsiveMin(992px) {
                    margin-left: rem(20);
                    margin-right: rem(20);
                    max-width: rem(370);
                }

                .author-name {
                    margin-bottom: rem(5);
                    font-weight: bold;
                    font-size: 16px;
                    line-height: rem(21);
                    color: #3E3E53;
                    text-align: left !important;

                    @include responsiveMin(992px) {
                        font-size: rem(24);
                        line-height: rem(28);
                        margin-bottom: rem(8);
                    }
                }

                .author-work {
                    margin-bottom: 0;
                    text-align: left;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: rem(19);
                    color: #1B1B1B;

                    @include responsiveMin(992px) {
                        font-weight: normal;
                        line-height: 150%;
                        font-size: rem(16);
                    }
                }
            }
        }

        .dates {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
        }

        .edited-date {
            font-size: rem(12);
            line-height: 1;
            font-style: italic;
            display: block;
            text-align: right;
            width: fit-content;
            
            @include responsiveMax(579px) {
                margin-bottom: 20px;
            }
        }

        .post-date {
            margin: 0;
            display: flex;
            font-size: rem(12);
            line-height: rem(14);
            position: relative;
            color: #000000; 
            align-items: center;
            margin-top: auto;
            margin-bottom: 0;
            margin-top: 10px;

            @include responsiveMin(580px) {
                margin-bottom: 10px;
                width: fit-content;
                margin-left: auto;
                margin-right: 0;
            }

            @include responsiveMin(580px) {
                margin-top: 0;
            }

            @include responsiveMin(992px) {
                font-size: rem(14);
                line-height: rem(16);
            }

            span {
                display: block;
                background: #fff;
                padding: 0;
                margin-left: rem(5);
            }

            svg {
                width: rem(20);
                height: rem(20);
            }
        }

        .post-featured-image {
            margin-top: rem(30);
            margin-bottom: rem(30);

            img {
                width: 100%;
                height: auto;
            }
        }

        .post-contents {
            box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
            margin-bottom: rem(40);
            padding: rem(20);
            background-color: #fff;

            @include responsiveMin(992px) {
                margin-bottom: rem(50);
                padding: rem(30);
            }

            > span {
                font-weight: bold;
                font-size: rem(18);
                line-height: rem(21);
                color: #181C2D;
                margin-bottom: rem(20);
                display: block;
                width: 100%;
                text-align: center;
            }

            ol {
                list-style: none;
                counter-reset: cupcake;
                padding: 0;
            }

            ol li a {
                counter-increment: cupcake;
            }

            ol li a:before {
                content: counters(cupcake, ".") ". ";
            }
        }

        .wp-block-image {
            img {
                height: 100%;
            }
        }

        p {
            font-size: rem(16);
            line-height: 170%;
            margin-bottom: rem(30);
            letter-spacing: -0.01em;
            color: #1B1B1B;

            @include responsiveMin(992px) {
                font-size: rem(18);
                line-height: rem(32);
            }

            img {
                height: 100%;
                max-width: 60%;

                @include responsiveMax(760px) {
                    max-width: 100%;
                }

                @include responsiveMax(992px) {
                    width: 100% !important;
                    margin: rem(20) auto;
                    max-width: 50%;
                }
            }

            a {
                color: #ff4d66;
                word-break: break-word;

                &:hover {
                    text-decoration: none;
                }
            }

            img[style] {
                float: right;
                margin: 0;

                @include responsiveMin(992px) {
                    margin: 0 0 rem(25) rem(25);
                }
            }

            > code {
                background: rgba(252, 96, 118, .1);
                color: #FC6076;
            }
        }

        .wp-block-image {
            margin: rem(10) 0 rem(20);
            text-align: center;

            @include responsiveMin(992px) {
                margin: rem(40) 0;
            }

            figcaption {
                margin-top: rem(10);
                line-height: 150%;
                font-size: rem(14);
                font-style: italic;

                @include responsiveMin(992px) {
                    margin-top: rem(20);
                    font-size: rem(16);
                }

                a {
                    color: #ff4d66;
                }
            }

            img {
                display: block;
                margin: auto;
                max-width: 100%;
            }
        }

        .wp-block-video {
            margin: rem(30) 0;

            video {
                width: 100%;
                cursor: pointer;
            }
        }

        .wp-block-table {
            margin: 0;
            margin-bottom: rem(30);
            padding-bottom: rem(20);
            overflow-x: scroll;

            figcaption {
                margin-top: 10px;
                font-family: Raleway;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: #3E3E53;

                a {
                    color: #ff4d66;
                }

                @include responsiveMin(992px) {
                    font-size: 14px;
                    line-height: 16px;
                }
            }

            table {
                width: 100%;

                @include responsiveMax(991px) {
                    width: auto;
                    margin-left: auto;
                    margin-right: auto;
                }

                tbody {
                    tr {
                        td {
                            border-right: 2px solid #D7D7D9;
                            padding: rem(20) rem(30);
                            width: fit-content; 
                            font-family: Raleway, sans-serif;
                            font-style: normal;
                            font-weight: 500;
                            font-size: rem(18);
                            line-height: rem(21);

                            @include responsiveMax(991px) {
                                padding: rem(20) rem(10);
                            }

                            &:last-child {
                                border: none;
                            }

                            &.has-text-align-center {
                                text-align: center;
                            }
                        }

                        &:nth-child(odd) {
                            td {
                                background: #F4F7FC;
                                border-right: 2px solid #fff;
                            }
                        }

                        &:first-child {
                            td {
                                position: relative;
                                background: transparent;
                                border: 0;
                                border-bottom: 2px solid #EEEEEE;
                                text-align: center;
                                padding: rem(5) rem(30);
                                font-weight: bold;
                                
                                &:not(:first-child) {
                                    &::before {
                                        content: '';
                                        display: block;
                                        position: absolute;
                                        left: 0;
                                        bottom: 0;
                                        height: 8px;
                                        width: 1px;
                                        background: #D7D7D9;
                                    }
                                }

                                &:not(:last-child) {
                                    &::after {
                                        content: '';
                                        display: block;
                                        position: absolute;
                                        right: 0;
                                        bottom: 0;
                                        height: 8px;
                                        width: 1px;
                                        background: #D7D7D9;
                                    }
                                }
                            }
                        }
                        
                        &:last-child {
                            border-bottom: 2px solid #EEEEEE;
                        }
                    }
                }
            }
        }

        .wp-block-button__link {
            min-width: rem(290);
            font-style: normal;
            font-weight: bold;
            font-size: rem(18);
            line-height: rem(21);
            color: #fff;
            height: rem(60);
            background: linear-gradient(315deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
            padding: rem(19);
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            text-decoration: none;
            text-transform: uppercase;
            margin-bottom: rem(30);
            transition: .3s;

            &:hover {
                background: linear-gradient(135deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
            }
        }

        .wp-block-quote {
            position: relative;
            margin: 0;
            margin-top: rem(54);
            margin-bottom: rem(45);
            width: fit-content;
            margin-left: auto;
            margin-right: auto;

            a {
                color: #ff4d66;

                &:hover {
                    text-decoration: none;
                }
            }

            p {
                padding: 0;

                strong {
                    font-weight: 500;
                    font-size: rem(20);
                    line-height: 170%;
                    color: #1B1B1B;
                    width: 100%;
                    display: block;
                    text-align: center;
                    font-style: italic;
        
                    @include responsiveMin(992px) {
                        font-size: rem(28);
                        line-height: 150%;
                    }
                }
            }

            cite {
                font-weight: normal;
                font-size: rem(16);
                line-height: 170%;
                color: #3E3E53;
                margin-left: auto;
                margin-right: 0;
                text-align: right;
                width: 100%;
                display: block;

                @include responsiveMin(992px) {
                    font-size: rem(18);
                    line-height: rem(32);
                }
            }
        }

        .schema-how-to-steps {
            margin-left: rem(6);
            padding: 0 rem(10);
            font-size: rem(14);

            @include responsiveMin(992px) {
                font-size: rem(16);
            }
        }

        &-left {
            width: rem(400);
            margin-left: rem(180);
            height: fit-content;
            background-color: #fff;
            position: relative;
            margin-bottom: rem(100);

            &.small {
                position: -webkit-sticky;
                position: sticky;
                top: rem(50);
            }

            @include responsiveMax(991px) {
                margin: 0;
                display: none;
            }

            .post-contents {
                padding: rem(20) rem(30);
                margin: 0;
                width: 100%;
                height: 100%;

                @include responsiveMin(992px) {
                    padding: rem(30) rem(50);
                }
            }

            span {
                font-weight: bold;
                font-size: rem(24);
                line-height: rem(28);
                color: #181C2D;
            }
        }

        &-inner {
            padding: 0 rem(20);
            position: relative;

            @include responsiveMin(760px) {
                padding: 0 rem(50);
            }

            @include responsiveMin(992px) {
                width: rem(760);
                padding: 0;
                padding-right: rem(40);
                padding-left: rem(40);
                overflow: hidden;
            }

            .wp-block-code {
                overflow: visible;
                background: #FFFFFF;
                box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
                padding: rem(20) rem(11) rem(15) rem(15);

                @include responsiveMin(992px) {
                    padding: rem(50);
                }

                code {
                    font-size: rem(16);
                    line-height: 170%;
                    color: #3E3E53;
                    white-space: pre-wrap;
                    letter-spacing: -0.01em;

                    @include responsiveMin(992px) {
                        font-size: rem(18);
                        line-height: rem(32);
                    }
                }
            }

            .posting {
                .wp-block-columns {
                    display: flex;

                    .wp-block-column {
                        flex: 1; 
                        
                        .wp-block-image {
                            & > figure {
                                margin: 10px 20px;
                            }
                        }
                    }
                }

                .infoblock {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
                    padding: rem(20);
                    margin-top: rem(40);
                    margin-bottom: rem(40);
                    clear: both;
    
                    @include responsiveMin(992px) {
                        padding: rem(50);
                    }
     
                    &:before {
                        content: "";
                        display: flex;
                        background-image: url("./images/info.svg");
                        background-size: cover;
                        width: rem(17);
                        height: rem(17);
                        margin-right: rem(5);
                        margin-top: rem(3);
    
                        @include responsiveMin(992px) {
                            margin-right: rem(15);
                            margin-top: rem(5);
                        }
                    }
    
                    p {
                        flex: 1;
                        position: relative;
                        font-style: normal;
                        font-weight: normal;
                        font-size: rem(16);
                        line-height: 170%;
    
                        &:last-of-type {
                            margin-bottom: 0;
                        }
    
                        @include responsiveMin(992px) {
                            font-size: rem(18);
                        }
                    }
                }

                ol {
                    counter-reset: cupcake;
                    list-style: none;
                    padding: 0;

                    a {
                        color: #ff4d66;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    li {
                        counter-increment: cupcake;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 170%;
                        color: #1B1B1B;
                        letter-spacing: -0.01em;
                        margin-bottom: rem(10);
                        padding-left: rem(24);
                        position: relative;

                        @include responsiveMin(992px) {
                            font-size: rem(18);
                            line-height: rem(32);
                            padding-left: rem(26);
                        }

                        &:before {
                            font-weight: bold;
                            content: counters(cupcake, ".") ". ";
                            background: linear-gradient(315deg, #FF6826 0%, #FF4D66 48.44%, #FC6076 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            position: absolute;
                            left: rem(5);

                            @include responsiveMin(992px) {
                                left: rem(6);
                            }
                        }

                        &:nth-child(n+10) {
                            padding-left: rem(32);

                            @include responsiveMin(992px) {
                                padding-left: rem(34);
                            }
                        }

                        ol {
                            margin: rem(10) 0 !important;

                            li {
                                padding-left: rem(30);

                                @include responsiveMin(992px) {
                                    padding-left: rem(32);
                                }

                                &:before {
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }

            ul {
                margin-bottom: rem(30);

                a {
                    color: #ff4d66;

                    &:hover {
                        text-decoration: none;
                    }
                }

                li {
                    font-size: 16px;
                    line-height: 150%;
                    margin-bottom: rem(10);
                    position: relative;
                    padding-left: rem(29);
                    color: #1B1B1B;

                    @include responsiveMin(992px) {
                        font-size: rem(18);
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &:before {
                        content: "";
                        background-image: url("./images/list.svg");
                        background-size: cover;
                        background-repeat: no-repeat;
                        width: 4px;
                        height: 4px;
                        min-width: 4px;
                        min-height: 4px;
                        position: absolute;
                        left: rem(10);
                        top: rem(10);

                        @include responsiveMin(992px) {
                            top: rem(12);
                        }

                        @include responsiveMin(1920px) {
                            width: rem(4);
                            height: rem(4);
                            min-width: rem(4);
                            min-height: rem(4);
                        }
                    }

                    ul {
                        margin: rem(10) 0 !important;
                    }
                }
            }

            ol.contents {
                padding: 0;

                & > li {
                    display: block;

                    & > a {
                        position: relative;

                        p {
                            margin-left: rem(20);
                            font-weight: bold;
                            font-size: rem(16);
                            line-height: rem(25);
                            color: #3E3E53;
                            letter-spacing: -0.01em;
                            margin-bottom: rem(5);

                            @include responsiveMin(992px) {
                                margin-bottom: rem(7);
                            }
                        }

                        &::before {
                            position: absolute;
                            left: 0;
                        }
                    }

                    & > ol {
                        & > li {
                            a {
                                position: relative;

                                p {
                                    margin-left: rem(30);
                                    font-weight: 500;
                                    font-size: rem(16);
                                    line-height: rem(25);
                                    letter-spacing: -0.01em;
                                    color: #3E3E53;
                                    margin-bottom: rem(5);

                                    @include responsiveMin(992px) {
                                        margin-bottom: rem(7);
                                    }
                                }
                            
                                &:before {
                                    position: absolute;
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }

            .socials {
                margin-top: rem(30);

                span {
                    display: block;
                    font-weight: bold;
                    font-size: rem(18);
                    line-height: rem(21);
                    color: #181C2D;
                    margin-bottom: rem(20);

                    @include responsiveMin(992px) {
                        font-size: rem(24);
                        line-height: rem(28);
                    }
                }
            }

            .post-socials {
                display: flex;

                button {
                    width: rem(38) !important;
                    height: rem(38) !important;
                    margin-right: rem(15);
                }

                a {
                    width: rem(38);
                    height: rem(38);
                    margin-right: rem(15);
                }

                svg {
                    width: 100%;
                    height: 100%;

                    rect {
                        transition: 0.3s;
                    }

                    &:hover {
                        rect {
                            fill: url(#paint1_linear);
                        }
                    }
                }
            }

            .post-tags {
                margin: rem(20) 0;

                @include responsiveMin(992px) {
                    margin: rem(80) 0 0;
                }
            }
        }

        &-right {
            margin-bottom: rem(20);
            margin-right: rem(20);
            margin-left: rem(20);
            box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
            padding: rem(30) rem(20);
            display: flex;
            flex-direction: column;
            background-color: #fff;
            margin-top: rem(50);
            
            @include responsiveMin(992px) {
                margin-right: rem(180);
                margin-left: 0;
                width: rem(400);
                height: fit-content;
                margin-top: 0;
                padding: rem(30) rem(50);
            }

            &-title {
                margin-bottom: rem(20) !important;
                margin-top: 0 !important;

                span {
                    font-weight: bold;
                    font-size: rem(18);
                    line-height: rem(21);
                    color: #181C2D;

                    @include responsiveMin(992px) {
                        font-size: rem(24);
                        line-height: rem(28);
                    }
                }

                &.title-author {
                    display: flex;
                    flex-wrap: wrap;

                    span {
                        display: block;

                        &:first-of-type {
                            &:after {
                                content: "\00a0";
                            }
                        }
                    }
                }
            }

            &-categories {
                margin-bottom: rem(40);

                .articles__category-post {
                    display: flex;
                    justify-content: left;
                    flex-direction: column;
                    opacity: 1;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .articles__category_item {
                        margin-bottom: rem(17);
                        display: flex;
                        align-items: center;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            display: flex;
                            align-items: center;
                        }

                        svg {
                            width: rem(30);
                            height: rem(16);
                        }

                        span {
                            font-weight: bold;
                            font-size: rem(18);
                            line-height: rem(21);
                            display: block;
                        }
                    }
                }
            }

            &-socials {
                margin-bottom: rem(40);

                @include responsiveMin(992px) {
                    margin-bottom: 0;
                    order: 4;
                }

                .post-socials {
                    display: flex;

                    button {
                        width: rem(38);
                        height: rem(38);
                        margin-right: rem(15);
                    }

                    a {
                        width: rem(38);
                        height: rem(38);
                        margin-right: rem(15);
                    }

                    svg {
                        width: 100%;
                        height: 100%;

                        rect {
                            transition: 0.3s;
                        }
    
                        &:hover {
                            rect {
                                fill: url(#paint1_linear);
                            }
                        }
                    }
                }
            }

            &-autor {
                margin-bottom: rem(40);

                p {
                    font-size: rem(16);
                    line-height: 170%;
                    color: #1B1B1B;
                    padding-bottom: 0;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            &-tags {
                @include responsiveMin(992px) {
                    margin-bottom: rem(40);
                }

                .post-tags {
                    margin-bottom: 0;
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: 0;

                    a {
                        margin-bottom: rem(10);
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            height: 100%;
                            width: 0;
                            left: 0;
                            top: 0;
                            background: #3E3E53;
                            transition: all 0.5s ease;
                            z-index: 1;
                        }

                        &:hover {
                            &::before {
                                width: 100%;
                            }
                        }

                        span {
                            position: relative;
                            z-index: 2;
                            white-space: pre-wrap;
                        }
                    }
                }
            }
        }

        &__last-posts {
            padding-left: rem(20);
            padding-right: rem(20);

            @include responsiveMin(992px) {
                margin-bottom: rem(130);
                padding-left: rem(220);
                padding-right: rem(220);
            }

            @include responsiveMin(1400px) {
                padding-left: rem(220);
                padding-right: rem(220);
            }

            .articles__wrapper {
                display: flex;
                flex-direction: row;
                margin: 0 !important;
                width: 100%;

                @include responsiveMin(992px) {
                    flex-wrap: wrap;
                }
            }

            .articles__item {
                margin-bottom: rem(50);
                margin-top: 0 !important;
                display: flex;
                background-color: transparent !important;
                padding: 0;

                &::before {
                    display: none;
                }

                &::after {
                    display: none;
                }

                @include responsiveMin(992px) {
                    margin-bottom: 0;
                    width: 31%;
                    margin-right: 3.5%;
                }

                &:last-child {
                    margin-right: 0;
                }

                &-link {
                    flex-direction: column;

                    .articles__img {
                        width: 100%;

                        @include responsiveMin(992px) {
                            height: rem(296);
                        }
                    }
                }

                .articles__content-excerpt {
                    max-height: rem(63);
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    /* autoprefixer: off */
                    -webkit-box-orient: vertical;
                    /* autoprefixer: on */

                    @include responsiveMin(992px) {
                        max-height: rem(72) !important;
                    }

                    p {
                        font-size: rem(14);
                        line-height: 150%;
                        margin-bottom: rem(10);
                        color: #1B1B1B;

                        @include responsiveMin(992px) {
                            font-size: rem(16);
                            font-weight: 600;
                            color: #3E3E53;
                        }
                    }
                }

                .articles__content {
                    &-item {
                        display: flex;
                        flex-direction: column;
                        padding-left: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    .title-post {
        font-weight: 900;
        font-size: rem(20);
        line-height: rem(23);
        margin-bottom: rem(30);
        color: #181C2D;
        margin-top: rem(50);

        @include responsiveMin(992px) {
            margin-bottom: rem(50);
            font-size: rem(36);
            line-height: rem(42);
            margin-top: rem(100);
        }
    }

    .markup {
        background: #f5f5f6;
        padding: rem(20) rem(15);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: rem(20);

        @include responsiveMin(992px) {
            margin: rem(40) 0;
            padding: rem(50);
            flex-direction: row;
        }

        div {
            @include responsiveMin(992px) {
                margin-right: rem(50);
            }
        }
        
        p {
            margin-bottom: rem(10);
            word-wrap: break-word;
            text-align: left;
            @include responsiveMin(992px) {
                margin-bottom: rem(30);
            }
        }

        button {
            border: none;
            width: rem(260);

            @include responsiveMin(400px) {
                width: rem(290);
            }

            @include responsiveMin(992px) {
                margin-bottom: 0;
            }
        }

        img {
            max-height: rem(250);
            @include responsiveMin(992px) {
                max-width: rem(250);
            }
        }
    }

    .red {
        color: #f66076;
        background: #f3e4e6;
    }

    .grey {
        background: #f5f5f6;
        padding: rem(20) rem(15);
        line-height: 150%;
        font-size: rem(14);
        margin: rem(10) 0 rem(20);
        
        @include responsiveMin(992px) {
            margin: rem(20) 0;
            padding: rem(50);
            font-size: rem(16);
        }
    }

    .wp-image {
        display: block;
        margin: 20px auto;
    }

    .fslightbox-container {
        background: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(rem(15));

        .lightbox-image-container {
            width: 80% !important;
        
            @include responsiveMin(992px) {
                width: rem(1109) !important;
                height: rem(742) !important;
            }

            img {
                max-width: 100%;
                max-height: 100%;
                display: block;
                margin: 0 auto;

                @include responsiveMin(992px) {
                    height: 100%;
                    object-fit: cover;
                }
    
            }

            span {
                font-style: italic;
                font-weight: normal;
                font-size: rem(16);
                line-height: rem(25);
                font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off, 'kern' off;
                color: #FFFFFF;
                margin-top: rem(20);
                display: block;
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

	&-tags {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: rem(40);
        
        @include responsiveMin(992px) {
            margin-bottom: rem(80);
            margin-left: rem(280);
            justify-content: start;
        }

        .tag {
            display: block;
            padding: rem(10);
            font-weight: bold;
            color: #ffffff;
            background: #767994;
            margin-right: rem(5);
            font-size: rem(14);
            line-height: rem(16);
            white-space: nowrap;
            width: max-content !important;
            text-decoration: none;

            @include responsiveMin(992px) {
                font-size: rem(16);
                line-height: rem(19);
                margin: 0 rem(10) rem(10) 0;
            }
        }
    }

    .contact-block {
        position: relative;
        padding: 0 rem(40);
        margin: rem(40) 0;
        display: flex;
        flex-direction: column;
        width: calc(100% + 2.86rem);
        margin-left: -1.43rem;

        @media (min-width: 760px) {
            width: calc(100% + 7.14rem);
            margin-left: -3.57rem;
        }

        @include responsiveMin(992px) {
            flex-direction: row;
            padding: 0 rem(80);
            width: 100%;
            margin-left: 0;
        }

        .contact-block__bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: rem(331);
            background: #FFFFFF;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            z-index: -1;
            display: flex;
            justify-content: center;

            @include responsiveMin(992px) {
                height: rem(150);
            }

            .contact_bg {
                width: 100%;
                height: fit-content;
                margin-bottom: 0;
                margin-top: auto;
                display: flex;
                justify-content: center;
                white-space: nowrap;
                font-weight: 900;
                font-size: rem(67);
                line-height: 100%;
                text-transform: uppercase;
                background: linear-gradient(315deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                opacity: 0.03;

                @include responsiveMin(992px) {
                    font-size: rem(127);
                }
            }
        }

        img {
            width: rem(270);
            margin-left: auto;
            margin-right: auto;

            @include responsiveMin(992px) {
                width: rem(231);
                margin-left: 0;
            }
        }

        a {
            padding: rem(18) rem(32);
            width: 100%;
            background: linear-gradient(315deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
            font-weight: 700;
            font-size: rem(18);
            line-height: rem(21);
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            color: #FFFFFF;
            text-decoration: none;
            height: fit-content;
            align-self: flex-end;
            margin-bottom: rem(40);

            @include responsiveMin(992px) {
                width: fit-content;
                margin-left: auto;
                margin-right: 0;
                margin-bottom: rem(45);
                min-width: rem(230);
            }

            &:hover {
                i {
                    transform-origin: top center;
                    animation-name: swing;
                    animation-duration: 1s;
                    animation-fill-mode: both;
                }
            }

            i {
                margin-right: rem(10);
            }
        }
    }

    .post-certificates {
        padding: rem(10);
        background: #3E3E53;

        @include responsiveMin(992px) {
            max-width: rem(400);
        }

        &--inner {
            width: 100%;
            padding: rem(10);
            box-shadow: 0 0 0 rem(2) #fff;

            img {
                width: 100%;
                margin-bottom: rem(10);

                @include responsiveMin(992px) {
                    margin-bottom: rem(10);
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}
