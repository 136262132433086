@import "../../../../scss/vars";
@import "../../../../scss/mixins";

.glossary-post {
    color: #3E3E53;
    font-size: rem(16);
    line-height: 150%;
    padding: rem(121) rem(20) rem(60) rem(20);

    @include responsiveMin(992px) {
        padding: rem(158) rem(150) rem(160) rem(150);
    }

    &--preloader {
        min-height: 100vh;
        position: relative;
    
        .data-preloader {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .breadcrumbs {
        display: flex;
        align-items: center;
        margin-bottom: rem(30);

        @include responsiveMin(992px) {
            margin-bottom: rem(60);
        }

        &__item {
            display: flex;
            align-items: center;
            font-size: rem(16);
            font-weight: 500;
            margin-right: rem(13);

            &:first-child {
                svg {
                    width: rem(24);
                    margin-right: 0;
                }
            }

            &:last-child{
                margin-right: 0;

                span {
                    flex: 1;
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    /* autoprefixer: off */
                    -webkit-box-orient: vertical;
                    /* autoprefixer: on */
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                }
            }

            svg {
                width: rem(8);
                margin-right: rem(10);
            }

            a {
                text-decoration: underline;
                background: linear-gradient(
                    282.39deg,
                    #fc6076 0%,
                    #ff4d66 49.48%,
                    #ff6826 100%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-decoration: underline;
                text-decoration-color: #fc6076;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    h2 {
        font-weight: 700;
        font-size: rem(22);
        line-height: rem(26);
        margin-top: 0;
        margin-bottom: rem(20);

        @include responsiveMin(992px) {
            font-size: rem(32);
            line-height: rem(38);
        }
    }

    &__content {
        @include responsiveMin(992px) {
            max-width: rem(810);
            margin: 0 auto;
        }

        h2 {
            margin-top: rem(30);
        }
    }

    article {

        h1 {
            font-size: rem(22);
            line-height: rem(26);
            font-weight: 900;
            margin-bottom: rem(20);

            @include responsiveMin(992px) {
                font-size: rem(48);
                line-height: rem(56);
            }

            & + i {
                display: block;
                font-style: rem(14);
                margin-bottom: rem(20);

                strong {
                    font-style: normal;
                }
            }
        }
    }

    &__date {
        margin-bottom: rem(40);
    }

    &__info {
        :first-child {
            margin-top: 0;
        }

        :last-child {
            margin-bottom: 0;
        }

        h2 {
            margin: rem(30) 0 rem(20) 0;
        }

        p {
            margin: rem(10) 0;
        }

        a {
            color: #ff4d66;

            &:hover {
                text-decoration: none;
            }
        }

        ul, ol, figure, img {
            margin: rem(20) 0;
        }

        ul {
            li {
                position: relative;
                padding-left: rem(29);
                margin-bottom: rem(10);

                &:before {
                    content: '';
                    position: absolute;
                    top: rem(10);
                    left: rem(10);
                    width: rem(4);
                    height: rem(4);
                    border-radius: 50%;
                    background: #ff4d66;
                }

                ul {
                    margin: rem(10) 0 !important;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        ol {
            list-style: none;
            padding: 0;
            counter-reset: cupcake;

            li {
                counter-increment: cupcake;
                font-weight: normal;
                margin-bottom: rem(10);
                padding-left: rem(24);
                position: relative;

                &:before {
                    font-weight: bold;
                    content: counters(cupcake, ".") ". ";
                    background: linear-gradient(315deg, #FF6826 0%, #FF4D66 48.44%, #FC6076 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    position: absolute;
                    left: rem(5);
                }

                &:nth-child(n+10) {
                    padding-left: rem(32);
                }

                ol {
                    margin: rem(10) 0 !important;

                    li {
                        padding-left: rem(30);

                        &:before {
                            left: 0;
                        }
                    }
                }
            }
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }

        figure {
            img {
                margin: 0;
            }

            figcaption {
                text-align: center;
                margin-top: rem(10);
                line-height: 150%;
                font-size: rem(14);
                font-style: italic;

                @include responsiveMin(992px) {
                    font-size: rem(16);
                }
            }
        }

        .wp-block-columns {
            gap: rem(15);
            @include responsiveMin(992px) {
                display: flex;
                flex-direction: row;
                width: 100%;
                gap: rem(30);

                div {
                    width: 100%;
                }
            }
        }

        .wp-block-image {
            margin: rem(20) 0 rem(20);

            @include responsiveMin(992px) {
                margin: 0;
            }
        }
    }

    .design_slider {
        margin: 0 rem(-20);

        @include responsiveMin(992px) {
            margin: 0 rem(-546);
        }
    }

    .slider-holder {
        order: 2;
        position: relative;
        margin-right: rem(-20);
        margin-left: rem(-20);

        .frame {
            margin-top: 0;
            position: absolute;
            width: 52.2%;
            pointer-events: none;
            z-index: 2;

            @include responsiveMin(600px) {
                width: rem(285);
            }
        }

        .swiper-container {
            padding-top: 10.5%;
            padding-bottom: 6.5%;
            margin-left: 15.83%;

            @include responsiveMin(600px) {
                padding-top: rem(57);
                margin-left: rem(86);
            }
        }

        .swiper-slide {
            display: block;
            width: 37.29%;
            box-shadow: rem(-2) rem(-2) rem(15) rem(2) rgba($color: #000000, $alpha: .2);
            margin-right: 6.3%;

            @include responsiveMin(600px) {
                width: rem(169);
            }

            img {
                width: 100%;
            }
        }
    }

    .design__phoneHolder {
        position: relative;
        margin-left: rem(166);
    }

    .design__frame {
        position: absolute;
        width: rem(350);
        z-index: 10;
    }

    .design__screensHolder {
        display: flex;
        margin-left: rem(106);
        padding-top: rem(71);
        padding-bottom: rem(59);

        &_item {
            display: block;
            width: rem(208);
            height: 100%;
            margin-right: rem(42);
            box-shadow: rem(-10) rem(10) rem(25) rem(1) rgba($color: #000000, $alpha: .4);

            &:first-child {
                margin-right: rem(62);
            }
        }
    }

    .relevats-words {

        @include responsiveMin(992px) {
            margin-top: rem(60);
        }

        ul {
            li {
                margin-bottom: rem(20);

                &:last-of-type {
                    margin-bottom: 0;
                }

                a {
                    display: flex;
                    width: fit-content;
                    color: inherit;
                    text-decoration-color: currentColor;
                    transition: .3s;

                    i {
                        display: block;
                        height: rem(20);
                        margin-right: rem(10);

                        @include responsiveMin(992px) {
                            height: rem(24);
                        }

                        svg {
                            width: rem(20);
                            height: rem(20);

                            @include responsiveMin(992px) {
                                width: rem(24);
                                height: rem(24);
                            }

                            path {
                                fill: currentColor;
                            }
                        }
                    }

                    span {
                        font-weight: 700;
                        font-size: rem(18);
                        line-height: rem(21);

                        @include responsiveMin(992px) {
                            font-size: rem(24);
                            line-height: rem(28);
                        }
                    }

                    &:hover {
                        color: #FC6076;
                    }
                }
            }
        }
    }

    .post-featured-image {
        margin-top: rem(30);
        margin-bottom: rem(30);

        img {
            width: 100%;
            height: auto;
        }
    }
}
