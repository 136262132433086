@import "../../../../scss/mixins.scss";
@import "../../../../scss/vars.scss";

.pagination-wrapper {
    @include responsiveMax(992px) {
        background: linear-gradient(315deg, #fc6076 0%, #ff4d66 48.44%, #ff6826 100%);
        padding: rem(2);
        margin: 0 auto;
        width: fit-content;
        margin-top: rem(30);

        &.pagination__disable {
            display: none;
        }
    }

    @include responsiveMin(992px) {
        width: rem(1080);
    }

    .data-preloader {
        height: rem(60);
        position: absolute;
        top: 0;
    }
    
    .sk-cube-grid {
        background: linear-gradient(315deg, #fc6076 0%, #ff4d66 48.44%, #ff6826 100%);
        right: 50px !important;
    }
}


.pagination {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @include responsiveMin(992px) {
        width: 100%;
    }


    &__btn {
        margin: 0 rem(20);
        cursor: pointer;
    }

    .pagination__btn--prev {
        display: none;

        @include responsiveMin(992px) {
            display: block;
        }
    }

    .pagination__btn {
        &.disabled {
            pointer-events: none;

            i {
                &:before {
                    color: rgba(62, 62, 83, 0.5);
                }
            }
        }

        a {
            outline: none;
        }
    }

    &__item {
        min-width: rem(50);
        min-height: rem(50);
        position: relative;
        background: #ffffff;
        border: rem(1) solid rgba(62, 62, 83, 0.03);
        margin: rem(5);
        cursor: pointer;
        font-size: rem(18);
        font-weight: bold;
        line-height: 100%;
        color: #3e3e53;
        display: none;
        padding: 2px;

        @include responsiveMin(992px) {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @include responsiveMin(1920px) {
            padding: rem(2);

        }

        &-active {
            border: none;
            background: linear-gradient(315deg, #fc6076 0%, #ff4d66 48.44%, #ff6826 100%);
        }
        
        a {
            width: 100%;
            height: rem(46);
            display: flex;
            justify-content: center;
            align-items: center;
            outline: none;
            border: none;
            background: #fff;
            outline: none;
            cursor: pointer;
        }
    }

    &__mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(320);
        height: rem(60);
        background: #fff;
        border: none;
        outline: none;

        @include responsiveMax(330px) {
            width: rem(280);
        }

        @include responsiveMin(992px) {
            margin: 0 auto 30px;
        }

        a {
            width: 100%;
            height: 100%;
            text-align: center;
            font-weight: bold;
            font-size: rem(18);
            line-height: rem(60);
            border: none;
            display: block;
            justify-content: center;
            align-items: center;
            background: linear-gradient(315deg, #fc6076 0%, #ff4d66 48.44%, #ff6826 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &--top {
            margin-bottom: 0;
        }
    }

    .pagination__disable {
        display: none;
    }
}
