@import "../../scss/vars";
@import "../../scss/mixins";
.wrapper {
    position: relative;
    &::before {
        content: '';
        display: block;
        padding-top: calc(var(--y, 9) / var(--x, 16) * 100%);
    }
}
.preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.icon {
    width: rem(34);
    height: rem(34);
    object-fit: contain;
    @include responsiveMin(992px) {
        width: rem(84);
        height: rem(84);
    }
}
.poster {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.video {
    display: block;
    object-fit: cover;
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}