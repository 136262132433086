@import "../../scss/vars";
@import "../../scss/mixins";

.page {
    &__logo {
        position: absolute;
        left: rem(20);
        top: rem(20);
        z-index: 100;

        &--dark {
            svg {
                path {
                    fill: #3E3E53 !important;
                }
            }
        }

        @include responsiveMin(992px) {
            position: fixed;
            left: rem(40);
            top: rem(30);
        }

        &--min-version {
            .page {
                &__logo {
                    &-img {
                        &--large {
                            transform: translateX(rem(-300));
                        }

                        &--min {
                            transform: translateX(rem(-167));

                            //Ukrainian flag
                            &:after {
                                right: rem(-15);
                            }
                        }
                    }
                }
            }
        }

        a {
            display: flex;
            align-items: flex-start;
        }

        &-img {
            transition: all .4s ease-in-out;

            svg {
                max-width: 100%;
                width: 100%;
                height: auto;
                max-height: rem(74);

                path {
                    transition: all .4s;
                }
            }

            &--large {
				width: rem(200);
			}

            //Ukrainian flag
            &--min, &--mobile {
                &:after {
                    content: '';
                    position: absolute;
                    background-image: url('../../images/ua-flag.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    transition: all .4s;
                }
            }

			&--min {
                position: relative;
				width: rem(31);
				margin-left: rem(-44);

                //Ukrainian flag
                &:after {
                    width: rem(16);
                    height: rem(12);
                    top: rem(7);
                    right: rem(-35);
                }
			}

            &--mobile {
                width: rem(150);

                //Ukrainian flag
                &:after {
                    width: rem(13);
                    height: rem(10);
                    top: rem(5);
                    right: rem(-17);
                }
            }
        }
    }

    &__nav {
        position: absolute;
        right: rem(20);
        top: rem(36);
        z-index: 100;
        display: flex;
        align-items: center;

        @include responsiveMin(992px) {
            &--right-margin {
                margin-right: rem(15);
            }

            position: fixed;
            right: rem(40);
            top: rem(50);
        }

        &-item {
            margin-left: rem(25);

            @include responsiveMin(992px) {
                margin-left: rem(50);
            }

            &.mobile-position {
              @include responsiveMax(991px) {
                position: fixed;
                right: rem(40);
                bottom: rem(143);

                .nav-item {
                  &__text {
                    display: block;
                    width: 100%!important;
                  }
                }
              }

              @include responsiveMax(767px) {
                right: rem(20);
                bottom: rem(33);
              }
            }

            &:first-child {
                margin-left: 0;
            }

            &--linkedin {
              display: none;

              @include responsiveMax(991px) {
                position: fixed;
                left: rem(30);
                bottom: rem(120);
                margin: 0;
                opacity: 0;

                a,
                button {
                  width: rem(50);
                  height: rem(50);
                  border-radius: 50%;
                  background: #fff;
                  border: none;
                  overflow: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-decoration: none;
                  transition: all .3s;
                  position: relative;
                  box-shadow: 0 0 rem(50) rgba(0, 0, 0, 0.2);

                  &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    transition: all .3s;
                    background: $footer-feedback-btn-bg;
                    opacity: 0;
                  }

                  &:hover {
                    background: transparent;

                    &:before {
                      opacity: 1;
                    }

                    i {
                      &:before {
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: #fff;
                      }
                    }
                  }

                  i {
                    position: relative;
                    z-index: 2;

                    &:before {
                      font-size: rem(18);
                      background: $footer-feedback-btn-bg;
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
              }

              @include responsiveMax(767px) {
                left: 6%;
                bottom: rem(20);
              }
            }

            &--visible {
              @include responsiveMax(991px) {
                display: block;
                animation-name: linkedInVisible;
                animation-fill-mode: forwards;
                animation-duration: .4s;
                animation-delay: .6s;
              }
            }

            @keyframes linkedInVisible {
              from { opacity: 0; }
              to { opacity: 1; }
            }
        }

        .nav-item {

            &__link {
                display: flex;
                align-items: center;
                text-decoration: none;
                cursor: pointer;
            }

            &__icon {
                i {
                    color: #fff;
                    font-size: rem(20);

                    &:before {
                        transition: all .4s ease-in-out;
                    }
                }
            }

            &__text {
                display: none;
                overflow: hidden;
                transition: all .4s ease-in-out;

                @include responsiveMin(992px) {
                    display: block;
                }

                &-wrap {
                    padding-left: rem(10);
                    font-weight: bold;
                    font-size: rem(18);
                    line-height: rem(21);
                    text-transform: uppercase;
                    color: #FFFFFF;
                    display: inline-block;
                }
            }

            &__feedback {

                @include responsiveMin(992px) {
                    &:hover {
                        .nav-item {
                            &__icon {
                                transform-origin: top center;
                                animation-name: swing;
                                animation-duration: 1s;
                                animation-fill-mode: both;
                            }
                        }
                    }
                }
            }

            &__menu {

                @include responsiveMin(992px) {

                    &:hover {
                        .menu-close-icon {
                            transform: scale(.8) translate(0,0);
                        }
                    }
                }
            }

            &__dark {

                .menu-close-icon {
                    .top-line,
                    .middle-line,
                    .bottom-line {
                        background: #3E3E53 !important;
                    }
                }

                .nav-item {
                    &__icon {
                        i {
                            color: #3E3E53;

                            &:before {
                                color: #3E3E53;
                            }
                        }
                    }

                    &__text {
                        &-wrap {
                            color: #3E3E53;
                        }
                    }
                }
            }
        }
    }

    &__footer {

        @include responsiveMin(992px) {
            display: flex;

            &-content {
                flex: 1;
            }
        }
    }
}

.menu-close-icon {
    width: rem(30);
    height: rem(24);
    position: relative;
    transition: all .4s;

    @include responsiveMin(992px) {
        width: rem(40);
        height: rem(32);
    }

    .top-line,
    .middle-line,
    .bottom-line {
        height: rem(5);
        background: #fff;
        display: block;
        border-radius: rem(5);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        transition: all .3s ease-in-out;

        @include responsiveMin(992px) {
            height: rem(6);
        }
    }

    .top-line,
    .bottom-line {
        width: rem(20);

        @include responsiveMin(992px) {
            width: rem(28);
        }
    }

    .middle-line {
        width: rem(30);

        @include responsiveMin(992px) {
            width: rem(40);
        }
    }

    .middle-line {
        animation: middle-line-animation-off .3s ease-in-out forwards;
    }

    .top-line {
        animation: top-line-animation-off .3s ease-out forwards;
    }

    .bottom-line {
        animation: bottom-line-animation-off .3s ease-out forwards;
    }

    @keyframes top-line-animation-on {
        0% {
            transform: translateY(-200%);
        }
        50% {
            transform: translateY(0);
            width: rem(30);
        }
        100% {
            transform: translateY(0) rotate(-45deg);
            width: rem(30);
        }
    }

    @include responsiveMin(992px) {
        @keyframes top-line-animation-on {
            0% {
                transform: translateY(-200%);
            }
            50% {
                transform: translateY(0);
                width: rem(40);
            }
            100% {
                transform: translateY(0) rotate(-45deg);
                width: rem(40);
            }
        }
    }

    @keyframes middle-line-animation-on {
        0% {
            opacity: 1;
        }
        50%, 100% {
            opacity: 0;
        }
    }

    @keyframes bottom-line-animation-on {
        0% {
            transform: translateY(200%);
        }
        50% {
            transform: translateY(0);
            width: rem(30);
        }
        100% {
            transform: translateY(0) rotate(45deg);
            width: rem(30);
        }
    }

    @include responsiveMin(992px) {
        @keyframes bottom-line-animation-on {
            0% {
                transform: translateY(200%);
            }
            50% {
                transform: translateY(0);
                width: rem(40);
            }
            100% {
                transform: translateY(0) rotate(45deg);
                width: rem(40);
            }
        }
    }

    @keyframes top-line-animation-off {
        0% {
            transform: translateY(0);
            width: rem(30);
        }
        50% {
            transform: translateY(0);
            width: rem(30);
        }
        100% {
            transform: translateY(-200%);
        }
    }

    @include responsiveMin(992px) {
        @keyframes top-line-animation-off {
            0% {
                transform: translateY(0);
                width: rem(40);
            }
            50% {
                transform: translateY(0);
                width: rem(40);
            }
            100% {
                transform: translateY(-200%);
            }
        }
    }

    @keyframes middle-line-animation-off {
        0% {
            opacity: 0;
        }
        50%, 100% {
            opacity: 1;
        }
    }

    @keyframes bottom-line-animation-off {
        0% {
            transform: translateY(0);
            width: rem(30);
        }
        50% {
            transform: translateY(0);
            width: rem(30);
        }
        100% {
            transform: translateY(200%);
        }
    }

    @include responsiveMin(992px) {
        @keyframes bottom-line-animation-off {
            0% {
                transform: translateY(0);
                width: rem(40);
            }
            50% {
                transform: translateY(0);
                width: rem(40);
            }
            100% {
                transform: translateY(200%);
            }
        }
    }

    &.menu-is-opened {
        .middle-line {
            animation: middle-line-animation-on .5s ease-in-out forwards;
        }

        .top-line {
            animation: top-line-animation-on .5s ease-out forwards;
        }

        .bottom-line {
            animation: bottom-line-animation-on .5s ease-out forwards;
        }
    }
}
