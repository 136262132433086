@import '../../scss/vars.scss';
@import '../../scss/mixins.scss';

.support {
  position: relative;

  &__title {
    position: relative;

    @include responsiveMin(992px) {
      margin-bottom: rem(50);
    }

    &-text {
      font-size: rem($m_h2_font_size);
      line-height: rem($m_h2_line_height);
      text-align: center;
      font-weight: $m_h2_font_weight;

      @include responsiveMin(992px) {
        width: fit-content;
        font-size: rem($d_h2_font_size);
        line-height: rem($d_h2_line_height);
        text-align: left;
      }
    }
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(19) rem(48);
    font-weight: 700;
    font-size: rem(18);
    line-height: rem(21);
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: rem(10) auto 0;
    text-decoration: none;
    z-index: 1;

    @include responsiveMin(560px) {
      max-width: rem(320);
    }

    @include responsiveMin(992px) {
      width: fit-content;
      min-width: rem(320);
      margin-top: rem(20);
      margin-left: rem(150);
    }

    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      transition: opacity .3s;
      background: linear-gradient(349.38deg, #FF6826 0%, #FF4D66 49.48%, #FC6076 100%);
    }

    &:before {
      z-index: -2;
    }

    &:after {
      opacity: 0;
      z-index: -1;
    }

    @include responsiveMin(992px) {
      &:before {
        transform: rotate(-180deg);
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &--bottom {
    padding: rem(20);

    @include responsiveMin(992px) {
      padding: rem(150);

      &:after {
        content: '';
        position: absolute;
        bottom: rem(50);
        right: rem(545);
        width: rem(875);
        height: rem(230);
        background-image: url('../../images/rotated-painted-arrows.svg');
        background-size: contain;
        background-repeat: no-repeat;
        animation: diagonalRight 10s infinite;
      }

      .support__button {
        margin: 0;
      }

      p {
        margin-left: 0;
      }
    }

    .support__text {
      margin-bottom: 20px;

      @include responsiveMin(992px) {
        max-width: rem(480);
        padding-left: 0;
      }
    }
  }

  &__text {
    width: fit-content;
    font-size: rem($m_text_font_size);
    line-height: rem($m_text_line_height);
    margin-bottom: 20px;

    @include responsiveMin(992px) {
      font-size: rem($d_text_font_size);
      line-height: rem($d_text_line_height);
      max-width: rem(480);
    }


    p {
      margin: rem(10) 0;

      a {
        color: #ff4d66;
        text-decoration: underline;
        text-decoration-color: #ff4d66;

        &:hover {
          text-decoration-color: transparent;
        }
      }
    }
  }
}

.background-gray {
  background: rgba($color: #3E3E53, $alpha: 0.04)
}