@import "../../../../scss/vars";
@import "../../../../scss/mixins";

/* START .support-numbers */
.support-numbers {
  padding: rem(200) 0 rem(152);
  position: relative;

  @include responsiveMax(991px) {
    padding: rem(30) rem(20);
  }

  &__mobile-media {
    display: none;

    @include responsiveMax(991px) {
      display: block;
      text-align: right;
      margin-right: rem(-20);

      img {
        max-width: 100%;
      }
    }
  }

  &__gears-bg {
    display: none;
  }

  @include responsiveMin(992px) {
    &:before,
    &:after {
      position: absolute;
      z-index: -1;
      display: block;
      content: "";
    }

    &:before {
      top: 0;
      left: 0;
      width: rem(800);
      height: rem(200);
      background: url("images/top_bg_element.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:after {
      right: 0;
      bottom: 0;
      width: rem(600);
      height: rem(500);
      background: url("images/bottom_bg_element.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    &__gears-bg {
      display: block;
      width: rem(350);
      height: rem(400);
      position: absolute;
      z-index: -1;
      right: rem(650);
      bottom: rem(50);
      background: url("images/bg_gears.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__title {
    position: relative;
    z-index: 1;
    padding: rem(30) 0 rem(10);

    &-text {
      font-weight: 900;
      text-align: center;
      font-size: rem($m_h2_font_size);
      line-height: rem($m_h2_line_height);
      text-transform: uppercase;
      color: #3E3E53;
    }

    &-bg {
      display: none;

      @include responsiveMin(992px) {
        display: block;
        font-weight: 900;
        font-size: rem(96);
        line-height: rem(113);
        text-transform: uppercase;
        color: #3E3E53;
        opacity: 0.03;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    @include responsiveMin(992px) {
      padding: 0 0 rem(56);
      margin-left: rem(200);

      &-text {
        text-align: left;
        font-size: rem($d_h2_font_size);
        line-height: rem($d_h2_line_height);
      }
    }
  }

  &__description {
    width: rem(560);
    margin: rem(76) 0 rem(174) rem(500);
    font-size: rem($d_text_font_size);
    line-height: rem($d_text_line_height);
    color: #3E3E53;

    @include responsiveMax(991px) {
      margin: 0 auto;
      width: 100%;
      font-size: rem($m_text_font_size);
      line-height: rem($m_text_line_height);
    }
  }

  &__row {
    display: flex;
    align-items: center;
    padding-left: rem(732);

    &-item {
      margin-right: rem(144);
    }

    @include responsiveMax(991px) {
      padding: rem(20) 0 0;
      justify-content: center;

      &-item {
        margin-right: 0;
      }
    }
  }
}

.support-number {
  text-align: center;

  &__value {
    font-weight: 900;
    font-size: rem(96);
    line-height: rem(113);
    text-align: center;
    letter-spacing: -0.05em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: $menu_bg;
  }

  &__title {
    font-weight: bold;
    font-size: rem(14);
    line-height: rem(16);
    color: $menu_bg;

    @include responsiveMin(992px) {
      font-size: rem(18);
      line-height: rem(21);
    }
  }

  &:nth-child(1) {
    .support-number {
      &__value {
        color: #FFBB50;
      }
    }
  }

  &:nth-child(2) {
    .support-number {
      &__value {
        color: #F599B9;
      }
    }
  }

  &:nth-child(3) {
    .support-number {
      &__value {
        color: #FC6076;
      }
    }
  }

  @include responsiveMax(991px) {

    &__value {
      font-size: rem(48);
      line-height: rem(56);
    }
  }

  @include responsiveMax(639px) {
    flex-grow: 1;
    margin: 0;
  }
}

.parallax-layer {
  display: none;

  @include responsiveMin(992px) {
    position: absolute;
    display: block;

    img {
      display: inline-block;
      max-width: 100%;
    }

    &__left {
      left: rem(70);
      bottom: rem(-50);
      width: rem(492);
    }

    &__right-top {
      right: rem(60);
      top: rem(0);
      width: rem(756);
    }

    &__right-bottom {
      right: 0;
      bottom: rem(-80);
      width: rem(333);
      transform: translateX(100%);
      text-align: right;
    }
  }
}

/* END .support-numbers */
