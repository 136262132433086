@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.e-commerce-page {
    color: #3E3E53;
    
    &--preloader {
        min-height: 100vh;
        position: relative;
        background: #3E3E53;
    
        .data-preloader {
            position: absolute;
            top: 0;
            left: 0;
        }

        .sk-cube {
            background: #FFF;
        }
    }

    h1 {
        color: #3E3E53;
        text-align: center;
        font-weight: 900;
        font-size: rem($m_h1_font_size);
        line-height: rem($m_h1_line_height);
        text-transform: uppercase;

        @include responsiveMin(992px) {
            font-size: rem($d_h1_font_size);
            line-height: rem($d_h1_line_height);
            max-width: rem(800);
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
    }
    
    &__titling {
		position: relative;
		padding-top: rem(30);
        padding-bottom: rem(10);

        @include responsiveMax(991px) {
            max-width: 100%;
            margin-left: rem(20);
            margin-right: rem(20);
        }

        @include responsiveMin(992px) {
            padding-top: rem(150);
        }

		&-text {
			font-weight: $d_h1_font_weight;
            font-size: rem($m_h1_font_size);
            line-height: rem($m_h1_line_height);
			text-transform: uppercase;
			color: #3e3e53;
			text-align: center;
		}

		&-bg {
			display: none;
		}

        @include responsiveMin(992px) {

            &-text {
                font-size: rem($d_h1_font_size);
                line-height: rem($d_h1_line_height);
            }

			&-bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				text-align: center;
				z-index: 2;
				font-weight: 900;
				font-size: rem(96);
				line-height: rem(113);
				text-transform: uppercase;
				color: #3e3e53;
				opacity: 0.03;
				display: flex;
				justify-content: center;
				overflow: hidden;
                padding-top: rem(150);

				span {
					white-space: nowrap;
				}
			}
		}
	}

    section {
        padding: rem(20) rem(20) rem(30) rem(20);
        position: relative;

        @include responsiveMin(992px) {
            padding: 0;
            min-height: rem(960);

            &.left-side-number {
                padding-left: rem(150);
    
                .number {
                    left: rem(130);
                }
            }
    
            &.right-side-number {
                .number {
                    left: auto;
                    right: rem(130);
                }
            }
        }

        .number {
            position: absolute;
            font-weight: 900;
            font-size: rem(96);
            line-height: 90%;
            color: rgba($color: #3E3E53, $alpha: .05);
            top: 0;
            left: rem(20);
            z-index: -1;
    
            @include responsiveMin(992px) {
                font-size: rem(400);
                line-height: 100%;
                top: rem(560);
            }
        }
    }

    &__title {
        position: relative;

        @include responsiveMin(992px) {
            margin-bottom: rem(50);
        }

        &-text {
            font-size: rem($m_h2_font_size);
            line-height: rem($m_h2_line_height);
            text-align: center;
            font-weight: $m_h2_font_weight;
            text-transform: uppercase;

            @include responsiveMin(992px) {
                width: fit-content;
                font-size: rem($d_h2_font_size);
                line-height: rem($d_h2_line_height);
                text-align: left;
            }
        }

        &-bg {
            display: none;

            @include responsiveMin(992px) {
                z-index: -10;
                display: block;
                min-width: 100vw;
                font-weight: 900;
                font-size: rem(96);
                line-height: 1;
                text-transform: uppercase;
                color: #3e3e53;
                opacity: 0.03;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        br {
            display: none;

            @include responsiveMin(992px) {
                display: block;
            }
        }
    }

    &__text {
        width: fit-content;

        p {
            font-size: rem($m_text_font_size);
            line-height: rem($m_text_line_height);
            margin-top: rem(10);

            @include responsiveMin(992px) {
                font-size: rem($d_text_font_size);
                line-height: rem($d_text_line_height);
                max-width: rem(500);
                margin-top: rem(20);
                margin-left: rem(300);

                &:first-of-type {
                    margin-left: rem(150);
                }
            }

            a {
                color: #ff4d66;
                text-decoration: underline;
                text-decoration-color: #ff4d66;

                &:hover {
                    text-decoration-color: transparent;
                }
            }
        }

        h3 {
            font-size: rem($m_h3_font_size);
            line-height: rem($m_h3_line_height);
            margin-top: rem(20);

            @include responsiveMin(992px) {
                font-size: rem($d_h3_font_size);
                line-height: rem($d_h3_line_height);
                margin-left: rem(300);
                margin-top: rem(50);
            }
        }
    }

    &__image {
        width: 100%;
        max-width: rem(500);
        margin: 0 auto;
        margin-top: rem(20);

        @include responsiveMin(992px) {
            max-width: none !important;  
            margin: 0;
        }
    }

    &__image-laptop {
        width: 100%;
        max-width: rem(500);
        margin: 0 auto;
        margin-top: rem(20);

        @include responsiveMin(992px) {
            max-width: 55%;
            margin: 0;
        }
    }

    &__certificate {
        width: rem(270);
        margin: rem(30) auto rem(30) auto;
        filter: drop-shadow(0px 0px rem(50) rgba(0, 0, 0, 0.2));

        @include responsiveMin(992px) {
            margin: rem(30) 0 0 rem(150);
        }
    }

    img {
        display: block;
        max-width: 100%;
    }

    .konzeption {
        margin-top: rem(10);

        @include responsiveMin(992px) {
            margin-top: 0;
            padding-top: rem(200);
            padding-left: rem(150);
    
            .number {
                left: rem(130);
            }
        }

        &__image {
            @include responsiveMin(992px) {
                position: absolute;
                top: rem(100);
                right: rem(100);
                margin: 0;
                width: rem(920);
            }

            .laptop {
                position: relative;
                width: 70.625%;
                margin-left: auto;
                margin-right: 0;
                z-index: 3;

                @include responsiveMin(992px) {
                    width: rem(650);
                }
            }

            .tablet {
                width: 54.0625%;
                margin-top: -62%;
                z-index: 1;

                @include responsiveMin(992px) {
                    width: rem(500);
                    animation-delay: 500ms !important;
                }
            }

            .phone {
                position: relative;
                width: 32.5%;
                margin-top: -11%;
                margin-left: 5.3125%;
                z-index: 2;

                @include responsiveMin(992px) {
                    width: rem(300);
                    animation-delay: 1000ms !important;
                }
            }

            .laptop, .tablet, .phone {
                img {
                    width: 100%;
                }
            }
        }
    }

    .design {
        @include responsiveMin(992px) {
            padding-top: rem(150);
            padding-left: rem(150);
    
            .number {
                left: rem(130);
            }
        }

        &__content {
            display: flex;
            flex-direction: column;

            @include responsiveMin(992px) {
                display: block;
            }

            &--text-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: rem(0);

                @include responsiveMin(992px) {
                    flex-direction: row;
                    gap: rem(200);
                }
            }

            &--text-content {

                @include responsiveMin(992px) {
                    max-width: rem(500);
                }

                p {
                    margin-top: rem(10);

                    a {
                        color: #FF4D66;
                    }
                }
            }
        }

        &__text {
            @include responsiveMin(992px) {
                display: flex;

                p {
                    margin-bottom: 0;

                    &:last-of-type {
                        margin-left: rem(200);
                    }
                }
            }
        }

        .slider-holder {
            order: 2;
            position: relative;
            margin-right: rem(-20);
            margin-left: rem(-20);

            .frame {
                margin-top: 0;
                position: absolute;
                width: 52.2%;
                pointer-events: none;
                z-index: 2;

                @include responsiveMin(600px) {
                    width: rem(285);
                }
            }

            .swiper-container {
                padding-top: 10.5%;
                padding-bottom: 6.5%;
                margin-left: 15.83%;

                @include responsiveMin(600px) {
                    padding-top: rem(57);
                    margin-left: rem(86);
                }
            }

            .swiper-slide {
                display: block;
                width: 37.29%;
                box-shadow: rem(-2) rem(-2) rem(15) rem(2) rgba($color: #000000, $alpha: .2);
                margin-right: 6.3%;

                @include responsiveMin(600px) {
                    width: rem(169);
                }

                img {
                    width: 100%;
                }
            }
        }

        &__phoneHolder {
            position: relative;
            margin-left: rem(150);
        }

        &__frame {
            position: absolute;
            width: rem(350);
            z-index: 10;
        }

        &__screensHolder {
            display: flex;
            margin-left: rem(106);
            padding-top: rem(71);
            padding-bottom: rem(59);

            &_item {
                display: block;
                width: rem(208);
                height: 100%;
                margin-right: rem(42);
                box-shadow: rem(-10) rem(10) rem(25) rem(1) rgba($color: #000000, $alpha: .4);

                &:first-child {
                    margin-right: rem(62);
                }
            }
        }

        &__number {
            @include responsiveMin(992px) {
                right: auto !important;
                left: rem(130) !important;
                top: rem(525);
            }
        }
    }

    .launch {
        @include responsiveMin(992px) {
            display: flex;
            padding-top: rem(130);
            padding-bottom: rem(130);
            padding-left: rem(150);
    
            .number {
                left: rem(130);
            }

            &__textHolder {
                padding-top: rem(70);
            }

            &__image {
                position: relative;
                width: rem(1200);
                margin-left: auto;
                margin-right: rem(-150);

                .can-scroll {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    left: rem(50);
                    top: rem(200);
                    transform: translateX(200%);

                    &__text {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: rem(150);
                        height: rem(150);
                        border: rem(5) solid #3E3E53;
                        background: #FFF;
                        border-radius: 50%;
                        font-family: "Joystix Monospace";
                        text-align: center;
                        text-transform: uppercase;
                        font-size: rem(14);
                        color: #3E3E53;
                        font-weight: bold;
                    }

                    svg {
                        display: block;
                        margin-top: rem(2);
                        width: rem(72);
                        height: rem(72);
                    }
                }

                .frame {
                    width: 100%;
                    pointer-events: none;
                    position: relative;
                    z-index: 2;
                }

                .scrollImage {
                    position: absolute;
                    top: rem(37);
                    left: rem(211);
                    width: 63.57%;
                    max-height: rem(522);
                    overflow-y: scroll;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    &::-webkit-scrollbar {
                        width: rem(10);
                    }

                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 rem(6) rgba(0, 0, 0, 0.3);
                        background: #FFF;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: darkgrey;
                        border-radius: rem(4);
                    }
                }
            }

            &.active {
                .scrollImage {
                    animation: preventScroll 5s ease forwards;
                    animation-delay: 1s;
    
                    img {
                        transform: translateY(0);
                        animation: autoScroll 5s ease forwards;
                        animation-delay: 1s;
                    }
                }
    
                .can-scroll {
                    animation: bounce 1s ease forwards;
                    animation-delay: 1s;
                    will-change: transform;
                }
    
                @keyframes preventScroll {
                    0% {
                        pointer-events: none;
                        overflow-y: hidden;
                    }
    
                    99% {
                        pointer-events: none;
                        overflow-y: hidden;
                    }
    
                    100% {
                        pointer-events: all;
                        overflow-y: scroll;
                    }
                }
    
                @keyframes autoScroll {
                    0% {
                        transform: translateY(0);
                    }
    
                    50% {
                        transform: translateY(calc(-100% + 37.3rem));
                    }
            
                    100% {
                        transform: translateY(0);
                    }
                }
    
                @keyframes bounce {
                    0% {
                        transform: translateX(200%);
                    }
    
                    50% {
                        transform: translateX(-10%);
                    }
            
                    100% {
                        transform: translateX(0);
                    }
                }
            }
        }
    }

    .systems {
        @include responsiveMin(992px) {
            display: flex;
            padding-top: rem(130);
            padding-bottom: rem(130); 
            padding-left: rem(150);
            padding-right: rem(320);

            &__textHolder {
                order: 2;
                margin-top: rem(70);
                margin-left: auto;
            }

            .number {
                left: auto;
                right: rem(130);
            }
        }

        &__image {
            position: relative;
            width: rem(320);
            height: rem(274);

            @include responsiveMin(992px) {
                width: rem(821);
                height: rem(700);
            }

            .gear {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;

                &__image {
                    width: 100%;
                    filter: drop-shadow(0 0 30px rgba(0, 0, 0, .5));

                    @include responsiveMin(1920px) {
                        filter: drop-shadow(0 0 rem(30) rgba(0, 0, 0, .6));
                    }
                }

                &__text {
                    position: absolute;
                }

                &--1 {
                    width: rem(117);
                    height: rem(112);
                    top: 0;
                    left: rem(10);

                    .gear__text {
                        width: rem(70);
                    }

                    .gear__image {
                        animation: rotation 5s linear infinite;
                    }

                    @include responsiveMin(992px) {
                        width: rem(300);
                        height: rem(286);
                        left: rem(26);

                        .gear__text {
                            width: rem(180);
                        }
                    }
                }

                &--2 {
                    width: rem(96);
                    height: rem(93);
                    top: rem(123);
                    left: rem(38);
                    
                    .gear__text {
                        width: rem(58);
                    }

                    .gear__image {
                        animation: rotation 5s linear infinite reverse;
                        animation-delay: 1s;
                    }

                    @include responsiveMin(992px) {
                        width: rem(248);
                        height: rem(238);
                        top: rem(315);
                        left: rem(96);

                        .gear__text {
                            width: rem(150);
                        }
                    }
                }

                &--3 {
                    width: rem(96);
                    height: rem(93);
                    top: rem(93);
                    left: rem(135);
                    
                    .gear__text {
                        margin-top: rem(13);
                        width: rem(77);
                    }

                    .gear__image {
                        animation: rotation 5s linear infinite;
                        animation-delay: 1.05s;
                    }

                    @include responsiveMin(992px) {
                        width: rem(248);
                        height: rem(238);
                        top: rem(238);
                        left: rem(346);

                        .gear__text {
                            width: rem(150);
                        }
                    }
                }

                &--4 {
                    width: rem(78);
                    height: rem(74);
                    bottom: rem(3);
                    right: rem(84);

                    .gear__text {
                        width: rem(47);
                    }

                    .gear__image {
                        animation: rotation 5s linear infinite reverse;

                        animation-delay: 1.25s;

                        @include responsiveMin(992px) {
                            animation-delay: 1.4s;
                        }
                    }

                    @include responsiveMin(992px) {
                        width: rem(200);
                        height: rem(190);
                        bottom: rem(8);
                        right: rem(220);

                        .gear__text {
                            width: rem(120);
                        }
                    }
                }

                &--5 {
                    width: rem(78);
                    height: rem(74);
                    bottom: rem(28);
                    right: 0;

                    .gear__text {
                        width: rem(47);
                    }

                    .gear__image {
                        animation: rotation 5s linear infinite;
                        animation-delay: 1.25s;

                        @include responsiveMin(992px) {
                            animation-delay: .65s;
                        }
                    }

                    @include responsiveMin(992px) {
                        width: rem(200);
                        height: rem(190);
                        bottom: rem(73);

                        .gear__text {
                            width: rem(120);
                        }
                    }

                }
            }
        }
    }

    .headless-commerce {
        padding: rem(50) rem(20);
        background: rgba($color: #3E3E53, $alpha: 0.04);

        @include responsiveMin(992px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: rem(120) rem(150);
        }

        .number {
            @include responsiveMax(991px) {
                top: rem(30);
            }

            @include responsiveMin(992px) {
                left: rem(130);
            }
        }

        &.active {
            .headless-commerce__backend, .headless-commerce__frontend {
                span {
                    transform: translateX(0);
                }
            }
        }

        &__textHolder {
            margin-bottom: rem(20);

            @include responsiveMin(992px) {
                margin-bottom: 0;
            }
        }

        &__animation-container {
            position: relative;
            width: 100%;
            max-width: rem(500);
            margin: 0 auto;

            @include responsiveMin(992px) {
                margin: 0;
                max-width: rem(690);
            }
        }

        &__frontend, &__backend {
            img {
                position: relative;
                width: 100%;
                z-index: 2;
            }

            span {
                display: block;
                text-align: center;
                text-transform: uppercase;
                font-weight: 800;
                font-size: rem(18);
                line-height: 1;
                transition: 1s;

                @include responsiveMin(992px) {
                    font-size: rem(25);
                }
            }
        }

        &__backend {
            color: #FF6826;

            span {
                margin-bottom: rem(10);
                transform: translateY(rem(50));

                @include responsiveMin(992px) {
                    margin-bottom: rem(20);
                }
            }
        }

        &__frontend {
            margin-top: rem(-16);
            padding: 0 rem(20);

            @include responsiveMin(992px) {
                padding: 0 rem(50);
            }

            span {
                color: #FF4D66;
                opacity: .8;
                margin-top: rem(10);
                transform: translateY(rem(-50));

                @include responsiveMin(992px) {
                    margin-top: rem(20);
                }
            }
        }

        &__gear {
            position: relative;
            width: 37%;
            margin: rem(-16) auto 0 auto;
            filter: drop-shadow(0px 12px 20px rgba(0, 0, 0, 0.15));
            z-index: 3;

            @include responsiveMin(1920px) {
                filter: drop-shadow(0px rem(12) rem(20) rgba(0, 0, 0, 0.15));
            }

            &_image {
                width: 100%;
                animation: rotation 5s linear infinite reverse;
            }

            &_icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 48%;
                height: auto;
                z-index: 2;
            }
        }

        &__arrows {
            position: absolute;
            top: 13%;
            left: 50%;
            transform: translateX(-50%);
            width: 62%;
            height: auto;
            animation: arrowsRotation 5s linear infinite;
        }
    }

    .tracking {
        padding-top: rem(50);

        @include responsiveMin(992px) {
            display: flex;
            padding-top: rem(150);
            padding-bottom: rem(30);
            padding-right: rem(150);
            padding-left: rem(150);
        }

        .number {
            @include responsiveMax(991px) {
                top: rem(30);
            }

            @include responsiveMin(992px) {
                left: rem(130);
            }
        }

        &__textHolder {
            @include responsiveMin(992px) {
                padding-top: rem(164);
            }
        }

        &__image {
            position: relative;
            width: rem(320);
            height: rem(385);

            @include responsiveMin(992px) {
                margin-left: auto;
                margin-right: 0;
                width: rem(712);
                height: rem(852);
            }

            .gear {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;

                &__image {
                    width: 100%;
                    filter: drop-shadow(0 0 30px rgba(0, 0, 0, .5));

                    @include responsiveMin(1920px) {
                        filter: drop-shadow(0 0 rem(30) rgba(0, 0, 0, .6));
                    }
                }

                &__text {
                    position: absolute;
                }

                &--1, &--5 {
                    width: rem(128);
                    height: rem(135);
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);

                    .gear__text {
                        width: rem(81);
                    }

                    .gear__image {
                        animation: rotation 5s linear infinite;
                    }

                    @include responsiveMin(992px) {
                        width: rem(291);
                        height: rem(304);

                        .gear__text {
                            width: rem(180);
                        }
                    }
                }

                &--5 {
                    top: rem(250);

                    @include responsiveMin(992px) {
                        top: rem(550);
                    }
                }

                &--3 {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: rem(90);
                    height: rem(85);

                    .gear__text {
                        width: rem(54);
                    }

                    .gear__image {
                        animation: rotation 5s linear infinite reverse;
                        animation-delay: .05s;
                    }

                    @include responsiveMin(992px) {
                        width: rem(200);
                        height: rem(190);

                        .gear__text {
                            width: rem(120);
                        }
                    }
                }

                &--2, &--4 {
                    top: 50%;
                    transform: translateY(-50%);
                    width: rem(106);
                    height: rem(112);

                    .gear__text {
                        width: rem(67);
                    }

                    @include responsiveMin(992px) {
                        width: rem(236);
                        height: rem(250);

                        .gear__text {
                            width: rem(150);
                        }
                    }
                }

                &--2 {
                    left: 0;

                    .gear__image {
                        animation: rotationBack 5s linear infinite reverse;
                    }
                }

                &--4 {
                    right: 0;

                    .gear__text{
                        margin-top: rem(13);
                    }

                    .gear__image {
                        animation: rotation 5s linear infinite;
                    }
                }
            }
        }

        @include responsiveMin(992px) {
            &__mobileImage {
                display: none;
            }

            &__desktopImage {
                width: rem(852);
                position: relative;
                margin-left: auto;
                margin-right: rem(96);
            }
        }
    }

    .hosting {
        @include responsiveMin(992px) {
            position: relative;
            display: flex;
            padding-top: rem(200);
            padding-bottom: rem(200);
            padding-left: rem(150);
        }

        &__mobileImage {
            margin-bottom: rem(20);
        }

        &__leftLaptop, &__rightLaptop {
            display: none;
        }

        @include responsiveMin(992px) {
            &__mobileImage {
                display: none;
            }

            &__leftLaptop, &__rightLaptop {
                display: block;
                position: absolute;
                top: rem(130);
                width: rem(1200);

                img {
                    width: 100%;
                }
            }

            &__leftLaptop {
                left: rem(-150);

                img {
                    width: 100%;
                }
            }

            &__rightLaptop {
                right: rem(-150);

                .animation {
                    position: absolute;
                    top: rem(35);
                    left: rem(170);
                    width: rem(700);
                    height: rem(450);
                    overflow: hidden;

                    &__text {
                        position: relative;
                        text-align: center;
                        font-weight: 900;
                        font-size: rem(120);
                        text-transform: uppercase;
                        color: #FF6826;
                        margin-top: rem(30);
                        z-index: 2;
                    }

                    &__image {
                        width: rem(650);
                        margin: 0 auto;
                        z-index: 1;

                        svg {
                            overflow: visible !important;
                        }
                    }
                }
            }
        }
    }

    .newsletter {
        @include responsiveMin(992px) {
            display: flex;
            padding-top: rem(190);
            padding-bottom: rem(190);
            padding-right: rem(300);
            padding-left: rem(150);
    
            .number {
                left: rem(130);
            }

            &__textHolder {
                padding-top: rem(10);
            }
        }

        &__image {
            max-width: rem(450);

            @include responsiveMin(992px) {
                margin-left: auto;
                margin-right: 0;
                width: rem(620);
            }

            .leftPhone {
                width: 61.875%;
                margin-left: rem(-20);
                margin-top: -57%;

                @include responsiveMin(992px) {
                    width: rem(413);
                    height: rem(475);
                    margin-top: rem(-376);
                }
            }

            .rightPhone {
                position: relative;
                width: 43.75%;
                margin-left: auto;
                margin-right: rem(38);
                z-index: 2;

                @include responsiveMin(992px) {
                    width: rem(287);
                    height: rem(475);
                    margin-right: 0;
                    animation-delay: 500ms !important;
                }
            }

            .leftPhone, .rightPhone {
                img {
                    width: 100%;
                }
            }
        }
    }

    .datenschutz {
        @include responsiveMin(992px) {
            display: flex;
            padding-top: rem(180);
            padding-bottom: rem(180);
            padding-left: rem(250);
            padding-right: rem(170);

            &__textHolder {
                padding-top: rem(20);
                margin-left: auto;
                margin-right: 0;
                order: 2;
            }
    
            .number {
                left: auto;
                right: rem(130);
            }
        }

        &__image {
            max-width: 400px;

            @include responsiveMin(992px) {
                width: rem(489);
                order: 1;
            }

            .leftPhone {
                position: relative;
                width: 52.5%;
                margin-left: rem(23);
                z-index: 2;

                @include responsiveMin(992px) {
                    width: rem(287);
                    margin-left: 0;
                    animation-delay: 500ms !important;
                }
            }

            .rightPhone {
                width: 63.75%;
                margin-left: auto;
                margin-right: rem(10);
                margin-top: -81%;

                @include responsiveMin(992px) {
                    width: rem(350);
                    margin-top: rem(-436);
                    margin-right: 0;
                }
            }

            .leftPhone, .rightPhone {
                img {
                    width: 100%;
                }
            }
        }
    }

    .tsparticles {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    // fade animations
    @include responsiveMin(992px) {
        .anim-item {
            .fadeInUp {
                will-change: transform;
                transform: translateY(200%);
                opacity: 0;
            }

            .fadeInLeft {
                will-change: transform;
                transform: translateX(-200%);
                opacity: 0;
            }

            .fadeInRight {
                will-change: transform;
                transform: translateX(200%);
                opacity: 0;
            }

            &.active {
                .fadeInUp {
                    animation: fadeInUp 1.5s ease forwards;
                }

                .fadeInLeft {
                    animation: fadeInLeft 1.5s ease forwards;
                }

                .fadeInRight {
                    animation: fadeInRight 1.5s ease forwards;
                }
            }
        }
    }

    @keyframes fadeInUp {
        from {
            transform: translateY(200%);
            opacity: 0;
        }

        to {
            transform: translateY(0);
            opacity: 1
        }
    }

    @keyframes fadeInLeft {
        0% {
            transform: translateX(-200%);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes fadeInRight {
        0% {
            transform: translateX(200%);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes rotationBack {
        0% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }

    @keyframes arrowsRotation {
        0% {
            transform: translateX(-50%) rotate(0deg);
        }
        100% {
            transform: translateX(-50%) rotate(360deg);
        }
    }

    &__button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(19) rem(48);
        font-weight: 700;
        font-size: rem(18);
        line-height: rem(21);
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        margin: rem(10) auto 0;
        text-decoration: none;
        z-index: 1;

        @include responsiveMin(560px) {
            max-width: rem(320);
        }

        @include responsiveMin(992px) {
            width: fit-content;
            min-width: rem(320);
            margin-top: rem(20);
            margin-left: rem(300);
        }

        &:before,
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            transition: opacity .3s;
            background: linear-gradient(349.38deg, #FF6826 0%, #FF4D66 49.48%, #FC6076 100%);
        }

        &:before {
            z-index: -2;
        }

        &:after {
            opacity: 0;
            z-index: -1;
        }

        @include responsiveMin(992px) {
            &:before {
                transform: rotate(-180deg);
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }
    }
}
