@import "../../../scss/vars";

@import "../../../scss/mixins";

@import "../Animation/Animation.scss";

.success-stories_page {
    .timeline {
        background: #3e3e53;
        position: relative;
        overflow: hidden;
    
        @include responsiveMin(992px) {
            &:before,
            &:after {
                content: "";
                position: absolute;
                background-position: center;
                background-size: cover;
                top: 0;
                right: 0;
            }
    
            &:before {
                width: rem(800);
                height: rem(200);
                background-image: url("../../../images/bg-wave-white.svg");
            }
            &:after {
                width: rem(600);
                height: rem(500);
                background-image: url("../../../images/bg-wave.svg");
            }
        }

        .second-level-title {
            color: #ffffff;
            margin-top: rem(30);
            margin-bottom: 0;

            @include responsiveMin(992px) {
                margin: rem(130) 0 rem(105) rem(130);
            }
        }
    
        &__wrapper {
            display: flex;
            &:after {
                content: "";
                position: absolute;
                background-image: url("../images/alkima-erfolgsgeschichte-bw-online-shop-rauch-hintergrund.svg");
                background-repeat: no-repeat;
                background-size: cover;
                bottom: rem(-25);
                right: 0;
                width: 60%;
                min-height: rem(150);
            }
            @include responsiveMin(992px) {
                margin-bottom: rem(30);
            }
        }
    
        &__items {
            &--left {
                flex-basis: 80%;
                padding: rem(30) rem(20);
                @include responsiveMin(992px) {
                    padding-left: rem(230);
                    flex-basis: 70%;
                }
            }
    
            &--right {
                position: relative;
                flex-basis: 20%;
                @include responsiveMin(992px) {
                    flex-basis: 30%;
                }
            }
    
            &__img--rocket {
                position: absolute;
                top: 20%;
                right: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                transition: all 0.1s linear;

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    right: rem(55);
                }
    
                &:after {
                    content: "";
                    width: rem(45);
                    transform: translateY(3%);
                    height: 150%;
                    background: #ffffff;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    z-index: 1;
                    clip-path: polygon(40% 0%, 60% 0%, 100% 100%, 0% 100%);

                    @include responsiveMin(992px) {
                        width: rem(100);

                        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                            width: rem(35);
                        }
                    }
                }

                img {
                    position: absolute;
                    right: 0;
                    left: 0;
                    margin: auto;
                    z-index: 2;
                    max-width: rem(35);
                    width: rem(129);

                    @include responsiveMin(992px) {
                        max-width: rem(129);
                    }
                }
            }
    
            .tsparticles {
                position: absolute;
                bottom: 0;
                width: 100%;
            }
    
            &__btn {
                display: inline-block;
                font-weight: bold;
                font-size: rem(14);
                line-height: rem(16);
                text-transform: uppercase;
                color: #ffffff;
                padding: rem(7);
                position: relative;
                z-index: 3;
                margin: rem(30) 0;
    
                &:before {
                    content: "";
                    position: absolute;
                    width: rem(4);
                    height: rem(17);
                    background: #ffffff;
                    opacity: 0.2;
                    left: 0;
                    transition: all 0.5s linear;
                }
    
                &:hover::before {
                    width: 100%;
                }
            }
        }
    
        &__schedule {
            display: flex;
            margin-bottom: rem(20);
            position: relative;
            z-index: 3;

            @include responsiveMin(992px) {
                margin-bottom: rem(77);
            }
    
            &__number {
                font-weight: 900;
                font-size: rem(24);
                line-height: rem(28);
                text-align: center;
                text-transform: uppercase;
                background: linear-gradient(315deg, #fc6076 0%, #ff4d66 48.44%, #ff6826 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                position: relative;
                margin-right: rem(20);

                @include responsiveMin(992px) {
                    font-size: rem(48);
                    line-height: rem(56);
                    margin-right: rem(40);
                }

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    background: none;
                    color: #fc6076;
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: rem(2);
                    height: rem(20);
                    top: 50%;
                    left: 50%;
                    transform: translateY(rem(20));
                    background: linear-gradient(315deg, #fc6076 0%, #ff4d66 48.44%, #ff6826 100%);
    
                    @include responsiveMin(992px) {
                        height: rem(40);
                        transform: translateY(rem(40));
                    }
                }
            }

            &:last-child {
                .timeline__schedule__number:after {
                    display: none;
                }
            }
    
            &__date {
                font-weight: bold;
                font-size: rem(14);
                line-height: rem(16);
                margin-bottom: rem(5);
                color: #ffffff;

                @include responsiveMin(992px) {
                    font-size: rem(18);
                    line-height: rem(21);
                    margin-bottom: rem(20);
                }
            }

            &__description {
                font-size: rem(14);
                line-height: 150%;
                display: flex;
                align-items: center;
                color: #ffffff;
                background: #3e3e53;
                outline: rem(2) solid #3e3e53;
                
                @include responsiveMin(992px) {
                    font-size: rem(16);
                }
            }
        }
    
        //code for smoke
    
        .exhaust-fumes {
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            bottom: -2%;
            right: -25%;
    
            .inner {
                display: block;
                background: #ffffff;
                border-radius: 50%;
                position: absolute;
                right: calc(var(--x) * 1px);
                bottom: rem(-70);
            }
    
            #smoke8 {
                animation: fumes2 calc((var(--y) * 0.05) * 1s) infinite;
            }
            #smoke3,
            #smoke5,
            #smoke7,
            #smoke9,
            #smoke11 {
                animation: fumes calc((var(--y) * 0.3) * 1s) infinite;
                animation-delay: calc((var(--y)) * 5ms);
            }
            // end code for smoke
        }
    }
}
