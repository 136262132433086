@import '../../../../scss/vars';
@import '../../../../scss/mixins';

/* START .support-projects */

.support-projects {
    background: #3e3e53;

    @include responsiveMin(992px) {
        display: flex;
    }

    &__title {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-weight: 900;
            font-size: rem(72);
            line-height: rem(72);
            text-transform: uppercase;
            color: #ffffff;
            opacity: 0.02;

            @include responsiveMax(991px) {
                font-size: rem(36);
                line-height: rem(36);
            }
        }

        &-text {
            font-weight: 900;
            font-size: rem(36);
            line-height: rem(42);
            text-transform: uppercase;
            color: #ffffff;
            padding: 0 rem(50);
            text-align: center;

            @include responsiveMax(991px) {
                font-size: rem($m_h2_font_size);
                line-height: rem($m_h2_line_height);
                padding: 0 rem(20);
            }
        }
    }

    &__list {
        @include responsiveMin(992px) {
            width: 66.666%;
        }

        &-inner {
            height: 100%;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }

        &-item {
            flex: 0 0 25%;
            max-width: 25%;
            min-height: rem(320);

            // &:hover {
            //     box-shadow: 0 0 rem(50) rgba(0, 0, 0, 0.2);
            
            //     img {
            //         transform: scale(.95);
            //     }
            // }

            @include responsiveMin(992px) {
                border-right: 1px solid rgba(255, 255, 255, 0.05);

                &:nth-child(4n + 4) {
                    border-right: none;
                }

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                }
            }

            @include responsiveMax(991px) {
                min-height: rem(180);
                flex: 0 0 50%;
                max-width: 50%;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                border-right: 1px solid rgba(255, 255, 255, 0.1);

                &:nth-child(2n + 2) {
                    border-right: none;
                }
            }
        }
    }

    &__poster {
        width: 100%;
        max-width: rem(500);
        margin: -30px auto 0;

        @include responsiveMin(992px) {
            position: relative;
            width: 33.333%;
            max-width: rem(640);
            margin: 0;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                background: #ffffff;
                opacity: 0.1;
                margin-top: -1px;
                z-index: 1;
            }
        }

        img {
            width: 100%;

            @include responsiveMin(992px) {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 2;
            }
        }
    }
}

.support-project {
    padding: rem(30);

    a {
        display: block;
        width: 100%;
        height: 100%;
        transition: all 0.4s;
        position: relative;

        img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            transition: all 0.4s;
            transform: scale(0.99);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
}

.support-projects__to-all {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        color: #fff;
    }
}
/* END .support-projects */
