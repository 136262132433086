@import "../../../scss/vars";
@import "../../../scss/mixins";

.perfectes {
    position: relative;
    @include responsiveMin(992px) {
        min-height: 100vh;
    }

    &__bg {
        @include responsiveMin(992px) {
            position: absolute;
            width: rem(350);
            height: rem(400);
            background-image: url("../images/bg/gear.svg");
            background-size: 100%;
            bottom: rem(10);
            left: rem(180);
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;

        @include responsiveMin(992px) {
            position: relative;
            flex-direction: row;
            min-height: 100vh;

            &:before {
                content: "";
                position: absolute;
                width: rem(800);
                height: rem(200);
                background-image: url("../images/bg/wave-top.svg");
                background-size: 100%;
                top: 0;
            }

            &:after {
                content: "";
                position: absolute;
                width: rem(600);
                height: rem(500);
                background-image: url("../images/bg/wave-bottom.svg");
                background-repeat: no-repeat;
                background-size: 100%;
                bottom: 0;
                right: 0;
            }
        }
    }

    &__content {
        position: relative;
        background: linear-gradient(0deg, #3e3e53, #3e3e53), linear-gradient(0deg, #ffffff, #ffffff),
            linear-gradient(0deg, #f599b9, #f599b9), #ff3e59;

        @include responsiveMin(992px) {
            flex-basis: 50%;
            background: transparent;
            min-height: 100vh;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                width: 53.2%;
                flex-basis: auto;
            }
        }

        &-title {
            position: relative;

            .main-title {
                color: #ffffff;
                font-size: rem(22);
                line-height: rem(26);
                font-weight: 900;
                padding: rem(30) rem(21) rem(10);
                text-align: center;
                text-transform: uppercase;
                @include responsiveMin(992px) {
                    font-size: rem(48);
                    line-height: rem(56);
                    text-align: left;
                    margin: 0;
                    color: #ffffff;
                    padding: rem(200) rem(195) rem(30) rem(150);
                }
            }

            @include responsiveMin(992px) {
                z-index: 1;
                &-animate {
                    @include responsiveMin(992px) {
                        transform: translateX(200%);
                        color: #3e3e53;
                        animation: titleEffect 3.9s ease forwards; //animation for title

                        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                            animation: none !important;
                            transition-property: none !important;
                            transform: none !important;
                        }
                        
                        @keyframes titleEffect {
                            0% {
                                transform: translateX(200%);
                            }
                            25% {
                                transform: translateX(100%);
                            }
                            50% {
                                transform: translateX(100%);
                                color: #ffffff;
                            }
                            75% {
                                transform: translateX(0);
                                color: #3e3e53;
                            }
                            100% {
                                transform: translateX(0);
                                color: #ffffff;
                            }
                        }
                    }
                }
            }

            .span {
                @include responsiveMin(992px) {
                    transform: rotate(90deg);
                    padding: 0 rem(30);
                    display: inline-block;
                    animation: letterEffect 1.5s linear 7s forwards; //animation for "i" in title

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        animation: none !important;
                        transition-property: none !important;
                        transform: none !important;
                        padding: 0;
                    }
                    
                    @keyframes letterEffect {
                        0% {
                            transform: rotate(90deg);
                            padding: 0 rem(30);
                        }
                        50% {
                            transform: rotate(0);
                        }
                        100% {
                            transform: rotate(0);
                            padding: 0;
                        }
                    }
                }
            }

            &-bg {
                display: none;

                @include responsiveMin(992px) {
                    display: block;
                    position: absolute;
                    color: #3e3e53;
                    opacity: 0.03;
                    font-size: rem(96);
                    line-height: rem(113);
                    font-weight: 900;
                    text-transform: uppercase;
                    min-width: rem(1000);
                    top: rem(190);
                    left: rem(-10);
                    transform: translateX(rem(158));

                    &-white {
                        color: #ffffff;
                    }
                }
            }
            &--mobile {
                font-weight: 900;
                font-size: rem(22);
                line-height: rem(26);
                text-align: center;
                text-transform: uppercase;
                color: #ffffff;
                margin: rem(30) auto rem(10);
            }
        }

        &-text {
            color: #ffffff;
            font-size: rem(14);
            line-height: 150%;
            padding: 0 rem(20) rem(30);

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                color: #fff !important;
            }

            @include responsiveMin(992px) {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                font-size: rem(16);
                padding: rem(363) rem(250) rem(303);

                &-animate {
                    @include responsiveMin(992px) {
                        animation: titleEffect 3.9s ease 0.2s forwards;
                        transform: translateX(200%);
                        color: #3e3e53;

                        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                            animation: none !important;
                            transition-property: none !important;
                            transform: none !important;
                        }
                    }
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: rem(800);
                    height: rem(200);
                    background-image: url("../images/bg/wave-top-white.svg");
                    background-size: 100%;
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: rem(350);
                    height: rem(400);
                    background-image: url("../images/bg/gear-white.svg");
                    background-size: 100%;
                    bottom: rem(10);
                    left: rem(180);
                }
            }
        }

        &-bg {
            @include responsiveMin(992px) {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: linear-gradient(0deg, #3e3e53, #3e3e53), linear-gradient(0deg, #ffffff, #ffffff),
                    linear-gradient(0deg, #f599b9, #f599b9), #ff3e59;
                transform: translateX(250%);

                &-animate {
                    animation: bgEffect 2s ease 1s forwards; //animation for bg

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        animation: none !important;
                        transition-property: none !important;
                        transform: none !important;
                    }

                    @keyframes bgEffect {
                        0% {
                            transform: translateX(250%);
                        }
                        50% {
                            transform: translateX(100%);
                        }
                        100% {
                            transform: translateX(-100%);
                        }
                    }
                }
            }
            &-second {
                @include responsiveMin(992px) {
                    transform: translateX(200%);
                    animation: bgSecondEffect 2.2s ease 2.1s forwards; //animation for  darck-blue bg

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        animation: none !important;
                        transition-property: none !important;
                        transform: none !important;
                    }

                    @keyframes bgSecondEffect {
                        0% {
                            transform: translateX(200%);
                        }
                        100% {
                            transform: translateX(0%);
                        }
                    }
                }
            }
        }
    }

    &__title {
        background: #ffffff;
        order: -1;
        padding: rem(30);
        text-align: center;
        @include responsiveMin(992px) {
            order: 1;
            flex-basis: 50%;
            padding-top: rem(331);
            padding-bottom: rem(364);
            height: 100%;

            &-animate {
                @include responsiveMin(992px) {
                    transform: translateX(100%);
                    animation: mainTitle 2s ease 3.5s forwards; //animation for main title with white bg

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        animation: none !important;
                        transition-property: none !important;
                        transform: none !important;
                    }

                    @keyframes mainTitle {
                        0% {
                            transform: translateX(100%);
                        }
                        100% {
                            transform: translateX(0);
                        }
                    }
                }
            }
        }
        img {
            width: 100%;
        }
    }

    &__text {
        order: -1;
        @include responsiveMin(992px) {
            order: 0;
            position: absolute;
            width: 100%;
            bottom: rem(170);
            left: rem(120);
            right: rem(149);
        }
        &-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include responsiveMin(992px) {
                max-width: 90%;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding-left: 0;
            }
        }
    }

    .text-item {
        position: relative;
        text-transform: uppercase;
        font-weight: 900;
        font-size: rem(20);
        line-height: rem(23);
        padding-bottom: rem(33);
        display: flex;
        align-items: center;

        &:after {
            content: "";
            position: relative;
            width: rem(75);
            height: rem(4);
            margin-left: rem(50);
        }
        &:first-child:after {
            margin-left: rem(70);
        }
        &:last-child:after {
            margin-left: rem(45);
        }

        @include responsiveMin(992px) {
            font-size: rem(36);
            line-height: rem(42);
            z-index: 5;

            &:after {
                width: rem(150);
                height: rem(6);
            }
        }

        &-yellow {
            color: #ffb744;
            &:after {
                background: #ffb744;
            }
            &-animate {
                @include responsiveMin(992px) {
                    transform: translateX(650%);
                    animation: yellowEffect 2s ease 5s forwards; //animation for von

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        animation: none !important;
                        transition-property: none !important;
                        transform: none !important;
                    }

                    @keyframes yellowEffect {
                        0% {
                            transform: translateX(650%);
                        }
                        100% {
                            transform: translateX(0);
                        }
                    }
                }
            }
        }

        &-pink {
            color: #f599b9;
            &:after {
                background: #f599b9;
            }
            @include responsiveMin(992px) {
                align-self: center;
            }
            &-animate {
                @include responsiveMin(992px) {
                    transform: translateX(500%);
                    animation: pinkEffect 2s ease 5.2s forwards; //animation for allen

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        animation: none !important;
                        transition-property: none !important;
                        transform: none !important;
                    }

                    @keyframes pinkEffect {
                        0% {
                            transform: translateX(600%);
                        }
                        100% {
                            transform: translateX(0);
                        }
                    }
                }
            }
        }

        &-red {
            color: #fc6076;
            &:after {
                background: #fc6076;
            }
            @include responsiveMin(992px) {
                align-self: flex-end;
            }
            &-animate {
                @include responsiveMin(992px) {
                    transform: translateX(400%);
                    animation: redkEffect 2s ease 5.4s forwards; // animation for seiten

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        animation: none !important;
                        transition-property: none !important;
                        transform: none !important;
                    }

                    @keyframes redkEffect {
                        0% {
                            transform: translateX(600%);
                        }
                        100% {
                            transform: translateX(0);
                        }
                    }
                }
            }
        }

        &-bg {
            position: absolute;
            opacity: 0.06;
            top: 0;
            font-size: rem(48);
            line-height: rem(56);
            @include responsiveMin(992px) {
                font-size: rem(96);
                line-height: rem(113);

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    left: 0;
                }
            }
        }
    }
}
