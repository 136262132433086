@import "../../../scss/vars.scss";
@import "../../../scss/mixins";

.second-level-title {
    position: relative;
    color: #3e3e53;
    margin-bottom: rem(10);

    @include responsiveMin(992px) {
        margin-bottom: rem(50);
    }

    &_text {
        position: relative;
        font-size: rem($m_h2_font_size);
        line-height: rem($m_h2_line_height);
        text-align: center;
        font-weight: $m_h2_font_weight;
        text-transform: uppercase;
        z-index: 2;
        margin-right: rem(20);
        margin-left: rem(20);

        @include responsiveMin(992px) {
            width: fit-content;
            font-size: rem($d_h2_font_size);
            line-height: rem($d_h2_line_height);
            text-align: left;
        }
    }

    &_bg {
        display: none;

        @include responsiveMin(992px) {
            display: block;
            min-width: 100vw;
            font-weight: 900;
            font-size: rem(96);
            line-height: 1;
            text-transform: uppercase;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.03;
            z-index: 1;
            pointer-events: none;
        }
    }
}
