@import "../../../../scss/vars";
@import "../../../../scss/mixins";

/* START .solutions */
.solutions {
    position: relative;

    .sk-cube-grid .sk-cube {
        background-color: #fff;
    }

    @include responsiveMin(992px) {
        height: 100vh;
    }

    &.transition {
        & * {
            transition: all .5s ease-in-out;
        }
    }

    &__bg-elements {
        @include responsiveMin(992px) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }

    &__content {
        @include responsiveMin(992px) {
            align-items: center;
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            z-index: 2;
        }
    }

    &__item {
        padding: rem(30) rem($left_right_padding);
        overflow: hidden;

        @include responsiveMin(992px) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        &--left-col {
            @include responsiveMax(991px) {
                background: #F599B9;
                padding-bottom: 0;
            }

            @include responsiveMin(992px) {
                padding: 0 rem(360) 0 rem(200);
            }

            .solutions {
                &__item {
                    &-image {
                        margin-top: rem(20);
                    }
                }
            }
        }

        &--right-col {
            @include responsiveMax(991px) {
                background: #FFB744;
                padding-top: 0;
            }

            @include responsiveMin(992px) {
                padding: 0 rem(200) 0 rem(360);
            }

            .solutions {
                &__item {
                    &-image {
                        margin-bottom: rem(20);
                    }
                }
            }
        }

        &-body {

            @include responsiveMin(992px) {
                text-align: left;
            }
        }

        &-title {
            color: #fff;
            font-size: rem($m_h2_font_size);
            line-height: rem($m_h2_line_height);
            text-transform: uppercase;
            text-align: center;
            font-weight: 900;

            @include responsiveMin(992px) {
                font-size: rem($d_h2_font_size);
                line-height: rem($d_h2_line_height);
                text-align: left;
            }
        }

        &-description {
            font-size: rem($m_text_font_size);
            line-height: rem($m_text_line_height);
            color: #FFF;

            @include responsiveMin(992px) {
                font-size: rem($d_text_font_size);
                line-height: rem($d_text_line_height);
            }

            p {
                margin: rem(10) 0;

                @include responsiveMin(992px) {
                    margin: 1rem 0;
                }
            }
        }

        &-link {
            color: #fff;
            display: block;
            width: fit-content;
            margin: 0 auto;

            @include responsiveMin(992px) {
                margin: 0;
            }
        }

        &-image {
            display: none;

            @include responsiveMax(991px) {
                display: block;

                img {
                    max-width: rem(230);
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
}

.view {
    &__wrapper {
        &--before {
            @include responsiveMax(991px) {
                width: 100% !important;
            }

            .view {
                &__content {
                    background: #fff;
                }

                &__bg-gears,
                &__image {
                    @include responsiveMax(991px) {
                        display: none;
                    }
                }

                &__title {
                    @include responsiveMax(991px) {
                        text-align: center;
                        padding: 30px 0;

                        h1 {
                            font-weight: 900;
                            font-size: rem($m_h1_font_size);
                            line-height: rem($m_h1_line_height);
                            text-transform: uppercase;
                            color: #3E3E53;
                        }
                    }
                }
            }
        }

        &--after {
            display: none;
        }
    }

    @include responsiveMin(992px) {
        &__wrapper {
            overflow: hidden;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;

            &--before {
                left: 0;
                z-index: 2;
                width: 50%;

                .view {
                    &__content {
                        background: #F599B9;
                    }

                    &__title {
                        color: #c68239;
                    }

                    &__bg-gears {
                        background: url("./solutions/bg_gear_before.svg") no-repeat bottom left rem(200);
                        bottom: 0;
                        left: 0;
                    }
                }
            }

            &--after {
                display: block;
                right: 0;
                z-index: 1;

                .view {
                    &__content {
                        background: #FFB744;
                    }

                    &__title {
                        color: #c8547e;
                    }

                    &__bg-gears {
                        background: url("./solutions/bg_gear_before.svg") no-repeat top rem(50) right rem(170);
                        top: 0;
                        right: 0;
                    }
                }
            }
        }

        &__content {
            position: relative;
            padding: 9% 0 6%;
            width: 100vw;
            height: 100%;

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
            }

            &:before {
                background: url("./solutions/cloud_bg_1.svg") no-repeat left top;
            }

            &:after {
                background: url("./solutions/cloud_bg_2.svg") no-repeat left top;
            }

            &--rotated-bg {

                &:before,
                &:after {
                    transform: rotate(180deg);
                }
            }
        }

        &__title {
            position: absolute;
            text-align: center;
            left: 0;
            top: rem(110);
            width: 100%;

            h1, p {
                font-weight: 900;
                font-size: rem($d_h1_font_size);
                line-height: rem($d_h1_line_height);
                text-transform: uppercase;
            }
        }

        &__image {
            z-index: 3;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: rem(470);
            display: flex;
            align-items: center;
            transform-style: preserve-3d;
            perspective: rem(470);

            img {
                width: 100%;
                display: block;
                margin-left: rem(-4);
            }
        }

        &__bg-gears {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 2;
        }
    }
}
/* END .solutions */