@import "../../../../scss/vars";
@import "../../../../scss/mixins";

.start-point {
    position: absolute;
    width: rem(36);
    height: rem(18);
    background: #E77D04;
    border: rem(3) solid #FFB744;
    top: rem(116);
    left: rem(108);
    z-index: 2;
    border-radius: 50%;

    @include responsiveMax(991px) {
        width: rem(36)/1.5;
        height: rem(18)/1.5;
        top: rem(116)/1.5;
        left: rem(108)/1.5;
        border: rem(1) solid #FFB744;
    }

    @include responsiveMax(639px) {
        width: rem(36)/3;
        height: rem(18)/3;
        top: rem(116)/3;
        left: rem(108)/3;
    }

}

.line {
    position: absolute;
    height: rem(1);
    z-index: 1;
    transform: rotate(30deg);
    transform-origin: rem(0) rem(0);

    &:before,
    &:after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
    }

    &:before {
        background: #FFB744;
        z-index: 2;
    }

    &:after {
        width: 100%;
        background: #655650;
    }


    &--1 {
        width: rem(48);
        top: rem(126);
        left: rem(127);
        @include responsiveMax(991px) {
            width: rem(48)/1.5;
            top: rem(126)/1.5;
            left: rem(127)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(48)/3;
            top: rem(126)/3;
            left: rem(127)/3;
        }
    }

    &--2 {
        width: rem(31);
        top: rem(160);
        left: rem(189);
        z-index: 4;

        @include responsiveMax(991px) {
            width: rem(34)/1.5;
            top: rem(160)/1.5;
            left: rem(189)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(34)/3;
            top: rem(160)/3;
            left: rem(189)/3;
        }
    }

    &--3 {
        width: rem(20);
        top: rem(205);
        left: rem(261);
        z-index: 4;

        @include responsiveMax(991px) {
            width: rem(23)/1.5;
            top: rem(203)/1.5;
            left: rem(263)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(23)/3;
            top: rem(201)/3;
            left: rem(263)/3;
        }
    }

    &--4-top {
        width: rem(62);
        top: rem(216);
        left: rem(283);
        transform: rotate(-30deg);

        @include responsiveMax(991px) {
            width: rem(60)/1.5;
            top: rem(215)/1.5;
            left: rem(283)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(61)/3;
            top: rem(216)/3;
            left: rem(283)/3;
        }
    }

    &--4-bottom {
        width: rem(76);
        top: rem(217);
        left: rem(283);
        transform: rotate(150deg);

        @include responsiveMax(991px) {
            width: rem(75)/1.5;
            top: rem(217)/1.5;
            left: rem(283)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(75)/3;
            top: rem(219)/3;
            left: rem(283)/3;
        }
    }

    &--5-top {
        width: rem(62);
        top: rem(185);
        left: rem(336);

        @include responsiveMax(991px) {
            width: rem(62)/1.5;
            top: rem(185)/1.5;
            left: rem(336)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(62)/3;
            top: rem(185)/3;
            left: rem(336)/3;
        }
    }

    &--5-bottom {
        width: rem(76);
        top: rem(253);
        left: rem(217);

        @include responsiveMax(991px) {
            width: rem(76)/1.5;
            top: rem(253)/1.5;
            left: rem(217)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(76)/3;
            top: rem(253)/3;
            left: rem(217)/3;
        }
    }

    &--6-top {
        width: rem(75);
        top: rem(233);
        left: rem(421);
        z-index: 4;

        @include responsiveMax(991px) {
            width: rem(76)/1.5;
            top: rem(233)/1.5;
            left: rem(423)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(76)/3;
            top: rem(233)/3;
            left: rem(423)/3;
        }
    }

    &--6-bottom {
        width: rem(75);
        top: rem(302);
        left: rem(300);
        z-index: 4;

        @include responsiveMax(991px) {
            width: rem(77)/1.5;
            top: rem(302)/1.5;
            left: rem(302)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(77)/3;
            top: rem(302)/3;
            left: rem(302)/3;
        }
    }

    &--7-top {
        width: rem(20);
        top: rem(296);
        left: rem(530);
        z-index: 4;

        @include responsiveMax(991px) {
            width: rem(17)/1.5;
            top: rem(297)/1.5;
            left: rem(532)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(20)/3;
            top: rem(297)/3;
            left: rem(532)/3;
        }
    }

    &--7-bottom {
        width: rem(20);
        top: rem(366);
        left: rem(409);
        z-index: 4;

        @include responsiveMax(991px) {
            width: rem(18)/1.5;
            top: rem(367)/1.5;
            left: rem(411)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(18)/3;
            top: rem(368)/3;
            left: rem(411)/3;
        }
    }


    &--8-top {
        width: rem(70);
        top: rem(307);
        left: rem(547);
        z-index: 4;
        transform: rotate(-210deg);

        @include responsiveMax(991px) {
            width: rem(70)/1.5;
            top: rem(307)/1.5;
            left: rem(548)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(70)/3;
            top: rem(308)/3;
            left: rem(549)/3;
        }
    }

    &--8-bottom {
        width: rem(70);
        top: rem(376);
        left: rem(426);
        z-index: 4;
        transform: rotate(-30deg);

        @include responsiveMax(991px) {
            width: rem(70)/1.5;
            top: rem(376)/1.5;
            left: rem(426)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(74)/3;
            top: rem(377)/3;
            left: rem(424)/3;
        }
    }

    &--9 {
        width: rem(70);
        top: rem(341);
        left: rem(489);

        @include responsiveMax(991px) {
            width: rem(70)/1.5;
            top: rem(340)/1.5;
            left: rem(488)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(70)/3;
            top: rem(341)/3;
            left: rem(489)/3;
        }
    }

    &--10 {
        width: rem(27);
        top: rem(391);
        left: rem(571);
        z-index: 4;

        @include responsiveMax(991px) {
            width: rem(27)/1.5;
            top: rem(391)/1.5;
            left: rem(575)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(27)/3;
            top: rem(391)/3;
            left: rem(575)/3;
        }
    }

    &--11 {
        width: rem(26);
        top: rem(405);
        left: rem(595);
        z-index: 4;

        @include responsiveMax(991px) {
            width: rem(22)/1.5;
            top: rem(406)/1.5;
            left: rem(600)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(24)/3;
            top: rem(405)/3;
            left: rem(598)/3;
        }
    }

    &--12-top {
        width: rem(74);
        top: rem(418);
        left: rem(618);
        z-index: 4;
        transform: rotate(-30deg);

        @include responsiveMax(991px) {
            width: rem(74)/1.5;
            top: rem(417)/1.5;
            left: rem(618)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(74)/3;
            top: rem(417)/3;
            left: rem(617)/3;
        }
    }

    &--12-bottom {
        width: rem(74);
        top: rem(419);
        left: rem(618);
        z-index: 4;
        transform: rotate(150deg);

        @include responsiveMax(991px) {
            width: rem(73)/1.5;
            top: rem(419)/1.5;
            left: rem(618)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(76)/3;
            top: rem(419)/3;
            left: rem(619)/3;
        }
    }

    &--13-top {
        width: rem(79);
        top: rem(381);
        left: rem(683);
        z-index: 4;

        @include responsiveMax(991px) {
            width: rem(87)/1.5;
            top: rem(381)/1.5;
            left: rem(683)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(87)/3;
            top: rem(381)/3;
            left: rem(683)/3;
        }
    }

    &--13-bottom {
        width: rem(78);
        top: rem(455);
        left: rem(554);
        z-index: 4;

        @include responsiveMax(991px) {
            width: rem(84)/1.5;
            top: rem(453)/1.5;
            left: rem(555)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(83)/3;
            top: rem(455)/3;
            left: rem(554)/3;
        }
    }

    &--14-top {
        width: rem(142);
        top: rem(405);
        left: rem(595);
        z-index: 4;
        transform: rotate(-30deg);

        @include responsiveMax(991px) {
            width: rem(142)/1.5;
            top: rem(405)/1.5;
            left: rem(600)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(142)/3;
            top: rem(405)/3;
            left: rem(600)/3;
        }
    }

    &--14-bottom {
        width: rem(113);
        top: rem(406);
        left: rem(595);
        transform: rotate(150deg);

        @include responsiveMax(991px) {
            width: rem(113)/1.5;
            top: rem(407)/1.5;
            left: rem(602)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(113)/3;
            top: rem(407)/3;
            left: rem(602)/3;
        }
    }

    &--15 {
        width: rem(140);
        top: rem(462);
        left: rem(497);

        @include responsiveMax(991px) {
            width: rem(140)/1.5;
            top: rem(461)/1.5;
            left: rem(498)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(140)/3;
            top: rem(461)/3;
            left: rem(498)/3;
        }
    }

    &--16-top {
        width: rem(43);
        top: rem(463);
        left: rem(497);
        transform: rotate(150deg);

        @include responsiveMax(991px) {
            width: rem(43)/1.5;
            top: rem(464)/1.5;
            left: rem(503)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(43)/3;
            top: rem(464)/3;
            left: rem(503)/3;
        }
    }

    &--16-bottom {
        width: rem(47);
        top: rem(533);
        left: rem(619);
        transform: rotate(150deg);

        @include responsiveMax(991px) {
            width: rem(47)/1.5;
            top: rem(532)/1.5;
            left: rem(618)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(47)/3;
            top: rem(533)/3;
            left: rem(619)/3;
        }
    }

    &--17 {
        width: rem(140);
        top: rem(334);
        left: rem(718);
        transform: rotate(-150deg);

        @include responsiveMax(991px) {
            width: rem(140)/1.5;
            top: rem(334)/1.5;
            left: rem(718)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(140)/3;
            top: rem(334)/3;
            left: rem(718)/3;
        }
    }

    &--18-top {
        width: rem(18);
        top: rem(263);
        left: rem(598);
        z-index: 4;
        transform: rotate(-30deg);

        @include responsiveMax(991px) {
            width: rem(21)/1.5;
            top: rem(263)/1.5;
            left: rem(598)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(21)/3;
            top: rem(262)/3;
            left: rem(598)/3;
        }
    }

    &--18-bottom {
        width: rem(18);
        top: rem(333);
        left: rem(718);
        z-index: 4;
        transform: rotate(-30deg);

        @include responsiveMax(991px) {
            width: rem(17)/1.5;
            top: rem(332)/1.5;
            left: rem(725)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(16)/3;
            top: rem(331)/3;
            left: rem(724)/3;
        }
    }

}

.word {
    position: absolute;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transform: translate(0,0);
    }

    &--1 {
        width: rem(85);
        height: rem(54);
        top: rem(177);
        left: rem(156);

        @include responsiveMax(991px) {
            width: rem(85)/1.5;
            height: rem(54)/1.5;
            top: rem(177)/1.5;
            left: rem(156)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(85)/3;
            height: rem(54)/3;
            top: rem(177)/3;
            left: rem(156)/3;
        }
    }

    &--2 {
        width: rem(48);
        height: rem(33);
        top: rem(300);
        left: rem(240);

        @include responsiveMax(991px) {
            width: rem(48)/1.5;
            height: rem(33)/1.5;
            top: rem(300)/1.5;
            left: rem(240)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(48)/3;
            height: rem(33)/3;
            top: rem(300)/3;
            left: rem(240)/3;
        }
    }

    &--3 {
        width: rem(66);
        height: rem(43);
        top: rem(354);
        left: rem(331);

        @include responsiveMax(991px) {
            width: rem(66)/1.5;
            height: rem(43)/1.5;
            top: rem(354)/1.5;
            left: rem(331)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(66)/3;
            height: rem(43)/3;
            top: rem(354)/3;
            left: rem(331)/3;
        }
    }

    &--4 {
        width: rem(85);
        height: rem(54);
        top: rem(365);
        left: rem(467);

        @include responsiveMax(991px) {
            width: rem(85)/1.5;
            height: rem(54)/1.5;
            top: rem(365)/1.5;
            left: rem(467)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(85)/3;
            height: rem(54)/3;
            top: rem(365)/3;
            left: rem(467)/3;
        }
    }

    &--5 {
        width: rem(81);
        height: rem(69);
        top: rem(528);
        left: rem(407);

        @include responsiveMax(991px) {
            width: rem(81)/1.5;
            height: rem(69)/1.5;
            top: rem(528)/1.5;
            left: rem(407)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(81)/3;
            height: rem(69)/3;
            top: rem(528)/3;
            left: rem(407)/3;
        }
    }

    &--6 {
        width: rem(160);
        height: rem(100);
        top: rem(449);
        left: rem(684);

        @include responsiveMax(991px) {
            width: rem(160)/1.5;
            height: rem(100)/1.5;
            top: rem(449)/1.5;
            left: rem(684)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(160)/3;
            height: rem(100)/3;
            top: rem(449)/3;
            left: rem(684)/3;
        }
    }

    &--7 {
        width: rem(116);
        height: rem(81);
        top: rem(273);
        left: rem(586);

        @include responsiveMax(991px) {
            width: rem(116)/1.5;
            height: rem(81)/1.5;
            top: rem(273)/1.5;
            left: rem(586)/1.5;
        }

        @include responsiveMax(639px) {
            width: rem(116)/3;
            height: rem(81)/3;
            top: rem(273)/3;
            left: rem(586)/3;
        }
    }
}

.point {
    fill: #655650;
}

@keyframes svg-pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

@keyframes line-size {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes changing-fill {
    from {
        fill: #655650;
        opacity: 0;
    }
    to {
        fill: #FFB744;
        opacity: 1;
    }
}

@keyframes changing-stroke {
    from {
        stroke: #655650;
        opacity: 0;
    }
    to {
        stroke: #FFB744;
        opacity: 1;
    }
}

@keyframes changing-points {
    from {
        fill: #655650;
    }
    to {
        fill: #FFB744;
    }
}

.hosting-animation {

    &__svg {
        position: relative;
        width: 100%;
        height: auto;
        z-index: 3;
        transform: translate(0,0);

        @include responsiveMin(992px) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }
    }

    button {
        z-index: 5;
        position: absolute;
    }

    &--play {
        .start-point {
            animation: svg-pulse .15s linear forwards;
        }

        .line {
            &--1 {
                &:before {
                    animation: line-size .15s linear forwards .15s;
                }
            }

            &--2 {
                &:before {
                    animation: line-size .2s linear forwards .45s;
                }
            }

            &--3 {
                &:before {
                    animation: line-size .15s linear forwards 1s;
                }
            }

            &--4-top,
            &--4-bottom {
                &:before {
                    animation: line-size .25s linear forwards 1.25s;
                }
            }

            &--5-top,
            &--5-bottom {
                &:before {
                    animation: line-size .2s linear forwards 1.5s;
                }
            }

            &--6-top,
            &--6-bottom {
                &:before {
                    animation: line-size .2s linear forwards 1.85s;
                }
            }

            &--7-top,
            &--7-bottom {
                &:before {
                    animation: line-size .1s linear forwards 2.2s;
                }
            }

            &--8-top,
            &--8-bottom {
                &:before {
                    animation: line-size .2s linear forwards 2.3s;
                }
            }

            &--9 {
                &:before {
                    animation: line-size .15s linear forwards 2.5s;
                }
            }

            &--10 {
                &:before {
                    animation: line-size .1s linear forwards 2.9s;
                }
            }

            &--11 {
                &:before {
                    animation: line-size .15s linear forwards 3s;
                }
            }

            &--12-top,
            &--12-bottom{
                &:before {
                    animation: line-size .3s linear forwards 3.15s;
                }
            }

            &--13-top,
            &--13-bottom {
                &:before {
                    animation: line-size .35s linear forwards 3.45s;
                }
            }

            &--14-top {
                &:before {
                    animation: line-size .2s linear forwards 3.1s;
                }
            }

            &--14-bottom {
                &:before {
                    animation: line-size .2s linear forwards 3.1s;
                }
            }

            &--15 {
                &:before {
                    animation: line-size .2s linear forwards 3.45s;
                }
            }

            &--16-top,
            &--16-bottom {
                &:before {
                    animation: line-size .15s linear forwards 3.65s;
                }
            }

            &--17 {
                &:before {
                    animation: line-size .2s linear forwards 3.45s;
                }
            }

            &--18-top,
            &--18-bottom {
                &:before {
                    animation: line-size .15s linear forwards 3.65s;
                }
            }

        }

        .box-shadow {

            &-1 {
                animation: changing-fill .15s linear forwards .3s;
            }

            &-2 {
                animation: changing-fill .15s linear forwards .75s;
            }

            &-3 {
                animation: changing-fill .15s linear forwards 1.7s;
            }

            &-4 {
                animation: changing-fill .15s linear forwards 2.05s;
            }

            &-5 {
                animation: changing-fill .15s linear forwards 2.65s;
            }

            &-6 {
                animation: changing-fill .15s linear forwards 3.8s;
            }

            &-7 {
                animation: changing-stroke .15s linear forwards 3.8s;
            }
        }

        .point {

            &-1 {
                animation: changing-points .15s linear forwards .9s;
            }

            &-2 {
                animation: changing-points .15s linear forwards 1.15s;
            }

            &-3 {
                animation: changing-points .15s linear forwards 2.8s;
            }

            &-4 {
                animation: changing-points .15s linear forwards 3s;
            }

            &-5-top,
            &-5-bottom {
                animation: changing-points .15s linear forwards 3.3s;
            }
        }

        .word {
            &--1 {
                animation: svg-pulse .15s linear forwards .75s;
            }

            &--2 {
                animation: svg-pulse .15s linear forwards 1.7s;
            }

            &--3 {
                animation: svg-pulse .15s linear forwards 2.05s;
            }

            &--4 {
                animation: svg-pulse .15s linear forwards 2.65s;
            }

            &--5,
            &--6,
            &--7 {
                animation: svg-pulse .15s linear forwards 3.8s;
            }
        }
    }
}