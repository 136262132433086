@import '../../scss/vars.scss';
@import '../../scss/mixins.scss';

.other-technologies__content-right {
  margin: rem(0) rem(20) rem(40) rem(20);
  background-color: #fff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
  padding: rem(20) rem(20);

  @include responsiveMin(992px) {
    margin: rem(0) rem(180) rem(50) rem(0);
    padding: rem(30) rem(50);
    width: rem(400);
    height: fit-content;
    display: flex;
    flex-direction: column;
  }

  .technologies-title {
    font-style: normal;
    font-weight: bold;
    font-size: rem(18);
    line-height: rem(21);
    color: #181C2D;
    margin-bottom: rem(20);

    @include responsiveMin(992px) {
      font-size: rem(24);
      line-height: rem(28);
      text-align: center;
    }
  }

  .technologies-list {
    display: flex;
    flex-direction: column;

    .technologies-item {
      margin-top: rem(10);

      a {
        text-decoration: none;
      }

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .technologies-item {
    display: inline;
    margin: 0;

    span {
      height: 100%;
      font-weight: bold;
      font-size: rem(18);
      line-height: rem(21);
      padding: rem(2) rem(10);
      position: relative;
      color: #3e3e53;

      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: rem(4);
        left: 0;
        bottom: 0;
        top: 0;
        background: #3e3e53;
        opacity: 0.3;
        transition: all 0.5s ease;

        @include responsiveMin(992px) {
          width: rem(6);
        }
      }

      &:hover::before {
        width: 100%;
      }
    }
  }
}