@import "../../../scss/vars";
@import "../../../scss/mixins";
@import "../Animation/Animation.scss";

.screen-page {
    @include responsiveMin(992px) {
        padding: rem(75) rem(200) rem(100);
        position: relative;
    }

    @include responsiveMax(991px) {
        .swiper-container {
            width: 100%;

            .swiper-wrapper {
                width: 100%;
                transform: none !important;
                margin-top: rem(20) !important;

                .swiper-slide {
                    width: 100%;
                    padding: 0 20px;
                    transform: none !important;
                }
            }
        }
    }

    .content__img__wrapper {
        @include responsiveMax(991px) {
            &--i1 {
                width: rem(90);
                height: rem(93);
            }
            &--i2,
            &--i3 {
                width: rem(110);
                height: rem(117);
            }
            &--i3 {
                top: rem(-12);
                @include responsiveMin(992px) {
                    top: auto;
                }
            }
        }
    }

    .right {
        @include responsiveMin(992px) {
            padding-left: rem(60);
            margin-top: rem(190);
        }
        .content__img__wrapper--i1 {
            display: none;
        }
        .content__img__wrapper {
            width: rem(155);
            height: rem(163);
            @include responsiveMin(992px) {
                width: rem(286);
                height: rem(290);
            }
        }
    }

    .order {
        order: -1;
        margin-top: 0;
        @include responsiveMin(992px) {
            order: 0;
        }
    }

    &__items {
        margin-top: rem(10);
        display: flex;
        flex-direction: column;
        padding: rem(30) rem(20);
 
        @include responsiveMin(992px) {
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                flex-wrap: wrap;
            }
        }

        &__img {
            max-width: 65%;
            position: relative;
            left: 40%;
            top: 5px;
            height: auto;

            @include responsiveMin(470px) {
                left: 45%;
            }

            @include responsiveMin(520px) {
                left: 30%;
            }

            @include responsiveMin(600px) {
                left: 35%;
            }

            @include responsiveMin(750px) {
                left: 25%;
            }
        }

        &__mockup {
            margin-bottom: rem(100);
        }

        &-menu {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: rem(30);
            &-item {
                margin-bottom: rem(30);
                @include responsiveMin(992px) {
                    margin-right: rem(27);
                    margin-bottom: 0;
                }
            }
            @include responsiveMin(992px) {
                margin: rem(10) 0 rem(25);
            }
            li:last-child {
                @include responsiveMax(670px) {
                    margin-right: rem(10);
                }
            }
        }

        &-img {
            width: 100%;
        }

        &-magazin {
            margin-top: 0 !important;
            padding-top: 0 !important;

            .screen-page__items-content {
                &:nth-child(2) {
                    @include responsiveMin(992px) {
                        margin-top: 0 !important;
                        margin-bottom: auto;
                    }
                }
            }
        }

        &-content {
            margin-top: rem(80);

            &:nth-child(1) {
                margin-top: rem(30);
                min-height: rem(260);
            }

            &:nth-child(4) {
                margin-top: rem(30);
                min-height: rem(260);
            }

            &:nth-child(2) {
                @include responsiveMin(992px) {
                    margin-top: rem(150);
                }
            }

            &:nth-child(3) {
                @include responsiveMin(992px) {
                    margin-top: rem(170);
                    margin-bottom: rem(220);
                }
            }
    
            @include responsiveMin(992px) {
                flex-basis: 50%;
                margin: rem(180) 0;

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    flex-basis: auto;
                    width: 50%;
                }
            }

            &-img {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                @include responsiveMin(992px) {
                    transform: translateY(rem(150));
                    opacity: 0;
                }

                &-animation {
                    @include responsiveMin(992px) {
                        animation: gare 1s ease forwards;
                    }
                }
            }
        }

        &-magazin {
            position: relative;

            @include responsiveMin(992px) {
                margin: rem(100) 0 rem(70);
            }

            @include responsiveMin(992px) {
                .scrolling-phone {
                    width: 50%;
                    height: 100%;
                }
    
                .scrolling-inner {
                    // background-color: red;
                    width: fit-content;
                    height: fit-content;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-40%);
                }
            }

            .screen-page__items-content {
                &:nth-child(2) {
                    @include responsiveMin(992px) {
                        margin-top: rem(10);
                    }
                }
            }

            .mobile-first__message {
                display: none;

                @include responsiveMin(992px) {
                    display: block;
                    position: absolute;
                    top: 10%;
                    right: -5%;
                    width: rem(150);
                    height: rem(220);
                    z-index: -1;
                }

                &--text {
                    @include responsiveMin(992px) {
                        width: 100%;
                        height: rem(150);
                        border: rem(5) solid #fff;
                        border-radius: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: rem(14);
                        line-height: rem(20);
                        text-transform: uppercase;
                        color: #ffffff;
                        font-family: "Joystix Monospace";
                        position: relative;
                        text-align: center;
                        padding: rem(50);
                        
                        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                            padding: rem(25);

                            div {
                                width: 100%;
                                display: flex;
                                text-align: center;
                            }
                        }
                    }
                }

                &--wire {
                    @include responsiveMin(992px) {
                        position: absolute;
                    }
                }

                &-animate {
                    @include responsiveMin(992px) {
                        animation: phone 1s ease 12s forwards;

                        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                            animation: none !important;
                            transition-property: none !important;
                            transform: none !important;
                        }

                        @keyframes phone {
                            0% {
                                transform: translateX(-100%);
                            }
                            50% {
                                transform: translateX(10%);
                            }
                            100% {
                                transform: translateX(0);
                            }
                        }
                    }
                }
            }

            .journal {
                position: absolute;
                width: 100%;
                bottom: 0;
                left: -7%;
                height: auto;

                @include responsiveMin(520px) {
                    width: 80%;
                }

                @include responsiveMin(650px) {
                    width: 70%;
                }

                @include responsiveMin(750px) {
                    width: 60%;
                    margin-top: rem(80);
                }

                @include responsiveMin(886px) {
                    width: 55%;
                    margin-top: rem(80);
                }

                @include responsiveMin(980px) {
                    width: 50%;
                    margin-top: rem(80);
                }

                @include responsiveMin(992px) {
                    width: rem(800);
                    position: relative;
                    margin-left: rem(-200);
                }
            }

            .mobile-first__img {
                width: rem(210);

                @include responsiveMin(992px) {
                    position: absolute;
                    bottom: 52%;
                    left: 32%;
                    top: auto;
                    width: rem(250);
                    height: rem(480);
                }
            }

            .mobile-first__phone {
                position: initial;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @include responsiveMin(767px) {
                    justify-content: center;
                }

                img {
                    z-index: 2;
                    margin: 0;
                }
            }
        }

        &__mockup {
            position: relative;
            min-height: rem(835);
        }

        &__button {
            font-weight: bold;
            font-size: rem(18);
            line-height: rem(21);
            text-transform: uppercase;
            background: -webkit-linear-gradient(315deg, #fc6076 0%, #ff4d66 48.44%, #ff6826 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            position: relative;
            padding: 0 rem(10);
            cursor: pointer;
            transition: color 0.5s ease;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                color: #fc6076;
                background: none;
            }

            &:hover {
                &::before {
                    width: 100%;
                }
            }

            &::before {
                content: "";
                position: absolute;
                width: rem(5);
                height: rem(22);
                background: linear-gradient(315deg, #fc6076 0%, #ff4d66 48.44%, #ff6826 100%);
                opacity: 0.2;
                left: 0;
                transition: all 0.5s ease;
                transform: translateY(-50%);
                top: 50%;
            }
        }

        &__button--active {
            -webkit-text-fill-color: #ffffff;
            background: none;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                color: #fff;
                background: none;
            }

            &:before {
                background: #3e3e53;
                width: 100%;
                opacity: 0.8;
                z-index: -1;
            }
        }
    }

    .tsparticles {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .mockup__wrapper {
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            @include responsiveMin(992px) {
                &:hover {
                    .mobile-first__message {
                        transform: translateX(0);
                    }
                }
            }
        }

        .mobile-first__message {
            display: none;

            @include responsiveMin(992px) {
                display: block;
                position: absolute;
                top: 15%;
                width: rem(150);
                height: rem(220);
                z-index: -1;
                transform: translateX(-100%);
            }

            &--text {
                @include responsiveMin(992px) {
                    width: 100%;
                    height: rem(150);
                    border: rem(5) solid #3e3e53;
                    border-radius: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: rem(14);
                    line-height: rem(20);
                    text-transform: uppercase;
                    font-family: "Joystix Monospace";
                    position: relative;
                    text-align: center;
                    padding: rem(50);
                    
                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        padding: rem(25);

                        div {
                            width: 100%;
                            display: flex;
                            text-align: center;
                        }
                    }
                }
            }

            &--wire {
                @include responsiveMin(992px) {
                    position: absolute;
                }
            }

            &-animate {
                @include responsiveMin(992px) {
                    animation: phone 1s ease 12s forwards;

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        animation: none !important;
                        transition-property: none !important;
                        transform: none !important;
                    }

                    @keyframes phone {
                        0% {
                            transform: translateX(-100%);
                        }
                        50% {
                            transform: translateX(10%);
                        }
                        100% {
                            transform: translateX(0);
                        }
                    }
                }
            }
        }

        @include responsiveMin(992px) {
            position: absolute;
            filter: drop-shadow(0px 0px rem(50) rgba(0, 0, 0, 0.2));
            transition: all 0.3s ease-in-out;
            bottom: 2%;

            &:hover {
                transform: scale(1.1);
            }

            .mobile-screen {
                width: 100%;
            }

            &--1 {
                width: rem(840);
                left: -10%;
                z-index: 2;
                bottom: 3%;

                &.desktop,
                &.table,
                &.phone {
                    transform: translateX(rem(-520));
                }

                .mobile-screen {
                    padding-top: rem(40);
                    
                    &__frame {
                        width: 76.6%;
                        max-height: rem(420);
                        padding-bottom: rem(11);

                        &::-webkit-scrollbar-track {
                            margin-bottom: rem(10);
                        }
                    }
                }

                .mobile-first__message {
                    right: -7.5%;
                }

                &:hover {
                    transform: translateX(rem(50));
                }
            }

            &--2 {
                top: rem(50);
                right: 0;
                width: rem(1260);

                &.laptop {
                    transform: translateX(rem(550));
                }

                &.tablet,
                &.phone {
                    transform: translateX(rem(-550));
                }

                .mobile-screen {
                    padding-top: rem(70);

                    &__frame {
                        width: 70.5%;
                        max-height: rem(600);
                        padding-bottom: rem(97);

                        &::-webkit-scrollbar-track {
                            margin-bottom: rem(100);
                        }
                    }
                }

                .mobile-first__message {
                    right: 0;
                }

                &:hover {
                    transform: translateX(rem(-150));
                }
            }

            &--3 {
                width: rem(350);
                z-index: 2;
                right: 3%;

                &.laptop {
                    transform: translateX(rem(250));
                }

                &.desktop {
                    transform: translateX(rem(300));
                }

                &.phone {
                    transform: translateX(rem(-500));
                }

                .mobile-screen {
                    padding-top: rem(40);

                    &__frame {
                        width: 93%;
                        max-height: rem(470);
                        padding-bottom: rem(5);
                    }
                }
                
                .mobile-first__message {
                    right: -40%;
                }

                &:hover {
                    transform: translateX(rem(-100));
                }
            }

            &--4 {
                width: rem(200);
                z-index: 2;
                right: -3%;

                &:hover {
                    &:after {
                        content: '';
                        position: absolute;
                        width: rem(250);
                        height: 110%;
                        top: 0;
                        right: -130%;

                        @include responsiveMin(1400px) {
                            width: rem(200);
                            right: -100%;
                        }
                    }
                }

                &.laptop {
                    transform: translateX(rem(250));
                }

                &.desktop {
                    transform: translateX(rem(180));
                }

                &.tablet {
                    transform: translateX(rem(210));
                }

                .mobile-screen {
                    padding-top: rem(40);

                    &__frame {
                        width: 84%;
                        max-height: rem(365);
                    }
                }

                .mobile-first__message {
                    right: -73%;
                }

                &:hover {
                    transform: translateX(rem(-150));
                }
            }
        }
    }

    .mockup__wrapper:hover .mobile-first__message {
        animation: phone 1s ease forwards;
    }

    .swiper-container {
        min-height: rem(220);
        overflow: visible !important;
        margin-bottom: rem(50);

        @include responsiveMin(430px) {
            min-height: rem(270);
        }

        @include responsiveMin(550px) {
            min-height: rem(370);
        }

        @include responsiveMin(710px) {
            min-height: rem(450);
        }

        @include responsiveMin(850px) {
            min-height: rem(550);
        }

        .mockup__wrapper {
            left: rem(-15);

            &.swiper-slide-active {
                &.mockup__wrapper--1 {
                    .mobile-first__img {
                        &::before {
                            content: '';
                            width: 100%;
                            height: rem(10);
                            position: absolute;
                            bottom: -9px;
                            background-color: #fff;
                            z-index: 10;
                        }
    
                        &::after {
                            content: '';
                            width: 100%;
                            height: rem(11);
                            position: absolute;
                            top: -10px;
                            background-color: #fff;
                            z-index: 10;
                        }
                    }
                }

                &.mockup__wrapper--2 {
                    .mobile-first__img {
                        &::before {
                            content: '';
                            width: 100%;
                            height: rem(15);
                            position: absolute;
                            bottom: -10px;
                            background-color: #fff;
                            z-index: 10;
                        }
    
                        &::after {
                            content: '';
                            width: 100%;
                            height: rem(13);
                            position: absolute;
                            top: -10px;
                            background-color: #fff;
                            z-index: 10;
    
                            @include responsiveMin(500px) {
                                height: rem(15);
                            }
                        }
                    }
                }
            }

            .mobile-screen {
                opacity: 0.5;
                transition: opacity 1s linear;
                position: relative;
                overflow-y: scroll;
                z-index: 2;

                &::-webkit-scrollbar {
                    width: 0;
                }

                .mobile-screen__moc-up {
                    position: sticky;
                    top: 0;
                    z-index: 2;
                    height: fit-content;

                    img {
                        display: block;
                    }
                }

                .mobile-screen__frame {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }


            &--1 {
                width: 75%;

                .mobile-first__img {
                    .mobile-screen {
                        height: fit-content;

                        &__frame {
                            width: 77%;
                            border-radius: 0;
                            padding-top: 12%;
                            padding-bottom: 3%;

                            @include responsiveMin(414px) {
                                padding-top: 10%;
                            }

                            @include responsiveMin(500px) {
                                padding-top: 9%;
                                padding-bottom: 3%;
                            }

                            @include responsiveMin(600px) {
                                padding-top: 8%;
                            }

                            @include responsiveMin(700px) {
                                padding-top: 7%;
                                padding-bottom: 3.5%;
                            }

                            @include responsiveMin(768px) {
                                padding-bottom: 3.8%;
                            }

                            @include responsiveMin(800px) {
                                padding-top: 6%;
                            }

                            @include responsiveMin(850px) {
                                padding-bottom: 3.7%;
                            }
                        }
                    }
                }
            }

            &--2 {
                width: 85%;

                .mobile-first__img {

                    &::before {
                        content: '';
                        width: 90%;
                        right: 0;
                        height: rem(15);
                        position: absolute;
                        bottom: -10px;
                        background-color: #fff;
                        z-index: 10;
                    }

                    &::after {
                        content: '';
                        width: 60%;
                        right: 0;
                        height: rem(13);
                        position: absolute;
                        top: -10px;
                        background-color: #fff;
                        z-index: 10;

                        @include responsiveMin(500px) {
                            height: rem(15);
                        }
                    }
                    .mobile-screen {
                        &__frame {
                            width: 71%;
                            padding-top: 12%;
                            padding-bottom: 25%;

                            @include responsiveMin(414px) {
                                padding-top: 11%;
                            }

                            @include responsiveMin(500px) {
                                padding-top: 10%;
                            }

                            @include responsiveMin(600px) {
                                padding-top: 9%;
                                padding-bottom: 25.3%;
                            }

                            @include responsiveMin(700px) {
                                padding-top: 8%;
                                padding-bottom: 25.4%;
                            }

                            @include responsiveMin(768px) {
                                padding-top: 7.5%;
                                padding-bottom: 25.5%;
                            }

                            @include responsiveMin(800px) {
                                padding-bottom: 25.4%;
                            }

                            @include responsiveMin(850px) {
                                padding-bottom: 25.4%;
                                padding-top: 7%;
                            }

                            @include responsiveMin(950px) {
                                padding-bottom: 24.3%;
                            }

                            @include responsiveMin(970px) {
                                padding-bottom: 23.5%;
                            }

                            @include responsiveMin(980px) {
                                padding-bottom: 22.5%;
                            }
                        }
                    }
                }
            }

            &--3 {
                width: 85%;

                .mobile-first__img {
                    width: 60%;
                    height: fit-content;

                    &::before {
                        content: '';
                        width: 100%;
                        height: rem(10);
                        position: absolute;
                        bottom: -9px;
                        background-color: #fff;
                        z-index: 10;
                    }

                    &::after {
                        content: '';
                        width: 100%;
                        height: rem(10);
                        position: absolute;
                        top: -9px;
                        background-color: #fff;
                        z-index: 10;
                    }

                    .mobile-screen {
                        height: fit-content;

                        &__moc-up {
                            width: 70%;
                            display: block;
                            margin: 0 auto;
                        }

                        &__frame {
                            width: 65%;
                            padding-top: 17%;
                            padding-bottom: 2%;
                            border-radius: rem(10);

                            @include responsiveMin(414px) {
                                padding-top: 15%;
                                padding-bottom: 1.5%;
                            }

                            @include responsiveMin(500px) {
                                padding-top: 13%;
                                padding-bottom: 2%;
                            }

                            @include responsiveMin(600px) {
                                padding-top: 11%;
                            }

                            @include responsiveMin(700px) {
                                padding-top: 10%;
                            }

                            @include responsiveMin(768px) {
                                padding-top: 9%;
                            }

                            @include responsiveMin(950px) {
                                padding-top: 8%;
                                padding-bottom: 2.3%;
                            }
                        }
                    }
                }
            }

            &--4 {
                 .mobile-first__img {
                    width: 45%;
                    display: block;
                    top: -25px;

                    &::before {
                        content: '';
                        width: 100%;
                        height: rem(10);
                        position: absolute;
                        bottom: -9px;
                        background-color: #fff;
                        z-index: 10;
                    }

                    &::after {
                        content: '';
                        width: 100%;
                        height: rem(10);
                        position: absolute;
                        top: -9px;
                        background-color: #fff;
                        z-index: 10;
                    }

                    .mobile-screen {
                        &__moc-up {
                            width: 50%;
                            display: block;
                            margin: auto;
                        }

                        &__frame {
                            width: 43%;
                            border-radius: rem(15);
                            padding-top: 20%;
                            padding-bottom: 2%;

                            @include responsiveMin(414px) {
                                padding-top: 17%;
                                padding-bottom: 3%;
                            }

                            @include responsiveMin(500px) {
                                padding-top: 15%;
                                padding-bottom: 3.2%;
                            }

                            @include responsiveMin(600px) {
                                padding-top: 13%;
                                padding-bottom: 3.7%;
                            }

                            @include responsiveMin(700px) {
                                padding-top: 11.5%;
                                padding-bottom: 3.5%;
                            }

                            @include responsiveMin(768px) {
                                padding-top: 11%;
                                padding-bottom: 4%;
                            }

                            @include responsiveMin(800px) {
                                padding-bottom: 3.5%;
                            }

                            @include responsiveMin(850px) {
                                padding-top: 10.5%;
                                padding-bottom: 3.2%;
                            }

                            @include responsiveMin(950px) {
                                padding-top: 10%;
                                padding-bottom: 3.5%;
                            }
                        }
                    }
                }
            }

            &--4.swiper-slide{
                transform: translate(rem(-50), rem(-25));
            }

            &--4.swiper-slide-active{
                transform: translate(rem(50), rem(55));
            }
        }

        .mobile-first__phone {
            img {
                width: 100%;
            }
        }

        .swiper-pagination {
            min-height: rem(20);
            position: absolute;
            bottom: 0;
            margin: auto;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;

            &-bullet {
                margin: 0 0.57143rem !important;
                width: rem(10);
                height: rem(10);
                border-radius: 50%;
                outline: 0;
                position: relative;
                background: #3e3e53;
                opacity: 0.2;
                display: block;
                font-size: 0;
                &-active {
                    background: linear-gradient(315deg, #fc6076 0%, #ff4d66 48.44%, #ff6826 100%);
                    opacity: 1;
                }
            }
        }

        .swiper-wrapper {
            margin-top: rem(80);

        }

        .swiper-slide {
            transform: translate(-10px, -25px);
            transition: all .2s ease-in-out;
        }

        .swiper-slide-active {
            transform: translate(20px, 55px);

            .mobile-screen {
                opacity: 1;
                transition: opacity .2s ease-in-out;
            }
        }
    }

    .mobile-first__message {
        width: rem(120);
        // right: 0;

        &--text {
            color: #3e3e53;
            border-color: #3e3e53;
            height: rem(120);
            font-size: rem(12);
            padding: rem(10);
        }

        &--wire {
            
            svg {
                width: rem(100);
                height: rem(90);

                path {
                    stroke: #3e3e53;
                }
            }
        }
    }
}

.secondGearWrapper {
    margin-top: rem(30);

    .content__img__wrapper--i3 {
        .content__img {
            animation-delay: -1.03s;
            
            @include responsiveMin(992px) {
                animation-delay: -0.95s;
            }
        }
    }
}

.thirdGearWrapper {
    .content__img__wrapper--i2 {
        .content__img {
            animation: gearRotate 5s infinite linear;
            animation-delay: -1.05s;

            @include responsiveMin(992px) {
                animation-delay: -1.4s;
            }
        }
    }

    .content__img__wrapper--i3 {
        .content__img {
            animation-delay: -1.1s;
        }
    }
}