$root-font-size: 14;

// start desktop font-sizes

$d_h1_font_size: 48;
$d_h1_line_height: 56;
$d_h1_font_weight: 900;

$d_h2_font_size: 36;
$d_h2_line_height: 42;
$d_h2_font_weight: 900;

$d_h3_font_size: 32;
$d_h3_line_height: 38;

$d_h4_font_size: 24;
$d_h4_line_height: 26;

$d_text_font_size: 16;
$d_text_line_height: 24;

// end desktop font-sizes

// start mobile font-sizes

$m_h1_font_size: 22;
$m_h1_line_height: 26;
$m_h1_font_weight: 900;

$m_h2_font_size: 20;
$m_h2_line_height: 23;
$m_h2_font_weight: 900;

$m_h3_font_size: 18;
$m_h3_line_height: 21;

$m_h4_font_size: 16;
$m_h4_line_height: 19;

$m_text_font_size: 14;
$m_text_line_height: 21;

// end mobile font-sizes

$left_right_padding: 20;

// colors & gradients

$main_gradient: linear-gradient(315deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
$main_gradient_hover: linear-gradient(315deg, #FF6826 0%, #FF4D66 48.44%, #FC6076 100%);


// $home_start_banner_gradient: linear-gradient(294.63deg, #FF6826 0%, #FF4D66 49.48%, #FC6076 100%);
$home_start_banner_gradient: linear-gradient(104.44deg, #FFD600 12.77%, #F9D406 29.51%, #0D7AF2 72.69%, #0075FF 88.96%);
$it_betreuung_banner_gradient: #FC6076;
$referenzen_banner_gradient: #767994;


$services_list_bg: #3E3E53;

$services_support_bg: #FC6076;
$services_description_bg: #767994;

$footer-copyright: #181C2D;
$footer-main-bg: #353540;

$footer-feedback-btn-bg: linear-gradient(282.39deg, #FC6076 0%, #FF4D66 49.48%, #FF6826 100%);
$footer-feedback-btn-bg-hover: linear-gradient(282.39deg, #FF6826 0%, #FF4D66 49.48%, #FC6076 100%);

$reviews_title_color: #3E3E53;
$reviews_author_position_color: #767994;

$menu_bg: #3E3E53;

$service_advantages_bg: #787A95;

$contact-page__form-col-bg: #3E3E53;

$software-page__link-col: #FFFFFF;