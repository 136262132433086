@import '../../../../scss/mixins.scss';

@include responsiveMin(992px) {
    .anim-item {
        .design__frame {
            will-change: transform;
            transform: translateX(1000%);
        }

        .design__screensHolder_item--1 {
            will-change: transform;
            transform: translateX(1000%);
        }

        .design__screensHolder_item--2 {
            will-change: transform;
            transform: translateX(1000%);
        }

        .design__screensHolder_item--3 {
            will-change: transform;
            transform: translateX(1000%);
        }

        .design__screensHolder_item--4 {
            will-change: transform;
            transform: translateX(1000%);
        }

        .design__screensHolder_item--5 {
            will-change: transform;
            transform: translateX(1000%);
        }

        .design__screensHolder_item--6 {
            will-change: transform;
            transform: translateX(1000%);
        }

        &.active {
            .design__frame {
                animation: design__frame 1s ease forwards;
            }
            
            .design__screensHolder_item--1 {
                animation: design__screensHolder_item--1 1s ease forwards;
                animation-delay: .5s;
            }
    
            .design__screensHolder_item--2 {
                animation: design__screensHolder_item--2 5s ease forwards;
                animation-delay: 1s;
            }
    
            .design__screensHolder_item--3 {
                animation: design__screensHolder_item--3 5.1s ease forwards;
                animation-delay: 1.5s;
            }
    
            .design__screensHolder_item--4 {
                animation: design__screensHolder_item--4 5.2s ease forwards;
                animation-delay: 2s;
            }
    
            .design__screensHolder_item--5 {
                animation: design__screensHolder_item--5 5.3s ease forwards;
                animation-delay: 2.5s;
            }
    
            .design__screensHolder_item--6 {
                animation: design__screensHolder_item--6 5.4s ease forwards;
                animation-delay: 3s;
            }
        }
    }
}

@keyframes design__frame {
    0% {
        transform: translateX(1000%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes design__screensHolder_item--1 {
    0% {
        transform: translateX(1000%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes design__screensHolder_item--2 {
    0% {
        transform: translateX(1000%);
    }

    20% {
        transform: translateX(-130%);
    }

    70% {
        transform: translateX(-130%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes design__screensHolder_item--3 {
    0% {
        transform: translateX(1000%);
    }

    20% {
        transform: translateX(-250%);
    }

    60% {
        transform: translateX(-250%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes design__screensHolder_item--4 {
    0% {
        transform: translateX(1000%);
    }

    20% {
        transform: translateX(-370%);
    }

    50% {
        transform: translateX(-370%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes design__screensHolder_item--5 {
    0% {
        transform: translateX(1000%);
    }

    20% {
        transform: translateX(-490%);
    }

    40% {
        transform: translateX(-490%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes design__screensHolder_item--6 {
    0% {
        transform: translateX(1000%);
    }

    20% {
        transform: translateX(-610%);
    }

    30% {
        transform: translateX(-610%);
    }

    100% {
        transform: translateX(0);
    }
}