@import "../../../scss/vars";
@import "../../../scss/mixins";

.success__services {
    @include responsiveMin(992px) {
        padding-top: rem(45);
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include responsiveMin(992px) {
            flex-direction: row;
            align-items: start;
        }
    }

    .items {
        flex: 1;
        flex-basis: 50%;

        .item {
            &__statistics {
                display: flex;
                justify-content: center;

                @include responsiveMin(992px) {
                    margin-right: rem(160);
                    justify-content: space-between;
                }

                &__wrapper {
                    text-align: center;
                }

                &-number {
                    font-weight: 900;
                    font-size: rem(48);
                    line-height: rem(56);
                    text-align: center;
                    letter-spacing: -0.05em;
                    text-transform: uppercase;
                    background: -webkit-linear-gradient(315deg, #fc6076 0%, #ff4d66 48.44%, #ff6826 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    @include responsiveMin(992px) {
                        font-size: rem(70);
                        line-height: rem(82);
                    }
                }

                &-title {
                    background: -webkit-linear-gradient(315deg, #fc6076 0%, #ff4d66 48.44%, #ff6826 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: bold;
                    text-align: center;
                    font-size: rem(14);
                    line-height: rem(16);
                    @include responsiveMin(992px) {
                        font-size: rem(18);
                        line-height: rem(21);
                    }
                }
            }

            &__services {
                color: #3e3e53;

                &-services {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    @include responsiveMin(992px) {
                        margin-left: rem(45);
                    }

                    &-group {
                        width: 48%;
                        display: flex;
                        align-items: center;
                        margin-bottom: rem(20);
                        overflow-wrap: break-word;

                        p {
                            font-weight: bold;
                            font-size: rem(14);
                            line-height: rem(16);
                            margin-left: rem(10);

                            @include responsiveMin(992px) {
                                font-size: rem(18);
                                line-height: rem(21);
                                flex-basis: 45%;
                            }
                        }
                        img {
                            max-width: rem(50);
                            height: auto;
                            @include responsiveMin(992px) {
                                max-width: rem(70);
                            }
                        }
                    }
                    &-project {
                        display: flex;
                        flex-direction: column;

                        @include responsiveMin(992px) {
                            flex-direction: row;
                            flex-wrap: wrap;
                        }
                        .project-description {
                            flex-basis: 50%;
                            &-text {
                                font-weight: bold;
                                font-size: rem(14);
                                line-height: rem(16);
                                margin-left: rem(20);
                                @include responsiveMin(992px) {
                                    font-size: rem(18);
                                    line-height: rem(21);
                                    margin-left: rem(40);
                                }
                            }
                        }
                    }
                }

                &-list {
                    font-size: rem(14);
                    line-height: rem(21);
                    color: #3e3e53;
                    margin-left: rem(20);
                    @include responsiveMin(992px) {
                        margin-left: rem(45);
                        font-size: rem(16);
                        line-height: 150%;
                    }

                    li {
                        padding-left: rem(15);
                        line-height: rem(24);
                        position: relative;
                        margin-top: rem(5);
                        @include responsiveMin(992px) {
                            margin-top: rem(10);
                        }
                        &:before {
                            content: "";
                            display: block;
                            width: 0.28571rem;
                            height: 0.28571rem;
                            border-radius: 50%;
                            background: linear-gradient(315deg, #fc6076 0%, #ff4d66 48.44%, #ff6826 100%);
                            position: absolute;
                            top: 0.64286rem;
                            left: 0;
                        }
                        ul {
                            margin-left: rem(20);
                            li {
                                &:before {
                                    background: #767994;
                                }
                            }
                        }
                        a {
                            color: #FF4D66;
                                                        
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }

                .video {
                    box-shadow: 0 0 rem(50) rgba(0, 0, 0, 0.2);
                    margin-bottom: rem(50);
                    
                    @include responsiveMin(992px) {
                        margin-bottom: rem(100);
                    }
                }

                &-link {
                    display: block;
                    font-weight: bold;
                    font-size: rem(18);
                    line-height: rem(21);
                    color: #3e3e53;
                    text-decoration: none;
                    text-transform: uppercase;
                    margin-bottom: rem(30);

                    span {
                        padding: 0 rem(10);
                        position: relative;

                        &:before {
                            content: "";
                            position: absolute;
                            width: rem(5);
                            height: rem(22);
                            background: #3e3e53;
                            opacity: 0.2;
                            left: 0;
                            transition: all 0.5s ease;
                            transform: translateY(-50%);
                            top: 50%;
                        }

                        &:hover::before {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &--right_side {
            padding: 0 rem(10) rem(10);
            @include responsiveMin(992px) {
                padding: rem(40) rem(100);
            }
        }

        &--left_side {
            width: 100%;
            max-width: rem(320);
            border-radius: rem(30);
            box-shadow: 0 rem(20) rem(70) rgba(0, 0, 0, 0.3);
            margin-bottom: rem(50);

            @include responsiveMin(992px) {
                max-width: 50%;
                border-radius: 0;
            }
        }

        &--video {
            @include responsiveMax(991px) {
                max-width: rem(600);
            }
        }

        .items__img {
            width: 100%;
            display: block;
            height: auto;
        }
    }
}
