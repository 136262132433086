@import "../../../scss/vars";
@import "../../../scss/mixins";

/* START .text-page */

.text-page {
  position: relative;
  padding-top: rem(130);

  @include responsiveMin(992px) {
    padding-top: rem(200);
  }

  &--preloader {
    min-height: 100vh;
    position: relative;

    .data-preloader {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__top-bg,
  &__bottom-bg {
    position: absolute;
    z-index: -1;

    &:before,
    &:after {
      position: absolute;
      z-index: -1;
      display: block;
      content: "";
    }

    &:before {
      width: rem(800);
      height: rem(200);
      background: url("../../../images/bg-el-1-dark.svg") no-repeat;
      background-size: contain;
      opacity: 0.03;
    }

    &:after {
      width: rem(600);
      height: rem(500);
      background: url("../../../images/bg-el-2-dark.svg") no-repeat;
      background-size: contain;
      opacity: 0.04;
    }

    @include responsiveMax(991px) {

      &:before {
        width: rem(133);
        height: rem(33);
      }

      &:after {
        width: rem(100);
        height: rem(83);
      }
    }
  }

  &__top-bg {
    top: 0;
    left: 0;
  }

  &__bottom-bg {
    bottom: 0;
    right: 0;
    transform: rotate(180deg);
  }

  &__title {
    position: relative;
    padding-bottom: rem(50);

    @include responsiveMin(992px) {
      padding-bottom: rem(100);
    }

    &-text {
      font-weight: 900;
      font-size: rem(22);
      line-height: rem(26);
      text-transform: uppercase;
      color: #3E3E53;

      @include responsiveMin(992px) {
        font-size: rem(48);
        line-height: rem(56);
      }
    }

    &-bg {
      display: none;

      @include responsiveMin(992px) {
        display: block;
        font-weight: 900;
        font-size: rem(96);
        line-height: rem(113);
        text-transform: uppercase;
        color: #3E3E53;
        opacity: 0.03;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
    }
  }

  &__container {
    width: 100%;
    padding: 0 rem(20) rem(50);

    @include responsiveMin(992px) {
      padding: 0 rem(20) rem(100);
      max-width: rem(1170);
      margin: 0 auto;
    }
  }

  &__content {
    color: #3E3E53;

    p, li {
      font-weight: normal;
      font-size: rem(14);
      line-height: rem(21);
      margin-top: 10px;

      @include responsiveMin(992px) {
        font-size: rem(16);
        line-height: rem(24);
      }
    }

    td, th {
      font-size: rem(14);
      line-height: rem(21);
      margin-top: 10px;
      text-align: left;
      padding-right: 15px;

      @include responsiveMin(992px) {
        font-size: rem(16);
        line-height: rem(24);
      }
    }

    p:first-child {
      margin-top: 0;
    }

    h2, .h2-styles {
      font-weight: 900;
      font-size: rem(20);
      line-height: rem(23);

      @include responsiveMin(992px) {
        font-size: rem(36);
        line-height: rem(42);
      }
    }

    h3, .h3-styles {
      font-weight: bold;
      font-size: rem(18);
      line-height: rem(18);

      @include responsiveMin(992px) {
        font-size: rem(24);
        line-height: rem(24);
      }
    }

    h4 {
      font-weight: bold;
      font-size: rem(14);
      line-height: rem(16);

      @include responsiveMin(992px) {
        font-size: rem(18);
        line-height: rem(21);
      }
    }

    h2, h3, h4 {
      margin: rem(30) 0 rem(10);

      @include responsiveMin(992px) {
        margin: rem(50) 0 rem(20);
      }
    }

    a {
      color: #FF4D66;

      &:hover {
        text-decoration: none;
      }
    }

    ol {
      counter-reset: item;
      padding: 0;

      &:first-child {
        & > li {
          &:first-child {
            h2, h3 {
              margin-top: 0;
            }
          }
        }
      }

      & > li {
        display: block;
        position: relative;
        padding-left: rem(50);

        &:before {
          content: counters(item, ".") " ";
          counter-increment: item;
          position: absolute;
          top: 0;
          left: 0;
          font-weight: 900;
          font-size: rem(20);
          line-height: rem(23);

          @include responsiveMin(992px) {
            font-size: rem(36);
            line-height: rem(42);
          }
        }

        & > ol {
          & > li {
            &:before {
              font-size: rem(14);
              line-height: rem(21);
              font-weight: normal;

              @include responsiveMin(992px) {
                font-size: rem(16);
                line-height: rem(24);
              }
            }
          }
        }
      }

      .reset-numeration {
        counter-reset: section;

        & > li {
          padding-left: rem(25);

          &::before {
            counter-increment: section;
            content: counters(section, ".") " ";
          }

          ol > li {
            padding-left: rem(50);
          }
        }
      }

      &[type="a"] {
        counter-reset: list !important;

        li {
          padding-left: rem(25) !important;

          &:before {
            counter-increment: list !important;
            content: counter(list, lower-alpha) !important;
          }
        }
      }

      &.simple-list {
        & > li {
          padding-left: rem(25) !important;

          &:before {
            font-weight: normal;
            font-size: rem(14);
            line-height: rem(21);

            @include responsiveMin(992px) {
              font-size: rem(16);
              line-height: rem(24);
            }
          }
        }
      }

      &.h3-list {
        & > li {
          padding-left: rem(50) !important;

          &:before {
            font-weight: bold;
            font-size: rem(18);
            line-height: rem(18);

            @include responsiveMin(992px) {
              font-size: rem(24);
              line-height: rem(24);
            }
          }
        }
      }
    }

    ul {
        list-style: none;

      & > li {
        padding-left: rem(13);
        position: relative;

        @include responsiveMin(992px) {
          padding-left: rem(19);
        }

        &:before {
          content: '';
          display: block;
          width: rem(4);
          height: rem(4);
          border-radius: 50%;
          background: $main_gradient;
          position: absolute;
          top: rem(9);
          left: 0;

          @include responsiveMin(992px) {
            top: rem(11);
          }
        }
      }
    }

  }
}

/* END .text-page */