@import "../../../../scss/vars";
@import "../../../../scss/mixins";

.glossary-archive {
    font-size: rem(16);
    line-height: 150%;

    select {
        color: #3E3E53;
    }

    &--preloader {
        min-height: 100vh;
        position: relative;
    
        .data-preloader {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .glossary-banner {
        color: #fff;
        background: #3E3E53;

        &__text-container {
            padding: rem(124) rem(20) rem(15) rem(20);

            @include responsiveMin(992px) {
                padding: rem(164) rem(150) rem(40) rem(150);

                p {
                    max-width: rem(810);
                    margin: 0 auto;
                }
            }
        }

        &__title {
            position: relative;
            text-align: center;
            margin-bottom: rem(20);
            z-index: 1;

            h1 {
                font-weight: 900;
                font-size: rem(22);
                line-height: rem(26);

                @include responsiveMin(992px) {
                    font-size: rem(48);
                    line-height: rem(56);
                }
            }

            &--bg {
                position: absolute;
                top: 0;
                left: 50%;
                display: block;
                min-width: rem(1100);
                font-weight: 900;
                font-size: rem(90);
                line-height: rem(105);
                color: rgba($color: #fff, $alpha: 0.03);
                transform: translateX(-50%);
                z-index: -1;

                @include responsiveMax(991px) {
                    display: none;
                }
            }
        }

        select {
            width: 100%;
            padding: rem(16) rem(20);
            color: #fff;
            background: none;
            border: none;
            border-top: rem(2) solid #54546C;
            -moz-appearance: none; /* Firefox */
            -webkit-appearance: none; /* Safari and Chrome */
            appearance: none;
            background-image: url('../../../../images/select-arrow.svg');
            background-repeat: no-repeat;
            background-position-x: calc(100% - 20px);
            background-position-y: rem(20);
            cursor: pointer;
        }

        .alphabet {
            display: flex;
            flex-wrap: wrap;
            gap: rem(20);
            padding: rem(30) rem(150);
            border-top: rem(2) solid #54546C;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: rem(58);
                height: rem(58);
                color: #9696AC;
                font-size: rem(24);
                line-height: 1;
                font-weight: 700;
                white-space: nowrap;
                box-shadow: inset 0 0 0 rem(2) #9696AC;
                background: none;
                border: none;
                border-radius: rem(55);
                transition: .3s;

                &.not-available {
                    color: #54546C;
                    box-shadow: inset 0 0 0 rem(2) #54546C;
                    pointer-events: none;
                }

                &:hover:not(.not-available),
                &.active {
                    color: #3E3E53;
                    background: #fff;
                    box-shadow: none;
                }

                &:last-of-type {
                    padding: 0 rem(47);
                }
            }
        }
    }

    &__content {
        color: #3E3E53;
        padding: rem(20) rem(20) rem(60);

        @include responsiveMin(992px) {
            padding: rem(30) rem(150) rem(160) rem(150);
        }

        .breadcrumbs {
            display: flex;
            align-items: center;
            margin-bottom: rem(30);

            @include responsiveMin(992px) {
                margin-bottom: rem(60);
            }

            &__item {
                display: flex;
                align-items: center;
                font-size: rem(16);
                font-weight: 500;
                margin-right: rem(13);

                &:first-child {
                    svg {
                        width: rem(24);
                        margin-right: 0;
                    }
                }

                &:last-child {
                    margin-right: 0;

                    span {
                        flex: 1;
                        display: -webkit-box;
                        text-overflow: ellipsis;
                        /* autoprefixer: off */
                        -webkit-box-orient: vertical;
                        /* autoprefixer: on */
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                    }
                }

                svg {
                    width: rem(8);
                    margin-right: rem(10);
                }

                a {
                    text-decoration: underline;
                    background: linear-gradient(
                        282.39deg,
                        #fc6076 0%,
                        #ff4d66 49.48%,
                        #ff6826 100%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-decoration: underline;
                    text-decoration-color: #fc6076;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &__list {
        margin-bottom: rem(60);

        @include responsiveMin(992px) {
            margin-bottom: rem(100);
        }
    }

    &__item {
        margin-bottom: rem(40);

        @include responsiveMin(992px) {
            margin-bottom: rem(60);
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        & > div {
            position: relative;
            width: fit-content;
            margin: 0 auto;
            margin-bottom: rem(20);
            color: #fff;
            padding: rem(2);
            background: linear-gradient(
                282.39deg,
                #fc6076 0%,
                #ff4d66 49.48%,
                #ff6826 100%
            );
            border-radius: rem(22);

            @include responsiveMin(992px) {
                border-radius: rem(28);
            }

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: -50vw;
                transform: translateY(-50%);
                width: 150vw;
                height: 2px;
                background: linear-gradient(
                    282.39deg,
                    #fc6076 0%,
                    #ff4d66 49.48%,
                    #ff6826 100%
                );
                z-index: -1;
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                font-size: rem(18);
                line-height: 1;
                min-width: rem(36);
                min-height: rem(36);
                background: #FC6076;
                border: 2px solid #fff;
                border-radius: rem(18);

                @include responsiveMin(992px) {
                    font-size: rem(24);
                    width: rem(50);
                    height: rem(50);
                    border-radius: rem(25);
                }

                &.letter--0-9 {
                    width: rem(70);

                    @include responsiveMin(992px) {
                        width: rem(90);
                    }
                }
            }

            @include responsiveMin(992px) {
                margin-bottom: rem(30);
            }
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            gap: rem(20);

            @include responsiveMin(992px) {
                gap: 30px;
            }

            li {
                width: 100%;

                @include responsiveMin(560px) {
                    width: calc(50% - 10px);
                }

                @include responsiveMin(768px) {
                    width: calc(33.333% - 20px/3*2);
                }

                @include responsiveMin(992px) {
                    width: calc(25% - 2.14285714rem/4*3);
                }

                a {
                    display: flex;
                    color: inherit;
                    text-decoration-color: currentColor;
                    transition: .3s;

                    i {
                        display: block;
                        height: rem(20);
                        margin-right: rem(10);

                        @include responsiveMin(992px) {
                            height: rem(24);
                        }

                        svg {
                            width: rem(20);
                            height: rem(20);

                            @include responsiveMin(992px) {
                                width: rem(24);
                                height: rem(24);
                            }

                            path {
                                fill: currentColor;
                            }
                        }
                    }

                    span {
                        font-weight: 700;
                        font-size: rem(18);
                        line-height: rem(21);

                        @include responsiveMin(992px) {
                            font-size: rem(24);
                            line-height: rem(28);
                        }
                    }

                    &:hover {
                        color: #FC6076;
                    }
                }
            }
        }
    }

    &__info {
        h2 {
            font-weight: 700;
            font-size: rem(22);
            line-height: rem(26);
            margin-bottom: rem(20);

            @include responsiveMin(992px) {
                font-size: rem(32);
                line-height: rem(38);
            }
        }

        & > div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: rem(10);

            @include responsiveMin(992px) {
                flex-direction: row;
                gap: rem(30);
            }

            div {
                @include responsiveMin(992px) {
                    width: calc(50% - 1.07142757rem);
                }

                p {
                    margin-bottom: rem(10);

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
