@import '../../../../scss/vars';
@import '../../../../scss/mixins';
/* START .referenzen-projects */

.referenzen-projects {
	position: relative;
	padding: rem(10);

	@include responsiveMin(768px) {
		padding: rem(20);
		display: flex;
		justify-content: center;
	}

	@include responsiveMin(992px) {
		padding: rem(53);

		&::before,
		&::after {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			background-size: cover;
		}

		&:before {
			background: url('../images/top-wave.svg') no-repeat;
			top: 0;
			left: 0;
		}

		&:after {
			background: url('../images/bottom-wave.svg') no-repeat;
			background-position: right bottom;
			bottom: 0;
			right: 0;
			z-index: -1;
		}
	}

	&--inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: rem(340);
		margin: 0 auto;
		width: 100%;
		
		@include responsiveMin(700px) {
			max-width: rem(680);
		}

		@include responsiveMin(768px) {
			max-width: rem(720);
		}

		@include responsiveMin(992px) {
			max-width: 100%;
		}
	}

	.referenzen-card {
		width: 100%;
		padding: rem(10);
		text-decoration: none;

		&.hidden {
			display: none;
		}

		@include responsiveMin(700px) {
			width: 50%;
		}

		@include responsiveMin(768px) {
			padding: rem(20);
		}

		@include responsiveMin(992px) {
			width: 33.333%;
			padding: rem(27);
		}

		&--inner {
			display: block;
			position: relative;
			height: 100%;
			background: #fff;
			box-shadow: 0px 0px rem(50) rgba(0, 0, 0, 0.2);
			text-decoration: none;
			z-index: 1;
		}

		&__top {
			position: relative;
			
			
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.6);
				backdrop-filter: blur(2px);
				z-index: 1;

				@include responsiveMin(1920px) {
					backdrop-filter: blur(rem(2));
				}
			}
		}

		&__img {
			position: relative;
			top: 0;
			left: 0;
			width: 100%;
			height: rem(207);
			object-fit: cover;
			z-index: -2;

			@include responsiveMin(992px) {
				height: rem(356);
			}
		}

		&__logo {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-height: rem(140);
			margin: 0 auto;
			z-index: 2;

			@include responsiveMin(992px) {
				max-height: rem(180);
				min-width: rem(186);
			}

			img {
				height: 100%;
				width: 100%;
			}
		}

		&__bottom {
			justify-content: space-between;
			align-items: flex-end;
			padding: rem(20) rem(20);

			@include responsiveMin(700px) {
				min-height: rem(202);
			}

			@include responsiveMin(992px) {
				padding: rem(20);
			}
		}

		&__text-container {
			color: #fff;
			margin-bottom: rem(20);

			@include responsiveMin(992px) {
				margin-bottom: rem(15);
			}
		}

		&__text {
			display: flex;
			margin-bottom: rem(10);

			&:last-of-type {
				margin-bottom: 0;
			}

			p {
				font-size: rem(16);
				line-height: 150%;
				color: #353540;
				word-break: break-word;
			}

			svg {
				display: block;
				min-width: rem(24);
				min-height: rem(24);
				max-width: rem(24);
				max-height: rem(24);
				margin-right: rem(5);
			}
		}

		&__title {
			font-weight: bold;
			font-size: rem(18);
			line-height: 100%;
			margin-bottom: rem(15);
		}

		&__btn {
			display: none;
		}

		&--link {
			.referenzen-card__btn {
				display: block;
				width: fit-content;
				margin-left: auto;
				margin-right: 0;
			}
		}

		&--end {
			flex: 1;

			.referenzen-card--inner {
				padding-top: rem(125);
				padding-bottom: rem(125);
				width: 100%;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #3e3e53;
				overflow: hidden;
				box-shadow: none;
				height: 100%;
				text-decoration: none;
				padding-left: rem(70);
				padding-right: rem(70);

				@include responsiveMin(992px) {
					padding-top: rem(186);
					padding-bottom: rem(186);
					padding-left: rem(100);
					padding-right: rem(100);
				}
			}

			&__title {
				font-weight: 900;
				font-size: rem(20);
				line-height: rem(23);
				color: #ffffff;
				text-transform: uppercase;
				position: relative;
				text-align: center;

				@include responsiveMin(992px) {
					font-size: rem(36);
					line-height: rem(42);
				}
			}

			&__bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 900;
				font-size: rem(63);
				line-height: 1;
				text-align: center;
				text-transform: uppercase;
				color: #fff;
				opacity: 0.01;

				@include responsiveMin(992px) {
					font-size: rem(96);
				}
			}
		}
	}
}

/* END .referenzen-projects */
