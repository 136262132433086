@import "../../../scss/vars";
@import "../../../scss/mixins";

/* START .software-page */

.software-page {

    &__list {

        @include responsiveMin(992px) {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }
    }

    &__item {
        padding: rem(30) rem(20);

        @include responsiveMax(991px) {
            text-align: center;
        }

        @include responsiveMin(992px) {
            // flex: 0 0 50%;
            padding: rem(291) rem(180) rem(291) rem(280);
            position: relative;
            z-index: 1;
            width: 50%;

            :last-child {
                max-width: none;
            }
        }

        &-icon-bg {
            display: none;

            @include responsiveMin(992px) {
                display: block;
                position: absolute;
                left: rem(50);
                top: rem(50);
                opacity: .03;
                z-index: -1;
                width: rem(500);

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &-text-bg {
            display: none;

            @include responsiveMin(992px) {
                position: absolute;
                right: 0;
                bottom: 0;
                font-weight: 900;
                font-size: rem(135);
                line-height: rem(135);
                display: flex;
                align-items: flex-end;
                text-transform: uppercase;
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: #FFFFFF;
                opacity: 0.03;
                text-align: right;
            }
        }

        &-icon {
            width: rem(70);

            svg {
                width: 100%;
                height: auto;
            }

            @include responsiveMax(991px) {
                width: rem(50);
                margin: 0 auto;
            }
        }

        &-title {
            margin: rem(20) 0 rem(5);
            font-weight: bold;
            font-size: rem(24);
            line-height: rem(28);
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #FFFFFF;
            text-transform: uppercase;

            @include responsiveMax(991px) {
                font-size: rem($m_h3_font_size);
                line-height: rem($m_h3_line_height);
                margin: rem(10) 0 0;
            }
        }

        &-subtitle {
            font-size: rem(14);
            line-height: rem(16);
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #FFFFFF;

            @include responsiveMax(991px) {
                font-size: rem(12);
                line-height: rem(14);
                font-weight: normal;

                p {
                    margin-top: rem(10);

                    &:first-child {
                        margin: 0;
                    }
                }
            }
        }

        &-description {
            font-size: rem($d_text_font_size);
            line-height: rem($d_text_line_height);
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #FFFFFF;
            max-width: rem(600);

            @include responsiveMax(991px) {
                font-size: rem($m_text_font_size);
                line-height: rem($m_text_line_height);
                max-width: 100%;
                text-align: left;
            }

            p {
                margin-top: rem(10);

                a{
                    color: $software-page__link-col;
                }
            }
        }

        &-button {
            display: block;
            text-decoration: none;
            margin-top: rem(20);

            span {
                color: #fff;
            }
        }

        &--magento {
            background: linear-gradient(0deg, #F26322, #F26322), linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #F599B9, #F599B9), #FF3E59;
            mix-blend-mode: normal;

            @include responsiveMin(992px) {
                .software-page {
                    &__item {
                        &-icon-bg {
                            width: rem(430);
                            opacity: 0.02;
                        }

                        &-text-bg {
                            opacity: 0.02;
                        }

                        &-icon {
                            width: rem(60);

                            @include responsiveMax(991px) {
                                width: rem(43);
                            }
                        }
                    }
                }
            }
        }

        &--shopware {
            background: linear-gradient(0deg, #26A0FC, #26A0FC), linear-gradient(0deg, #FFB744, #FFB744), linear-gradient(0deg, #FF6826, #FF6826);
            mix-blend-mode: normal;
        }

        &--wordpress {
            background: linear-gradient(0deg, #00749A, #00749A), linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #F599B9, #F599B9), #FF3E59;
            mix-blend-mode: normal;


            @include responsiveMin(992px) {
                padding: rem(312) rem(180) rem(312) rem(280);

                .software-page {
                    &__item {
                        &-icon-bg {
                            opacity: 0.02;
                        }

                        &-text-bg {
                            opacity: 0.02;
                        }
                    }
                }
            }
        }

        &--typo3 {
            background: linear-gradient(0deg, #FF8700, #FF8700), linear-gradient(0deg, #F49800, #F49800), linear-gradient(0deg, #FFB744, #FFB744), linear-gradient(0deg, #FF6826, #FF6826);
            mix-blend-mode: normal;

            @include responsiveMin(992px) {
                padding: rem(312) rem(180) rem(312) rem(280);
            }
        }

        &--shopify {
            background: linear-gradient(0deg, #95BF47, #95BF47), #95BF47;
            mix-blend-mode: normal;
        }

        &--individualentwicklung {
            background: #FF3E59;

            @include responsiveMin(992px) {

                .software-page {
                    &__item {
                        &-text-bg {
                            opacity: 0.02;
                        }
                    }
                }
            }
        }

        &--weitere-systeme {
            background: linear-gradient(0deg, #F599B9, #F599B9), linear-gradient(0deg, #F599B9, #F599B9), #FF3E59;
            mix-blend-mode: normal;

            @include responsiveMin(992px) {
                flex: 0 0 100%;
                padding: rem(340) rem(560) rem(340) rem(560);
            }
        }
    }

    &__text-info {

        @include responsiveMin(992px) {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }
    }

	&__services {
		max-width: rem(320);
		margin: rem(20) auto 0 auto;
		padding: rem(10);
		box-shadow: inset 0 0 0 rem(2) #fff;

		@include responsiveMin(992px) {
			position: absolute;
			left: rem(50);
			bottom: rem(50);
			display: flex;
			flex-wrap: wrap;
			max-width: rem(860);
			margin: 0;
		}

		img {
			display: block;
			width: 100%;
			margin-bottom: rem(10);

			@include responsiveMin(992px) {
				width: rem(233);
				margin-bottom: 0;
				margin-right: rem(10);
			}

			&:last-of-type {
				margin-bottom: 0;

				@include responsiveMin(992px) {
					margin-right: 0;
				}
			}
		}
	}
}

.software-info {
    background: #3E3E53;
    // flex: 0 0 50%;
    padding: rem(30) rem(20);
    overflow: hidden;

    @include responsiveMin(992px) {
        padding: rem(180) rem(150);
        width: 50%;
    }

    &__title {
        padding-bottom: rem(50);
        position: relative;

        &-text {
            font-weight: 900;
            font-size: rem($d_h2_font_size);
            line-height: rem($d_h2_line_height);
            text-transform: uppercase;
            color: #FFFFFF;
        }

        &-bg {
            display: none;

            @include responsiveMin(992px) {
                display: block;
                font-weight: 900;
                font-size: rem(96);
                line-height: rem(113);
                text-transform: uppercase;
                color: #FFFFFF;
                opacity: 0.01;
                position: absolute;
                top: rem(100);
                left: 0;
            }
        }

        @include responsiveMax(991px) {
            padding-bottom: rem(10);

            &-text {
                font-size: rem($m_h2_font_size);
                line-height: rem($m_h2_line_height);
                text-align: center;
            }
        }
    }

    &__description {
        font-size: rem($d_text_font_size);
        line-height: rem($d_text_line_height);
        color: #FFFFFF;
        max-width: rem(600);
        padding-left: rem(100);

        p {
            margin-top: rem(10);

            &:first-child {
                margin-top: 0;
            }
        }

        @include responsiveMax(991px) {
            padding: 0;
            text-align: left;
            max-width: 100%;

            font-size: rem($m_text_font_size);
            line-height: rem($m_text_line_height);

            p {
                margin-top: rem(10);
            }
        }
    }

    &--right {
        background: linear-gradient(0deg, #787A95, #787A95), linear-gradient(0deg, #FFB744, #FFB744), linear-gradient(0deg, #FF6826, #FF6826);

        .software-info {
            &__title {
                &-bg {

                    @include responsiveMin(992px) {
                        opacity: 0.02;
                    }
                }
            }
        }
    }
}

/* END .software-page */