@import "../../../../scss/mixins.scss";
@import "../../../../scss/vars.scss";

.articles {
  padding: rem(25) rem(20);
  color: #3e3e53;
  background-color: #fff;

  @include responsiveMax(991px) {
    max-width: rem(500);
    margin-left: auto;
    margin-right: auto;
  }

  @include responsiveMin(992px) {
    // animation: none;
    padding: 0;
    padding-bottom: rem(50);
    width: rem(1080);
  }

  &__title__wrapper {
    position: relative;

    .title-back {
      display: none;
    }
  }

  &__title {
    font-weight: bold;
    font-size: rem(20);
    line-height: rem(23);
    text-transform: uppercase;
    margin-bottom: rem(15);
    color: #181c2d;

    @include responsiveMin(992px) {
      font-size: rem(36);
      line-height: rem(42);
    }
  }

  &__description {
    font-size: rem(16);
    line-height: 150%;
    text-align: center;
    margin-bottom: rem(100);
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include responsiveMin(760px) {
      justify-content: start;
    }

    @include responsiveMin(992px) {
      width: rem(1110);
    }
  }

  &__author {
    width: 100%;
    padding-top: rem(25);
    padding-bottom: rem(30);
    animation: postLoaded 1s linear forwards;
    opacity: 0;
    position: relative;

    > div.articles__item-link {
      > a.articles__item-link {
        @include responsiveMin(992px) {
          min-width: rem(365);
          min-height: rem(240);
        }
      }
    }

    &:nth-child(2n + 1) {
      background-color: rgba(118, 121, 148, 0.1);
      opacity: 0.1;

      @include responsiveMax(991px) {
        &::before {
          content: " ";
          width: rem(20);
          height: 100%;
          position: absolute;
          left: rem(-20);
          top: 0;
          background-color: rgba(118, 121, 148, 0.1);
        }

        &::after {
          content: " ";
          width: rem(20);
          height: 100%;
          position: absolute;
          right: rem(-20);
          top: 0;
          background-color: rgba(118, 121, 148, 0.1);
        }
      }

      @include responsiveMin(992px) {
        &::before {
          content: " ";
          width: rem(150);
          height: 100%;
          position: absolute;
          left: rem(-150);
          top: 0;
          background-color: rgba(118, 121, 148, 0.1);
        }
      }
    }

    @include responsiveMin(992px) {
      padding-top: rem(30);
      padding-bottom: rem(30);
      padding-right: rem(40);
    }

    &:nth-child(1) {
      margin-top: 0;
    }

    @keyframes postLoaded {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &_wrapper {
      @include responsiveMin(992px) {
        display: flex;
      }

      > .articles__author-link {
        display: block;
        width: rem(200);
        height: rem(200);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: rem(20);

        @include responsiveMin(992px) {
          width: rem(240);
          height: rem(240);
          min-width: rem(240);
          min-height: rem(240);
          margin-right: rem(50);
          margin-bottom: 0;
        }

        img {
          display: block;
          border: 1px solid #767994;
          border-radius: 50%;
          width: 100%;
          height: 100%;
        }
      }
    }

    &-link {
      text-decoration: none;
      color: #3e3e53;
    }

    &_content {
      flex: 1;

      &-title {
        font-weight: bold;
        font-size: rem(18);
        line-height: rem(21);
        color: #1B1B1B;
        margin-bottom: rem(5);
  
        @include responsiveMin(992px) {
          font-size: rem(32) !important;
          line-height: rem(38);
        }
      }

      &-workplace {
        font-size: rem(16);
        line-height: rem(19);
        margin-bottom: rem(20);
        color: #1B1B1B;
  
        @include responsiveMin(992px) {
          font-size: rem(16);
          margin-bottom: rem(19);
        }
      }
  
      &-text {
        font-size: rem(16);
        line-height: 170%;
        margin-bottom: rem(10);
        color: #1B1B1B;
  
        @include responsiveMin(992px) {
          font-size: rem(16);
          line-height: rem(32);
        }
      }

      &-btn {
        display: flex;
        outline: none;
        border: none;

        a {
          margin-left: auto;
          margin-right: 0;
        }
  
        span {
          text-transform: uppercase;
          font-weight: bold;
          font-size: rem(14);
          line-height: rem(16);
          color: #3e3e53;
          position: relative;
          padding: rem(2) rem(10);
          display: flex;
          align-items: center;
          height: fit-content;
  
          @include responsiveMin(992px) {
            font-size: rem(18);
            line-height: rem(21);
          }
  
          &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: rem(5);
            left: 0;
            background: #181c2d;
            opacity: 0.1;
            transition: all 0.5s ease;
  
            @include responsiveMin(992px) {
              width: rem(6);
            }
          }
  
          &:hover {
            &:before {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__item {
    width: 100%;
    padding-top: rem(25);
    padding-bottom: rem(25);
    animation: postLoaded 1s linear forwards;
    opacity: 0;
    position: relative;

    > div.articles__item-link {
      > a.articles__item-link {
        @include responsiveMin(992px) {
          min-width: rem(365);
          min-height: rem(240);
        }
      }
    }

    &:nth-child(2n + 1) {
      background-color: rgba(118, 121, 148, 0.1);
      opacity: 0.1;

      @include responsiveMax(991px) {
        &::before {
          content: " ";
          width: rem(20);
          height: 100%;
          position: absolute;
          left: rem(-20);
          top: 0;
          background-color: rgba(118, 121, 148, 0.1);
        }

        &::after {
          content: " ";
          width: rem(20);
          height: 100%;
          position: absolute;
          right: rem(-20);
          top: 0;
          background-color: rgba(118, 121, 148, 0.1);
        }
      }

      @include responsiveMin(992px) {
        &::before {
          content: " ";
          width: rem(150);
          height: 100%;
          position: absolute;
          left: rem(-150);
          top: 0;
          background-color: rgba(118, 121, 148, 0.1);
        }
      }
    }

    @include responsiveMin(992px) {
      padding-top: rem(15);
      padding-bottom: rem(15);
      padding-right: rem(30);
    }

    &:nth-child(1) {
      margin-top: 0;
    }

    @keyframes postLoaded {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &-link {
      text-decoration: none;
      color: #3e3e53;

      @include responsiveMin(992px) {
        display: flex;
      }
    }

    .dates {
      @include responsiveMin(500px) {
        display: flex;
        align-items: center;
      }
    }

    .edited-date {
      font-size: rem(12);
      line-height: 1;
      font-style: italic;
      display: block;
    }

    .post-date {
      margin: 0;
      display: flex;
      font-size: rem(12);
      line-height: rem(14);
      position: relative;
      color: #000000;
      align-items: center;
      margin-bottom: 10px;

      @include responsiveMin(500px) {
        margin-bottom: 0;
        margin-right: 20px;
      }

      @include responsiveMin(992px) {
        font-size: rem(14);
        line-height: rem(16);
      }

      span {
        display: block;
        background-color: transparent;
        padding: 0;
        margin-left: rem(5);
      }

      svg {
        width: rem(20);
        height: rem(20);
      }
    }
  }

  &__img {
    width: 100%;
    object-fit: cover;
    object-position: center;

    @include responsiveMin(992px) {
      height: rem(240);
      width: rem(365);
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;

    @include responsiveMin(992px) {
      flex: 1;
    }

    .articles__item-link {
      width: fit-content;
    }

    &-title {
      font-weight: bold;
      font-size: rem(18);
      line-height: rem(21);
      color: #181c2d;
      margin-right: rem(5);
      margin-bottom: rem(10);
      max-height: rem(43);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */

      @include responsiveMin(992px) {
        font-size: rem(32) !important;
        line-height: rem(38);
        max-height: rem(76);
      }
    }

    &-excerpt {
      font-size: rem(14);
      line-height: 150%;
      margin-bottom: rem(10);
      color: #3e3e53;
      max-height: rem(63);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */

      @include responsiveMin(992px) {
        font-size: rem(16);
        margin-bottom: rem(15);
        font-weight: 600;
        -webkit-line-clamp: 2;
        max-height: rem(48);
      }
    }

    &-item {
      padding-top: rem(15);
      padding-bottom: rem(10);
      width: 100%;
      display: flex;
      flex-direction: column;

      @include responsiveMin(992px) {
        padding-left: rem(45);
      }
    }

    &-bottom {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      margin-bottom: 0;
      align-items: center;

      .dates {
        @include responsiveMin(500px) {
          display: flex;
          align-items: center;
        }
      }
    }

    &-btn {
      display: flex;
      outline: none;
      border: none;

      span {
        text-transform: uppercase;
        font-weight: bold;
        font-size: rem(14);
        line-height: rem(16);
        color: #3e3e53;
        position: relative;
        padding: rem(2) rem(10);
        display: flex;
        align-items: center;
        height: fit-content;

        @include responsiveMin(992px) {
          font-size: rem(18);
          line-height: rem(21);
        }

        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: rem(5);
          left: 0;
          background: #181c2d;
          opacity: 0.1;
          transition: all 0.5s ease;

          @include responsiveMin(992px) {
            width: rem(6);
          }
        }

        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  &__category {
    max-width: rem(180);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: rem(15);
    display: flex;

    @include responsiveMin(400px) {
      max-width: 100%;
    }

    &_item {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: rem(10);

      a {
        display: flex;
        align-items: center;
        text-decoration: none;

        svg {
          path {
            fill: rgba($color: #fff, $alpha: 0);
            transition: 0.3s;
          }
        }

        &:hover {
          svg {
            path {
              fill: #fc6076;
              stroke: #fc6076;
            }
          }
        }
      }

      @include responsiveMin(992px) {
        font-size: rem(14);
        line-height: rem(16);
      }

      svg {
        margin-right: rem(5);
        width: rem(21);
        height: rem(12);

        @include responsiveMin(992px) {
          width: rem(24);
          height: rem(13);
        }
      }

      span {
        font-size: rem(14);
        line-height: rem(16);
        font-weight: bold;
        background: linear-gradient(
          315deg,
          #fc6076 0%,
          #ff4d66 48.44%,
          #ff6826 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @include responsiveMin(992px) {
          font-size: rem(12);
          line-height: rem(14);
        }
      }
    }

    &-item {
      white-space: nowrap;
      display: inline;
      text-transform: uppercase;
      color: #3e3e53;
      font-size: rem(12);
      line-height: rem(14);

      @include responsiveMin(992px) {
        font-size: rem(14);
        line-height: rem(16);
      }
    }

    &-separator {
      margin: 0 rem(3);

      @include responsiveMin(992px) {
        margin: 0 rem(5);
      }
    }
  }
}

// CATEGORY IN POST

.articles__category-post {
  margin: 0 auto rem(20);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: rem(16);
  line-height: rem(19);
  font-weight: bold;
  color: #ffffff;
  opacity: 0.5;

  @include responsiveMin(992px) {
    margin: 0 0 rem(30) 0;
    justify-content: start;
    font-size: rem(24);
    line-height: rem(28);
  }

  .articles__category-item {
    font-weight: bold;
    color: #ffffff;
    opacity: 0.5;
    text-transform: none;
    font-size: rem(14);
    line-height: rem(16);

    @include responsiveMin(992px) {
      font-size: rem(24);
      line-height: rem(28);
    }

    span {
      display: block;
    }
  }
}

.articles__category-separator-post {
  display: none;
}

// END CATEGORY IN POST
.text__lower-case {
  text-transform: lowercase;
}
