@import "../../../scss/vars";

@import "../../../scss/mixins";

.success-stories_page {
    .success-story__text-head {
        padding-bottom: rem(60);
    }

    .success-story__type {
        @include responsiveMin(992px) {
            margin-right: rem(150);
        }
    }

    .description-success {
        padding: rem(30) rem(20);
        background: #ffffff;

        @include responsiveMin(992px) {
            padding: rem(125) rem(380);
        }

        .second-level-title {
            &_text {
                font-weight: 900;
                font-size: rem($m_h1_font_size);
                line-height: rem($m_h1_line_height);

                @include responsiveMin(992px) {
                    font-size: rem($d_h1_font_size);
                    line-height: rem($d_h1_line_height);
                }
            }

            &_text, &_bg {
                min-width: 100%;
                text-align: center;
            }
        }

        .text {
            max-width: rem(800);

            a {
                color: #ff4d66;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .mobile-first__img {
        transform: translateX(0);
        position: initial;
        
        .mobile-screen {
            @include responsiveMin(992px) {
                width: rem(320);
                max-height: rem(900);
                position: relative;
                padding-top: rem(70);
            }

            &__moc-up {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
                pointer-events: none;
                img {
                    width: 100%;
                    height: auto;
                }
            }

            &__frame {
                width: rem(278);
                max-height: rem(580);
                height: rem(580);
                margin: rem(-25) auto;
                overflow-y: scroll;
                border-radius: rem(20);
                background-color: #fff;

                @include responsiveMax(991px) {
                    &::-webkit-scrollbar {
                        display:  none;
                    }
                }

                &::-webkit-scrollbar {
                    width: rem(10);
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 rem(6) rgba(0, 0, 0, 0.3);
                    background: #FFF;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: darkgrey;
                    border-radius: rem(4);
                }

                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }

            &__img-wrap {
                display: inline-block;
                width: 100%;
            }
        }
    }

    .text {
        @include responsiveMin(992px) {
            max-width: rem(500);
            margin-left: rem(150);
        } 

        h3 {
            font-size: rem($m_h3_font_size);
            line-height: rem($m_h3_line_height);
            margin-top: rem(20);
            margin-bottom: rem(10);
    
            @include responsiveMin(992px) {
                font-size: rem($d_h3_font_size);
                line-height: rem($d_h3_line_height);
                margin-top: rem(50);
                margin-bottom: rem(20);
            }
        }
    }
}
.success-stories-loader {
    height: 100vh;
}
