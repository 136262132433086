@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.usercentrics-page {
    overflow: hidden;

    &--preloader {
        min-height: 100vh;
        position: relative;
        background: #3E3E53;

        .data-preloader {
            position: absolute;
            top: 0;
            left: 0;
        }

        .sk-cube {
            background: #FFF;
        }
    }

    section {
        padding: rem(50) rem(20) rem(30) rem(20);
        position: relative;
        color: #3E3E53;

        @include responsiveMin(992px) {
            padding: 0;
            padding: rem(150) rem(150) 0 rem(150);
        }

        .number {
            position: absolute;
            height: fit-content;
            font-weight: 900;
            font-size: rem(96);
            line-height: 90%;
            color: rgba($color: #3E3E53, $alpha: .05);
            top: rem(30);
            left: rem(20);
            z-index: -1;

            @include responsiveMin(992px) {
                top: auto;
                bottom: rem(100);
                font-size: rem(400);
                line-height: 1;

                &--left {
                    left: rem(150);
                }

                &--right {
                    left: auto;
                    right: rem(150);
                }
            }
        }
    }

    h1 {
        color: #3E3E53;
        text-align: center;
        font-weight: 900;
        font-size: rem($m_h1_font_size);
        line-height: rem($m_h1_line_height);

        @include responsiveMin(992px) {
            font-size: rem($d_h1_font_size);
            line-height: rem($d_h1_line_height);
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
    }

    p, li {
        line-height: 150%;

        @include responsiveMin(992px) {
            font-size: rem(16);
        }

        strong {
            background: rgba($color: #3E3E53, $alpha: 0.2);
            padding-left: rem(1);
            padding-right: rem(1);
        }
    }

    img {
        display: block;
    }

    &__list {
        @include responsiveMin(992px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: rem(100) 0;
        }

        li {
            @include responsiveMax(991px) {
                margin-bottom: 30px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            @include responsiveMin(992px) {
                width: calc(33.333% - 6.408rem / 3 * 2);
            }

            & > div {
                position: relative;

                & > div {
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: rem(24);
                    line-height: 90%;
                    font-weight: 900;
                    opacity: 0.03;
                    z-index: -1;

                    @include responsiveMin(992px) {
                        left: auto;
                        right: 0;
                        font-size: rem(96);
                    }

                    span {
                        font-size: rem(96);
                        line-height: 80%;

                        @include responsiveMin(992px) {
                            font-size: rem(400);
                        }
                    }
                }

                img {
                    width: rem(70);
                    margin: 0 auto rem(10);

                    @include responsiveMin(992px) {
                        margin: 0 0 rem(50) 0;
                    }
                }

                h3 {
                    font-size: rem(18);
                    margin-bottom: rem(10);

                    @include responsiveMax(991px) {
                        text-align: center;
                    }

                    @include responsiveMin(992px) {
                        font-size: rem(24);
                    }
                }
            }
        }
    }

    &__button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(19) rem(48);
        font-weight: 700;
        font-size: rem(18);
        line-height: rem(21);
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        margin: rem(10) auto 0;
        text-decoration: none;
        z-index: 1;

        @include responsiveMin(560px) {
            max-width: rem(320);
        }

        @include responsiveMin(992px) {
            width: fit-content;
            min-width: rem(320);
            margin-top: rem(20);
            margin-left: rem(150);
        }

        &:before,
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            transition: opacity .3s;
            background: linear-gradient(349.38deg, #FF6826 0%, #FF4D66 49.48%, #FC6076 100%);
        }

        &:before {
            z-index: -2;
        }

        &:after {
            opacity: 0;
            z-index: -1;
        }

        @include responsiveMin(992px) {
            &:before {
                transform: rotate(-180deg);
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &__title {
        position: relative;
        margin-bottom: rem(10);

        @include responsiveMin(992px) {
            margin-bottom: rem(50);
        }

        &-text {
            font-size: rem($m_h2_font_size);
            line-height: rem($m_h2_line_height);
            text-align: center;
            font-weight: $m_h2_font_weight;
            text-transform: uppercase;

            @include responsiveMin(992px) {
                width: fit-content;
                font-size: rem($d_h2_font_size);
                line-height: rem($d_h2_line_height);
                text-align: left;
            }
        }

        &-bg {
            display: none;

            @include responsiveMin(992px) {
                z-index: -10;
                display: block;
                min-width: 100vw;
                font-weight: 900;
                font-size: rem(96);
                line-height: 1;
                text-transform: uppercase;
                color: #3e3e53;
                opacity: 0.03;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        br {
            display: none;

            @include responsiveMin(992px) {
                display: block;
            }
        }
    }

    &__text {
        width: fit-content;
        font-size: rem($m_text_font_size);
        line-height: rem($m_text_line_height);

        @include responsiveMin(992px) {
            max-width: rem(650);
            padding-left: rem(150);
            font-size: rem($d_text_font_size);
            line-height: rem($d_text_line_height);
        }

        :first-child {
            margin-top: 0;
        }

        :last-child {
            margin-bottom: 0;
        }

        p, ul {
            margin: rem(10) 0;

            a {
                color: #ff4d66;
                text-decoration: underline;
                text-decoration-color: #ff4d66;

                &:hover {
                    text-decoration-color: transparent;
                }
            }
        }

        h3 {
            font-size: rem($m_h3_font_size);
            line-height: rem($m_h3_line_height);
            margin-top: rem(20);

            @include responsiveMin(992px) {
                font-size: rem($d_h3_font_size);
                line-height: rem($d_h3_line_height);
                margin-left: rem(300);
                margin-top: rem(50);
            }
        }

        ul {
            li {
                position: relative;
                padding-left: rem(20);

                @include responsiveMin(992px) {
                    padding-left: rem(25);
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: rem(10);
                    left: rem(9);
                    width: 3px;
                    height: 3px;
                    background: currentColor;
                    border-radius: 50%;

                    @include responsiveMin(992px) {
                        top: rem(11);
                        left: rem(10);
                    }
                }
            }
        }
    }

    .info {
        position: relative;
        padding: rem(30) rem(20);

        @include responsiveMin(992px) {
            padding: rem(100);
        }

        &__title {
            font-weight: $d_h1_font_weight;
            font-size: rem($m_h1_font_size);
            line-height: rem($m_h1_line_height);
            color: #3e3e53;
            text-align: center;
            margin-bottom: rem(10);

            @include responsiveMin(992px) {
                font-size: rem($d_h1_font_size);
                line-height: rem($d_h1_line_height);
                margin-bottom: rem(50);
            }
        }

        &__bg {
            @include responsiveMax(991px) {
                display: none;
            }

            @include responsiveMin(992px) {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                z-index: 2;
                font-weight: 900;
                font-size: rem(96);
                line-height: 1;
                text-transform: uppercase;
                color: #3e3e53;
                opacity: 0.03;
                display: flex;
                justify-content: center;
                overflow: hidden;
                padding-top: rem(100);
            }
        }

        &__text {
            font-size: rem(14);
            line-height: 150%;
            display: flex;
            align-items: center;
            font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
            color: #3E3E53;

            @include responsiveMin(992px) {
                max-width: rem(970);
                margin: 0 auto;
            }
        }
    }

    .usercentrics-required {
        background: rgba($color: #3E3E53, $alpha: 0.04);
        padding-bottom: 0;
        overflow: hidden;
        
        @include responsiveMin(992px) {
            margin-top: 0;
            padding-bottom: rem(190);
        }

        &-parallax {
            position: relative;
            z-index: 2;

            @include responsiveMin(992px) {
                width: rem(560);
            }

            img {
                width: 100%;
            }
        }

        &__image {
            position: relative;
            width: rem(260);
            margin: rem(20) auto 0;

            @include responsiveMin(992px) {
                position: absolute;
                bottom: 0;
                right: rem(300);
                width: rem(600);
                margin: 0;
            }
        }

        &__gear {
            position: absolute;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: rem(140);
            height: rem(145);
            left: rem(168);
            bottom: rem(96);
            animation: diagonalRight 10s infinite;

            @include responsiveMin(992px) {
                width: rem(300);
                height: rem(312);
                left: rem(360);
                bottom: rem(208);
            }

            .tsparticles {
                position: absolute;
                top: 50%;
                left: 65%;
                transform: translate(-50%, -50%) rotate(90deg);
                width: 180%;
                z-index: -1;
            }

            &-img {
                width: 100%;
                position: absolute;
                display: block;
                overflow: hidden;
                filter: drop-shadow(0 5px 30px rgba(0, 0, 0, 0.3));
                animation: rotation 5s infinite linear;

                @include responsiveMin(992px) {
                    filter: drop-shadow(0 20px 70px rgba(0, 0, 0, 0.3));
                }
            }

            &-logo {
                width: rem(58);
                z-index: 1;

                @include responsiveMin(992px) {
                    width: rem(125);
                }
            }
        }
    }

    .compliant {
        @include responsiveMin(992px) {
            position: relative;
            display: flex;
            padding: rem(150) rem(150) rem(150) rem(50);
        }

        &__image {
            position: relative;
            max-width: rem(500);
            margin: rem(20) auto 0 auto;

            @include responsiveMin(992px) {
                max-width: none;
                width: rem(900);
                margin: 0;
                order: -1;
            }

            &--sheet {
                width: 93.75%;
                animation: diagonalLeft 10s infinite;

                @include responsiveMin(992px) {
                    width: rem(750);
                }
            }

            &--pen {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 37.5%;
                animation: diagonalRight 8s infinite;

                @include responsiveMin(992px) {
                    width: rem(300);
                }
            }
        }
    }

    .management {
        padding: 0;
        
        &--top {
            position: relative;
            padding: rem(50) rem(20) 0 rem(20);
            margin-bottom: rem(100);
            background: rgba($color: #3E3E53, $alpha: 0.04);

            @include responsiveMin(992px) {
                padding: rem(150) 0 rem(50) rem(150);
            }
        }

        &__image {
            position: relative;
            max-width: rem(490);
            margin: rem(-50) auto 0;
            transform: translateY(70px);

            @include responsiveMin(992px) {
                position: absolute;
                top: rem(50);
                right: rem(340);
                width: rem(490);
                transform: none;
                margin: 0;
            }

            &--left, &--right {
                width: 53.125%;
                filter: drop-shadow(0px 0px 32.6531px rgba(0, 0, 0, 0.2));
            }

            &--left {
                position: absolute;
                top: 5%;
                left: 0;

                img {
                    width: 100%;
                    animation: diagonalRight 10s infinite;
                }
            }

            &--right {
                z-index: 2;
                margin-left: auto;
                animation: diagonalLeft 10s infinite;
            }
        }

        &__triangle {
            position: absolute;
            top: 100%;
            left: 0;
            width: 0;
            height: 0;
            border-left: 100vw solid transparent;
            border-top: rem(100) solid rgba($color: #3E3E53, $alpha: 0.04);
            z-index: -1;

            @include responsiveMin(992px) {
                border-top-color: rgba($color: #3E3E53, $alpha: 0.04);
                border-top-style: solid;
            }
        }

        &--bottom {
            position: relative;
            z-index: 2;
            padding: rem(30) rem(20) rem(20) rem(20);

            @include responsiveMin(992px) {
                padding: rem(300) rem(150) 0 rem(150);
            }
        }
    }

    .integration {
        position: relative;
        padding: rem(50) rem(20);
        background: rgba(62, 62, 83, 0.04);
        pointer-events: none;
        z-index: 2;

        @include responsiveMin(992px) {
            min-height: rem(1030);
            padding: rem(397) rem(150) rem(100) rem(150);
            background: transparent;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 0;
                border-left: 100vw solid transparent;
                border-bottom: rem(515) solid rgba(62, 62, 83, 0.04);
                z-index: -1;
            }

            &:after {
                content: '';
                position: absolute;
                top: rem(100);
                left: rem(300);
                width: rem(902);
                height: rem(500);
                background-image: url('../../../images/painted-arrows.svg');
                background-size: contain;
                background-repeat: no-repeat;
                animation: diagonalLeft 10s infinite;
            }
        }

        &__text-container {
            pointer-events: all;

            @include responsiveMin(992px) {
                max-width: rem(480);
                margin-left: auto;

                .usercentrics-page__button {
                    margin-left: 0;
                }

                p {
                    margin-left: 0;
                }
            }
        }

        &__text {
            padding-left: 0;

            strong {
                background: transparent;
            }
        }
    }

    .collaboration {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: rem(656);
        padding-bottom: 0;
        background: #3E3E53;
        z-index: 1;

        @include responsiveMin(992px) {
            height: rem(1623);
            padding-top: rem(400);
            margin-top: rem(-515);

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                height: 0;
                border-left: 100vw solid transparent;
                border-top: rem(515) solid #F7F7F8;
                z-index: -1;
            }
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: rem(515) solid transparent;
            border-bottom: rem(318) solid #0096FF;
            z-index: -1;

            @include responsiveMin(500px) {
                left: 0;
                transform: translate(0);
                border-left: 100vw solid transparent;
            }

            @include responsiveMin(992px) {
                border-bottom: rem(1109) solid #0096FF;
            }
        }

        .number {
            color: rgba($color: #fff, $alpha: 0.02);

            span {
                display: block;
            }

            @include responsiveMin(992px) {
                bottom: rem(646);
            }
        }

        &__title {
            color: #fff;

            .usercentrics-page__title-bg {
                color: #fff;
            }

            .usercentrics-page__title-text {
                span {
                    text-transform: none;
                    background: -webkit-linear-gradient(90deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                br {
                    display: block;
                }
    
                @include responsiveMin(992px) {
                    br {
                        display: none;
                    }
                }
            }
        }

        &__text {
            color: #fff;
        }

        &__animation {
            position: relative;
            margin: auto auto 0;
            padding-bottom: rem(80);

            @include responsiveMin(992px) {
                position: absolute;
                top: rem(749);
                left: rem(718);
                padding-bottom: 0;
            }

            &--circle {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: rem(164);
                height: rem(164);
                border-radius: 50%;
                margin: 0 auto;
                padding: 2px;
                background: linear-gradient(to bottom, #3E3E53 50%, #0096FF 50%);
                animation: rotation 10s linear infinite;

                @include responsiveMin(992px) {
                    width: rem(572);
                    height: rem(572);
                }
    
                &::before, &::after {
                    content: '';
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    width: calc(100% - 4px);
                    height: calc(100% - 4px);
                    background: linear-gradient(to bottom, #0096FF 50%, #3E3E53 50%);
                    border-radius: 50%;
                }

                svg {
                    width: rem(25);
                    height: auto;
                    transform: rotate(28deg);
                    z-index: 2;

                    @include responsiveMin(992px) {
                        width: rem(118);
                    }
                }
            }
        }

        &__badge {
            position: absolute;
            width: rem(140);

            @include responsiveMin(992px) {
                width: rem(500);
            }

            &--alkima {
                top: rem(-6);
                left: rem(-78);

                @include responsiveMin(992px) {
                    top: rem(-22);
                    left: rem(-273);
                }
            }

            &--usercentrics {
                top: rem(116);
                left: rem(99);

                @include responsiveMin(992px) {
                    top: rem(406);
                    left: rem(344);
                }
            }
        }

        .circle {
            position: absolute;

            div {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }

            &--1 {
                bottom: rem(304);
                right: rem(-19);
                width: rem(64);
                height: rem(64);

                @include responsiveMin(992px) {
                    top: rem(304);
                    bottom: auto;
                    right: rem(219);
                    width: rem(220);
                    height: rem(220);
                }

                div {
                    background: #B8CEE1;
                    animation: diagonalRight 3s infinite;
                }
            }

            &--2 {
                bottom: rem(49);
                left: rem(-38);
                width: rem(88);
                height: rem(88);

                @include responsiveMin(992px) {
                    bottom: rem(170);
                    left: rem(243);
                    width: rem(308);
                    height: rem(308);
                }

                div {
                    background: #F25800;
                    animation: diagonalLeft 3s infinite;
                }
            }


            &--3 {
                bottom: rem(-45);
                right: rem(20);
                width: rem(112);
                height: rem(112);
                z-index: 2;

                @include responsiveMin(992px) {
                    bottom: rem(-111);
                    right: rem(350);
                    width: rem(396);
                    height: rem(396);
                }

                div {
                    background: #0045A5;
                    animation: diagonalRight 4s infinite;
                }
            }
        }
    }

    .what {
        position: relative;

        @include responsiveMin(992px) {
            padding-right: rem(320);
            padding-bottom: rem(310);
            padding-left: 0;
        }

        &__triangle {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border-top-color: #0096FF;
            border-top-style: solid;
            border-top: rem(515) solid #0096FF;
            border-left: 100vw solid transparent;
            z-index: -1;

            @include responsiveMax(991px) {
                display: none;
            }
        }

        &__content {
            @include responsiveMin(992px) {
                padding-top: rem(150);
                display: flex;
                align-items: center;
            }
        }

        &__text-container {
            margin-bottom: rem(20);
        }

        &__image-container {
            position: relative;
            max-width: rem(500);
            margin: 0 auto;

            @include responsiveMin(992px) {
                width: rem(984);
                max-width: none;
                margin-left: rem(-40);
                order: -1;
            }

            .advantages {
                position: relative;
                width: rem(320);
                height: rem(340);
                margin: 0 auto;

                @include responsiveMin(992px) {
                    position: absolute;
                    top: rem(-90);
                    left: rem(80);
                    width: rem(878);
                    height: rem(748);
                    margin: 0;
                }

                &__item {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: rem(180);
                    height: rem(180);
                    padding: rem(20);
                    background: #fff;
                    border-radius: 50%;
                    box-shadow: 0px 0px 51.1364px rgba(0, 0, 0, 0.2);

                    @include responsiveMin(992px) {
                        width: rem(228);
                        height: rem(228);
                    }

                    &:first-of-type {
                        top: 0;
                        left: 0;
                        animation: diagonalLeft 9s infinite;

                        @include responsiveMin(992px) {
                            left: rem(270);
                        }

                        span {
                            color: #FFB744;
                        }
                    }

                    &:nth-of-type(2) {
                        top: rem(30);
                        right: 0;
                        animation: diagonalRight 10s infinite;

                        @include responsiveMin(992px) {
                            top: rem(300);
                            right: 0;
                        }

                        span {
                            color: #F599B9;
                        }
                    }

                    &:last-of-type {
                        left: rem(40);
                        bottom: 0;
                        animation: diagonalLeft 11s infinite;

                        @include responsiveMin(992px) {
                            bottom: 0;
                            left: rem(80);
                        }
                        
                        span {
                            color: #FF4D66;
                        }
                    }

                    p {
                        text-align: center;
                        font-style: rem(14);
                        line-height: rem(16);
                        font-weight: 700;
                    }

                    span {
                        display: block;
                        margin: 5px auto;
                        font-size: rem(32);
                        line-height: rem(38);
                        font-weight: 900;
                        letter-spacing: -0.05em;

                        @include responsiveMin(992px) {
                            font-size: rem(48);
                            line-height: rem(56);
                            margin: 0 auto;
                        }
                    }
                }
            }

            img {
                width: 100%;
                margin-top: rem(-47);

                @include responsiveMin(480px) {
                    margin-top: rem(-75);
                }

                @include responsiveMin(992px) {
                    margin-top: 0;
                }
            }
        }
    }

    .cmp {
        padding: 0;

        &--top {
            position: relative;
            padding: rem(50) rem(20) 0 rem(20);
            margin-bottom: rem(100);
            background: rgba($color: #3E3E53, $alpha: 0.04);

            @include responsiveMin(992px) {
                padding: rem(150) 0 rem(50) rem(150);
            }
        }

        &__image {
            max-width: rem(500);
            margin: rem(-50) auto 0;
            transform: translateY(70px);

            @include responsiveMin(992px) {
                position: absolute;
                top: rem(150);
                right: rem(120);
                width: rem(924);
                max-width: none;
                transform: none;
                margin: 0;
            }

            svg {
                width: 100%;
                height: auto;
                overflow: visible;

                .circle1 {
                    animation: diagonalLeft 8s infinite;
                }

                .circle2 {
                    animation: diagonalRight 9s infinite;
                }

                .circle3 {
                    animation: diagonalLeft 9s infinite;
                }

                .circle4 {
                    animation: diagonalRight 10s infinite;
                }

                .circle5 {
                    animation: diagonalLeft 11s infinite;
                }

                .circle6 {
                    animation: diagonalLeft 12s infinite;
                }

                .circle7 {
                    animation: diagonalLeft 10s infinite;
                }
            }
        }

        &__triangle {
            position: absolute;
            top: 100%;
            left: 0;
            width: 0;
            height: 0;
            border-left: 100vw solid transparent;
            border-top: rem(100) solid rgba($color: #3E3E53, $alpha: 0.04);
            z-index: -1;

            @include responsiveMin(992px) {
                border-top-color: rgba($color: #3E3E53, $alpha: 0.04);
                border-top-style: solid;
            }
        }

        &--bottom {
            position: relative;
            z-index: 2;
            padding: rem(30) rem(20) rem(20) rem(20);

            @include responsiveMin(992px) {
                padding: rem(300) rem(150) rem(50) rem(150);
            }
        }
    }

    .why {
        background: rgba($color: #3E3E53, $alpha: 0.04);

        @include responsiveMin(992px) {
            display: flex;
            min-height: rem(920);
            padding: rem(150) rem(150) rem(150) rem(100);
        }

        &__image {
            position: relative;
            max-width: rem(500);
            padding-left: rem(18);
            padding-bottom: rem(21);
            margin: rem(20) auto 0;

            @include responsiveMin(992px) {
                width: rem(760);
                max-width: none;
                align-self: flex-end;
                padding-left: rem(41);
                padding-bottom: rem(50);
                margin: 0 rem(90) 0 0;
                order: -1;
            }

            &--phone {
                width: 23.4375%;
                position: absolute;
                bottom: 0;
                left: 0;
                animation: diagonalRight 12s infinite;
                z-index: 2;

                img {
                    width: 100%;
                }
            }

            &--laptop {
                width: 100%;
                animation: diagonalRight 17s infinite;
            }
        }
    }

    .implementation {
        padding: 0;

        &--top {
            position: relative;
            padding: rem(50) rem(20) 0 rem(20);
            z-index: 2;

            @include responsiveMin(992px) {
                padding: rem(150) 0 rem(50) rem(150);
            }

            &-triangle {
                position: absolute;
                top: 100%;
                left: 0;
                width: 0;
                height: 0;
                border-left: 100vw solid transparent;
                border-top: 100px solid #fff;
                z-index: -1;
    
                @include responsiveMin(992px) {
                    border-top-color: #fff;
                    border-top-style: solid;
                }
            }
        }

        &__image {
            width: 100%;
            max-width: rem(500);
            margin: rem(-55) auto 0;
            transform: translateY(75px);

            @include responsiveMin(992px) {
                position: absolute;
                top: rem(100);
                right: rem(120);
                width: rem(924);
                max-width: none;
                transform: none;
                margin: 0;
            }

            svg {
                width: 100%;
                height: auto;
                overflow: visible;

                .circle1 {
                    animation: diagonalLeft 8s infinite;
                }

                .circle2 {
                    animation: diagonalRight 9s infinite;
                }

                .circle3 {
                    animation: diagonalLeft 9s infinite;
                }

                .circle4 {
                    animation: diagonalRight 10s infinite;
                }

                .circle5 {
                    animation: diagonalLeft 11s infinite;
                }

                .circle6 {
                    animation: diagonalLeft 12s infinite;
                }

                .circle7 {
                    animation: diagonalLeft 10s infinite;
                }
            }
        }

        &--center {
            position: relative;
            padding: rem(100) rem(20) rem(30) rem(20);
            background: rgba($color: #3E3E53, $alpha: 0.04);

            @include responsiveMin(992px) {
                padding: rem(200) rem(150) rem(200) rem(150);
            }

            &-triangle {
                display: none;

                @include responsiveMin(992px) {
                    display: block;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 0;
                    height: 0;
                    border-left: 100vw solid transparent;
                    border-top-color: rgba($color: #3E3E53, $alpha: 0.04);
                    border-top-style: solid;
                    z-index: -1;
                }
            }
        }
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes diagonalLeft {
    0% {
        transform: translate(0, 0);
    }
    
    50% {
        transform: translate(-15px, -15px);
    }
    
    100% {
        transform: translate(0, 0);
    }
}

@keyframes diagonalRight {
    0% {
        transform: translate(0, 0);
    }
    
    50% {
        transform: translate(15px, -15px);
    }
    
    100% {
        transform: translate(0, 0);
    }
}
