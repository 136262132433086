@import "../../../scss/vars";
@import "../../../scss/mixins";

/* START .referenzen-projects */

.referenzen-page {
  &--preloader {
    min-height: 100vh;
    position: relative;

    .data-preloader {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__subtitle {
    font-weight: bold;
    font-size: rem(24);
    line-height: rem(28);
    text-align: center;
    color: $menu_bg;
    margin-bottom: rem(15);

    @include responsiveMax(991px) {
      font-size: rem(18);
      line-height: rem(21);
      margin-bottom: rem(10);
    }

    @include responsiveMax(767px) {
      font-size: rem(14);
      line-height: rem(16);
    }
  }
}

.projects-filter {
  background: #3E3E53;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__title {
    display: none;

    @include responsiveMin(992px) {
      display: block;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      height: rem(320);
      position: relative;
      border-bottom: 1px solid rgba(255,255,255, .05);

      &-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        font-size: rem(96);
        line-height: rem(96);
        text-transform: uppercase;
        color: #FFFFFF;
        opacity: 0.01;
      }

      &-link {
        height: 100%;
        width: 100%;
        font-weight: 900;
        font-size: rem(48);
        line-height: rem(56);
        text-transform: uppercase;
        color: #FFFFFF;
        text-decoration: none;
        cursor: pointer;
        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s;

        &:hover {
          box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  &__item {
    flex: 0 0 16.666666%;
    max-width: 16.66666%;
    height: rem(320);
    position: relative;
    z-index: 1;

    &-icon {
      width: rem(70);
      height: rem(70);
      margin: 0 auto;
      transition: transform .3s;
      z-index: 2;

      p {
        width: 100%;
        height: 100%;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    &-link {
      height: 100%;

      a {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        transition: all .3s;
        cursor: pointer;

        @include responsiveMin(992px) {
          padding-top: rem(30);
        }

        @include responsiveMax(991px) {
          border-left: 1px solid rgba(255,255,255, .05);
          border-bottom: 1px solid rgba(255,255,255, .05);
        }

        .projects-filter__item-bg {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: .02;
          background-size: rem(200);
          background-repeat: no-repeat;
          background-position: center center;
          pointer-events: none;
    
          p {
            width: rem(200);
            height: rem(200);
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            @include responsiveMax(991px) {
              width: 80%;
              height: 80%;
            }
    
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }

        .projects-filter__item-title {
          z-index: 2;
          .projects-filter__item-arrow {
            margin-top: rem(20);
            transition: .3s;
            
            i {
              &::before {
                font-size: rem(8);
              }
            }
          }
        }

        &.active-filter {
          background: #fff;
          box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);

          .projects-filter {
            &__item {
              &-title {
                transform: translateY(rem(5));

                .projects-filter__item-arrow {
                  transform: translateY(rem(20));
                }
              }

              &-icon {
                transform: translateY(rem(-5));
              }
            }
          }

          .projects-filter__item-bg {
            svg path {
              fill: #3E3E53;
            }
          }

          .projects-filter__item-icon {
            svg path {
              fill: #3E3E53;
            }
          }

          .projects-filter__item-title {
            color: #3E3E53;

            .projects-filter__item-arrow {
              i {
                &::before {
                  color: #3E3E53;
                }
              }
            }
          }
        }

        &:hover {
          box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);

          .projects-filter {
            &__item {
              &-title {
                transform: translateY(rem(5));

                .projects-filter__item-arrow {
                  transform: translateY(rem(20));

                  @include responsiveMax(991px) {
                    transform: translateY(rem(10));
                  }
                }
              }

              &-icon {
                transform: translateY(rem(-5));
              }
            }
          }
        }
      }
    }

    &-title {
      font-weight: bold;
      font-size: rem(18);
      line-height: rem(21);
      text-align: center;
      color: #FFFFFF;
      margin-top: rem(20);
      transition: transform .3s;
    }

    &-arrow {
      // display: none;

      @include responsiveMax(991px) {
        display: block;
        margin-top: rem(10);
        font-size: rem(6);
        line-height: 1;

        i {
          &:before {
            font-size: rem(6);
          }
        }
      }
    }

    &:first-child {
      border-left: none;
    }

    @include responsiveMax(991px) {
      flex: 0 0 50%;
      max-width: 50%;
      height: rem(180);

      &-bg {
        background-size: rem(112);
      }

      &-icon {
        width: rem(50);
        height: rem(50);
      }

      &-title {
        font-size: rem(14);
        line-height: rem(16);
        margin-top: rem(10);
      }

      &:nth-child(4) {
        border-left: none;
      }
    }
  }
}

/* END .referenzen-projects */
