@import "../../../scss/mixins.scss";
@import "../../../scss/vars.scss";

.categories {
  overflow-anchor: none;
  scroll-behavior: smooth;
  position: relative;
  z-index: 9;

  .disable {
    display: none;
  }

  @include responsiveMin(992px) {
    overflow-anchor: initial;
    padding: rem(73) rem(195) rem(50) rem(150);
  }

  &__lists {
    @include responsiveMin(992px) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__breadcrumbs {
    margin-bottom: rem(50);
    display: flex;
    align-items: center;

    @include responsiveMax(991px) {
      margin-left: rem(20);
      margin-bottom: rem(30);
    }

    .home-link {
      svg {
        width: rem(24);
        height: rem(24);
      }
    }

    &_item {
      margin-left: rem(10);
      display: flex;
      align-items: center;
      font-size: rem(16);
      font-weight: 500;

      &--page {
        span {
          text-decoration: underline;
          background: linear-gradient(
            282.39deg,
            #fc6076 0%,
            #ff4d66 49.48%,
            #ff6826 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-decoration: underline;
          text-decoration-color: #fc6076;
        }

        a {
          text-decoration: none;

          &:hover {
            span {
              text-decoration: none !important;
              text-decoration-color: none !important;
            }
          }
        }
      }

      svg {
        margin-right: rem(10);
        width: rem(8);
        height: rem(12);
      }

      span {
        color: #1b1b1b;
        font-weight: 500;
        font-size: rem(16);
        line-height: 150%;
        height: fit-content;
      }
    }
  }

  &__sub-title {
    display: block;
    margin-bottom: rem(10);
    font-size: rem(18);
    line-height: 1.2;
    font-weight: 700;
    color: #181c2d;

    @include responsiveMin(992px) {
      font-size: rem(24);
    }
  }

  &__sub-list {
    margin: rem(50) rem(20);

    @include responsiveMin(992px) {
      margin: 0;
    }

    .sublist-wrapper {
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
      padding: rem(30) rem(25) rem(30) rem(20);

      @include responsiveMax(991px) {
        display: flex;
        flex-direction: column;
      }

      @include responsiveMin(992px) {
        padding: rem(50) rem(30);
        width: rem(410);
      }

      .sublist {
        &-kategorien {
          margin-bottom: rem(40);

          &__content {
            overflow: hidden;
            transition: 0.3s;
            margin-top: rem(20);

            .categories__items {
              display: flex;
              flex-direction: column;

              .categories__item {
                margin-top: rem(10);
              }
            }

            .categories__item {
              display: inline;
              margin: 0;

              span {
                height: 100%;
                font-weight: bold;
                font-size: rem(18);
                line-height: rem(21);
                padding: rem(2) rem(10);
                position: relative;
                color: #3e3e53;

                &::before {
                  content: "";
                  position: absolute;
                  height: 100%;
                  width: rem(4);
                  left: 0;
                  bottom: 0;
                  top: 0;
                  background: #3e3e53;
                  opacity: 0.3;
                  transition: all 0.5s ease;

                  @include responsiveMin(992px) {
                    width: rem(6);
                  }
                }

                &:hover::before {
                  width: 100%;
                }
              }
            }
          }
        }

        &-tags {
          border: none;
          text-align: left;
          outline: none;
          transition: 0.4s;
          background: #fff;
          padding: rem(0) rem(0) rem(10) rem(0);
          margin-bottom: rem(40);

          @include responsiveMax(991px) {
            order: 1;
            margin-bottom: 0;
          }

          a {
            text-decoration: none;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              height: 100%;
              width: 0;
              left: 0;
              top: 0;
              background: #3e3e53;
              transition: all 0.5s ease;
              z-index: 1;
            }

            &:hover {
              &::before {
                width: 100%;
              }
            }

            span {
              position: relative;
              z-index: 2;
            }
          }

          ul {
            display: flex;
            flex-wrap: wrap;

            li {
              width: fit-content;

              a {
                padding: rem(10) rem(20);
                background: #767994;
                margin-right: rem(10);
                margin-top: rem(10);
                width: fit-content;
                margin-top: rem(10);
                display: block;
              }

              span {
                font-weight: bold;
                font-size: rem(16);
                line-height: rem(19);
                color: #ffffff;
              }
            }
          }
        }

        &-autoren {
          border: none;
          text-align: left;
          outline: none;
          transition: 0.4s;
          background: #fff;

          @include responsiveMax(991px) {
            margin-bottom: rem(40);
          }

          &__list {
            &_item {
              margin-bottom: rem(10);
              display: flex;
              align-items: center;

              &:last-child {
                margin-bottom: 0;
              }

              .post__author {
                padding: 0;
                margin: 0;

                a {
                  text-decoration: none;
                }

                &-img {
                  width: fit-content;
                  margin: 0;

                  img {
                    width: rem(56);
                    height: rem(56);
                    border-radius: 100%;

                    @include responsiveMin(992px) {
                      width: rem(80);
                      height: rem(80);
                    }
                  }
                }

                &-info {
                  margin-left: rem(10);

                  .author-name {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: rem(21);
                    margin-bottom: rem(10);
                    color: #181c2d;
                    display: block;

                    @include responsiveMin(992px) {
                      font-size: rem(22);
                      line-height: rem(28);
                    }
                  }

                  .author-work {
                    font-size: 14px;
                    color: #181c2d;
                    line-height: rem(19);
                    margin: 0;
                    text-align: left;

                    @include responsiveMin(992px) {
                      line-height: 150%;
                      font-size: rem(16);
                    }
                  }
                }
              }
            }
          }

          .authors-button {
            color: #3e3e53;
            flex-direction: column;
            margin-top: rem(20);

            a {
              text-decoration: none;
            }
    
            &--title {
              position: relative;
              padding: 0 rem(10);
              font-style: normal;
              font-weight: bold;
              font-size: rem(18);
              line-height: rem(21);
              color: #3E3E53;

              &::before {
                content: "";
                background: #3e3e53;
                position: absolute;
                height: 100%;
                width: rem(4);
                left: 0;
                opacity: 0.3;
                transition: all 0.5s ease;

                @include responsiveMin(992px) {
                  width: rem(6);
                }
              }
              &:hover::before {
                width: 100%;
              }
            }
            a {
                color: #3e3e53;
            }
          }
        }
      }
    }
  }

  &__mainlist {
    &_wrapper {
      margin-left: rem(20);
      margin-bottom: rem(10);

      .swiper-container {
        .swiper-wrapper {
          .swiper-slide {
            &:last-of-type {
              margin-right: rem(20) !important;
            }
          }
        }
      }
    }

    &_title {
      margin-bottom: rem(20);
      font-weight: bold;
      font-size: rem(22);
      line-height: rem(26);
      color: #181c2d;
    }

    &_item {
      width: fit-content;
      background: #fc6076;
      padding: rem(10);

      a {
        text-decoration: none !important;
      }

      &--title {
        color: #fff;
        font-weight: bold;
        font-size: rem(16);
        line-height: rem(19);
      }
    }
  }

  &__wrapper {
    background: #fc6076;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: rem(20);
    margin-bottom: rem(10);

    @include responsiveMin(992px) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      min-height: rem(180);
      padding: 0;
    }
  }

  &__title__wrapper {
    position: relative;
    text-align: center;
    cursor: pointer;

    .title-back--white {
      display: block;
      font-weight: 900;
      text-transform: uppercase;
      opacity: 0.1;
      position: absolute;
      color: #ffffff;
      font-size: rem(36);
      line-height: 100%;
      top: rem(10);

      @include responsiveMax(992px) {
        width: 100%;
        margin: auto;
      }

      @include responsiveMin(992px) {
        font-size: rem(72);
        line-height: rem(85);
        top: rem(-20);
        left: rem(-115);
      }
    }

    @include responsiveMin(992px) {
      text-align: left;
    }
  }

  &__title {
    text-align: center;
    text-transform: uppercase;
    font-size: rem(20);
    line-height: rem(23);
    margin: rem(16) rem(30) rem(40);
    font-weight: 900;

    @include responsiveMin(992px) {
      font-size: rem(36);
      line-height: rem(42);
      flex-basis: rem(500);
      margin: 0;
      padding-right: rem(245);
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    font-size: rem(18);
    line-height: rem(21);
    color: #ffffff;

    @include responsiveMin(992px) {
      font-size: rem(24);
      line-height: rem(28);
    }
    a {
      text-decoration: none;
      color: #ffffff;
    }
  }

  &__item {
    margin-bottom: rem(30);
    flex-basis: 100%;
    cursor: pointer;

    @include responsiveMin(992px) {
      flex-basis: 30%;
      margin: rem(20) rem(30) rem(20) 0;
      white-space: nowrap;
    }

    &--title {
      position: relative;
      padding: 0 rem(10);

      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: rem(4);
        left: 0;
        background: #ffffff;
        opacity: 0.1;
        transition: all 0.5s ease;
        @include responsiveMin(992px) {
          width: rem(6);
        }
      }
      &:hover::before {
        width: 100%;
      }
    }
  }

  .data-preloader__el {
    width: rem(30);
    height: rem(30);
    margin: 0;
    position: absolute;
    top: 17px;
    right: 50px;
    @include responsiveMin(400px) {
      right: 75px;
    }
  }
  .sk-cube {
    background-color: #ffffff;
  }

  .loading-posts {
    min-height: 100vh;
  }
}
.selected {
  display: none;
}
