@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.about-us-page {
    &--preloader {
        min-height: 100vh;
        position: relative;
    
        .data-preloader {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    h1 {
        color: #FFF;
        text-align: center;
        font-weight: 900;
        font-size: rem($m_h1_font_size);
        line-height: rem($m_h1_line_height);
        text-transform: uppercase;

        @include responsiveMin(992px) {
            font-size: rem($d_h1_font_size);
            line-height: rem($d_h1_line_height);
        }
    }

    h2 {
        font-weight: 900;
        font-size: rem($m_h2_font_size);
        line-height: rem($m_h2_line_height);
        text-transform: uppercase;

        @include responsiveMin(992px) {
            font-size: rem($d_h2_font_size);
            line-height: rem($d_h2_line_height);
        }
    }

    p {
        font-size: rem($m_text_font_size);
        line-height: rem($m_text_line_height);

        @include responsiveMin(992px) {
            font-size: rem($d_text_font_size);
            line-height: rem($d_text_line_height);
        }
    }

    img {
        display: block;
        max-width: 100%;
    }

    &__title {
		position: relative;
        position: relative; 
        background: #3E3E53;
        padding-top: rem(30);
        padding-bottom: rem(10);

        &-text {
            font-weight: $d_h1_font_weight;
            font-size: rem($m_h1_font_size);
            line-height: rem($m_h1_line_height);
            text-transform: uppercase;
            color: #fff; 
            text-align: center;
        }

        &-bg {
            display: none;
        }

        &--top-bg {
            display: none;

            @include responsiveMin(992px) {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                background: url('../../../images/bg-el-1-white.svg') no-repeat;
                width: rem(800);
                height: rem(200);
                background-size: contain;
                opacity: 0.03;
                z-index: 1;
            }
        }

        @include responsiveMin(992px) {
            padding-top: rem(150);
            padding-bottom: rem(113);

            &-text {
                font-size: rem($d_h1_font_size);
                line-height: rem($d_h1_line_height);
            }

            &-bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                z-index: 2;
                font-weight: 900;
                font-size: rem(96);
                line-height: rem(113);
                text-transform: uppercase;
                color: #fff;
                opacity: 0.03;
                display: flex;
                justify-content: center;
                overflow: hidden;
                padding-top: rem(150);

                span {
                    white-space: nowrap;
                }
            }
        }
    }

    .scroll-down__btn {
        border: rem(2) solid #3e3e53;
    }

    .loading-block--3 {
        order: -1;

        @include responsiveMin(992px) {
            order: 0;
        }
    }

    .start-banner {
        &:before {
            background: #FFFFFF;
        }

        @include responsiveMin(992px) {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 75vh;
        }

        &__slider {
            @include responsiveMin(992px) {
                width: 50%;
            }
        }

        .banner-title {
            position: relative;
            z-index: 2;

            @include responsiveMax(991px) {
                margin-bottom: rem(20);
            }

            @include responsiveMin(992px) {
                width: 100%;
                margin-top: 0;
            }

            &__text {
                color: #3E3E53;

                @include responsiveMin(992px) {
                    font-size: rem(48);
                    line-height: rem(56);
                }
            }

            &__text-wrap {
                &--right-position {
                    .banner-title__text{
                        font-size: rem(14);
                        line-height: rem(16);
                        font-weight: bold;
                        text-transform: none;
                        color: #3E3E53;

                        @include responsiveMin(992px) {
                            font-size: rem(24);
                            line-height: rem(31);
                        }

                        @include responsiveMax(767px) {
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }

            &__separator {
                &:before {
                    background: #3E3E53;
                }
            }
        }

        .scroll-down {
            @include responsiveMin(992px) {
                display: none;
            }

            &__btn {
                border: rem(2) solid #3E3E53;

                path {
                    stroke: #3E3E53;
                }
            }
        }

        .video-wrapper {
            position: relative;
            z-index: 2;
            padding: rem(50) rem(20);

            @include responsiveMin(992px) {
                width: 50%;
                padding: rem(50) rem(100);
            }

            .tsparticles {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 0;
                margin: 0;
                z-index: -1;
                width: 105%;
                height: 95%;
            }

            .video {
                box-shadow: 0px 0px rem(50) rgba(0, 0, 0, 0.2);
            }
        }
    }

    .who-we-are, .why-we {
        position: relative;
        padding: rem(30) rem(20);

        @include responsiveMin(992px) {
            padding: 0;
            display: flex;
            align-items: center;
        }

        &__title {
            margin-bottom: rem(10);

            @include responsiveMin(992px) {
                position: relative;
                margin-bottom: rem(50);
            }

            &_text {
                text-align: center;

                @include responsiveMin(992px) {
                    text-align: left;
                }
            }

            &_bg {
                display: none;

                @include responsiveMin(992px) {
                    display: block;
                    position: absolute;
                    top: 0;
                    text-transform: uppercase;
                    opacity: 0.03;
                    font-weight: 900;
                    font-size: rem(96);
                    line-height: rem(114);
                }
            }
        }

        &__text-container { 
            width: fit-content;
        }

        &__info {
            margin-bottom: rem(30);

            @include responsiveMin(992px) {
                margin-bottom: 0;
            }

            p {
                display: block;
                margin-top: rem(10);
    
                @include responsiveMin(992px) {
                    margin-top: rem(15);
                    padding-left: rem(100);
                }

                &:first-of-type {
                    margin-top: 0;
                }
            }
        }

        &__img {
            margin: 0 auto;
            width: 100%;

            @include responsiveMin(992px) {
                margin: 0;
            }
        }
    }

    .who-we-are {
        position: relative;
        padding-top: 0;
        color: white;
        background: #3E3E53;
        padding-bottom: 0;

        @include responsiveMin(992px) {
            padding-bottom: rem(100);
            padding-left: rem(100);
            padding-right: rem(220);
        }

        &__text-container {
            @include responsiveMin(992px) {
                order: 2;
                margin-left: auto;
                margin-right: 0;
            }
        }

        &__info {
            @include responsiveMin(992px) {
                width: rem(850);
            }
        }

        &__img {
            max-width: rem(400);
            width: 100%;

            @include responsiveMin(992px) {
                position: absolute;
                bottom: 0;
                left: rem(-50);
                max-width: none;
                width: rem(900);
                order: 1;
            }
        }

        &--bottom-bg {
            display: none;

            @include responsiveMin(992px) {
                display: block;
                position: absolute;
                z-index: 1;
                bottom: 0;
                right: 0;
                transform: rotate(180deg);
            }
      
            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                display: block;
                width: rem(600);
                height: rem(500);
                background: url("../../../images/bg-el-2-white.svg") no-repeat;
                background-size: contain;
                opacity: 0.02;
            }
        }
    }

    .why-we {
        background: #767994;
        color: white;
        overflow: hidden;

        @include responsiveMin(992px) {
            padding-left: rem(150);
        }

        &__text-container {
            position: relative;
            z-index: 2;
        }

        &__info {
            @include responsiveMin(992px) {
                width: rem(640);
            }
        }

        &__img {
            position: absolute;
            top: 0; left: 0; bottom: 0; right: 0;
            margin: auto;
            opacity: .1;
            z-index: 1;

            @include responsiveMin(992px) {
                position: relative;
                width: rem(960);
                max-width: none;
                margin-right: 0;
                margin-left: auto;
                opacity: 1;
            }
        }

        &--top-bg {
            display: none;

            @include responsiveMin(992px) {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                background: url('../../../images/bg-el-1-dark.svg') no-repeat;
                width: rem(800);
                height: rem(200);
                background-size: contain;
                opacity: 0.03;
                z-index: 1;
            }
        }
    }

    .gallery {
        position: relative;

        @include responsiveMin(992px) {
            height: rem(960);
        }

        @include responsiveMin(1400px) {
            height: 100vh;
        }

        .swiper-container {
            height: 100%;
        }

        .swiper-wrapper {
            transition-timing-function: linear;
        }

        &__section {
            display: flex;
            transition-timing-function: linear;

            .subSection {
                &:first-of-type {
                    width: 33.333%;
                }

                &:last-of-type {
                    width: 66.666%;
                }

                div {
                    width: 100%;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            &:nth-child(odd) {
                .subSection {
                    &:first-of-type {
                        div {
                            &:first-of-type {
                                height: 66.666%;
                            }
        
                            &:last-of-type {
                                height: 33.333%;
                            }
                        }
                    }

                    &:last-of-type {
                        div {
                            &:first-of-type {
                                height: 33.333%;
                            }
        
                            &:last-of-type {
                                height: 66.666%;
                            }
                        }
                    }
                }

            }

            &:nth-child(even) {
                .subSection {
                    &:first-of-type {
                        div {
                            &:first-of-type {
                                height: 33.333%;
                            }
        
                            &:last-of-type {
                                height: 66.666%;
                            }
                        }
                    }

                    &:last-of-type {
                        div {
                            &:first-of-type {
                                height: 66.666%;
                            }
        
                            &:last-of-type {
                                height: 33.333%;
                            }
                        }
                    }
                }

            }
        }

        &__button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding-top: rem(12);
            padding-bottom: rem(8);
            background: #3E3E53;
            outline: none;
            border: none;

            @include responsiveMin(992px) {
                position: absolute;
                margin: auto;
                top: 0; left: 0; bottom: 0; right: 0;
                width: rem(180);
                height: rem(180);
                border-radius: 50%;
                z-index: 2;
                box-shadow: inset 0 0 0 rem(5) #FFFFFF;
                transition: .3s;
                padding: 0;

                &:hover {
                    width: rem(250);
                    height: rem(250);
                }
            }

            img {
                width: rem(50);
                margin-bottom: rem(8);
            }

            p {
                color: #FFF;
                font-weight: bold;
                font-size: rem(18);
                line-height: rem(21);
                text-transform: uppercase;
            }
        }
    }

    .support-projects {
        &__poster {
            display: none;

            @include responsiveMin(992px) {
                position: relative;
                display: flex;
            }

            img {
                position: absolute;
                bottom: 0;
                right: 0;
                height: 100%;
                width: auto;
            }

            .tsparticles {
                position: absolute;
                width: 100%;
                left: 0;

                &:first-of-type {
                    top: 0;
                }

                &:last-of-type {
                    bottom: 0;
                }
            }
        }
    
        &__title {
            overflow: hidden;

            &-bg {
                text-align: center;

                @include responsiveMin(992px) {
                    font-size: rem(58);
                    line-height: rem(75);
                }
            }
        }
    }

    .founders {
        @include responsiveMin(992px) {
            position: relative;
            display: flex;
        }

        &__title {
            position: absolute;
            top: rem(100);
            left: 50%;
            transform: translateX(-50%);
            font-weight: 900;
            font-size: rem(36);
            line-height: rem(42);
            text-transform: uppercase;
            color: #FFFFFF;
            text-shadow: 0px 0px 30px #000000;
            z-index: 4;
        }

        &__image {
            height: 100%;
            width: 100%;
            z-index: 1;
            transition: .3s;
        }

        &__content {
            padding: rem(20);
            background: #181C2D;
            color: #FFF;
            transition: .6s;

            @include responsiveMin(992px) {
                top: rem(330);
                max-width: rem(460);
                padding: 0;
                background: none;
                position: absolute;
                z-index: 4;
            }
        }

        &__position {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: rem(172);
            margin-bottom: rem(20);

            &_name, &_left, &_right {
                display: flex;
                justify-content: center;
                padding: rem(7) 0;
                font-weight: bold;
            }

            &_name {
                width: 100%;
                font-size: rem(24);
                line-height: rem(28);
                font-weight: bold;
                margin-bottom: 2px;
                background: linear-gradient(315deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);

                @include responsiveMin(992px) {
                    margin-bottom: 0.14rem;
                }
            }

            &_left, &_right {
                font-size: rem(18);
                line-height: rem(21);
            }

            &_left {
                width: calc(60% - 1px);
                background: #3E3E53;

                @include responsiveMin(992px) {
                    width: calc(60% - 0.07rem);
                }
            }

            &_right {
                width: calc(40% - 1px);
                background: #767994;

                @include responsiveMin(992px) {
                    width: calc(40% - 0.07rem);
                    background: #181C2D;
                }
            }
        }

        &__portrait {
            position: absolute;
            bottom: 0;
            z-index: 5;
            transition: .6s;
        }

        &__text {
            @include responsiveMin(992px) {
                opacity: 0;
                transition: .3s;
            }
        }

        &__bg-element {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            transition: .6s;
        }

        &__overlay {
            width: 50%;
            height: 100%;
            background: linear-gradient(0deg, rgba(24, 28, 45, 0.9), rgba(24, 28, 45, 0.9));
            position: absolute;
            top: 0;
            z-index: 2;
            opacity: 0;
            will-change: width;

            &.active {
                opacity: 1;
                transition: opacity .6s;
            }
            
            &--left {
                left: 0;
            }

            &--right {
                right: 0;
            }
        }

        &__item {
            @include responsiveMin(992px) {
                position: relative;
                height: rem(960);
                width: 50%;
                cursor: pointer;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 4;
                }

                &.marco {
                    .founders__portrait {
                        right: rem(20);
                        width: rem(315);
                        will-change: right;
                    }

                    .founders__content {
                        right: rem(0);
                    }
                }
        
                &.kian {
                    .founders__portrait {
                        left: rem(20);
                        width: rem(324);
                        will-change: left;
                    }

                    .founders__content {
                        left: rem(266);
                    }
                }

                &:hover {
                    .founders__bg-element {
                        filter: blur(10px);
                    }

                    .founders__text {
                        opacity: 1;
                    }

                    &.marco {
                        .founders__content {
                            right: rem(400);
                        }

                        .founders__portrait {
                            right: rem(70);
                        }
                    }

                    &.kian {
                        .founders__content {
                            left: rem(400);
                        }

                        .founders__portrait {
                            left: rem(70);
                        }
                    }
                }
            }
        }
    }
}

.fslightbox-container {
    .fslightbox-nav {
        z-index: 2;
        background: rgba(35, 35, 35, .5);

        @include responsiveMin(500px) {
            background: none;
        }
    }

    .fslightbox-toolbar {
        background: none;

        &-button {
            &:first-of-type {
                display: none;
            }
        }
    }

    img {
        max-width: 100% !important;
        height: auto !important;
    }
}
