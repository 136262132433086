@import "../../../scss/mixins.scss";
@import "../../../scss/vars.scss";

.blog__description {
  position: relative;
  background: #fff;
  color: #181c2d;
  padding: rem(146) rem(0) rem(0) rem(0);
  z-index: 10;

  @include responsiveMin(992px) {
    padding: rem(182) 0 rem(79) 0;
    height: fit-content;
    min-height: rem(540);
  }

  &_header {
    display: flex;
    align-items: center;

    @include responsiveMin(992px) {
      width: rem(680);
      margin-left: rem(150);
    }

    @include responsiveMax(991px) {
      margin-right: rem(20);
      margin-left: rem(20);
    }

    &__img {
      margin-bottom: rem(20);
      margin-right: rem(10);
      width: rem(57);
      height: rem(57);
      min-width: rem(57);
      min-height: rem(57);

      @include responsiveMin(992px) {
        margin-bottom: rem(10);
        margin-right: rem(30);
        width: rem(113);
        height: rem(113);
        min-width: rem(113);
        min-height: rem(113);
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: 1px solid #767994;

        @include responsiveMin(1920px) {
          border: rem(1) solid #767994;
        }
      }
    }
  }

  &__title {
    text-align: left;
    position: relative;
    font-weight: 900;
    font-size: rem(22);
    line-height: rem(26);
    margin-bottom: rem(20);

    br {
      display: none;
    }

    @include responsiveMin(992px) {
      width: rem(680);
      margin: 0 auto;
      padding: 0;
      font-size: rem(48);
      line-height: rem(56);
      margin-bottom: rem(10);

      br {
        display: block;
      }
    }
  }

  &__text {
    line-height: 150%;
    padding-bottom: rem(10);
    font-size: rem(16);

    @include responsiveMin(992px) {
      font-size: rem(18);
      line-height: rem(32);
      padding: 0;
      text-align: left;
      width: rem(680);
      margin-left: rem(150);
      letter-spacing: -0.01em;
    }

    @include responsiveMax(991px) {
      margin-right: rem(20);
      margin-left: rem(20);
    }

    a {
      color: #ffffff;
    }
  }

  &__mobile_circles {
    width: 100%;
    margin-bottom: 30px;

    @include responsiveMin(992px) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .magento {
    position: absolute;
    width: rem(116);
    height: rem(116);
    bottom: rem(-58);
    top: auto;
    left: rem(266);
    right: auto;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsiveMin(992px) {
      width: rem(345);
      height: rem(345);
      top: rem(-101);
      right: rem(365);
      bottom: auto;
      left: auto;
    }

    @include responsiveMax(991px) {
      display: none;
    }

    &__logo {
      width: rem(48);

      @include responsiveMin(992px) {
        width: rem(197);
      }
    }
  }

  .react {
    position: absolute;
    width: rem(125);
    height: rem(125);
    bottom: rem(70);
    left: rem(268);
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsiveMin(992px) {
      width: rem(196);
      height: rem(196);
      bottom: auto;
      left: auto;
      top: rem(270);
      right: rem(389);
    }

    @include responsiveMax(991px) {
      display: none;
    }

    &__logo {
      width: rem(40);

      @include responsiveMin(992px) {
        width: rem(110);
      }
    }
  }

  .shopware {
    position: absolute;
    width: rem(183);
    height: rem(183);
    top: rem(354);
    right: rem(122);
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsiveMin(992px) {
      width: rem(279);
      height: rem(279);
      top: rem(112);
      right: rem(-59);
    }

    @include responsiveMax(991px) {
      display: none;
    }

    &__logo {
      width: rem(43);

      @include responsiveMin(992px) {
        width: rem(155);
      }
    }
  }

  .typo3 {
    position: absolute;
    width: rem(211);
    height: rem(211);
    top: rem(314);
    right: rem(342);
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsiveMin(992px) {
      width: rem(128);
      height: rem(128);
      top: rem(408);
      right: rem(24);
    }

    @include responsiveMax(991px) {
      display: none;
    }

    &__logo {
      width: rem(29);

      @include responsiveMin(992px) {
        width: rem(71);
      }
    }
  }

  .woocomerce {
    position: absolute;
    width: rem(164);
    height: rem(164);
    top: auto;
    bottom: rem(8);
    right: auto;
    left: rem(-10);
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsiveMin(992px) {
      width: rem(251);
      height: rem(251);
      top: rem(57);
      right: rem(725);
      left: auto;
      bottom: auto;
    }

    @include responsiveMax(991px) {
      display: none;
    }

    &__logo {
      width: rem(50);

      @include responsiveMin(992px) {
        width: rem(142);
      }
    }
  }

  .next {
    position: absolute;
    width: rem(83);
    height: rem(83);
    bottom: rem(43);
    left: rem(180);
    top: auto;
    right: auto;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsiveMin(992px) {
      width: rem(196);
      height: rem(196);
      top: rem(324);
      right: rem(743);
      bottom: auto;
      left: auto;
    }

    @include responsiveMax(991px) {
      display: none;
    }

    &__logo {
      width: rem(24);

      @include responsiveMin(992px) {
        width: rem(88);
      }
    }
  }

  .php {
    position: absolute;
    width: rem(83);
    height: rem(83);
    bottom: rem(43);
    left: rem(180);
    top: auto;
    right: auto;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsiveMin(992px) {
      width: rem(162);
      height: rem(162);
      top: rem(236);
      right: rem(592);
      bottom: auto;
      left: auto;
    }

    @include responsiveMax(991px) {
      display: none;
    }

    &__logo {
      width: rem(24);

      @include responsiveMin(992px) {
        width: rem(85);
      }
    }
  }

  .vue {
    position: absolute;
    width: rem(83);
    height: rem(83);
    bottom: rem(43);
    left: rem(180);
    top: auto;
    right: auto;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsiveMin(992px) {
      width: rem(164);
      height: rem(164);
      top: rem(168);
      right: rem(240);
      bottom: auto;
      left: auto;
    }

    @include responsiveMax(991px) {
      display: none;
    }

    &__logo {
      width: rem(24);

      @include responsiveMin(992px) {
        width: rem(88);
      }
    }
  }

  .bootstrap {
    position: absolute;
    width: rem(83);
    height: rem(83);
    bottom: rem(43);
    left: rem(180);
    top: auto;
    right: auto;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsiveMin(992px) {
      width: rem(139);
      height: rem(139);
      top: rem(420);
      right: rem(571);
      bottom: auto;
      left: auto;
    }

    @include responsiveMax(991px) {
      display: none;
    }

    &__logo {
      width: rem(24);

      @include responsiveMin(992px) {
        width: rem(65);
      }
    }
  }

  .laravel {
    position: absolute;
    width: rem(83);
    height: rem(83);
    bottom: rem(43);
    left: rem(180);
    top: auto;
    right: auto;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsiveMin(992px) {
      width: rem(139);
      height: rem(139);
      top: rem(456);
      right: rem(312);
      bottom: auto;
      left: auto;
    }

    @include responsiveMax(991px) {
      display: none;
    }

    &__logo {
      width: rem(24);

      @include responsiveMin(992px) {
        width: rem(51);
      }
    }
  }

  .symfony {
    position: absolute;
    width: rem(83);
    height: rem(83);
    bottom: rem(43);
    left: rem(180);
    top: auto;
    right: auto;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsiveMin(992px) {
      width: rem(139);
      height: rem(139);
      top: rem(348);
      right: rem(196);
      bottom: auto;
      left: auto;
    }

    @include responsiveMax(991px) {
      display: none;
    }

    &__logo {
      width: rem(24);

      @include responsiveMin(992px) {
        width: rem(51);
      }
    }
  }

  &__img {
    position: absolute;
    opacity: 0.15;
    pointer-events: none;

    @include responsiveMin(992px) {
      opacity: 1;
    }

    &--left {
      display: none;

      @include responsiveMin(992px) {
        display: block;
        bottom: rem(0);
        left: rem(0);
        width: rem(455);
        height: rem(325);
      }
    }

    &--leftphone {
      bottom: rem(0);
      left: rem(0);
      width: rem(132);
      height: rem(123);

      @include responsiveMin(992px) {
        display: none;
      }
    }

    &--right {
      width: rem(220);
      height: rem(171);
      bottom: rem(0);
      right: rem(0);

      @include responsiveMin(992px) {
        width: rem(402);
        height: rem(312);
      }
    }

    &--magento {
      position: absolute;

      animation: rotation 20s linear infinite reverse;

      &:nth-child(odd) {
        animation: rotation 20s linear infinite;
      }

      &-1 {
        width: rem(116);
        height: rem(116);
      }

      &-2 {
        width: rem(109);
        height: rem(109);
      }

      &-3 {
        width: rem(102);
      }

      &-4 {
        width: rem(91);
      }

      &-5 {
        width: rem(90);
      }

      &-6 {
        width: rem(82);
      }

      &-7 {
        width: rem(76);
      }

      @include responsiveMin(992px) {
        &-1 {
          width: rem(345);
          height: rem(345);
        }

        &-2 {
          width: rem(323);
          height: rem(323);
        }

        &-3 {
          width: rem(303);
        }

        &-4 {
          width: rem(271);
        }

        &-5 {
          width: rem(269);
        }

        &-6 {
          width: rem(243);
        }

        &-7 {
          width: rem(228);
        }
      }
    }

    &--react {
      position: absolute;

      animation: rotation 20s linear infinite reverse;

      &:nth-child(odd) {
        animation: rotation 20s linear infinite;
      }

      &-1 {
        width: rem(125);
        height: rem(125);
      }

      &-2 {
        width: rem(117);
        height: rem(117);
      }

      &-3 {
        width: rem(110);
      }

      &-4 {
        width: rem(98);
      }

      &-5 {
        width: rem(97);
      }

      &-6 {
        width: rem(88);
      }

      &-7 {
        width: rem(82);
      }

      @include responsiveMin(992px) {
        &-1 {
          width: rem(196);
          height: rem(196);
        }

        &-2 {
          width: rem(184);
          height: rem(184);
        }

        &-3 {
          width: rem(172);
        }

        &-4 {
          width: rem(154);
        }

        &-5 {
          width: rem(153);
        }

        &-6 {
          width: rem(138);
        }

        &-7 {
          width: rem(129);
        }
      }
    }

    &--shopware {
      position: absolute;

      animation: rotation 20s linear infinite reverse;

      &:nth-child(odd) {
        animation: rotation 20s linear infinite;
      }

      &-1 {
        width: rem(183);
        height: rem(183);
      }

      &-2 {
        width: rem(172);
        height: rem(172);
      }

      &-3 {
        width: rem(161);
      }

      &-4 {
        width: rem(144);
      }

      &-5 {
        width: rem(143);
      }

      &-6 {
        width: rem(129);
      }

      &-7 {
        width: rem(121);
      }

      @include responsiveMin(992px) {
        &-1 {
          width: rem(279);
          height: rem(279);
        }
  
        &-2 {
          width: rem(262);
          height: rem(262);
        }
  
        &-3 {
          width: rem(245);
        }
  
        &-4 {
          width: rem(219);
        }
  
        &-5 {
          width: rem(218);
        }
  
        &-6 {
          width: rem(197);
        }
  
        &-7 {
          width: rem(184);
        }
      }
    }

    &--typo3 {
      position: absolute;

      animation: rotation 20s linear infinite reverse;

      &:nth-child(odd) {
        animation: rotation 20s linear infinite;
      }

      &-1 {
        width: rem(211);
        height: rem(211);
      }

      &-2 {
        width: rem(198);
        height: rem(198);
      }

      &-3 {
        width: rem(186);
      }

      &-4 {
        width: rem(166);
      }

      &-5 {
        width: rem(164);
      }

      &-6 {
        width: rem(149);
      }

      &-7 {
        width: rem(139);
      }

      @include responsiveMin(992px) {
        &-1 {
          width: rem(128);
          height: rem(128);
        }
  
        &-2 {
          width: rem(120);
          height: rem(120);
        }
  
        &-3 {
          width: rem(113);
        }
  
        &-4 {
          width: rem(101);
        }
  
        &-5 {
          width: rem(100);
        }
  
        &-6 {
          width: rem(90);
        }
  
        &-7 {
          width: rem(84);
        }
      }
    }

    &--woocomerce {
      position: absolute;

      animation: rotation 20s linear infinite reverse;

      &:nth-child(odd) {
        animation: rotation 20s linear infinite;
      }

      &-1 {
        width: rem(164);
        height: rem(164);
      }

      &-2 {
        width: rem(154);
        height: rem(154);
      }

      &-3 {
        width: rem(144);
      }

      &-4 {
        width: rem(129);
      }

      &-5 {
        width: rem(128);
      }

      &-6 {
        width: rem(116);
      }

      &-7 {
        width: rem(108);
      }

      @include responsiveMin(992px) {
        &-1 {
          width: rem(251);
          height: rem(251);
        }

        &-2 {
          width: rem(236);
        }

        &-3 {
          width: rem(221);
        }

        &-4 {
          width: rem(197);
        }

        &-5 {
          width: rem(196);
        }

        &-6 {
          width: rem(177);
        }

        &-7 {
          width: rem(165);
        }
      }
    }

    &--next {
      position: absolute;

      animation: rotation 20s linear infinite reverse;

      &:nth-child(odd) {
        animation: rotation 20s linear infinite;
      }

      &-1 {
        width: rem(83);
        height: rem(83);
      }

      &-2 {
        width: rem(78);
        height: rem(78);
      }

      &-3 {
        width: rem(73);
      }

      &-4 {
        width: rem(65);
      }

      &-5 {
        width: rem(65);
      }

      &-6 {
        width: rem(59);
      }

      &-7 {
        width: rem(56);
      }

      @include responsiveMin(992px) {
        &-1 {
          width: rem(196);
          height: rem(196);
        }

        &-2 {
          width: rem(184);
          height: rem(184);
        }

        &-3 {
          width: rem(172);
        }

        &-4 {
          width: rem(154);
        }

        &-5 {
          width: rem(153);
        }

        &-6 {
          width: rem(138);
        }

        &-7 {
          width: rem(129);
        }
      }
    }

    &--php {
      position: absolute;

      animation: rotation 20s linear infinite reverse;

      &:nth-child(odd) {
        animation: rotation 20s linear infinite;
      }

      &-1 {
        width: rem(83);
        height: rem(83);
      }

      &-2 {
        width: rem(78);
        height: rem(78);
      }

      &-3 {
        width: rem(73);
      }

      &-4 {
        width: rem(65);
      }

      &-5 {
        width: rem(65);
      }

      &-6 {
        width: rem(59);
      }

      &-7 {
        width: rem(56);
      }

      @include responsiveMin(992px) {
        &-1 {
          width: rem(162);
          height: rem(162);
        }

        &-2 {
          width: rem(152);
          height: rem(152);
        }

        &-3 {
          width: rem(142);
        }

        &-4 {
          width: rem(127);
        }

        &-5 {
          width: rem(126);
        }

        &-6 {
          width: rem(114);
        }

        &-7 {
          width: rem(107);
        }
      }
    }

    &--vue {
      position: absolute;

      animation: rotation 20s linear infinite reverse;

      &:nth-child(odd) {
        animation: rotation 20s linear infinite;
      }

      &-1 {
        width: rem(83);
        height: rem(83);
      }

      &-2 {
        width: rem(78);
        height: rem(78);
      }

      &-3 {
        width: rem(73);
      }

      &-4 {
        width: rem(65);
      }

      &-5 {
        width: rem(65);
      }

      &-6 {
        width: rem(59);
      }

      &-7 {
        width: rem(56);
      }

      @include responsiveMin(992px) {
        &-1 {
          width: rem(164);
          height: rem(164);
        }

        &-2 {
          width: rem(154);
          height: rem(154);
        }

        &-3 {
          width: rem(144);
        }

        &-4 {
          width: rem(129);
        }

        &-5 {
          width: rem(128);
        }

        &-6 {
          width: rem(116);
        }

        &-7 {
          width: rem(108);
        }
      }
    }

    &--bootstrap {
      position: absolute;

      animation: rotation 20s linear infinite reverse;

      &:nth-child(odd) {
        animation: rotation 20s linear infinite;
      }

      &-1 {
        width: rem(83);
        height: rem(83);
      }

      &-2 {
        width: rem(78);
        height: rem(78);
      }

      &-3 {
        width: rem(73);
      }

      &-4 {
        width: rem(65);
      }

      &-5 {
        width: rem(65);
      }

      &-6 {
        width: rem(59);
      }

      &-7 {
        width: rem(56);
      }

      @include responsiveMin(992px) {
        &-1 {
          width: rem(139);
          height: rem(139);
        }

        &-2 {
          width: rem(130);
          height: rem(130);
        }

        &-3 {
          width: rem(122);
        }

        &-4 {
          width: rem(109);
        }

        &-5 {
          width: rem(108);
        }

        &-6 {
          width: rem(98);
        }

        &-7 {
          width: rem(92);
        }
      }
    }

    &--laravel {
      position: absolute;

      animation: rotation 20s linear infinite reverse;

      &:nth-child(odd) {
        animation: rotation 20s linear infinite;
      }

      &-1 {
        width: rem(83);
        height: rem(83);
      }

      &-2 {
        width: rem(78);
        height: rem(78);
      }

      &-3 {
        width: rem(73);
      }

      &-4 {
        width: rem(65);
      }

      &-5 {
        width: rem(65);
      }

      &-6 {
        width: rem(59);
      }

      &-7 {
        width: rem(56);
      }

      @include responsiveMin(992px) {
        &-1 {
          width: rem(139);
          height: rem(139);
        }

        &-2 {
          width: rem(130);
          height: rem(130);
        }

        &-3 {
          width: rem(122);
        }

        &-4 {
          width: rem(109);
        }

        &-5 {
          width: rem(108);
        }

        &-6 {
          width: rem(98);
        }

        &-7 {
          width: rem(92);
        }
      }
    }

    &--symfony {
      position: absolute;

      animation: rotation 20s linear infinite reverse;

      &:nth-child(odd) {
        animation: rotation 20s linear infinite;
      }

      &-1 {
        width: rem(83);
        height: rem(83);
      }

      &-2 {
        width: rem(78);
        height: rem(78);
      }

      &-3 {
        width: rem(73);
      }

      &-4 {
        width: rem(65);
      }

      &-5 {
        width: rem(65);
      }

      &-6 {
        width: rem(59);
      }

      &-7 {
        width: rem(56);
      }

      @include responsiveMin(992px) {
        &-1 {
          width: rem(139);
          height: rem(139);
        }

        &-2 {
          width: rem(130);
          height: rem(130);
        }

        &-3 {
          width: rem(122);
        }

        &-4 {
          width: rem(109);
        }

        &-5 {
          width: rem(108);
        }

        &-6 {
          width: rem(98);
        }

        &-7 {
          width: rem(92);
        }
      }
    }
  }
}

.title-back {
  display: none;

  @include responsiveMin(992px) {
    display: block;
    position: absolute;
    color: #3e3e53;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.scroll-down--description {
  position: relative;
  padding-bottom: rem(50);
  z-index: 2;

  @include responsiveMin(992px) {
    position: absolute;
    bottom: rem(30);
    width: 100%;
    margin: auto;
    display: none;
  }

  a {
    border: rem(2) solid #fff;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
