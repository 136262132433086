@import "../../scss/vars";
@import "../../scss/mixins";

.wrap {
    display: flex;
    align-items: center;
    gap: rem(5);
    font-size: rem(14);

    svg {
        width: rem(20);
        height: rem(20);
        padding: rem(1);
    }
}

.icon {
    flex-shrink: 0;
    font-size: 1.2em;
}

.text {
    font-size: rem(14);

    @include responsiveMin(1612px) {
        font-size: rem(16);
    }
}