@import "../../../scss/vars";

@import "../../../scss/mixins";

$color-gridlines: #c14694;

.design-process {

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: none;
    }

    position: relative;
    &__slider {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 1;
        .swiper-container {
            height: 100%;
        }
        .swiper-wrapper {
            @include responsiveMin(992px) {
                height: 100%;
                transform: none !important;
            }
        }
    }
    @include responsiveMin(1025px) {
        min-height: 100vh;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__inner {
        position: relative;

        &-top {
            background: linear-gradient(180deg, #fc6076 0%, #ff4d66 50%, #ff6826 100%);
            @include responsiveMin(992px) {
                min-height: rem(620);
            }
        }

        &-bottom {
            background-image: url("./images/bg.svg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            min-height: rem(360);

            @include responsiveMin(992px) {
                width: 100%;
                background: #011627;
                perspective: rem(550);
                background-image: linear-gradient(180deg, transparent 20%, rgba(255, 147, 249, 0.4));
            }
            @include responsiveMin(1025px) {
                min-height: rem(230);
            }
        }
    }

    &__grid {
        @include responsiveMin(992px) {
            position: absolute;
            min-height: 180vh;
            top: -280%;
            left: -50vw;
            width: 200vw;
            transform-origin: 0 50%;
            &::after,
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            &::before {
                background: #011627;
                background-image: linear-gradient(180deg, transparent 20%, rgba(255, 147, 249, 0.4));
            }

            &::after {
                background-image: linear-gradient($color-gridlines 3px, transparent 0),
                    linear-gradient(90deg, $color-gridlines 3px, transparent 0),
                    linear-gradient($color-gridlines 3px, transparent 0),
                    linear-gradient(90deg, $color-gridlines 3px, transparent 0);
                background-size: 400px 400px, 400px 400px, 100px 100px, 100px 100px;
                background-position: -2px -2px, -2px -2px, -2px -2px, -2px -2px;
            }
        }

        &-animate {
            @include responsiveMin(992px) {
                transition: bottom 1s ease;
                animation: move-grid 1.5s forwards;
                @keyframes move-grid {
                    //animation for bottom bg
                    0% {
                        transform: scale3d(0) rotateX(0);
                        min-height: 180vh;
                        top: -280%;
                    }
                    100% {
                        transform: scale3d(1, 1, 2) rotateX(35deg);
                        min-height: rem(550);
                        top: -50%;
                    }
                }
            }
        }
    }

    &__title {
        color: #ffffff;
        margin: rem(30) rem(90) rem(20);
        font-size: rem(20);
        font-weight: 900;
        line-height: 150%;
        text-align: center;
        text-transform: uppercase;

        @include responsiveMin(992px) {
            margin: rem(45);
            position: relative;
            font-size: rem(36);
            line-height: rem(42);
        }

        &-bg {
            position: absolute;
            width: 100%;
            color: #ffffff;
            top: rem(30);
            opacity: 0.03;
            font-size: rem(96);
            line-height: rem(113);
            font-weight: 900;
            white-space: nowrap;
            text-align: center;
            display: none;
            @include responsiveMin(992px) {
                display: block;
            }
        }
    }

    &__img {
        &-sun {
            width: rem(200);
            height: rem(200);
            border-radius: 100%;
            background: linear-gradient(0deg, #ff3857 -90.57%, #ffc05c 86.81%);
            margin: auto;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 100%;
                background-image: url("./images/sun-bg.svg");
                background-size: cover;
            }

            @include responsiveMin(992px) {
                width: rem(500);
                height: rem(500);
            }
            &-animate {
                @include responsiveMin(992px) {
                    transform: translateY(200%);
                    animation: sunrise 2s ease 1.5s forwards; //animation for sun
                    @keyframes sunrise {
                        0% {
                            transform: translateY(200%);
                        }
                        100% {
                            transform: translateY(0);
                        }
                    }
                }
            }
        }

        &-town {
            position: absolute;
            width: 100%;
            z-index: 2;
            bottom: -4px;
            img {
                width: 100%;
            }

            @include responsiveMin(992px) {
                z-index: 0;
            }

            &-animate {
                @include responsiveMin(992px) {
                    transform: translateY(100%);
                    animation: town 1.5s ease 1s forwards;
                    @keyframes town {
                        0% {
                            z-index: 0;
                            transform: translateY(100%);
                        }
                        50% {
                            z-index: 0;
                            transform: translateY(0);
                        }
                        100% {
                            z-index: 2;
                            transform: translateY(0);
                        }
                    }
                }
            }
        }
    }

    &__btn {
        display: none;
        font-size: rem(14);
        font-family: "Joystix Monospace";
        line-height: rem(17);
        color: #ffffff;
        text-transform: uppercase;
        cursor: pointer;
        position: absolute;
        bottom: rem(30);
        width: 100%;
        text-align: center;
        z-index: 3;

        @include responsiveMin(992px) {
            display: block;
            font-size: rem(24);
            line-height: rem(29);
            color: transparent;
            bottom: rem(50);
            &-animate {
                animation: btn 1s steps(2) 4s infinite;
                @keyframes btn {
                    0% {
                        color: transparent;
                    }
                    100% {
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .slide {
        display: flex;
        flex-direction: column;
        @include responsiveMin(992px) {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -2;
            opacity: 0;
        }

        &__inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            overflow: hidden;
            flex-basis: 40%;
            @include responsiveMin(992px) {
                min-height: rem(620);
            }
        }

        &-icon {
            width: rem(37);
            height: rem(14);
            position: absolute;
            bottom: rem(107);
            right: rem(19);

            img {
                width: 100%;
            }

            @include responsiveMin(992px) {
                width: rem(94);
                height: rem(36);
                bottom: 0;
                opacity: 0;
                right: rem(46);

                &-animate {
                    animation: slideAnimation 1.5s ease 3s forwards;
                    @keyframes slideAnimation {
                        0% {
                            bottom: 0;
                            opacity: 0;
                        }
                        100% {
                            bottom: rem(268);
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &__sun {
            width: rem(200);
            height: rem(200);
            border-radius: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            position: absolute;
            top: rem(95);

            @include responsiveMin(992px) {
                width: rem(500);
                height: rem(500);
                top: rem(131);
            }
        }

        &__number {
            font-weight: 900;
            font-size: rem(160);
            line-height: 100%;
            text-align: center;
            padding-top: rem(25);
            background: linear-gradient(180deg, #fc6076 0%, #ff4d66 50%, #ff6826 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @include responsiveMin(992px) {
                font-size: rem(400);
            }
        }

        &__title {
            padding-top: rem(110);
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin: auto;
            min-height: rem(300);

            img {
                max-width: 100%;
                height: rem(33);
                display: block;
                margin-bottom: rem(15);
                @include responsiveMin(992px) {
                    margin-left: rem(20);
                    height: rem(57);
                }
            }
            @include responsiveMin(992px) {
                flex-direction: row;
                position: absolute;
                transform: scale(0);
                top: 61%;
                width: 100%;
                transition: 50% 100%;
            }
        }
    }
}

.design-process {
    .design-process__slider--play-animation {
        .swiper-slide-active {
            z-index: 2;
            opacity: 1;

            .slide__number {
                @include responsiveMin(992px) {
                    animation: number 4.5s ease forwards;
                    transform: translateY(100%);
                    @keyframes number {
                        0% {
                            transform: translateY(100%);
                        }
                        50% {
                            transform: translateY(0);
                        }
                        100% {
                            transform: translateY(-150%);
                        }
                    }
                }
            }
            .slide__title {
                @include responsiveMin(992px) {
                    animation: slideTitle 3s linear 0.5s forwards;
                    position: absolute;
                    @keyframes slideTitle {
                        0% {
                            transform: scale(0);
                            top: 50%;
                        }
                        100% {
                            transform: scale(1.2);
                            top: 90%;
                        }
                    }
                }
            }
        }
    }
    &-btn-prev,
    &-btn-next {
        opacity: 0;
        @include responsiveMin(992px) {
            z-index: 1;
            position: absolute;
            top: rem(300);
            right: rem(640);
            cursor: pointer;
            transform: translateY(rem(200));
        }

        &-animate {
            animation: slideBtn 1.5s ease 4s forwards;
            @keyframes slideBtn {
                0% {
                    transform: translateY(rem(200));
                    opacity: 0;
                    z-index: 1;
                }
                100% {
                    transform: translateY(0);
                    opacity: 1;
                    z-index: 4;
                }
            }
        }
    }
    &-btn-prev {
        top: rem(360);
    }
}
.slider-btn {
    position: absolute;
    top: rem(200);
    transform: rotate(90deg);
    left: rem(30);
    z-index: 7;
}
.next {
    left: auto;
    right: rem(30);
}
