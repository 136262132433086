@import "../../scss/vars";
@import "../../scss/mixins";

.page {
    &__menu {
        position: fixed;
        left: 0;
        top: -100%;
        right: 0;
        height: 100vh;
        padding-bottom: rem(125);
        transition: all .5s;
        opacity: 0.5;
        visibility: hidden;
        z-index: -1;
        will-change: top;

        @include responsiveMax(767px) {
            padding: 0 0 0 12%;
            width: 100%;
            min-width: rem(240);
            max-width: rem(440);
            left: auto;
            display: block;
            right: -100%;
            top: 0;

            &--visible {
                display: block;
                right: 0;
            }
        }

        &-close-layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        &-inner {
            box-shadow: 0px 0px rem(50) rgba(0, 0, 0, 0.2);
            background: $menu_bg;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: rem(35);
            overflow: hidden;

            @include responsiveMax(991px) {
                padding-bottom: rem(40);
                overflow: visible;
            }

            @include responsiveMax(767px) {
                padding: rem(100) 0;
                display: flex;
                flex-direction: column;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: rem(150);
                    background: linear-gradient(180deg, rgba(62, 62, 83, 0.00) 0%, #3E3E53 100%);
                }
            }
        }

        &-nav {
            overflow: hidden;

            @include responsiveMin(992px) {
                flex: 1;
                display: flex;
                align-items: center;
                padding-top: rem(92);
            }

            @include responsiveMax(991px) {
                padding: rem(75) rem(80) rem(50) rem(50);
                height: 100%;
                flex: 1;
            }
            @include responsiveMax(767px) {
                padding: 0;
                height: 100%;
                overflow: hidden;
            }
        }

        &-bg {
            display: none;

            @include responsiveMin(768px) {
                display: block;
                position: absolute;
                width: 68.75%;
                height: fit-content;
                transform: translateX(-50%);
                left: 50%;
                bottom: rem(30);
                z-index: -1;
            }

            @include responsiveMin(992px) {
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                bottom: 0;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &-icons {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            width: 100%;
            padding-right: rem(50);
            transform: translateY(100%);
            transition: all .4s;

            @include responsiveMax(991px) {
                display: none;
            }
        }

        &-controls {
            display: flex;
            align-items: flex-end;

            .controls {
                &-item {

                    @include responsiveMax(767px) {
                        margin-right: rem(25);
                    }

                    a,
                    button {
                        width: rem(50);
                        height: rem(50);
                        border-radius: 50%;
                        background: #fff;
                        border: none;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-decoration: none;
                        transition: all .3s;
                        position: relative;
                        box-shadow: 0 0 rem(50) rgba(0, 0, 0, 0.2);

                        &:before {
                            content: '';
                            display: block;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            transition: all .3s;
                            background: $footer-feedback-btn-bg;
                            opacity: 0;
                        }

                        &:hover {
                            background: transparent;

                            &:before {
                                opacity: 1;
                            }

                            i {
                                &:before {
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: #fff;

                                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                                        color: #fff;
                                    }
                                }
                            }
                        }

                        i {
                            position: relative;
                            z-index: 2;

                            &:before {
                                font-size: rem(18);
                                background: $footer-feedback-btn-bg;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;

                                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                                    color: rgba(255, 87, 77, 1);
                                    background: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .main-projects {
            flex: 1;

            @include responsiveMax(991px) {
                display: none;
            }

            &__logos {
                display: flex;
                align-items: flex-end;
                justify-content: space-around;

                li {
                    a {
                        transition: all .3s;
                        cursor: pointer;
                        text-decoration: none;
                        opacity: .3;

                        &:hover {
                            opacity: 1;
                            img {
                                transform: scale(1.01);
                            }
                        }

                        img {
                            max-width: 100%;
                            transition: all .3s;
                        }
                    }
                }
            }
        }

        .main-nav {
            padding: 0 rem(422) 0 rem(450);
            transition: all .4s;

            &--submenu-opened {
                @include responsiveMax(767px) {
                    transform: translateX(-100%);
                }
            }

            @include responsiveMax(991px) {
                padding: 0;
                flex: 1;
                width: 100%;
                height: 100%;
                position: relative;
                display: flex;
                align-items: center;
            }

            @include responsiveMax(767px) {
                justify-content: center;
                flex-direction: column;
            }

            &__panel {
                width: rem(250);

                @include responsiveMax(991px) {
                    border-right: 1px solid transparent;
                }

                @include responsiveMax(767px) {
                    width: 100%;
                    padding: 0 rem(30) 0 rem(50);
                    border-right: none;
                }

                & > ul {

                    & > li {
                        margin-top: rem(40);
                        position: relative;
                        display: flex;
                        align-items: center;
                        transform: translateX(rem(-120));
                        opacity: 0;
                        transition: none;

                        @include responsiveMax(767px) {
                            margin-top: rem(25);
                            position: static;
                        }

                        & > a {
                            color: #fff;
                            text-decoration: none;
                            text-transform: uppercase;
                            font-size: rem(24);
                            line-height: 1;
                            padding: rem(4) rem(16) rem(2);
                            display: block;
                            font-weight: 700;
                            position: relative;
                            width: 100%;

                            @include responsiveMax(767px) {
                                font-size: rem(18);
                            }

                            &:before {
                                content: '';
                                display: block;
                                width: rem(6);
                                height: 100%;
                                background: #fff;
                                opacity: .1;
                                position: absolute;
                                top: 0;
                                left: 0;
                                transition: all .4s;

                                @include responsiveMax(767px) {
                                    width: rem(4);
                                }
                            }
                        }

                        ul {
                            display: flex;
                            position: absolute;
                            left: 100%;
                            top: 0;
                            padding-left: rem(100);
                            flex-wrap: wrap;
                            width: rem(750);
                            height: 0;
                            overflow: hidden;
                            visibility: hidden;
                            z-index: -1;
                            opacity: 0;
                            transition: all .4s;
                            transform: translateX(rem(-30));

                            li {
                                flex: 0 0 50%;
                                margin-bottom: rem(40);

                                &:last-of-type {
                                    margin-bottom: 0;
                                }

                                a {
                                    display: flex;
                                    align-items: center;
                                    color: #fff;
                                    transition: all .3s;
                                    text-decoration: none;

                                    span {
                                        padding-left: rem(20);
                                        font-weight: bold;
                                        font-size: rem(18);
                                        line-height: rem(21);
                                        display: inline-block;
                                        transition: all .3s;
                                    }

                                    &:hover {

                                        span {
                                            transform: translateX(rem(-6));
                                        }
                                    }
                                }
                            }

                            &.submenu {
                                &__referenzen {
                                    li {
                                        &:nth-child(even) {
                                            flex: 0 0 30%;
                                        }

                                        &:nth-child(odd) {
                                            flex: 0 0 65%;
                                        }
                                    }
                                }

                                &__uber-uns {
                                    li {
                                        flex: 0 0 100%;
                                    }
                                }

                                &__service {
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: space-between;

                                    li {
                                        flex: 0 0 48%;
                                    }
                                }
                            }
                        }

                        .show-submenu {
                            display: none;

                            @include responsiveMax(991px) {
                                display: block;
                                background: transparent;
                                border: none;
                                width: rem(65);
                                color: #fff;

                                i {
                                    &:before {
                                        color: #fff;
                                        font-size: rem(20);
                                    }
                                }
                            }

                            @include responsiveMax(767px) {
                                i {
                                    &:before {
                                        font-size: rem(16);
                                    }
                                }
                            }
                        }

                        &:hover {
                            @include responsiveMin(992px) {
                                & > a {
                                    &:before {
                                        width: 100%;
                                    }
                                }

                                & > ul {
                                    height: auto;
                                    visibility: visible;
                                    overflow: visible;
                                    z-index: 1;
                                    opacity: 1;
                                    transform: translateX(0);
                                }
                            }
                        }

                        &.has-submenu {
                            a {
                                @include responsiveMax(991px) {
                                    border-right: 1px solid rgba(255, 255, 255, .1);
                                }
                            }
                        }
                    }
                }

                &-title {
                    display: inline-block;
                    font-size: rem(24);
                    line-height: rem(28);
                    text-transform: uppercase;
                    color: #FFFFFF;
                    opacity: 0;
                    position: relative;
                    z-index: 1;
                    font-weight: 700;
                    padding: 0 rem(16);
                    transform: none;

                    @include responsiveMax(767px) {
                        font-size: rem(18);
                    }

                    &:before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        opacity: .1;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                &-bgImage {
                    position: absolute;
                    top: 2%;
                    width: 90%;
                    height: fit-content;
                    transform: translateX(-50%);
                    left: 50%;
                    z-index: -1;

                    @media screen and (min-height: 736px) {
                        top: 4%;
                    }

                    @media screen and (min-height: 812px) {
                        top: 8%;
                    }

                    @include responsiveMin(768px) {
                        display: none;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                &-bgText {
                    font-weight: 900;
                    font-size: rem(60);
                    line-height: rem(60);
                    color: #FFFFFF;
                    opacity: 0.02;
                    text-align: center;
                    text-transform: uppercase;
                    display: none;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    z-index: -1;

                    &--small {
                        font-size: rem(36);
                        line-height: rem(28);
                    }

                    @include responsiveMax(767px) {
                        display: block;
                    }
                }
            }

            &__sub-panels {
                display: flex;
                flex: 1;
                width: 100%;
                transition: all .4s ease-in-out;

                @include responsiveMin(992px) {
                    display: none;
                }

                @include responsiveMax(767px) {
                    position: absolute;
                    top: 0;
                    left: 100%;
                    height: 100%;
                }
            }

            &__sub-panel {
                padding-left: rem(50);
                display: none;

                @include responsiveMax(767px) {
                    padding: 0 rem(50);
                }

                &--active {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                & > ul {
                    @include responsiveMax(767px) {
                        overflow: scroll;
                        border-left: 1px solid rgba(255, 255, 255, .1);
                        padding-left: 20px;
                    }

                    & > li {
                        margin-bottom: rem(30);

                        @include responsiveMax(767px) {
                            margin-bottom: rem(20);
                        }

                        &:last-child {
                            margin-bottom: rem(50);
                        }

                        a {
                            display: flex;
                            align-items: center;
                            text-decoration: none;
                            color: #fff;
                            font-size: rem(18);
                            line-height: 1;
                            font-weight: bold;
                            transition: all .4s;
                            cursor: pointer;

                            @include responsiveMax(767px) {
                                font-size: rem(12);
                            }

                            span {
                                padding-left: rem(20);

                                @include responsiveMax(767px) {
                                    padding: 0 0 0 rem(10);
                                }
                            }
                        }

                        &.parent-link {
                            display: none;

                            @include responsiveMax(767px) {
                                display: block;
                            }
                        }
                    }
                }
            }

            &__prev-panel {
                display: none;

                @include responsiveMax(767px) {
                    display: block;
                    padding-bottom: rem(25);

                    i {
                        &:before {
                            color: #fff;
                        }
                    }
                    span {
                        padding-left: rem(10);
                        font-weight: bold;
                        font-size: rem(18);
                        line-height: rem(21);
                        text-transform: uppercase;
                        color: #FFFFFF;
                    }
                }
            }
        }

        &--visible {
            top: 0;
            opacity: 1;
            visibility: visible;
            z-index: 99;

            .page {
                &__menu {
                    &-icons {
                        transform: translateY(0);
                        transition-delay: .1s;
                    }
                }
            }

            .main-nav {
                &__panel {
                    & > ul {
                        & > li {
                            transform: translateX(0);
                            opacity: 1;
                            transition: all .4s;
                        }
                    }

                    @include responsiveMax(991px) {
                        transition: border .1s;
                        transition-delay: 1.1s;
                        border-color: rgba(255, 255, 255, .1);
                    }

                    &-title {
                        transition: opacity .3s;
                        transition-delay: .3s;
                        opacity: .3;
                    }
                }
            }
        }
    }
}