@import "../../scss/vars";
@import "../../scss/mixins";


//desktop
.faqBlock {
    background: #3E3E53;
    padding-bottom: rem(20);
    
    @include responsiveMin(992px) {
        display: flex;
        padding-bottom: 0;
        box-shadow: 0px 0px rem(50) rgba(0, 0, 0, 0.2);
    }

    &--left {
        position: relative;
        padding: rem(30) rem(20);

        @include responsiveMin(992px) {
            width: 50%;
            padding: rem(100) rem(150);
        }
    }

    &__title {
        font-weight: $m_h2_font_weight;
        font-size: rem($m_h2_font_size);
        line-height: rem($m_h2_line_height);
        text-transform: uppercase;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: rem(10);

        @include responsiveMin(992px) {
            font-size: rem($d_h2_font_size);
            line-height: rem($d_h2_line_height);
            text-align: left;
            margin-bottom: rem(20);
        }
    }

    &__subtitle {
        font-size: rem($m_text_font_size);
        line-height: rem($m_text_line_height);
        text-align: center;
        color: #FFFFFF;

        @include responsiveMin(992px) {
            font-size: rem($d_text_font_size);
            line-height: rem($d_text_line_height);
            text-align: left;
            margin-bottom: rem(140);
        }
    }

    &__bg-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgba($color: #fff, $alpha: 0.04);
        font-weight: 900;
        font-size: rem(96);
        line-height: 90%;

        @include responsiveMin(992px) {
            top: rem(100);
            left: rem(150);
            transform: none;
            font-size: rem(230);
            line-height: 1;
        }
    }

    &--right {
        padding: rem(20) rem(20) rem(180) rem(20);
        background: #fff;
        
        @include responsiveMin(992px) {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: rem(150);
        }
    }

    &__item {
        border-bottom: 6px solid rgba($color: #3E3E53, $alpha: 0.06);

        &.active {
            .question i {
                &:after {
                    transform: translate(-50%, -50%) rotate(90deg);
                }
            }
        }
      
        &:first-of-type {
            .question {
                padding-top: 0;
            }
        }
      
        .question {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: rem(20) 0 rem(14) 0;
            cursor: pointer;
        
            h3 {
                flex: 1;
                color: #767994;
                font-size: rem(18);
                margin-right: rem(20);

                @include responsiveMin(992px) {
                    font-size: rem(24);
                }
            }
        
            i {
                position: relative;
                width: rem(20);
                height: rem(20);

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    border-radius: rem(0.5);
                    transform: translate(-50%, -50%);
                    background: $main-gradient;
                    transition: 0.5s;
                }
        
                &:before {
                    width: 100%;
                    height: rem(2);
                }
        
                &:after {
                    width: rem(2);
                    height: 100%;
                    transform-origin: center;
                }
            }
        }
      
        .answerWrapper {
            overflow: hidden;
            transition: 0.5s;
        }
      
        .answer {
            color: #3E3E53;
            font-size: rem(14);
            padding-bottom: rem(14);
        
            @include responsiveMin(992px) {
                font-size: rem(16);
            }

            :first-child {
                margin-top: 0;
            }

            :last-child {
                margin-bottom: 0;
            }

            p, ul {
                margin: rem(10) 0;

                a {
                    color: #ff4d66;
                    text-decoration: underline;
                    text-decoration-color: #ff4d66;

                    &:hover {
                        text-decoration-color: transparent;
                    }
                }
            }

            h3 {
                font-size: rem($m_h3_font_size);
                line-height: rem($m_h3_line_height);
                margin-top: rem(20);

                @include responsiveMin(992px) {
                    font-size: rem($d_h3_font_size);
                    line-height: rem($d_h3_line_height);
                    margin-left: rem(300);
                    margin-top: rem(50);
                }
            }

            ul {
                li {
                    position: relative;
                    padding-left: rem(20);

                    @include responsiveMin(992px) {
                        padding-left: rem(25);
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: rem(10);
                        left: rem(9);
                        width: 3px;
                        height: 3px;
                        background: currentColor;
                        border-radius: 50%;

                        @include responsiveMin(992px) {
                            top: rem(11);
                            left: rem(10);
                        }
                    }
                }
            }
        }
    }

    &__icon {
        width: 100%;
        max-width: rem(320);
        margin: rem(-180) auto 0;

        @include responsiveMin(992px) {
            margin: 0;
        }
    }
}
