@import "../../../../scss/vars";
@import "../../../../scss/mixins";
@import "../../Animation//Animation.scss";

.content__img__wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: rem(110);
    height: rem(117);

    @include responsiveMin(992px) {
        width: rem(236);
        height: rem(250);
    }

    .content__img {
        width: 100%;
        position: absolute;
        display: block;
        overflow: hidden;
        filter: drop-shadow(0 5px 30px rgba(0, 0, 0, 0.3));

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            top: 0;
            left: 0;
        }

        @include responsiveMin(992px) {
            filter: drop-shadow(0 20px 70px rgba(0, 0, 0, 0.3));
        }

        &-logo {
            width: 60%;
            z-index: 1;

            &--second {
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .one {
        animation: gearRotate 5s infinite linear;
        animation-delay: -0.05s;

        @include responsiveMin(992px) {
            animation-delay: 0.95s;
        }
    }

    .two {
        animation: gearRotateTwo 5s infinite linear;
    }

    .three {
        animation: gearRotate 5s infinite linear;
        animation-delay: -0.1s;
    }

    &--i1 {
        top: rem(50);
        width: rem(190);
        height: rem(200);

        @include responsiveMin(992px) {
            top: rem(135);
        }
    }

    &--i2 {
        top: rem(25);

        @include responsiveMin(992px) {
            top: rem(67);
        }
    }

    .logo-two {
        width: 30%;
    }
    .logo-one {
        width: 50%;
    }
}
