@import "../../../../scss/mixins.scss";
@import "../../../../scss/vars.scss";

.partner-banner {
    @include responsiveMin(992px) {
        position: relative;
        height: rem(650);
        overflow: visible;
    }

    &:before {
        background: #fff;
    }

    .banner-title {
        position: relative;
        z-index: 2;

        @include responsiveMax(991px) {
            margin-bottom: rem(30);
        }

        &__text {
            color: #3E3E53;

            @include responsiveMin(992px) {
                font-size: rem(48);
                line-height: rem(56);
            }
        }

        &__text-wrap {
            &--right-position {
                .banner-title__text {
                    font-size: rem(14);
                    line-height: rem(16);
                    font-weight: bold;
                    text-transform: none;
                    color: #3E3E53;

                    @include responsiveMin(992px) {
                        font-size: rem(24);
                        line-height: rem(31);
                    }

                    @include responsiveMax(767px) {
                        br {
                            display: none;
                        }
                    }
                }
            }
        }

        &__separator {
            &:before {
                background: #3E3E53;
            }
        }
    }

    &__image {
        display: block;
        width: calc(100% - 40px);
        margin: 0 auto;

        @include responsiveMin(992px) {
            position: absolute;
            top: rem(-80);
            right: rem(-50);
            width: rem(966);
            height: rem(760);
            margin: 0;
        }
    }

    .partner-gear {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        &__circle {
            position: absolute;
            animation: rotation 20s linear infinite reverse;

            &:nth-child(odd) {
                animation: rotation 20s linear infinite;
            }
        }

        &--klarna {
            width: rem(162);
            height: rem(162);
            top: rem(160);
            left: rem(16);

            .logo {
                width: rem(90);
            }

            .circle1 {
                width: rem(162);
            }

            .circle2 {
                width: rem(153);
            }

            .circle3 {
                width: rem(142);
            }

            .circle4 {
                width: rem(128);
            }

            .circle5 {
                width: rem(128);
            }

            .circle6 {
                width: rem(115);
            }

            .circle7 {
                width: rem(108);
            }
        }

        &--atrava {
            width: rem(345);
            height: rem(345);
            top: 0;
            left: rem(197);

            .logo {
                width: rem(200);
            }

            .circle1 {
                width: rem(345);
            }

            .circle2 {
                width: rem(325);
            }

            .circle3 {
                width: rem(304);
            }

            .circle4 {
                width: rem(272);
            }

            .circle5 {
                width: rem(270);
            }

            .circle6 {
                width: rem(244);
            }

            .circle7 {
                width: rem(228);
            }
        }

        &--liveChat {
            width: rem(128);
            height: rem(128);
            top: rem(165);
            right: rem(271);

            .logo {
                width: rem(70);
            }

            .circle1 {
                width: rem(128);
            }

            .circle2 {
                width: rem(121);
            }

            .circle3 {
                width: rem(114);
            }

            .circle4 {
                width: rem(101);
            }

            .circle5 {
                width: rem(100);
            }

            .circle6 {
                width: rem(92);
            }

            .circle7 {
                width: rem(85);
            }
        }

        &--usercentrics {
            width: rem(279);
            height: rem(279);
            top: rem(211);
            right: rem(0);

            .logo {
                width: rem(171);
            }

            .circle1 {
                width: rem(279);
            }

            .circle2 {
                width: rem(263);
            }

            .circle3 {
                width: rem(246);
            }

            .circle4 {
                width: rem(220);
            }

            .circle5 {
                width: rem(219);
            }

            .circle6 {
                width: rem(198);
            }

            .circle7 {
                width: rem(184);
            }
        }

        &--epoq {
            width: rem(196);
            height: rem(196);
            top: rem(310);
            left: rem(93);

            .logo {
                width: rem(110);
            }

            .circle1 {
                width: rem(196);
            }

            .circle2 {
                width: rem(185);
            }

            .circle3 {
                width: rem(174);
            }

            .circle4 {
                width: rem(155);
            }

            .circle5 {
                width: rem(154);
            }

            .circle6 {
                width: rem(139);
            }

            .circle7 {
                width: rem(130);
            }
        }

        &--unzer {
            width: rem(139);
            height: rem(139);
            bottom: rem(247);
            left: rem(311);

            .logo {
                width: rem(80);
            }

            .circle1 {
                width: rem(139);
            }

            .circle2 {
                width: rem(131);
            }

            .circle3 {
                width: rem(123);
            }

            .circle4 {
                width: rem(110);
            }

            .circle5 {
                width: rem(109);
            }

            .circle6 {
                width: rem(99);
            }

            .circle7 {
                width: rem(93);
            }
        }

        &--trustedShops {
            width: rem(196);
            height: rem(196);
            top: rem(300);
            right: rem(300);

            .logo {
                width: rem(110);
            }

            .circle1 {
                width: rem(196);
            }

            .circle2 {
                width: rem(185);
            }

            .circle3 {
                width: rem(174);
            }

            .circle4 {
                width: rem(155);
            }

            .circle5 {
                width: rem(154);
            }

            .circle6 {
                width: rem(139);
            }

            .circle7 {
                width: rem(130);
            }
        }

        &--findologic {
            width: rem(180);
            height: rem(180);
            bottom: rem(80);
            left: 0;

            .logo {
                width: rem(100);
            }

            .circle1 {
                width: rem(180);
            }

            .circle2 {
                width: rem(170);
            }

            .circle3 {
                width: rem(159);
            }

            .circle4 {
                width: rem(143);
            }

            .circle5 {
                width: rem(142);
            }

            .circle6 {
                width: rem(128);
            }

            .circle7 {
                width: rem(120);
            }
        }

        &--mollie {
            width: rem(160);
            height: rem(160);
            bottom: rem(98);
            left: rem(200);

            .logo {
                width: rem(80);
            }

            .circle1 {
                width: rem(160);
            }

            .circle2 {
                width: rem(151);
            }

            .circle3 {
                width: rem(141);
            }

            .circle4 {
                width: rem(126);
            }

            .circle5 {
                width: rem(126);
            }

            .circle6 {
                width: rem(114);
            }

            .circle7 {
                width: rem(107);
            }
        }

        &--jentis {
            width: rem(251);
            height: rem(251);
            bottom: 0;
            right: rem(343);

            .logo {
                width: rem(150);
            }

            .circle1 {
                width: rem(251);
            }

            .circle2 {
                width: rem(237);
            }

            .circle3 {
                width: rem(222);
            }

            .circle4 {
                width: rem(198);
            }

            .circle5 {
                width: rem(197);
            }

            .circle6 {
                width: rem(178);
            }

            .circle7 {
                width: rem(166);
            }
        }

        //TODO: Remove after approving nexi logo
        //&--nets {
        //    width: rem(139);
        //    height: rem(139);
        //    bottom: rem(146);
        //    right: rem(202);
        //
        //    .logo {
        //        width: rem(80);
        //    }
        //
        //    .circle1 {
        //        width: rem(139);
        //    }
        //
        //    .circle2 {
        //        width: rem(131);
        //    }
        //
        //    .circle3 {
        //        width: rem(123);
        //    }
        //
        //    .circle4 {
        //        width: rem(110);
        //    }
        //
        //    .circle5 {
        //        width: rem(109);
        //    }
        //
        //    .circle6 {
        //        width: rem(99);
        //    }
        //
        //    .circle7 {
        //        width: rem(93);
        //    }
        //}

        &--nexi {
            width: rem(139);
            height: rem(139);
            bottom: rem(146);
            right: rem(202);

            .logo {
                width: rem(80);
            }

            .circle1 {
                width: rem(139);
            }

            .circle2 {
                width: rem(131);
            }

            .circle3 {
                width: rem(123);
            }

            .circle4 {
                width: rem(110);
            }

            .circle5 {
                width: rem(109);
            }

            .circle6 {
                width: rem(99);
            }

            .circle7 {
                width: rem(93);
            }
        }

        &--adyen {
            width: rem(164);
            height: rem(164);
            bottom: rem(56);
            right: rem(36);

            .logo {
                width: rem(90);
            }

            .circle1 {
                width: rem(164);
            }

            .circle2 {
                width: rem(155);
            }

            .circle3 {
                width: rem(145);
            }

            .circle4 {
                width: rem(130);
            }

            .circle5 {
                width: rem(128);
            }

            .circle6 {
                width: rem(116);
            }

            .circle7 {
                width: rem(109);
            }
        }
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
