@import "../../../scss/vars";

@import "../../../scss/mixins";

.project-implementation {
  display: flex;
  flex-direction: column;
  position: relative;

  .rocket__start {
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    right: -1%;
    top: -80px;

    @include responsiveMin(992px) {
      top: rem(35);
      left: 59.5%;
      right: auto;
      width: rem(980);
      height: rem(300);
    }

    &__wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      transform: translateY(0);

      &--animate {
        animation: springBottom 0.6s ease-in-out 1s forwards;
      }
    }

    .rocket-start {
      position: absolute;
      pointer-events: none;

      &--animate {
        animation: rings 0.33s ease-in-out forwards;
      }

      &--1 {
        @include responsiveMin(992px) {
          top: rem(30);

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 50%;
            transform: translateX(-50%);
          }

          &--animate {
            animation: scale 0.5s ease 2.6s forwards;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              animation: ringsIE 0.33s ease-in-out 2.6s forwards;
            }
          }
        }

        @include responsiveMin(1901px) {
          width: rem(160);
        }
      }

      &--2 {
        @include responsiveMin(992px) {
          top: rem(26);

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 50%;
            transform: translateX(-50%);
          }

          &--animate {
            animation: rings 0.33s ease-in-out 2.5s forwards;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              animation: ringsIE 0.33s ease-in-out 2.5s forwards;
            }
          }
        }

        @include responsiveMin(1901px) {
          width: rem(169);
        }
      }

      &--3 {
        @include responsiveMin(992px) {
          top: rem(24);

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 50%;
            transform: translateX(-50%);
          }

          &--animate {
            animation: rings 0.33s ease-in-out 2.4s forwards;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              animation: ringsIE 0.33s ease-in-out 2.4s forwards;
            }
          }
        }

        @include responsiveMin(1901px) {
          width: rem(226);
        }
      }

      &--4 {
        @include responsiveMin(992px) {
          top: rem(23);

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 50%;
            transform: translateX(-50%);
          }

          &--animate {
            animation: rings 0.33s ease-in-out 2.3s forwards;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              animation: ringsIE 0.33s ease-in-out 2.3s forwards;
            }
          }
        }

        @include responsiveMin(1901px) {
          width: rem(256);
        }
      }

      &--5 {
        @include responsiveMin(992px) {
          top: rem(20);

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 50%;
            transform: translateX(-50%);
          }

          &--animate {
            animation: rings 0.33s ease-in-out 2.2s forwards;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              animation: ringsIE 0.33s ease-in-out 2.2s forwards;
            }
          }
        }

        @include responsiveMin(1901px) {
          width: rem(290);
        }
      }

      &--6 {
        @include responsiveMin(992px) {
          top: rem(21);

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 50%;
            transform: translateX(-50%);
          }

          &--animate {
            animation: rings 0.33s ease-in-out 2.1s forwards;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              animation: ringsIE 0.33s ease-in-out 2.1s forwards;
            }
          }
        }

        @include responsiveMin(1901px) {
          width: rem(350);
        }
      }

      &--7 {
        @include responsiveMin(992px) {
          top: rem(14);

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 50%;
            transform: translateX(-50%);
          }

          &--animate {
            animation: rings 0.33s ease-in-out 2s forwards;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              animation: ringsIE 0.33s ease-in-out 2s forwards;
            }
          }
        }

        @include responsiveMin(1901px) {
          width: rem(400);
        }
      }

      &--8 {
        @include responsiveMin(992px) {
          top: rem(10);

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 50%;
            transform: translateX(-50%);
          }

          &--animate {
            animation: rings 0.33s ease-in-out 1.9s forwards;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              animation: ringsIE 0.33s ease-in-out 1.9s forwards;
            }
          }
        }

        @include responsiveMin(1901px) {
          width: rem(460);
        }
      }

      &--9 {
        @include responsiveMin(992px) {
          top: rem(7);

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 50%;
            transform: translateX(-50%);
          }

          &--animate {
            animation: rings 0.33s ease-in-out 1.8s forwards;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              animation: ringsIE 0.33s ease-in-out 1.8s forwards;
            }
          }
        }

        @include responsiveMin(1901px) {
          width: rem(490);
        }
      }

      &--10 {
        @include responsiveMin(992px) {
          top: rem(6);

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 50%;
            transform: translateX(-50%);
          }

          &--animate {
            animation: rings 0.33s ease-in-out 1.7s forwards;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              animation: ringsIE 0.33s ease-in-out 1.7s forwards;
            }
          }
        }

        @include responsiveMin(1901px) {
          width: rem(581);
        }
      }

      &--11 {
        @include responsiveMin(992px) {
          top: rem(5);

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 50%;
            transform: translateX(-50%);
          }

          &--animate {
            animation: rings 0.33s ease-in-out 1.6s forwards;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              animation: ringsIE 0.33s ease-in-out 1.6s forwards;
            }
          }
        }

        @include responsiveMin(1901px) {
          width: rem(727);
        }
      }

      &--12 {
        @include responsiveMin(992px) {
          top: rem(10);

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 50%;
            transform: translateX(-50%);
          }

          &--animate {
            animation: rings 0.33s ease-in-out 1.5s forwards;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              animation: ringsIE 0.33s ease-in-out 1.5s forwards;
            }
          }
        }

        @include responsiveMin(1901px) {
          width: rem(860);
        }
      }

      &--13 {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;

        @include responsiveMin(992px) {
          top: rem(-165);
        }

        @include responsiveMin(1200px) {
          top: rem(-175);
        }

        @include responsiveMin(1500px) {
          top: rem(-190);
        }

        @include responsiveMin(1700px) {
          top: rem(-195);
        }

        @include responsiveMin(992px) {
          transform: translateY(0);

          &--animate {
            animation: pillar 0.33s linear 3s forwards;

            @include responsiveMin(1150px) {
              animation: none;
              animation: pillar1 0.33s linear 3s forwards;
            }

            @include responsiveMin(1310px) {
              animation: none;
              animation: pillar2 0.33s linear 3s forwards;
            }

            @include responsiveMin(1500px) {
              animation: none;
              animation: pillar3 0.33s linear 3s forwards;
            }

            @include responsiveMin(1850px) {
              animation: none;
              animation: pillar4 0.33s linear 3s forwards;
            }

            @include responsiveMin(2300px) {
              animation: none;
              animation: pillar5 0.33s linear 3s forwards;
            }

            @include responsiveMin(2900px) {
              animation: none;
              animation: pillar6 0.33s linear 3s forwards;
            }

            @include responsiveMin(3500px) {
              animation: none;
              animation: pillar7 0.33s linear 3s forwards;
            }

            svg:last-child {
              height: rem(452);
              animation: visibleElem 0.33s linear 4s forwards;

              @for $i from 1 through 30 {
                .sparks {
                  &:nth-child(#{$i}) {
                    animation-delay: $i * (1s / 60);
                  }
                }
              }

              .sparks {
                animation: stars 0.8s linear infinite;
              }
            }
          }
        }

        svg:first-child {
          position: absolute;
          bottom: 0;
          height: rem(600);
          width: auto;

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 0;
          }

          @include responsiveMin(1500px) {
            height: rem(500);
          }

          @media not all and (min-resolution: 0.001dpcm) {
            @supports (-webkit-appearance: none) {
              path {
                fill: rgba($color: #ff4d66, $alpha: 0.3);
              }
            }
          }
        }

        svg:last-child {
          position: absolute;
          opacity: 0;
          bottom: rem(70);

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      &--14 {
        top: rem(-10);
        margin-left: rem(-20);

        @include responsiveMin(992px) {
          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            left: 50%;
            transform: translateX(-50%);
          }

          &--animate {
            animation: rings 0.33s ease-in-out 1.4s forwards;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              animation: ringsIE 0.33s ease-in-out 1.4s forwards;
            }
          }
        }

        @include responsiveMin(1901px) {
          width: rem(980);
        }
      }
    }
  }

  &__wrapper {
    overflow: hidden;
    position: static;
    padding: 0 rem(20);
    margin-top: rem(165);

    @include responsiveMin(992px) {
      padding: 0 rem(155);
      margin-top: 0;
    }
  }

  &__items {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    margin-top: rem(80);

    @include responsiveMin(992px) {
      flex-direction: row;
      margin-top: rem(250);
    }
    
    .project-implementation__item-image {
      @include responsiveMax(991px) {
        margin-top: rem(30);
      }
    }

    &:nth-child(7) {
      .project-implementation__item-image {
        @include responsiveMax(991px) {
          margin-top: rem(60);
        }
      }
    }

    &:first-of-type {
      @include responsiveMin(992px) {
        align-items: flex-start;

        .project-implementation__item-image {
          > div {
            right: rem(10) !important;
            top: 0 !important;
            transform: initial !important;
          }
        }
      }
    }

    &:nth-child(even) {
      .project-implementation__item-image {
        > div {
          @include responsiveMin(992px) {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .project-implementation__search-engine {
          @include responsiveMin(992px) {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .project-implementation__item-text {
        order: 0;

        @include responsiveMin(992px) {
          order: 2;
          padding-left: rem(130);
        }
      }
    }

    &:nth-child(odd) {
      .project-implementation__item-image {
        > div {
          @include responsiveMin(992px) {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    &:nth-child(1) {
      margin-top: rem(30);
      
      @include responsiveMin(992px) {
        flex-direction: row;
        margin-bottom: 0;
        margin-top: rem(194);
      }

      .project-implementation__item-image {
        .project-implementation__gears {
          @include responsiveMin(992px) {
            height: rem(640);
            width: rem(235);
          }
        }
      }
    }

    &:nth-of-type(11) {
      @include responsiveMin(992px) {
        align-items: flex-start;

        .project-implementation__item-image {
          > div {
            right: rem(-155) !important;
          }
        }
      }
    }

    &:last-of-type {
      @include responsiveMin(992px) {
        padding-bottom: rem(250);
      }
    }
  }

  &__gear {
    position: relative;
    height: rem(402);
    max-width: rem(320);
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @include responsiveMin(992px) {
      position: absolute;
      height: rem(600);
      width: rem(500);
      max-width: none;
      margin-top: 0;
    }
  }

  &__gears {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;

    @include responsiveMin(992px) {
      margin-bottom: 0;
      align-items: flex-end;
      top: 0;
      flex: 1;
      margin-top: rem(-125);

      .content__img__wrapper {
        transform: translateY(500px);
        opacity: 0;
      }

      &-animate {
        .content__img__wrapper {
          z-index: 3;

          @include responsiveMin(992px) {
            animation: gare 1s ease 3s forwards;
          }
        }
      }
    }

    .content__img__wrapper--i3 {
      .content__img {
        animation: gearRotateTwo 5s infinite linear;
      }
    }

    .pillar {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      width: rem(130);
      height: rem(248);
      top: rem(110);
      z-index: -1;
      
      svg {
        width: 100%;
        height: 100%;
      }
      svg:last-child {
        position: absolute;
      }
    }

    .rocket-start--5 {
      position: absolute;
      top: 25%;
      max-height: rem(235);

      svg {
        width: rem(154);
        height: 100%;
      }
      @include responsiveMin(992px) {
        opacity: 0;
        max-height: rem(450);
        top: 5%;
        svg {
          width: rem(250);
        }
        &--animate {
          animation: visibleElem 0.5s ease 4s forwards;
        }
      }
    }
    &-animate {
      &:after {
        @include responsiveMin(992px) {
          animation: visibleElem 0.5s ease 4s forwards;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include responsiveMin(992px) {
      flex-basis: 50%;
    }

    .content__img__wrapper {
      &--i1 {
        z-index: 1;
        width: rem(100);
        height: rem(105);
        top: auto;

        @include responsiveMax(991px) {
          order: 1;
        }

        @include responsiveMin(992px) {
          width: rem(190);
          height: rem(200);
          z-index: 2;
          position: absolute;
          top: rem(440);
          left: rem(25);

          .content__img {
            animation: gearRotate 5s infinite linear;
          }
        }
      }

      &--i2 {
        display: none;
      }

      &--i3 {
        width: rem(124);
        height: rem(131);
        margin-bottom: rem(160);

        @include responsiveMin(992px) {
          margin-bottom: 0;
          position: absolute;
          width: rem(235);
          height: rem(250);
          top: 0;
          z-index: 2;
        }
      }

      &--4 {
        width: rem(191);
        height: rem(201);
        position: absolute;
        left: 0;

        @include responsiveMin(992px) {
          top: 0;
          width: rem(286);
          height: rem(300);
          opacity: 0;
          transform: translateY(rem(150));
        }

        .content__img {
          animation: gearRotate 5s infinite linear;
          animation-delay: 0.05s;

          @include responsiveMin(992px) {
            animation-delay: 0.02s;
          }
        }
      }

      &--5 {
        position: absolute;
        width: rem(191);
        height: rem(201);
        bottom: 0;
        right: 0;

        @include responsiveMin(992px) {
          bottom: 0;
          width: rem(286);
          height: rem(300);
          opacity: 0;
          transform: translateY(rem(150));
        }

        .content__img {
          animation: gearRotateTwo 5s infinite linear;
        }
      }
      &-animate {
        @include responsiveMin(992px) {
          animation: gare 1s ease forwards;
        }
      }
    }

    .content__img-logo--second {
      position: absolute;
      max-width: 65%;

      @include responsiveMin(1900px) {
        width: 65%;
      }
    }
  }

  &__product-cards {
    position: relative;
    width: 100%;
    height: 100%;
    width: rem(320);
    margin-left: auto;
    margin-right: auto;

    @include responsiveMin(992px) {
      width: rem(640);
      margin: 0;
      height: rem(660);
      z-index: 2;
    }

    .product-cards {
      position: relative;
      box-shadow: 0px 0px rem(50) rgba(0, 0, 0, 0.2);

      @include responsiveMin(992px) {
        transition: all 0.5s linear;
      }

      &--1 {
        width: rem(155);
        height: rem(160);
        margin-top: 5%;

        @include responsiveMin(992px) {
          width: rem(350);
          height: rem(356);
        }
      }

      &--2 {
        width: rem(133);
        height: rem(225);
        z-index: 1;
        margin-top: -55%;
        margin-left: 60%;

        @include responsiveMin(992px) {
          width: rem(300);
          height: rem(503);
        }
      }

      &--3 {
        width: rem(107);
        height: rem(175);
        margin-top: -35%;
        margin-left: 36%;

        @include responsiveMin(992px) {
          width: rem(240);
          height: rem(390);
        }
      }

      img {
        width: 100%;
      }
    }

    &__product-cards--mobile {
      width: 100%;
    }

    &--mobile {
      width: 100%;
      display: block;
      margin-top: rem(30);
    }
  }

  &__payment__methods {
    position: relative;
    max-width: rem(400);
    margin: 0 auto;
    // margin-top: -10%;
    width: 100%;
    pointer-events: none;

    // @include responsiveMin(600px) {
    //   margin-top: -5%;
    // }

    @include responsiveMin(992px) {
      margin: auto;
      width: auto;
      position: absolute;
      max-width: none;
      width: rem(740);
      height: rem(640);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__marketing-tracking {
    position: relative;
    width: 100%;
    min-height: rem(360);
    max-width: rem(320);
    margin-left: auto;
    margin-right: auto;

    @include responsiveMin(767px) {
      min-height: rem(560);
      width: 60%;
      max-width: none;
      // min-height: rem(460);
      margin: 0 auto;
      z-index: 2;
    }

    @include responsiveMin(992px) {
      width: 90%;
      margin: auto;
      width: rem(580);
      height: rem(640);
      min-height: 0;
      // margin-right: rem(130);
    }

    .content__img__wrapper {
      width: rem(150);
      height: rem(158);
      position: absolute;

      @include responsiveMin(767px) {
        width: rem(200);
        height: rem(208);
      }

      @include responsiveMin(992px) {
        width: rem(240);
        height: rem(250);
      }

      &--i1 {
        display: none;
      }

      &--i2 {
        top: 0;
        display: inline-flex;
      }

      &--i3 {
        top: 0;
        right: 0;
      }

      &--6 {
        bottom: 0;
        .content__img {
          animation: gearRotate 5s infinite linear;
          animation-delay: -0.3s;
        }
      }

      &--7 {
        width: rem(100);
        height: rem(105);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;

        @include responsiveMin(767px) {
          width: rem(150);
          height: rem(155);
        }

        @include responsiveMin(992px) {
          width: rem(190);
          height: rem(200);
        }

        .content__img {
          animation: gearRotateTwo 5s infinite linear;
        }
      }

      &--8 {
        right: 0;
        bottom: 0;

        .content__img {
          animation: gearRotate 5s infinite linear;
          animation-delay: -1.4s;
        }
      }
    }
  }

  .conversion-optimization {
    position: relative;
    min-height: rem(320);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: rem(20);
    width: rem(320);

    // @include responsiveMin(767px) {
    //   width: auto;
    //   min-height: rem(960);
    //   margin-bottom: rem(120);
    // }

    @include responsiveMin(992px) {
      margin: auto;
      min-height: auto;
      height: rem(640);
      width: rem(640);
    }

    &__item {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      &--i1 {
        margin-left: rem(36);

        // @include responsiveMin(767px) {
        //   margin: rem(75) 0 0 0;
        // }

        @include responsiveMin(992px) {
          margin: 0;
        }
      }

      &--i2 {
        align-self: flex-end;

        &--animate {
          opacity: 0;
          animation: visibleElem 0.3s ease 1.6s forwards;
        }
      }

      &--i3 {
        align-self: flex-start;
        margin-left: rem(128);

        @include responsiveMin(992px) {
          margin-left: rem(150);
        }

        &--animate {
          opacity: 0;
          animation: visibleElem .3s ease 2.5s forwards;
        }
      }
    }

    &__number {
      font-weight: 900;
      font-size: rem(55);
      line-height: 100%;
      color: #dddde4;
      opacity: 0;

      @include responsiveMin(992px) {
        font-size: rem(126);
      }

      &--1-animate {
        opacity: 1;
        animation: numberOptimization .3s ease 1s forwards;
      }

      &--2-animate {
        opacity: 1;
        animation: numberOptimization .3s ease 1.9s forwards;
      }

      &--3-animate {
        opacity: 1;
        animation: numberOptimization .3s ease 2.8s forwards;
      }
    }

    &__text {
      font-weight: bold;
      font-size: rem(6);
      line-height: rem(8);
      text-transform: uppercase;
      color: #3e3e53;

      @include responsiveMin(992px) {
        font-size: rem(15);
        line-height: rem(18);
      }
    }

    &__arrow {
      position: absolute;
      right: rem(30);
      transform: rotate(-11deg);

      p {
        svg {
          path {
            fill: #dddde4;
          }
        }
      }

      @include responsiveMin(992px) {
        right: rem(95);
      }

      &--1 {
        top: -65px;
        opacity: 0;

        &-animate {
          animation: visibleElem .3s ease 1.3s forwards;

            p {
              svg {
                path {
                  animation: arrowOptimization .3s ease 1.6s forwards;
                }
              }
            }
        }

        svg {
          width: rem(66) !important;

          @include responsiveMin(992px) {
            width: rem(142) !important;
            height: auto;
          }
        }

        @include responsiveMin(992px) {
          top: rem(20);
          right: rem(80);
        }
      }

      &--2 {
        right: rem(15);
        opacity: 0;

        &-animate {
          animation: visibleElem .3s ease 2.2s forwards;

            p {
              svg {
                path {
                  animation: arrowOptimization .3s ease 2.5s forwards;
                }
              }
            }
        }

        svg {
          width: rem(113);

          @include responsiveMin(992px) {
            width: rem(264);
            height: auto;
          }
        }

        @include responsiveMin(992px) {
          bottom: rem(30);
          right: rem(50);

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            bottom: rem(120);
          }
        }

        @include responsiveMin(1400px) {
          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            bottom: rem(200);
          }
        }
      }
    }

    &__img {
      position: absolute;

      &--hand {
        max-width: rem(162);
        left: rem(-20);
        top: rem(-20);

        @include responsiveMin(992px) {
          max-width: rem(370);
          left: rem(-160);
          top: 0;
          width: rem(369);
        }
      }

      &--phone {
        max-width: rem(58);
        height: auto;
        filter: drop-shadow(0px 0px rem(50) rgba(0, 0, 0, 0.2));
        bottom: rem(100);
        left: 0;
        right: 0;
        display: block;
        margin: auto;

        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            filter: none;
          }
        }

        @include responsiveMin(992px) {
          max-width: rem(168);
          bottom: rem(286);
          left: 20%;

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            bottom: rem(80);
          }
        }

        @include responsiveMin(992px) {
          left: 0;
          opacity: 0;
          will-change: transform;
          max-width: rem(141);
          top: rem(193);
          bottom: auto;

          &--animate {
            transform: translateX(-500%);
            animation: phoneEffect .3s ease-in-out 1.9s forwards;
            opacity: 1;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              transform: translateX(0);
              opacity: 1;
            }
          }
        }
      }

      &--phone-done {
        max-width: rem(58);
        height: auto;
        filter: drop-shadow(0px 0px rem(50) rgba(0, 0, 0, 0.2));
        bottom: -5%;
        left: 10%;

        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            filter: none;
          }
        }

        @include responsiveMin(992px) {
          max-width: rem(168);
          left: 5%;

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            bottom: 5%;
          }
        }

        @include responsiveMin(992px) {
          left: rem(-70);
          opacity: 0;
          will-change: transform;
          max-width: rem(141);
          bottom: 0;

          &--animate {
            transform: translateX(-500%);
            animation: phoneEffect .3s ease-in-out 2.8s forwards;
            opacity: 1;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              animation: none;
              transform: translateX(0);
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__newsletter-marketing {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-top: rem(-40);

    @include responsiveMin(992px) {
      margin-bottom: auto;
      margin-top: rem(-20);
      margin-right: rem(10);
      z-index: 2;
    }

    .wrapper-for-envelope {
      width: 100%;
      position: relative;
      top: rem(-30);

      @include responsiveMin(992px) {
        height: rem(660);
      }
    }

    .envelope {
      position: relative;
      width: rem(320);
      height: rem(352);
      overflow: hidden;
      margin: auto;

      @include responsiveMin(992px) {
        width: rem(600);
        height: rem(650);
        margin: 0 0 0 rem(140);

        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          margin-right: 0;
          margin-left: auto;
        }
      }

      &__top {
        position: absolute;
        left: 0;
        top: rem(9);
        width: 100%;
        transition: 0.2s;
        transform: translateY(37%) rotateX(180deg);
        transform-origin: bottom;
        z-index: 3;

        &-animate {
          animation: envelope 1s ease 2.5s forwards;
        }

        img {
          width: 100%;
        }
      }

      &__bottom {
        position: absolute;
        left: 0;
        bottom: rem(-37);
        z-index: 1;

        @include responsiveMin(992px) {
          bottom: rem(-75);
        }
      }

      &__back {
        position: absolute;
        left: 0;
        top: rem(-42);
        z-index: -1;

        @include responsiveMin(992px) {
          top: rem(-77);
        }
      }

      &__front {
        position: relative;
        z-index: 1;
      }

      &__body {
        position: absolute;
        bottom: rem(-3);
        right: 0;
        left: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }

      &__paper {
        position: absolute;
        transform: translateY(rem(100));
        transition: 0.2s;
        bottom: rem(103);
        z-index: 1;
        left: 3%;

        img {
          width: rem(300);
          border-radius: rem(20);
          filter: drop-shadow(0px 0px rem(10) rgba(0, 0, 0, 0.2));

          @include responsiveMin(992px) {
            width: rem(550);
          }
        }

        &-animate {
          animation: paper 1s ease 3s forwards;
        }

        @include responsiveMin(992px) {
          bottom: rem(190);
          transform: translateY(rem(180));
        }
      }

      &__logo {
        position: absolute;
        bottom: rem(21);
        left: rem(10);
        width: rem(60);
        height: rem(20);
        background: #ffffff;
        border-radius: rem(5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;

        @include responsiveMin(992px) {
          width: rem(217);
          height: rem(50);
          border-radius: rem(10);
          left: rem(29);
        }

        img {
          width: 80%;
        }
      }

      &__message {
        position: absolute;
        width: rem(25);
        height: rem(25);
        background: linear-gradient(
          315deg,
          #fc6076 0%,
          #ff4d66 48.44%,
          #ff6826 100%
        );
        top: 35%;
        right: -3%;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 4;
        transform: scale(0);

        span {
          font-weight: bold;
          font-size: 9.984px;
          line-height: 12px;
          color: #ffffff;

          @include responsiveMin(992px) {
            font-size: 24px;
            line-height: 28px;
          }

          @include responsiveMin(1900px) {
            font-size: rem(24);
          }
        }

        &-animate {
          animation: envelopeMessage 0.5s ease 2s forwards;
        }

        @include responsiveMin(992px) {
          width: rem(50);
          height: rem(50);
          top: 35%;
          right: -2%;
        }
      }
    }
  }

  &__hosting {
    max-width: rem(700);
    display: block;
    margin-right: rem(100);
    margin-top: rem(35);
    margin-bottom: rem(65);
    width: 100%;
    @include responsiveMin(992px) {
      margin-top: 0;
      margin-bottom: 0;
    }

    svg {
      height: rem(400);
      @include responsiveMin(992px) {
        height: rem(720);
      }
    }
  }

  &__process-optimization {
    position: relative;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    width: rem(320);
    height: rem(282);

    svg .anim * {
      stroke: url(#optimization0_linear);
    }

    #line {
      animation: reveal 2s ease-in-out 0s infinite;
    }

    @include responsiveMin(992px) {
      max-width: none;
      margin: auto;
      height: rem(540);
      width: rem(610);
      z-index: 2;
    }
  }

  .process-optimization {
    &__wrapper {
      height: 100%;

      @include responsiveMin(992px) {
        height: rem(570);
      }
    }

    &-schedule {
      position: relative;
      width: 68%;
      margin-top: -46%;
      margin-left: 6%;

      @include responsiveMin(992px) {
        margin-top: -51%;
      }

      svg {
        width: 100%;
        height: auto;

        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          height: rem(383);
        }
      }
    }

    &-rings {
      position: absolute;
      z-index: -1;
      display: block;

      &--1 {
        width: 15%;
        top: 43%;
        left: 16%;

        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          top: 60%;
        }
      }

      &--2 {
        width: 17%;
        left: 77%;
        top: 28%;

        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          top: 39%;
        }
      }

      &--3 {
        width: 11%;
        left: 40%;
        top: 67%;

        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          top: 95%;
        }
      }
    }

    &-cards {
      position: relative;
      display: block;
      z-index: 2;

      &--1 {
        width: 30%;
        z-index: 3;
      }

      &--2 {
        width: 51%;
        margin-top: -23%;
        margin-left: 27%;
      }

      &--3 {
        width: 27%;
        margin-left: 74%;
        margin-top: -8%;
      }
    }

    svg {
      @include responsiveMax(991px) {
        width: 100%;
        height: auto;
      }

      .gradient {
        animation: changeColor 1s ease infinite;
      }
    }
  }

  &__search-engine {
    position: relative;
    width: 105%;
    margin-left: rem(-60);
    max-width: rem(500);

    @include responsiveMin(992px) {
      margin-left: rem(-190);
      margin-right: rem(60);
      margin-top: 0;
      margin-bottom: 0;
      max-width: none;
      height: rem(640);
      width: rem(866);

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 100%;
        width: 100%;
      }
    }
  }

  &__landing {
    position: relative;
    width: 100%;
    height: rem(567);

    @include responsiveMax(991px) {
      margin-left: rem(-20);
    }

    @include responsiveMin(992px) {
      min-height: rem(920);
      height: rem(920);
      width: rem(850);
      z-index: 2;
      margin-top: rem(100);
    }

    &--1 {
      right: 0;
      top: 0;
      // transform: rotate(10deg);
      width: rem(250);

      // &-animate {
      //   animation: cardsEffect 1s ease-in-out forwards;
      // }
    }

    &--2 {
      top: rem(350);
      left: 26%;
      z-index: 3;
      // transform: rotate(100deg);
      width: rem(1203);

      // &-animate {
      //   animation: cardsEffect 1s ease-in-out 0.4s forwards;
      // }
    }

    &--3 {
      top: rem(104);
      left: 40%;
      z-index: 1;
      // transform: rotate(100deg);
      width: rem(506);

      // &-animate {
      //   animation: cardsEffect 1s ease-in-out 0.2s forwards;
      // }
    }

    &--4 {
      top: rem(210);
      left: 31%;
      z-index: 2;
      // transform: rotate(100deg);
      width: rem(636);

      // &-animate {
      //   animation: cardsEffect 1s ease-in-out 0.3s forwards;
      // }
    }

    &--5 {
      left: 54%;
      top: rem(30);
      // transform: rotate(100deg);
      width: rem(436);

      // &-animate {
      //   animation: cardsEffect 1s ease-in-out 0.1s forwards;
      // }
    }

    &__desktop {
      position: absolute;
      filter: drop-shadow(0px 0px rem(10) rgba(0, 0, 0, 0.2));
      border-radius: rem(20);
      will-change: transform;
      pointer-events: none;
    }

    &__mobile {
      position: relative;
      right: 0;
      margin-right: rem(-40);
      margin-left: auto;
      display: block;
      filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2));
    }
  }

  &__marketing-cards {
    position: relative;
    width: 100%;
    height: 100%;
    width: rem(320);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(60);

    @include responsiveMin(992px) {
      width: rem(751);
      margin: 0;
      height: rem(660);
      z-index: 2;
      margin-top: rem(-120);
      margin-bottom: auto;
    }

    .marketing-cards {
      position: relative;
      box-shadow: 0px 0px rem(50) rgba(0, 0, 0, 0.2);

      @include responsiveMin(992px) {
        transition: all 0.5s linear;
      }

      &--1 {
        width: rem(178);
        height: rem(251);
        margin-top: 0;

        @include responsiveMin(992px) {
          width: rem(417);
          height: rem(589);
        }
      }

      &--2 {
        width: rem(196);
        height: rem(78);
        z-index: 1;
        margin-top: -64%;
        margin-left: 38%;

        @include responsiveMin(992px) {
          width: rem(461);
          height: rem(183);
        }
      }

      &--3 {
        width: rem(94);
        height: rem(182);
        margin-top: -7.5%;
        margin-left: 60%;
        z-index: 2;

        @include responsiveMin(992px) {
          width: rem(220);
          height: rem(425);
        }
      }

      img {
        width: 100%;
      }
    }

    &__marketing-cards--mobile {
      width: 100%;
    }

    &--mobile {
      width: 100%;
      display: block;
      margin-top: rem(30);
    }
  }
}
