@import "../../../scss/vars";
@import "../../../scss/mixins";

.success-story {
    position: relative;
    overflow: hidden;

    @include responsiveMin(992px) {
        min-height: 65.71429rem;
    }

    &__text {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        z-index: 10;
        width: 45%;
        padding: rem(310) rem(4) rem(310) rem(100);

        &-head {
            padding: 0 rem(20) rem(12);
        }

        &-title {
            font-weight: 900;
            color: #3e3e53;
            font-size: rem(48);
            line-height: rem(56);
            text-align: left;
            margin: 0 0 rem(30);

            &--mobile {
                color: #3e3e53;
                font-size: rem(22);
                line-height: rem(26);
                font-weight: 900;
                margin: rem(105) 0 rem(73);
                margin: rem(105) 0 rem(20);
            }
        }

        &-description {
            font-size: rem(14);
            line-height: 150%;
            color: #3e3e53;
            margin: rem(20) rem(20) rem(10);

            @include responsiveMin(992px) {
                font-size: rem(16);
                margin: 0 rem(150) rem(30) 0;
            }
        }

        &-client {
            text-align: center;
            margin: 0 rem(20) rem(10);
            @include responsiveMin(992px) {
                text-align: left;
                margin: 0;
            }
            .client-position {
                font-size: rem(14);
                line-height: rem(16);
                color: #767994;
                margin-bottom: rem(7);
            }

            .client-name {
                font-weight: bold;
                font-size: rem(24);
                line-height: rem(28);
                color: #3e3e53;
            }
        }
    }

    &__type {
        position: relative;
        display: table;
        padding: 0 0 0 rem(100);
        margin: 0 0 rem(20) auto;
        font-size: rem(18);
        font-weight: 700;
        color: #3e3e53;
        text-align: right;

        @include responsiveMin(992px) {
            padding-left: rem(200);
            margin: 0 rem(30) rem(30) auto;
            font-size: rem(24);
        }

        &::before {
            content: '';
            position: absolute;
            margin: auto;
            left: 0;
            top: 0;
            bottom: 0;
            width: rem(50);
            height: rem(4);
            background-color: currentColor;
            
            @include responsiveMin(992px) {
                width: rem(150);
            }
        }
    }

    &__founder-photo {
        display: flex;
        justify-content: center;
        position: relative;
        margin-bottom: rem(30);

        @include responsiveMin(992px) {
            display: block;
            margin-bottom: rem(20);
        }

        &:before {
            content: '';
            display: block;
            height: 4px;
            width: 100%;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 0;
            background: #eee;
            z-index: -1;

            @include responsiveMin(992px) {
                display: none;
            }
        }

        img {
            display: block;
            width: 80px;
            height: auto;
            background: #fff;
            border: 4px solid #eee;
            border-radius: 50%;

            @include responsiveMin(992px) {
                width: rem(140);
                border: rem(5) solid #eee;
            }
        }
    }

    #imgAfter {
        display: none;

        @include responsiveMin(992px) {
            display: block;
        }
    }

    .view__content {
        &:before,
        &:after {
            display: none;
        }
    }

    &.transition {
        & * {
            transition: all 0.5s ease-in-out;
        }
    }

    &__content {
        position: relative;
        
        @include responsiveMin(992px) {
            pointer-events: none;
            position: relative;
            min-height: 65.71429rem;
            width: 100%;
            height: 100%;
            display: flex;
        }
    }

    &__item {
        @include responsiveMin(992px) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        &--left-col {
            z-index: 2;
            @include responsiveMax(991px) {
                background: #ffffff;
            }
        }

        &--right-col {
            overflow: hidden;
            min-height: rem(295);

            @include responsiveMax(991px) {
                background: #afca1e;
                position: relative;
            }
            @include responsiveMin(600px) {
                min-height: rem(295);
            }
        }

        &-body {
            position: relative;

            @include responsiveMin(992px) {
                text-align: left;
                padding: rem(310) rem(100);
            }
        }

        &-description {
            font-size: rem(14);
            line-height: 150%;
            color: #3e3e53;
            margin: rem(20) rem(20) rem(10);

            @include responsiveMin(992px) {
                font-size: rem(16);
                margin: 0 rem(150) rem(20) 0;
            }
        }

        &-client {
            text-align: center;
            margin: 0 rem(20) rem(10);
            @include responsiveMin(992px) {
                text-align: left;
                margin: 0;
            }
            .client-position {
                font-size: rem(14);
                line-height: rem(16);
                color: #767994;
                margin-bottom: rem(7);
            }

            .client-name {
                font-weight: bold;
                font-size: rem(24);
                line-height: rem(28);
                color: #3e3e53;
            }
        }

        &-image {
            position: absolute;
            top: rem(-52);
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            max-width: rem(300);

            img {
                width: 100%;
            }
        }
    }
    .images--back,
    .images--front {
        position: absolute;
        right: 0;
        bottom: -1%;
        max-width: 50%;
        img {
            width: 100%;
        }
    }
    .images--front {
        display: none;
        @include responsiveMin(992px) {
            display: block;
        }
    }

    .images--back {
        @include responsiveMin(670px) {
            max-width: 30%;
        }
        @include responsiveMin(992px) {
            max-width: 50%;
        }
    }

    .view {
        display: block;
        &__wrapper {
            &--before {
                @include responsiveMax(991px) {
                    width: 100% !important;
                }

                .view {
                    &__content {
                        background: #fff;
                    }
                }
            }
            &--after {
                display: block;
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 70%;
                text-align: center;

                .view__image {
                    position: absolute;
                    bottom: 0;
                    top: rem(140);
                    left: 0;
                    right: 0;
                    margin: auto;
                    
                    @include responsiveMin(992px) {
                        top: 0;
                    }
                }

                @include responsiveMin(992px) {
                    display: none;
                    position: relative;
                    z-index: 0;
                }
            }
        }

        @include responsiveMin(992px) {
            &__wrapper {
                overflow: hidden;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;

                &--before {
                    left: 0;
                    width: 50%;

                    .view {
                        &__content {
                            background: #ffffff;
                        }
                    }
                }

                &--after {
                    display: block;
                    right: 0;

                    .view {
                        &__content {
                            background: #afca1e;
                            background-size: cover;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }

            &__content {
                position: relative;
                padding: 9% 0 6%;
                width: 100vw;
                height: 100%;

                &--rotated-bg {
                    &:before,
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }

            &__image {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: rem(470);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transform-style: preserve-3d;
                perspective: rem(470);

                img {
                    width: 100%;
                    height: auto;
                    display: block;
                    margin-left: rem(-4);
                }
            }
        }
    }
    .scroll-down {
        position: absolute;
        top: rem(235);
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        @include responsiveMin(615px) {
            bottom: 0;
        }
        @include responsiveMin(992px) {
            z-index: 3;
            bottom: rem(30);
            top: auto;
        }
    }

    &__logo {
        max-width: 100%;
    }
}

.view__video--img {
    max-width: rem(40);
    max-height: rem(40);
    margin-bottom: rem(8);
    cursor: pointer;

    @include responsiveMin(992px) {
        margin-top: rem(50);
        max-width: rem(84);
        max-height: rem(84);
    }
}
.view__video--title {
    font-weight: bold;
    font-size: rem(18);
    line-height: rem(21);
    text-transform: uppercase;
    color: #afca0b;
    &-white {
        color: #ffffff;
    }
}

.modal__video {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s;
    &--open {
        z-index: 100;
        visibility: visible;
        opacity: 1;
    }

    .modal__video__open {
        width: 90%;
        
        @include responsiveMin(992px) {
            width: 50%;
            max-height: 90%;
        }
    }

    &--event {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
}
.modal__video__close {
    width: rem(20);
    height: rem(20);
    position: absolute;
    top: 10px;
    right: 10px;

    cursor: pointer;
    svg {
        fill: #ffffff;
    }
    @include responsiveMin(992px) {
        top: 10%;
        right: 20%;
        width: rem(30);
        height: rem(30);
    }
}
