@keyframes fumes {
    0% {
        transform: scale(0.5);
    }
    50% {
        transform: scale(1.1);
        background-color: transparent;
    }
    51% {
        transform: scale(1);
    }
    100% {
        background: #ffffff;
        transform: scale(1.5);
    }
}

@keyframes fumes2 {
    50% {
        transform: scale(1.4);
    }
}

@keyframes rings {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(220%);
        opacity: 0;
    }
    100% {
        transform: translateY(220%);
        opacity: 0;
    }
}

@keyframes ringsIE {
    0% {
        transform: translate(-50%, 0);
    }
    50% {
        transform: translate(-50%, 220%);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, 220%);
        opacity: 0;
    }
}

@keyframes gearRotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes gearRotateTwo {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

@keyframes envelope {
    0% {
        transform: translateY(37%) rotateX(180deg);
        z-index: 3;
    }
    100% {
        transform: translateY(38%) rotateX(0);
        z-index: 0;
    }
}

@keyframes envelopeMessage {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes paper {
    0% {
        transform: translateY(rem(185));
    }
    100% {
        transform: translateY(0);
        z-index: 1;
    }
}

@keyframes gare {
    0% {
        transform: translateY(rem(150));
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes cardsEffect {
    0% {
        transform: rotate(100deg);
    }
    100% {
        transform: rotate(0);
    }
}

@keyframes numberOptimization {
    0% {
        color: #dddde4;
    }
    100% {
        color: #fc6076;
    }
}

@keyframes arrowOptimization {
    0% {
        fill: #dddde4;
    }
    100% {
        fill: #787a95;
    }
}

@keyframes changePosition {
    0% {
        top: 20%;
    }
    100% {
        top: 240%;
    }
}

@keyframes pillar {
    0% { 
        top: -355px;
    }
    100% {
        top: 195px;
    }
}

@keyframes pillar1 {
    0% {
        top: -355px;
    }
    100% {
        top: 225px;
    }
}

@keyframes pillar2 {
    0% {
        top: -355px;
    }
    100% {
        top: 265px;
    }
}

@keyframes pillar3 {
    0% {
        top: -355px;
    }
    100% {
        top: 300px;
    }
}

@keyframes pillar4 {
    0% {
        top: -355px;
    }
    100% {
        top: 370px;
    }
}

@keyframes pillar5 {
    0% {
        top: -355px;
    }
    100% {
        top: 450px;
    }
}

@keyframes pillar6 {
    0% {
        top: -355px;
    }
    100% {
        top: 575px;
    }
}  

@keyframes pillar7 {
    0% {
        top: -355px;
    }
    100% {
        top: 690px;
    }
}


@keyframes springBottom {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes scale {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(0);
    } 
}

@keyframes visibleElem {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes stars {
    0%{
        transform: translateY(200px);
    }
    100%{
        transform: translateY(-60px);
    }
}
@keyframes reveal {
    from {
        stroke-dasharray: 0 570;
        stroke-dashoffset:  0;
      }
    
      to {
        stroke-dasharray: 570 0;
        stroke-dashoffset: 570 ;
      }
  }

@keyframes changeColor {
    from {
        fill: #ebebeb;
    }
    to {
        fill: url(#optimization1_linear);
    }
}