@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';


.shopware {
    position: relative;

    &__banner {
        background-image: url(./images/magento_background.png);
        background-repeat: no-repeat;
        background-size: cover;
        padding-left: rem(20);
        padding-right: rem(20);

        @include responsiveMin(992px) {
            display: flex;
            padding-left: 0;
            padding-right: 0;
        }

        @include responsiveMax(991px) {
            background-position-x: right;
        }
        
        &_left {
            @include responsiveMin(992px) {
                width: 50%;
            }

            &--wrapper {
                padding-top: rem(116);

                @include responsiveMin(992px) {
                    max-width: rem(686);
                    padding-top: rem(234);
                    margin-left: rem(150);
                    padding-bottom: rem(133);
                }

                h1 {
                    color: #3E3E53;
                    font-weight: 900;
                    font-size: rem($m_h1_font_size);
                    line-height: rem($m_h1_line_height);
            
                    margin-bottom: rem(10);

                    @include responsiveMin(992px) {
                        font-size: rem($d_h1_font_size);
                        line-height: rem($d_h1_line_height);
                        margin-bottom: rem(20);
                    }

                    span {
                        color: #20A1FF;
                    }
                }
                
                > p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: rem($m_text_font_size);
                    line-height: rem($m_text_line_height);
                    color: #3E3E53;
                    margin-bottom: rem(42);

                    @include responsiveMin(992px) {
                        font-size: rem($d_text_font_size);
                        line-height: rem($d_text_line_height);
                        margin-bottom: rem(20);
                    }
                }

                img {
                    width: rem(200);
                    
                    @include responsiveMax(991px) {
                        display: none;
                    }
                }
            }
        }

        &_right {
            padding-bottom: rem(20);

            @include responsiveMin(992px) {
                padding-bottom: 0;
                position: relative;
                width: 50%;
            }

            &--image {
                display: block;
                width: 100%;

                @include responsiveMin(992px) {
                    width: rem(610);
                    height: rem(565);
                    padding-top: rem(149);
                    margin-right: rem(150);
                    margin-bottom: rem(35);
                    margin-left: auto;
                }
            }

            &--background {
                width: rem(652);
                position: absolute;
                top: 0;
                right: rem(129);

                @include responsiveMax(991px) {
                    display: none;
                }
            }
        }
    }

    .articles__wrapper {
        justify-content: center;
        margin-bottom: rem(20);

        @include responsiveMin(992px) {
            margin-top: rem(50);
        }

        .articles__item {
            margin-left: 0;
            margin-top: rem(20);
        }
    }

    .categories__items {
        color: #3e3e53;
        flex-direction: column;
        padding: 0 rem(20);

        @include responsiveMin(992px) {
            margin-bottom: rem(60);
            padding: 0;
        }

        .categories__item--title {
            &:before {
                background: #3e3e53;
            }
        }
        a {
            color: #3e3e53;
        }
    }

    &__img {
        height: rem(300);
        object-fit: cover;
        height: 100%;

        @include responsiveMin(992px) {
            height: rem(240);
            width: rem(365);
        }
    }

    &__breadcrumbs {
        margin-bottom: rem(50);
        display: flex;

        @include responsiveMax(991px) {
            margin-bottom: rem(30);
        }

        .home-link {
            display: flex;
            align-items: center;

            svg {
                width: rem(24);
                height: rem(24);
            }
        }

        &_item {
            margin-left: rem(10);
            display: flex;
            font-size: rem(16);
            font-weight: 500;

            &--page {
                span {
                    text-decoration: underline;
                    background: linear-gradient(282.39deg, #FC6076 0%, #FF4D66 49.48%, #FF6826 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-decoration-color: #FC6076;
                }

                a {
                    text-decoration: none;

                    &:hover {
                        span {
                            text-decoration: none !important;
                            text-decoration-color: none !important;
                        }
                    }
                }
            }

            svg {
                margin-right: rem(10);
                width: rem(8);
                height: rem(12);
                margin-top: rem(5);
                min-width: rem(8);
                min-height: rem(12);

                @include responsiveMin(992px) {
                    margin-top: rem(6);
                }
            }

            span {
                color: #1B1B1B;
                font-weight: 500;
                font-size: rem(16);
                line-height: 150%;
                max-height: rem(24);
                overflow : hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                /* autoprefixer: off */
                -webkit-box-orient: vertical;
                /* autoprefixer: on */
            }
        }
    }

    &__content {
        color: #3e3e53;
        padding-top: rem(33);

        @include responsiveMin(992px) {
            display: flex;
            position: relative;
            justify-content: space-between;
            padding-top: rem(30);
        }

        ol {
            list-style: none;
            counter-reset: cupcake;
            padding: 0;
        }

        ol li a {
            counter-increment: cupcake;
        }

        ol li a:before {
            content: counters(cupcake, ".") ". ";
        }

        ol.contents {
            margin-top: 0;
            margin-bottom: 0;

            > li {
                &:nth-child(n+10) {
                    >a {
                        p {
                            margin-left: rem(25);
                        }
                    }

                    ol {
                        li {
                            a {
                                p {
                                    margin-left: rem(35);
                                }
                            }
                        }
                    }
                }

                > a {
                    text-decoration: none;
                    
                    p {
                        text-decoration: none;
                        font-weight: bold;
                        font-size: rem(16);
                        line-height: rem(25);
                        color: #3E3E53;
                        margin-left: rem(20);
                        padding-bottom: 0;
                        margin-bottom: rem(5);
                    }

                    &:before {
                        position: absolute;
                        left: rem(30);
                        text-decoration: none;
                        font-weight: bold;
                        font-size: rem(16);
                        line-height: rem(25);
                        color: #3E3E53;

                        @include responsiveMin(992px) {
                            left: rem(50);
                        }
                    }

                    &:hover {
                        color: #FC6076;

                        &:before {
                            color: #FC6076;
                        }

                        p {
                            color: #FC6076;
                        }
                    }
                }
                
                ol {
                    padding-left: rem(16);
                    
                    > li {
                        &:hover {
                            > a {
                                color: #FC6076;

                                &:before {
                                    color: #FC6076;
                                }
        
                                p {
                                    color: #FC6076;
                                }
                            }
                        }

                        > a {
                            position: relative;
                            text-decoration: none;

                            p {
                                text-decoration: none;
                                font-weight: 500;
                                font-size: rem(16);
                                line-height: rem(25);
                                color: #3E3E53;
                                margin-left: rem(30);
                                padding-bottom: 0;
                                margin-bottom: rem(5);
                            }

                            &::before {
                                position: absolute;
                                left: 0;
                                text-decoration: none;
                                font-weight: 500;
                                font-size: rem(16);
                                line-height: rem(25);
                                color: #3E3E53;
                            }
                        }
                    }
                }
            }
        }

        h1 {
            font-weight: 900;
            font-size: rem(24);
            line-height: rem(28);
            color: #181C2D;
            margin-bottom: rem(15);

            @include responsiveMin(992px) {
                font-size: rem(48);
                line-height: rem(56);
                margin-bottom: rem(20);
            }
        }

        h2 {
            font-weight: bold;
            font-size: rem(22);
            line-height: rem(26);
            color: #181C2D;
            margin-bottom: rem(30);
            margin-top: rem(10);

            @include responsiveMin(992px) {
                font-size: rem(32);
                line-height: rem(38);
            }
        }

        h3 {
            font-weight: 600;
            font-size: rem(20);
            line-height: rem(23);
            color: #181C2D;
            margin-bottom: rem(20);
            margin-top: rem(10);

            @include responsiveMin(992px) {
                font-size: rem(30);
                line-height: rem(35);
            }
        }

        h4 {
            font-weight: 600;
            font-size: rem(18);
            line-height: rem(21);
            color: #181C2D;
            margin-bottom: rem(20);

            @include responsiveMin(992px) {
                font-size: rem(24);
                line-height: rem(28);
            }
        }

        h2, h3, h4 {
            a {
                color: #181C2D;
                word-break: break-word;

                &:hover {
                    text-decoration: none;
                    color: #ff4d66;
                }
            }
        }

        .post-contents {
            box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
            margin-bottom: rem(40);
            padding: rem(20);
            background-color: #fff;

            @include responsiveMin(992px) {
                margin-bottom: rem(50);
                padding: rem(30);
            }

            .contents-title {
                font-style: normal;
                font-weight: bold;
                font-size: rem(18);
                line-height: rem(21);
                color: #181C2D;
                margin-bottom: rem(20);
                text-align: center;

                @include responsiveMin(992px) {
                    font-size: rem(24);
                    line-height: rem(28);
                }
            }

            > span {
                font-weight: bold;
                font-size: rem(18);
                line-height: rem(21);
                color: #181C2D;
                margin-bottom: rem(20);
                display: block;
                width: 100%;
                text-align: center;
            }
        }

        .wp-block-image {
            @include responsiveMax(991px) {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }

            figure {
                @include responsiveMax(991px) {
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
            }

            img {
                height: 100%;
                // filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.2));
                box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
                width: 100%;
                
                @include responsiveMax(991px) {
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
            }
        }

        p {
            font-size: rem(16);
            line-height: 170%;
            margin-bottom: rem(30);
            letter-spacing: -0.01em;
            color: #1B1B1B;

            @include responsiveMin(992px) {
                font-size: rem(18);
                line-height: rem(32);
            }

            img {
                height: 100%;
                max-width: 60%;
                box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
                margin: 20px !important;

                @include responsiveMax(760px) {
                    max-width: 100%;
                }

                @include responsiveMax(991px) {
                    width: 100% !important;
                    margin: rem(20) auto;
                    max-width: 50%;
                }

                @include responsiveMin(1920px) {
                    box-shadow: 0px 0px rem(25) rgba(0, 0, 0, 0.2);
                    margin: rem(20) !important;
                }
            }

            a {
                color: #ff4d66;
                word-break: break-word;

                &:hover {
                    text-decoration: none;
                }
            }

            img[style] {
                float: right;
                margin: rem(20) 0;

                @include responsiveMin(992px) {
                    margin: 0 0 rem(25) rem(25);
                }
            }

            > code {
                background: rgba(252, 96, 118, .1);
                color: #FC6076;
            }
        }

        .wp-block-image {
            text-align: center;
            margin: rem(10) 0 rem(40);

            @include responsiveMin(992px) {
                margin: rem(40) 0;
            }

            figcaption {
                margin-top: rem(10);
                line-height: 150%;
                font-size: rem(14);
                font-style: italic;

                @include responsiveMin(992px) {
                    margin-top: rem(20);
                    font-size: rem(16);
                }
            }

            img {
                display: block;
                margin: auto;
                max-width: 100%;
            }
        }

        .wp-block-quote {
            position: relative;
            margin: 0;
            margin-top: rem(54);
            margin-bottom: rem(45);
            width: fit-content;
            margin-left: auto;
            margin-right: auto;

            a {
                color: #ff4d66;

                &:hover {
                    text-decoration: none;
                }
            }

            p {
                padding: 0;

                strong {
                    font-weight: 500;
                    font-size: rem(20);
                    line-height: 170%;
                    color: #1B1B1B;
                    width: 100%;
                    display: block;
                    text-align: center;
                    font-style: italic;
        
                    @include responsiveMin(992px) {
                        font-size: rem(28);
                        line-height: 150%;
                    }
                }
            }

            cite {
                font-weight: normal;
                font-size: rem(16);
                line-height: 170%;
                color: #3E3E53;
                margin-left: auto;
                margin-right: 0;
                text-align: right;
                width: 100%;
                display: block;

                @include responsiveMin(992px) {
                    font-size: rem(18);
                    line-height: rem(32);
                }
            }
        }

        .schema-how-to-steps {
            margin-left: rem(6);
            padding: 0 rem(10);
            font-size: rem(14);

            @include responsiveMin(992px) {
                font-size: rem(16);
            }
        }

        &--left {
            width: rem(400);
            margin-left: rem(180);
            height: fit-content;
            background-color: #fff;
            position: relative;
            margin-bottom: rem(100);

            &.small {
                position: -webkit-sticky;
                position: sticky;
                top: rem(50);
            }

            @include responsiveMax(991px) {
                margin: 0;
                display: none;
            }

            .post-contents {
                padding: rem(20) rem(30);
                margin: 0;
                width: 100%;
                height: 100%;

                @include responsiveMin(992px) {
                    padding: rem(30) rem(50);
                }
            }

            span {
                font-weight: bold;
                font-size: rem(24);
                line-height: rem(28);
                color: #181C2D;
            }
        }

        &--inner {
            padding: 0 rem(20);
            position: relative;
            margin-bottom: rem(50);

            @include responsiveMin(760px) {
                padding: 0 rem(50);
            }

            @include responsiveMin(992px) {
                width: rem(760);
                padding: 0;
                padding-right: rem(40);
                padding-left: rem(40);
                overflow: hidden;
                margin-bottom: rem(100);
            }

            .infoblock {
                display: flex;
                justify-content: space-between;
                width: 100%;
                background: #FFFFFF;
                box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
                padding: rem(20);
                margin-top: rem(40);
                margin-bottom: rem(40);
                clear: both;

                @include responsiveMin(992px) {
                    padding: rem(50);
                }

                &:before {
                    content: "";
                    display: flex;
                    background-image: url("./images/info.svg");
                    background-size: cover;
                    width: rem(17);
                    height: rem(17);
                    margin-right: rem(5);
                    margin-top: rem(3);

                    @include responsiveMin(992px) {
                        margin-right: rem(15);
                        margin-top: rem(5);
                    }
                }

                p {
                    flex: 1;
                    position: relative;
                    font-style: normal;
                    font-weight: normal;
                    font-size: rem(16);
                    line-height: 170%;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    @include responsiveMin(992px) {
                        font-size: rem(18);
                    }
                }
            }

            .wp-block-code {
                overflow: visible;
                background: #FFFFFF;
                box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
                padding: rem(20) rem(11) rem(15) rem(15);

                @include responsiveMin(992px) {
                    padding: rem(50);
                }

                code {
                    font-size: rem(16);
                    line-height: 170%;
                    color: #3E3E53;
                    white-space: pre-wrap;
                    letter-spacing: -0.01em;

                    @include responsiveMin(992px) {
                        font-size: rem(18);
                        line-height: rem(32);
                    }
                }
            }

            .posting {
                .wp-block-columns {
                    display: flex;

                    @include responsiveMax(991px) {
                        &.block {
                            display: block;
                        } 
                    }
          
                    .wp-block-column {
                      flex: 1;

                      p, ol, ul, h2, h3, span {
                        @include responsiveMin(992px) {
                            padding: 0 rem(15);
                        }

                        &:first-child {
                            margin-top: 0;
                            padding-top: 0;
                        }
                      }

                      .wp-block-image {
                          margin: 0;

                            &.fullwidth {
                                @include responsiveMax(991px) {
                                    max-width: rem(600);
                                    margin-bottom: rem(30);
                                    margin-left: auto !important;
                                    margin-right: auto !important;
                                }

                                img {
                                    width: 100%; 
                                    height: 100%;
                                }
                            }

                            img {
                                box-shadow: none;
                                margin-left: auto !important;
                                margin-right: auto !important;
                            }
                        }
                    }
                }

                .diagram ~ .wp-block-columns {
                    @include responsiveMax(991px) {
                        display: block;
                    }
                }

                .wp-block-columns ~ .wp-block-columns {
                    margin-bottom: 30px;
                }

                .wp-block-table {
                    margin: 0;
                    margin-bottom: rem(30);
                    padding-bottom: rem(20);
                    overflow-x: auto;

                    figcaption {
                        margin-top: 10px;
                        font-family: Raleway;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 14px;
                        font-feature-settings: 'pnum' on, 'lnum' on;
                        color: #3E3E53;

                        a {
                            color: #ff4d66;
                        }

                        @include responsiveMin(992px) {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }

                    table {
                        width: 100%;

                        @include responsiveMax(991px) {
                            width: auto;
                            margin-left: auto;
                            margin-right: auto;
                        }

                        tbody {
                            tr {
                                td {
                                    border-right: 2px solid #D7D7D9;
                                    padding: rem(20) rem(30);
                                    width: fit-content; 
                                    font-family: Raleway, sans-serif;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: rem(18);
                                    line-height: rem(21);

                                    @include responsiveMax(991px) {
                                        padding: rem(20) rem(10);
                                    }

                                    &:last-child {
                                        border: none;
                                    }

                                    &.has-text-align-center {
                                        text-align: center;
                                    }

                                    img {
                                        max-width: 100%;
                                    }
                                }

                                &:nth-child(odd) {
                                    td {
                                        background: #F4F7FC;
                                        border-right: 2px solid #fff;
                                    }
                                }

                                &:first-child {
                                    td {
                                        position: relative;
                                        background: transparent;
                                        border: 0;
                                        border-bottom: 2px solid #EEEEEE;
                                        text-align: center;
                                        padding: rem(5) rem(30);
                                        font-weight: bold;
                                        
                                        &:not(:first-child) {
                                            &::before {
                                                content: '';
                                                display: block;
                                                position: absolute;
                                                left: 0;
                                                bottom: 0;
                                                height: 8px;
                                                width: 1px;
                                                background: #D7D7D9;
                                            }
                                        }

                                        &:not(:last-child) {
                                            &::after {
                                                content: '';
                                                display: block;
                                                position: absolute;
                                                right: 0;
                                                bottom: 0;
                                                height: 8px;
                                                width: 1px;
                                                background: #D7D7D9;
                                            }
                                        }
                                    }
                                }
                                
                                &:last-child {
                                    border-bottom: 2px solid #EEEEEE;
                                }
                            }
                        }
                    }
                }

                .sh-table {
                    table {
                        tr {
                            td {
                                padding: rem(20) rem(20) !important;

                                &:not(:first-of-type) {
                                    width: rem(153);
                                    min-width: rem(153);
                                }
                            }
                        }
                    }
                }

                .wp-block-button__link {
                    min-width: rem(290);
                    font-style: normal;
                    font-weight: bold;
                    font-size: rem(18);
                    line-height: rem(21);
                    color: #fff;
                    height: rem(60);
                    background: linear-gradient(315deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
                    padding: rem(19);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;
                    text-decoration: none;
                    text-transform: uppercase;
                    margin-bottom: rem(30);
                    transition: .3s;

                    &:hover {
                        background: linear-gradient(135deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
                    }
                }

                ol {
                    counter-reset: cupcake;

                    a {
                        color: #ff4d66;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    li {
                        counter-increment: cupcake;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 170%;
                        color: #1B1B1B;
                        letter-spacing: -0.01em;
                        margin-bottom: rem(10);
                        padding-left: rem(24);
                        position: relative;

                        @include responsiveMin(992px) {
                            font-size: rem(18);
                            line-height: rem(32);
                            padding-left: rem(26);
                        }

                        &:before {
                            font-weight: bold;
                            content: counters(cupcake, ".") ". ";
                            background: linear-gradient(315deg, #FF6826 0%, #FF4D66 48.44%, #FC6076 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            position: absolute;
                            left: rem(5);

                            @include responsiveMin(992px) {
                                left: rem(6);
                            }
                        }

                        &:nth-child(n+10) {
                            padding-left: rem(32);

                            @include responsiveMin(992px) {
                                padding-left: rem(34);
                            }
                        }

                        ol {
                            margin: rem(10) 0 !important;

                            li {
                                padding-left: rem(30);

                                @include responsiveMin(992px) {
                                    padding-left: rem(32);
                                }

                                &:before {
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }

            ul {
                margin-bottom: rem(30);

                a {
                    color: #ff4d66;

                    &:hover {
                        text-decoration: none;
                    }
                }

                li {
                    font-size: 16px;
                    line-height: 150%;
                    margin-bottom: rem(10);
                    position: relative;
                    padding-left: rem(29);
                    color: #1B1B1B;

                    @include responsiveMin(992px) {
                        font-size: rem(18);
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &:before {
                        content: "";
                        background-image: url("./images/list.svg");
                        background-size: cover;
                        background-repeat: no-repeat;
                        width: 4px;
                        height: 4px;
                        min-width: 4px;
                        min-height: 4px;
                        position: absolute;
                        left: rem(10);
                        top: rem(10);

                        @include responsiveMin(992px) {
                            top: rem(12);
                        }

                        @include responsiveMin(1920px) {
                            width: rem(4);
                            height: rem(4);
                            min-width: rem(4);
                            min-height: rem(4);
                        }
                    }

                    ul {
                        margin: rem(10) 0 !important;
                    }
                }
            }

            ol {
                list-style: none;
                counter-reset: cupcake;
                padding: 0;
            }
    
            ol li a {
                counter-increment: cupcake;
            }
            
            ol li a:before {
                content: counters(cupcake, ".") ". ";
            }

            ol.contents {
                padding: 0;
                margin-top: 0;
                margin-bottom: 0;

                & > li {
                    display: block;

                    & > a {
                        position: relative;

                        p {
                            margin-left: rem(20);
                            font-weight: bold;
                            font-size: rem(16);
                            line-height: rem(25);
                            color: #3E3E53;
                            letter-spacing: -0.01em;
                            margin-bottom: rem(5);

                            @include responsiveMin(992px) {
                                margin-bottom: rem(7);
                            }
                        }

                        &::before {
                            position: absolute;
                            left: 0;
                        }
                    }

                    & > ol {
                        & > li {
                            a {
                                position: relative;

                                p {
                                    margin-left: rem(30);
                                    font-weight: 500;
                                    font-size: rem(16);
                                    line-height: rem(25);
                                    letter-spacing: -0.01em;
                                    color: #3E3E53;
                                    margin-bottom: rem(5);

                                    @include responsiveMin(992px) {
                                        margin-bottom: rem(7);
                                    }
                                }
                            
                                &:before {
                                    position: absolute;
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }

            .socials {
                margin-top: rem(30);

                span {
                    display: block;
                    font-weight: bold;
                    font-size: rem(18);
                    line-height: rem(21);
                    color: #181C2D;
                    margin-bottom: rem(20);

                    @include responsiveMin(992px) {
                        font-size: rem(24);
                        line-height: rem(28);
                    }
                }
            }

            .post-socials {
                display: flex;

                button {
                    width: rem(38) !important;
                    height: rem(38) !important;
                    margin-right: rem(15);
                }

                a {
                    width: rem(38);
                    height: rem(38);
                    margin-right: rem(15);
                }

                svg {
                    width: 100%;
                    height: 100%;

                    rect {
                        transition: 0.3s;
                    }

                    &:hover {
                        rect {
                            fill: url(#paint1_linear);
                        }
                    }
                }
            }

            .post-tags {
                margin: rem(20) 0;

                @include responsiveMin(992px) {
                    margin: rem(80) 0 0;
                }
            }
        }

        &--right {
            margin-bottom: rem(50);
            background-color: #fff;
            margin-top: rem(50);
            
            @include responsiveMin(992px) {
                margin-right: rem(180);
                margin-left: 0;
                width: rem(400);
                height: fit-content;
                margin-top: 0;
            }

            &.small {
                position: -webkit-sticky;
                position: sticky;
                top: rem(50);
            }

            .technologies__mainlist {
                &_wrapper {
                  margin-left: rem(20);
                  margin-bottom: rem(10);
            
                  .swiper-container {
                    .swiper-wrapper {
                      .swiper-slide {
                        &:last-of-type {
                          margin-right: rem(20) !important;
                        }
                      }
                    }
                  }
                }
            
                &_title {
                  margin-bottom: rem(20);
                  font-weight: bold;
                  font-size: rem(22);
                  line-height: rem(26);
                  color: #181c2d;
                }
            
                &_item {
                  width: fit-content;
                  background: #fc6076;
                  padding: rem(10);
            
                  a {
                    text-decoration: none !important;
                  }
            
                  &--title {
                    color: #fff;
                    font-weight: bold;
                    font-size: rem(16);
                    line-height: rem(19);
                  }
                }
              }

            .technologies-title {
                font-style: normal;
                font-weight: bold;
                font-size: rem(18);
                line-height: rem(21);
                color: #181C2D;
                margin-bottom: rem(20);

                @include responsiveMin(992px) {
                    font-size: rem(24);
                    line-height: rem(28);
                    text-align: center;
                }
            }

            .technologies-list {
                display: flex;
                flex-direction: column;
  
                .technologies-item {
                  margin-top: rem(10);

                  a {
                    text-decoration: none;
                  }

                  &:first-of-type {
                      margin-top: 0;
                  }
                }
              }
  
              .technologies-item {
                display: inline;
                margin: 0;
  
                span {
                  height: 100%;
                  font-weight: bold;
                  font-size: rem(18);
                  line-height: rem(21);
                  padding: rem(2) rem(10);
                  position: relative;
                  color: #3e3e53;
  
                  &::before {
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: rem(4);
                    left: 0;
                    bottom: 0;
                    top: 0;
                    background: #3e3e53;
                    opacity: 0.3;
                    transition: all 0.5s ease;
  
                    @include responsiveMin(992px) {
                      width: rem(6);
                    }
                  }
  
                  &:hover::before {
                    width: 100%;
                  }
                }
              }
        }

        .certificates-sidebar {
            padding: rem(10);
            background: #3E3E53;

            &--inner {
                padding: rem(10);
                box-shadow: 0 0 0 rem(2) #fff;

                img {
                    width: 100%;
                    margin-bottom: rem(10);

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &_projects {
            padding-left: rem(20);
            padding-right: rem(20);
            margin-bottom: rem(50);

            @include responsiveMin(992px) {
                margin-bottom: rem(130);
                padding-left: rem(180);
                padding-right: rem(180);
            }

            &-wrapper {
                @include responsiveMin(992px) {
                    display: flex;
                    justify-content: center;
                }
            }

            &-title {
                font-style: normal;
                font-weight: 900;
                font-size: rem(20);
                line-height: rem(23);
                color: #181C2D;
                margin-bottom: rem(30);

                @include responsiveMin(992px) {
                    font-size: rem(36);
                    line-height: rem(42);
                    margin-bottom: rem(50);
                }
            }

            .projects-card {
                width: 100%;
                text-decoration: none;
                background: #fff;
                box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
                margin-bottom: 30px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                @include responsiveMin(992px) {
                    width: 33.3333%;
                    margin-bottom: 0; 
                    margin-right: 1%;
                    margin-left: 1%;

                    &:first-of-type {
                        margin-left: 0;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }
                }

                &.hidden {
                    display: none;
                }
        
                &--inner {
                    display: block;
                    position: relative;
                    height: 100%;
                    background: #fff;
                    box-shadow: 0px 0px rem(50) rgba(0, 0, 0, 0.2);
                    text-decoration: none;
                    z-index: 1;
                }
        
                &__top {
                    position: relative;
                    
                    
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.6);
                        backdrop-filter: blur(2px);
                        z-index: 1;
        
                        @include responsiveMin(1920px) {
                            backdrop-filter: blur(rem(2));
                        }
                    }
                }
        
                &__img {
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: rem(207);
                    object-fit: cover;
                    z-index: 0;
        
                    @include responsiveMin(992px) {
                        height: rem(356);
                    }
                }
        
                &__logo {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-height: rem(140);
                    margin: 0 auto;
                    z-index: 2;
        
                    @include responsiveMin(992px) {
                        max-height: rem(180);
                        min-width: rem(186);
                    }
        
                    img {
                        height: 100%;
                        width: 100%;
                        max-height: rem(140);

                        @include responsiveMin(992px) {
                            max-height: rem(180);
                            min-width: rem(186);
                        }
                    }
                }
        
                &__bottom {
                    justify-content: space-between;
                    align-items: flex-end;
                    padding: rem(20) rem(20);
        
                    @include responsiveMin(700px) {
                        min-height: rem(202);
                    }
        
                    @include responsiveMin(992px) {
                        padding: rem(20);
                    }
                }
        
                &__text-container {
                    color: #fff;
                    margin-bottom: rem(20);
        
                    @include responsiveMin(992px) {
                        margin-bottom: rem(15);
                    }
                }
        
                &__text {
                    display: flex;
                    margin-bottom: rem(10);
        
                    &:last-of-type {
                        margin-bottom: 0;
                    }
        
                    p {
                        font-size: rem(16);
                        line-height: 150%;
                        color: #353540;
                        word-break: break-word;
                    }
        
                    svg {
                        display: block;
                        min-width: rem(24);
                        min-height: rem(24);
                        max-width: rem(24);
                        max-height: rem(24);
                        margin-right: rem(5);
                    }
                }
        
                &__title {
                    font-weight: bold;
                    font-size: rem(18);
                    line-height: 100%;
                    margin-bottom: rem(15);
                }
        
                &__btn {
                    display: block;
                    width: fit-content;
                    margin-left: auto;
                    margin-right: 0;
                }
        
                &--link {
                    .projects-card__btn {
                        display: block;
                        position: relative;
                        width: fit-content;
                        margin-left: auto;
                        margin-right: 0;
                        display: block;
                        text-decoration: none;
        
                        &_text {
                            font-weight: bold;
                            font-size: rem(14);
                            line-height: rem(16);
                            color: #3E3E53;
                            padding: 0 rem(10);
                            position: relative;
        
                            &:before {
                                content: "";
                                position: absolute;
                                width: rem(5);
                                height: rem(17);
                                background: #3e3e53;
                                opacity: 0.2;
                                left: 0;
                                transition: all 0.5s ease;
                                transform: translateY(-50%);
                                top: 50%;
        
                                @include responsiveMin(992px) {
                                    height: rem(22);
                                }
                            }
        
                            @include responsiveMin(992px) {
                                font-size: rem(18);
                                line-height: rem(21);
                            }
                        }
                    }
                }
        
                &--end {
                    flex: 1;
        
                    .projects-card--inner {
                        padding-top: rem(125);
                        padding-bottom: rem(125);
                        width: 100%;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #3e3e53;
                        overflow: hidden;
                        box-shadow: none;
                        height: 100%;
                        text-decoration: none;
        
                        @include responsiveMin(992px) {
                            padding-top: rem(186);
                            padding-bottom: rem(186);
                        }
                    }
        
                    &__title {
                        font-weight: 900;
                        font-size: rem(20);
                        line-height: rem(23);
                        color: #ffffff;
                        text-transform: uppercase;
                        position: relative;
                        max-width: rem(101);
        
                        @include responsiveMin(992px) {
                            font-size: rem(36);
                            line-height: rem(42);
                            max-width: rem(182);
                        }
                    }
        
                    &__bg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 900;
                        font-size: rem(63);
                        line-height: 1;
                        text-align: center;
                        text-transform: uppercase;
                        color: #fff;
                        opacity: 0.01;
        
                        @include responsiveMin(992px) {
                            font-size: rem(96);
                        }
                    }
                }
            }
        }

        &__last-posts {
            padding-left: rem(20);
            padding-right: rem(20);

            @include responsiveMin(992px) {
                margin-bottom: rem(130);
                padding-left: rem(180);
                padding-right: rem(180);
            }

            .title-post {
                font-style: normal;
                font-weight: 900;
                font-size: rem(20);
                line-height: rem(23);
                color: #181C2D;
                margin-bottom: rem(30);

                @include responsiveMin(992px) {
                    font-size: rem(36);
                    line-height: rem(42);
                    margin-bottom: rem(50);
                }
            }

            .articles__wrapper {
                display: flex;
                flex-direction: row;
                margin: 0 !important;
                width: 100%;

                @include responsiveMin(992px) {
                    flex-wrap: wrap;
                }
            }

            .articles__item {
                margin-bottom: rem(50);
                margin-top: 0 !important;
                display: flex;
                background-color: transparent !important;
                padding: 0;

                &::before {
                    display: none;
                }

                &::after {
                    display: none;
                }

                @include responsiveMin(992px) {
                    margin-bottom: 0;
                    width: 31%;
                    margin-right: 3.5%;
                }

                &:last-child {
                    margin-right: 0;
                }

                &-link {
                    flex-direction: column;

                    .articles__img {
                        width: 100%;

                        @include responsiveMin(992px) {
                            height: rem(296);
                        }
                    }
                }

                .articles__content-excerpt {
                    max-height: rem(63);
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    /* autoprefixer: off */
                    -webkit-box-orient: vertical;
                    /* autoprefixer: on */

                    @include responsiveMin(992px) {
                        max-height: rem(72) !important;
                    }

                    p {
                        font-size: rem(14);
                        line-height: 150%;
                        margin-bottom: rem(10);
                        color: #1B1B1B;

                        @include responsiveMin(992px) {
                            font-size: rem(16);
                            font-weight: 600;
                            color: #3E3E53;
                        }
                    }
                }

                .articles__content {
                    &-item {
                        display: flex;
                        flex-direction: column;
                        padding-left: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    .title-post {
        font-weight: 900;
        font-size: rem(20);
        line-height: rem(23);
        margin-bottom: rem(30);
        color: #181C2D;
        margin-top: rem(50);

        @include responsiveMin(992px) {
            margin-bottom: rem(50);
            font-size: rem(36);
            line-height: rem(42);
            margin-top: rem(100);
        }
    }

    .markup {
        background: #f5f5f6;
        padding: rem(20) rem(15);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: rem(20);

        @include responsiveMin(992px) {
            margin: rem(40) 0;
            padding: rem(50);
            flex-direction: row;
        }

        div {
            @include responsiveMin(992px) {
                margin-right: rem(50);
            }
        }
        
        p {
            margin-bottom: rem(10);
            word-wrap: break-word;
            text-align: left;
            @include responsiveMin(992px) {
                margin-bottom: rem(30);
            }
        }

        button {
            border: none;
            width: rem(260);

            @include responsiveMin(400px) {
                width: rem(290);
            }

            @include responsiveMin(992px) {
                margin-bottom: 0;
            }
        }

        img {
            max-height: rem(250);
            @include responsiveMin(992px) {
                max-width: rem(250);
            }
        }
    }

    .red {
        color: #f66076;
        background: #f3e4e6;
    }

    .grey {
        background: #f5f5f6;
        padding: rem(20) rem(15);
        line-height: 150%;
        font-size: rem(14);
        margin: rem(10) 0 rem(20);
        
        @include responsiveMin(992px) {
            margin: rem(20) 0;
            padding: rem(50);
            font-size: rem(16);
        }
    }

    .wp-image {
        display: block;
        margin: 20px auto;
    }

    .fslightbox-container {
        background: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(rem(15));

        .lightbox-image-container {
            width: 80% !important;
        
            @include responsiveMin(992px) {
                width: rem(1109) !important;
                height: rem(742) !important;
            }

            img {
                max-width: 100%;
                max-height: 100%;
                display: block;
                margin: 0 auto;

                @include responsiveMin(992px) {
                    height: 100%;
                    object-fit: cover;
                }
    
            }

            span {
                font-style: italic;
                font-weight: normal;
                font-size: rem(16);
                line-height: rem(25);
                font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off, 'kern' off;
                color: #FFFFFF;
                margin-top: rem(20);
                display: block;
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .contact-block {
        position: relative;
        padding: 0 rem(40);
        margin: rem(40) 0;
        display: flex;
        flex-direction: column;
        width: calc(100% + 2.86rem);
        margin-left: -1.43rem;

        @media (min-width: 760px) {
            width: calc(100% + 7.14rem);
            margin-left: -3.57rem;
        }

        @include responsiveMin(992px) {
            flex-direction: row;
            padding: 0 rem(80);
            width: 100%;
            margin-left: 0;
        }

        .contact-block__bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: rem(331);
            background: #FFFFFF;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
            z-index: -1;
            display: flex;
            justify-content: center;

            @include responsiveMin(992px) {
                height: rem(150);
            }

            .contact_bg {
                width: 100%;
                height: fit-content;
                margin-bottom: 0;
                margin-top: auto;
                display: flex;
                justify-content: center;
                white-space: nowrap;
                font-weight: 900;
                font-size: rem(67);
                line-height: 100%;
                text-transform: uppercase;
                background: linear-gradient(315deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                opacity: 0.03;

                @include responsiveMin(992px) {
                    font-size: rem(127);
                }
            }
        }

        img {
            width: rem(270);
            margin-left: auto;
            margin-right: auto;

            @include responsiveMin(992px) {
                width: rem(231);
                margin-left: 0;
            }
        }

        a {
            padding: rem(18) rem(32);
            width: 100%;
            background: linear-gradient(315deg, #FC6076 0%, #FF4D66 48.44%, #FF6826 100%);
            font-weight: 700;
            font-size: rem(18);
            line-height: rem(21);
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            color: #FFFFFF;
            text-decoration: none;
            height: fit-content;
            align-self: flex-end;
            margin-bottom: rem(40);

            @include responsiveMin(992px) {
                width: fit-content;
                margin-left: auto;
                margin-right: 0;
                margin-bottom: rem(45);
                min-width: rem(230);
            }

            &:hover {
                i {
                    transform-origin: top center;
                    animation-name: swing;
                    animation-duration: 1s;
                    animation-fill-mode: both;
                }
            }

            i {
                margin-right: rem(10);
            }
        }
    }
}
