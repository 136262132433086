@import "../../../scss/vars";
@import "../../../scss/mixins";

/* START .not-found-page */

.not-found-page {
  position: relative;

  .sk-cube-grid .sk-cube {
    background-color: #fff;
  }

  @include responsiveMin(992px) {
    &:before,
    &:after {
      content: '';
      display: block;
      height: 100%;
      width: 50%;
      position: absolute;
      top: 0;
      z-index: -1;
    }

    &:before {
      left: 0;
      background: $main_gradient;
    }

    &:after {
      right: 0;
      background: #3E3E53;
    }
  }

  &.transition {
    & * {
      transition: all .5s ease-in-out;
    }
  }

  &__content {
    position: relative;

    @include responsiveMin(992px) {
      height: 100vh;
      display: flex;
      z-index: 2;
    }
  }

  &__col {
    flex: 0 0 50%;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &--left {
      padding: rem(200) 0 0 rem(200);

      @include responsiveMax(991px) {
        background: $main_gradient;
        padding: rem(126) 0 rem(90);

        .not-found-page {
          &__description {
            display: none;
          }

          &__bg-cloud {
            &:after {
              display: none;
            }
          }

          &__number {
            bottom: rem(-64);
            color: #fff;
          }
        }
      }
    }

    &--right {
      .not-found-page {
        &__description {
          display: none;
        }
      }

      @include responsiveMax(991px) {
        background: #3E3E53;
        padding: rem(86) rem(20) rem(30);

        .not-found-page {
          &__description {
            display: block;
          }

          &__bg-cloud {
            &:before {
              display: none;
            }
          }

          &__number {
            top: rem(-80);
            background: $main_gradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-feature-settings: normal;
          }
        }
      }
    }
  }

  &__number {
    display: none;

    @include responsiveMax(991px) {
      display: block;
      font-weight: 900;
      font-size: rem(144);
      line-height: rem(144);
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      left: 0;
      width: 100%;
      font-feature-settings: normal;
    }
  }

  &__title {
    position: relative;

    &-text {
      font-weight: 900;
      font-size: rem($d_h1_font_size);
      line-height: rem($d_h1_line_height);
      text-transform: uppercase;
      color: #fff;
    }

    &-bg {
      font-weight: 900;
      font-size: rem(96);
      line-height: rem(113);
      text-transform: uppercase;
      color: #fff;
      opacity: 0.03;
      position: absolute;
      top: 0;
      left: 0;
    }

    @include responsiveMax(992px) {
      &-text {
        font-size: rem($m_h1_font_size);
        line-height: rem($m_h1_line_height);
        text-align: center;
        width: rem(150);
        margin: 0 auto;
      }

      &-bg {
        font-size: rem(48);
        line-height: rem(56);
        text-align: center;
        width: 100%;
      }
    }
  }

  &__description {
    margin: rem(50) 0 0 rem(100);
    width: rem(385);

    &-text {
      font-size: rem(16);
      line-height: rem(24);
      color: #fff;
      display: inline;
    }

    &-link {
      display: inline;

      a {
        font-weight: bold;
        font-size: rem(18);
        line-height: rem(21);
        text-transform: uppercase;
        color: #fff;
        margin-left: rem(4);
        padding: 0 rem(10);
      }
    }

    @include responsiveMax(992px) {
      margin: 0;
      width: 100%;
      text-align: center;

      &-text {
        font-size: rem(14);
        line-height: rem(21);
        display: block;
        padding-bottom: rem(20);
      }

      &-link {
        display: block;

        a {
          font-size: rem(14);
          line-height: rem(16);
        }
      }
    }
  }


  .animation {
    display: none;

    @include responsiveMin(992px) {
      overflow: hidden;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
      display: block;

      &__front {
        left: 0;
        z-index: 2;
        width: 50%;

        .animation {

          &__content {
            background: $main_gradient;
          }
        }
      }

      &__back {
        right: 0;
        z-index: 1;

        .animation {

          &__content {
            background: #3E3E53;
          }

          &__image {

            span {
              display: block;
              background: $main_gradient;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-feature-settings: normal;
            }
          }
        }
      }

      &__content {
        position: relative;
        padding: 0;
        width: 100vw;
        height: 100%;
      }

      &__image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        width: rem(515);
        display: flex;
        align-items: center;
        transform-style: preserve-3d;
        perspective: rem(515);

        span {
          font-weight: 900;
          font-size: rem(288);
          line-height: rem(338);
          display: flex;
          align-items: center;
          text-transform: uppercase;
          color: #FFFFFF;
          font-feature-settings: normal;
        }
      }
    }
  }

  &__bg-gears {

    @include responsiveMin(992px) {
      position: absolute;
      z-index: -1;

      &--left {
        width: rem(350);
        height: rem(400);
        left: rem(200);
        bottom: 0;
        background: url("../../../images/gears-left-white.svg") no-repeat;
        background-size: contain;
        opacity: 0.04;
      }

      &--right {
        width: rem(300);
        height: rem(350);
        right: rem(300);
        top: rem(50);
        background: url("../../../images/gears-right-white.svg") no-repeat;
        background-size: contain;
        opacity: 0.02;
      }
    }
  }

  &__bg-cloud {
    display: none;

    @include responsiveMin(992px) {
      display: block;
      position: absolute;
      z-index: -1;

      &:before,
      &:after {
        position: absolute;
        z-index: -1;
        display: block;
        content: "";
      }

      &:before {
        width: rem(800);
        height: rem(200);
        background: url("../../../images/bg-el-1-white.svg") no-repeat;
        background-size: contain;
        opacity: 0.03;
      }

      &:after {
        width: rem(600);
        height: rem(500);
        background: url("../../../images/bg-el-2-white.svg") no-repeat;
        background-size: contain;
        opacity: 0.04;
      }

      &--top {
        top: 0;
        left: 0;
      }

      &--bottom {
        right: 0;
        bottom: 0;
        transform: rotate(180deg);
      }
    }
  }
}

/* END .not-found-page */