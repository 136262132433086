@import "../../../scss/vars";

@import "../../../scss/mixins";

.success-stories_short {
    .success-story__text-title {
        @include responsiveMin(992px) {
            margin-right: rem(100);
        }
    }

    .success-story__text-description {
        @include responsiveMin(992px) {
            margin-right: rem(30);
        }
    }

    .success-story__text-title--mobile {
        margin-bottom: rem(20);
    }

    .success-story__text-description-wrapper {
        position: relative;

        .success-story__text-description {
            max-height: rem(400);
            overflow-y: scroll;

            @include responsiveMin(1100px) {
                max-height: rem(450);
            }

            @include responsiveMin(1550px) {
                max-height: rem(500);
            }

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                border-radius: 4px;
            }
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 100px;
            bottom: 0;
            background: linear-gradient(to top, #fff, transparent);
            pointer-events: none;
            transition: opacity 0.3s ease;
            opacity: 1;
        }

        &.scrolled-to-end::after {
            opacity: 0;
        }
    }

    .success-story__text {
        padding: 0 rem(4) rem(80) rem(100);
        transform: none;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .view__wrapper--after {
        @include responsiveMax(991px) {
            position: relative;

            &::before {
                content: '';
                display: block;
                padding-top: (295 / 360) * 100%;
            }
        }

        @include responsiveMin(992px)  {
            width: 50%;
        }

        .view__content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 0 rem(20);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
            display: flex;
            align-items: center;
            justify-content: center;

            @include responsiveMin(992px) {
                padding: rem(100) rem(200);
                background-color: #3E3E53;
            }
        }
    }

    .mobile-first__img {
        .mobile-screen {
            @include responsiveMin(992px) {
                max-height: rem(900);
                position: relative;
            }

            &__moc-up {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
                pointer-events: none;
                img {
                    width: 100%;
                    height: auto;
                }
            }

            &__frame {
                margin: rem(-25) auto;
                overflow-y: scroll;
                border-radius: rem(20);
                background-color: #fff;

                @include responsiveMax(991px) {
                    &::-webkit-scrollbar {
                        display:  none;
                    }
                }

                &::-webkit-scrollbar {
                    width: rem(10);
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 rem(6) rgba(0, 0, 0, 0.3);
                    background: #FFF;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: darkgrey;
                    border-radius: rem(4);
                }

                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }

            &__img-wrap {
                display: inline-block;
                width: 100%;
            }
        }
    }

    .text {
        @include responsiveMin(992px) {
            max-width: rem(500);
        } 

        h3 {
            font-size: rem($m_h3_font_size);
            line-height: rem($m_h3_line_height);
            margin-top: rem(20);
            margin-bottom: rem(10);
    
            @include responsiveMin(992px) {
                font-size: rem($d_h3_font_size);
                line-height: rem($d_h3_line_height);
                margin-top: rem(50);
                margin-bottom: rem(20);
            }
        }
    }

    .success__services .items--left_side {
        @include responsiveMax(991px) {
            box-shadow: none;
            margin-top: rem(40);
        }
    }

    .item__services-link:first-child {
        margin-top: rem(30);

        @include responsiveMin(992px) {
            margin-top: rem(60);
        }
    }

    .second-level-title_text {
        text-transform: none;
    }

    .screen-page .swiper-container {
        min-height: 0;
    }

    .success__services .items {
        .item__statistics {
            flex-direction: column;
            grid-gap: rem(20) 0;

            @include responsiveMin(400px) {
                flex-direction: row;
                margin-right: 0;
            }
        }

        .item__statistics__wrapper {
            @include responsiveMin(400px) {
                flex: 1 1 0;
            }
        }
    }

    .screen-page__items {
        padding-top: 0;
        padding-bottom: 0;
    }

    .success__services {
        @include responsiveMin(992px) {
            padding-top: rem(150);
        }
    }

}

.success-stories-loader {
    height: 100vh;
}
