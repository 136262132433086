@import "../../../../scss/vars";
@import "../../../../scss/mixins";

/* START .docs */
.docs {

  &__header {
    background: $main_gradient;
    padding: rem(100) 0 rem(210) rem(200);

    @include responsiveMax(991px) {
      padding: rem(30) rem(20) rem(150);
      position: relative;
    }
  }

  &__title {
    position: relative;

    &-text {
      font-weight: 900;
      font-size: rem(48);
      line-height: rem(56);
      text-transform: uppercase;
      color: #FFFFFF;

    }

    @include responsiveMax(991px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: static;

      &-text {
        font-size: rem($m_h2_font_size);
        line-height: rem($m_h2_line_height);
        text-align: center;
      }
    }
  }

  &__bg {
    font-weight: 900;
    font-size: rem(96);
    line-height: rem(113);
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.03;
    position: absolute;
    top: 0;
    left: 0;

    @include responsiveMax(991px) {
      font-size: rem(48);
      line-height: rem(48);
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      br {
        display: none;
      }
    }

  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem(-240);

    @include responsiveMax(991px) {
      margin-top: rem(0);
      display: block;
    }

    &-col {
      flex: 0 0 50%;
      max-width: 50%;
      position: relative;
      z-index: 1;

      @include responsiveMax(991px) {
        max-width: 100%;
        text-align: left;
      }

      &--top-left {
        overflow: hidden;
        order: 1;
        padding-top: rem(240);

        @include responsiveMax(991px) {
          overflow: visible;
          padding: 0;
        }
      }

      &--top-right{
        padding: rem(150) rem(175) rem(305) rem(190);
        background: #3E3E53;
        order: 2;

        .link-wrapper {
          display: inline;
        }

        .base-btn {
          padding: 0 rem(10);
          color: #fff;
        }

        @include responsiveMax(991px) {
          padding: rem(30) rem(20) rem(124);

          .link-wrapper {
            display: inline;
          }

          .base-btn {
            font-size: rem(14);
            line-height: rem(16);
          }
        }
      }

      &--bottom-left{
        padding: rem(100) rem(150);
        background: #767994;
        z-index: 2;
        order: 3;

        @include responsiveMax(991px) {
          padding: rem(30) rem(20);
        }
      }

      &--bottom-right{
        order: 4;
      }
    }
  }

  &__left-parallax{
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: rem(80);
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-top: rem(50);

    img {
      max-width: 100%;
      max-height: 100%;
      display: inline-block;
    }

    @include responsiveMax(991px) {
      height: auto;
      position: relative;
      display: block;
      bottom: 0;
      padding: 0;

      img {
        display: block;
        width: rem(200);
        margin: rem(-115) auto 0;
      }
    }

    &-bg {
      display: none;

      @include responsiveMin(992px) {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }

  &__right-parallax {
    position: absolute;
    top: rem(-190);
    right: 0;
    width: 100%;
    padding-right: rem(100);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transform: translateX(100%);

    img {
      width: 100%;
    }

    @include responsiveMax(991px) {
      transform: none;
      position: relative;
      display: block;
      margin-top: rem(-100);
      top: 0;
      padding: 0 rem(30) rem(30) 0;
    }
  }

  &__right-bottom-parallax {
    width: 100%;
    text-align: right;

    img {
      width: 37%;
    }
  }

  &__right-top-parallax {
    width: 100%;
    height: 100%;
  }

  &__about-websiten,
  &__about-data-protection {
    font-size: rem(18);
    line-height: rem(27);
    color: #FFFFFF;

    p {
      margin-top: rem(15);

      &:first-child {
        margin-top: 0;
      }
    }

    @include responsiveMax(991px) {
      font-size: rem($m_text_font_size);
      line-height: rem($m_text_line_height);

      p {
        margin-top: rem(10);
      }
    }
  }

  &__text-title {
    font-weight: bold;
    font-size: rem(36);
    line-height: rem(42);
    color: #FFFFFF;

    @include responsiveMax(991px) {
      font-size: rem($m_h3_font_size);
      line-height: rem($m_h3_line_height);
      text-align: center;
    }
  }

}

/* END .docs */
